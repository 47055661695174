import { Title } from "@solidjs/meta";
import { BreadcrumbItem, BreadcrumbSymbol, H1, Segment, SegmentContent, Spinner } from '@biketravel/solid-ui';
import { onMount, Show } from "solid-js";
import { trackViewStore } from "@biketravel/state";
import { useCurrentRoute, useNavigate } from "@biketravel/solid-router";
import { AppBreadcrumbs, EventCreateForm } from "@biketravel/component";

export const EventCreatePage = () => {
    const { navigate } = useNavigate();
    const currentRoute = useCurrentRoute<{
        track_id: number,
    }>();

    onMount(async () => {
        await trackViewStore.actions.fetch(currentRoute().params.track_id)
    });

    return (
        <Show when={!trackViewStore.loading() && trackViewStore.track()} fallback={<Spinner/>}>
            <Title>Добавление поездки по маршруту {trackViewStore.track()?.name}</Title>
            <AppBreadcrumbs>
                <BreadcrumbItem to='track_list'>
                    Маршруты
                </BreadcrumbItem>
                <BreadcrumbSymbol/>
                <BreadcrumbItem to='track_view' params={{ track_id: trackViewStore.track()!.id }}>
                    {trackViewStore.track()!.name}
                </BreadcrumbItem>
                <BreadcrumbSymbol/>
                <BreadcrumbItem>
                    Добавление поездки по маршруту
                </BreadcrumbItem>
            </AppBreadcrumbs>
            <H1>Добавление поездки по маршруту {trackViewStore.track()!.name}</H1>
            <Segment>
                <SegmentContent>
                    <EventCreateForm
                        route={trackViewStore.track()!.track_route}
                        trackId={trackViewStore.track()!.id}
                        onSuccess={(event_id) => navigate('event_view', { event_id })}/>
                </SegmentContent>
            </Segment>
        </Show>
    );
}