import { Menu, MenuDivider, MenuItem, MenuTitle, Popover } from '@biketravel/solid-ui';
import { jwtStore, trackDraftUpdateStore, trackRemoveStore } from '@biketravel/state';
import type { Track } from '@biketravel/sdk';
import { Component, createSignal, Show } from "solid-js";
import { FiMoreVertical } from "solid-icons/fi";
import toast from "solid-toast";

type TrackMenuProps = {
  track: Pick<Track, "id" | "is_draft" | "user_id">;
  onRemove(): void;
}

export const TrackMenu: Component<TrackMenuProps> = (props) => {
  const [
    visible,
    setVisible,
  ] = createSignal(false);

  const [
    targetRef,
    setTargetRef,
  ] = createSignal<Element | undefined>();

  const deleteCallback = async (id: number) => {
    setVisible(false);
    if (!window.confirm('Вы действительно хотите удалить маршрут?')) {
      return;
    }

    await trackRemoveStore.actions.remove(id);
    toast.success('Маршрут удален');
    props.onRemove();
  }

  const onPublish = async () => {
    await trackDraftUpdateStore.actions.publish(props.track.id);
    toast.success('Маршрут опубликован');
  }

  const onUnpublish = async () => {
    await trackDraftUpdateStore.actions.unpublish(props.track.id);
    toast.success('Маршрут снят с публикации');
  }

  return (
    <Show when={jwtStore.actions.isObjectOwner(props.track.user_id)}>
      <button
        onclick={() => setVisible(!visible())}
        ref={setTargetRef}
        class='b-more-button'>
        <FiMoreVertical/>
      </button>
      <Popover
        targetRef={targetRef()}
        visible={visible}
        setVisible={setVisible}
      >
        <Menu>
          <MenuTitle title='Статус'/>
          <Show
            when={props.track.is_draft}
            fallback={(
              <MenuItem onClick={() => onUnpublish()}>
                Снять с публикации
              </MenuItem>
            )}
          >
            <MenuItem onClick={() => onPublish()}>
              Опубликовать
            </MenuItem>
          </Show>
          <MenuDivider/>
          <MenuTitle title='Редактирование'/>
          <MenuItem
            to='track_update'
            params={{ track_id: props.track.id }}>
            Изменить описание
          </MenuItem>
          <MenuItem
            to='track_marker_edit'
            params={{ track_id: props.track.id }}>
            Изменить метки
          </MenuItem>
          <MenuDivider/>
          <MenuItem
            to='track_edit'
            params={{ track_id: props.track.id }}>
            Изменить маршрут
          </MenuItem>
          <MenuDivider/>
          <MenuItem
            onClick={() => deleteCallback(props.track.id)}
            params={{ track_id: props.track.id }}>
            Удалить
          </MenuItem>
        </Menu>
      </Popover>
    </Show>
  );
}