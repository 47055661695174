import { DateTime } from "luxon";

export const findFirstFocusableElement = (el: HTMLElement): HTMLElement | undefined => {
    // Order may not be correct if children elements use tabindex values > 0.
    // Selectors derived from this SO question:
    // https://stackoverflow.com/questions/1599660/which-html-elements-can-receive-focus
    const focusableElements = el.querySelectorAll<HTMLElement>([
        '[data-focus-me]',
        'a[href]:not([tabindex="-1"])',
        'button:not([disabled]):not([tabindex="-1"])',
        'details:not([tabindex="-1"])',
        'input:not([disabled]):not([tabindex="-1"])',
        'select:not([disabled]):not([tabindex="-1"])',
        'textarea:not([disabled]):not([tabindex="-1"])',
        '[tabindex]:not([tabindex="-1"])',
    ].join(","));

    if (focusableElements.length === 0) {
        return;
    }

    return focusableElements.item(0);
}

export const focusFirstFocusableElement = (el: HTMLElement) => {
    const firstEl = findFirstFocusableElement(el);
    if (firstEl) {
        firstEl.focus({
            preventScroll: true,
        });
    }
}

export const stringToDateTime = (value: string) => DateTime.fromISO(value).toUTC();

// export const dateTimeToMediumDate = (dt: DateTime) => dt.toLocaleString(DateTime.DATETIME_MED);
export const dateTimeToMediumDate = (dt: DateTime) => dt.toLocaleString({ dateStyle: 'medium' });

export const dateTimeToMediumTime = (dt: DateTime) => dt.toLocaleString({ timeStyle: 'medium' });

export const dateTimeToShortTime = (dt: DateTime) => dt.toLocaleString({ timeStyle: 'short' });

export const dateTimeToRelative = (dt: DateTime) => dt.toRelative();

export const dateTimeToIso = (dt: DateTime) => {
    const value = dt.toISO();

    return value ? value : '';
}