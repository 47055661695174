import { Button, Form, FormRow, InputText, Label, Spinner, useForm, ValidationError } from "@biketravel/solid-ui";
import { restoreStore } from "@biketravel/state";
import type { RestoreRequest } from "@biketravel/sdk";
import { Component } from "solid-js";

type RestoreFormProps = {
  onSuccess(): void;
}

export const RestoreForm: Component<RestoreFormProps> = (props) => {
  const {
    errors,
    setErrors,
    values,
    onChange,
  } = useForm<RestoreRequest>({
    email: '',
  });

  const onSubmit = async () => {
    const formErrors = await restoreStore.actions.restore(values());
    if (formErrors) {
      setErrors(formErrors);
    } else {
      props.onSuccess();
    }
  };

  return (
    <Form onSubmit={() => onSubmit()}>
      <FormRow modifiers='margin-bottom'>
        <Label for="email" required>Адрес электронной почты</Label>
        <InputText
          type='text'
          id='email'
          name='email'
          value={values().email}
          onChange={({ value }) => onChange('email', value)}
        />
        <ValidationError path='email' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Button modifiers={['primary', 'block']} type="submit">
          Восстановить пароль
        </Button>
      </FormRow>
      <Spinner overlay show={restoreStore.loading()}/>
    </Form>
  );
}