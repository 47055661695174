import { Menu, MenuDivider, MenuItem, Popover } from '@biketravel/solid-ui';
import { commentRemoveStore, jwtStore } from '@biketravel/state';
import { Component, createSignal, Show } from "solid-js";
import { FiMoreVertical } from "solid-icons/fi";
import toast from "solid-toast";
import type { Comment } from '@biketravel/sdk';

type CommentMenuProps = {
  comment: Comment;
  onEdit(): void;
}

export const CommentMenu: Component<CommentMenuProps> = (props) => {
  const [
    visible,
    setVisible,
  ] = createSignal(false);

  const [
    targetRef,
    setTargetRef,
  ] = createSignal<Element | undefined>();

  const onRemove = async () => {
    setVisible(false);
    if (window.confirm('Вы действительно хотите удалить комментарий?')) {
      await commentRemoveStore.actions.remove(props.comment.id);
      toast.success('Комментарий удален');
    }
  };

  return (
    <Show when={jwtStore.actions.isObjectOwner(props.comment.author_id)}>
      <button
        class='b-more-button'
        ref={setTargetRef}
        onclick={() => setVisible(!visible())}>
        <FiMoreVertical/>
      </button>
      <Popover
        targetRef={targetRef()}
        visible={visible}
        setVisible={setVisible}
      >
        <Menu>
          <MenuItem onClick={() => props.onEdit()}>
            Изменить
          </MenuItem>
          <MenuDivider/>
          <MenuItem onClick={() => onRemove()}>
            Удалить
          </MenuItem>
        </Menu>
      </Popover>
    </Show>
  );
}