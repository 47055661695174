import { For, Show } from "solid-js";
import { eventListStore } from "@biketravel/state";
import { EventListItem } from "./EventListItem";
import { InfinityScroll, Spinner, SpinnerGlobal } from "@biketravel/solid-ui";
import { EventEmpty } from "./EventEmpty";

export const EventList = () => {
  const onVisible = () => {
    const data = eventListStore.events();
    if (!eventListStore.loading() && data?.cursor?.has_more) {
      eventListStore.actions.fetch(eventListStore.filter(), data.cursor.cursor, true);
    }
  };

  const isEmpty = () => !eventListStore.loading() && (eventListStore.events()?.edge || []).length === 0;

  return (
    <InfinityScroll onVisible={onVisible}>
      <Show when={isEmpty()}>
        <EventEmpty/>
      </Show>
      <For each={eventListStore.events().edge}>
        {(event) => (
          <EventListItem event={event}/>
        )}
      </For>
      <Spinner show={eventListStore.loading()} overlay={true}/>
      <SpinnerGlobal show={eventListStore.loading()}/>
    </InfinityScroll>
  )
}