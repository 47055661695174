import { useForm } from "@biketravel/solid-ui";
import { eventCreateStore } from "@biketravel/state";
import { Component } from "solid-js";
import { EventForm, EventFormType } from "./EventForm";
import type { TrackRoute } from "@biketravel/sdk";

type EventCreateFormProps = {
  route: TrackRoute;
  trackId: number;
  onSuccess(eventId: number): void;
}

export const EventCreateForm: Component<EventCreateFormProps> = (props) => {
  const {
    errors,
    setErrors,
    values,
    onChange,
  } = useForm<EventFormType>({
    description: '',
    start_lng: '',
    start_lat: '',
    start_at: '',
    name: ''
  });

  const onSubmit = async () => {
    const resp = await eventCreateStore.actions.create({
      name: values().name,
      description: values().description,
      start_at: values().start_at,
      start_lat: values().start_lat ? parseFloat(values().start_lat) : 0,
      start_lng: values().start_lng ? parseFloat(values().start_lng) : 0,
      track_id: props.trackId,
    });
    if (resp?.id) {
      props.onSuccess(resp.id);
    } else {
      setErrors(resp);
    }
  };

  return (
    <EventForm
      route={props.route}
      onChange={onChange}
      loading={eventCreateStore.loading}
      onSubmit={onSubmit}
      values={values}
      errors={errors}
    />
  );
}