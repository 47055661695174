import { batch, createSignal } from "solid-js";
import { AppStore, captureError, ExportableStore, LoadingType, sdk } from "..";
import { AxiosError } from "axios";

type CoordinatesType = {
  lng: number;
  lat: number;
  name?: string;
};

export type LocationState = {
  coordinates: CoordinatesType | undefined
};

export type LocationClientState = LoadingType & LocationState;

const [
  loading,
  setLoading,
] = createSignal<LocationClientState["loading"]>(false);

const [
  coordinates,
  setCoordinates,
] = createSignal<LocationClientState["coordinates"]>();

const detectPromise = (): Promise<LocationClientState["coordinates"]> => {
  return new Promise((resolve, reject) => {
    const successCallback: PositionCallback = (position) => {
      batch(() => {
        setLoading(false);
        setCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        resolve(coordinates());
      })
    };

    const errorCallback: PositionErrorCallback = (error) => {
      setLoading(false);
      console.error(error.message);
      reject();
    };

    if (typeof navigator !== 'undefined') {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }
  });
}

const detect = () => {
  const successCallback: PositionCallback = (position) => {
    batch(() => {
      setLoading(false);
      setCoordinates({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    })
  };

  const errorCallback: PositionErrorCallback = (error) => {
    setLoading(false);
    console.error(error.message);
  };

  if (typeof navigator !== 'undefined') {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }
}

const detectByIp = async (ip: string) => {
  setLoading(true);

  try {
    const resp = await sdk.location.detect(ip);

    setCoordinates(resp.data ? {
      lat: resp.data?.lat,
      lng: resp.data?.lng,
      name: resp.data?.city,
    } : undefined);
  } catch (error: AxiosError | unknown) {
    setLoading(false);

    if (error instanceof AxiosError) {
      return error.response?.data.errors;
    }

    captureError(error);
  }
}

type LocationActions = {
  detect: typeof detect,
  detectPromise: typeof detectPromise,
  detectByIp: typeof detectByIp,
}

export const locationStore = {
  coordinates,
  loading,
  actions: {
    detect,
    detectPromise,
    detectByIp,
  },
  exportState() {
    return {
      coordinates: coordinates(),
    };
  },
  importState(value) {
    setCoordinates(value.coordinates);
  }
} satisfies AppStore<LocationClientState, LocationActions> & ExportableStore<LocationState>;