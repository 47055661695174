import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";

export type MarkerRemoveState = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<MarkerRemoveState["loading"]>(false);

const remove = async (id: number) => withLoading(setLoading, async () => {
  await sdk.marker.markerRemove(id);
});

type MarkerRemoveActions = {
  remove: typeof remove,
}

export const markerRemoveStore = {
  loading,
  actions: {
    remove,
  }
} satisfies AppStore<MarkerRemoveState, MarkerRemoveActions>;