export const HomepageVideo = () => (
  <div class="b-homepage-video">
    <div class="b-homepage-video__content">
      <h1 class="b-homepage-video__title">Откройте для себя мир вело-туризма и спорта!</h1>
      <p class="b-homepage-video__text">
        Если вы увлечены велосипедом, то вы знаете, что это не просто средство передвижения, а образ
        жизни. Велосипед позволяет вам ощутить свободу и природу, насладиться скоростью и адреналином. Наш сайт
        создан для тех, кто разделяет эту страсть.
      </p>
    </div>
  </div>
);
