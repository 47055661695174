import { Route } from "@biketravel/solid-router";
import { NotFoundPage } from "../page";
import { DefaultLayout } from "../Layout";
import { RouteProps } from "../renderer";

export const systemRoutes: Route<RouteProps>[] = [
    {
        path: "(.*)",
        name: "404",
        component: NotFoundPage,
        props: {
            layout: DefaultLayout,
        }
    },
];