import { BaseControl } from "./BaseControl";
import type { StyleSpecification } from "maplibre-gl";

export type BaseLayer = {
  style: StyleSpecification | string
  isDefault?: boolean
  name: string
  id: string
}

export type StyleSwitcherControlOptions = {
  basemaps: BaseLayer[]
  initial: string
};

export class StyleSwitcherControl extends BaseControl {
  private _options: StyleSwitcherControlOptions;

  constructor(options: StyleSwitcherControlOptions) {
    super();
    this._options = { ...options };
  }

  protected createButtonImage(layer: BaseLayer, onClick: (e: Event) => void) {
    const button = document.createElement('button');
    button.title = layer.name;
    button.ariaLabel = layer.name;
    button.onclick = onClick;
    button.innerText = layer.name;
    button.dataset.id = layer.id;
    button.classList.add("maplibre-ctrl-styles-list-button");

    if (this._options.initial === layer.id) {
      button.classList.add("maplibre-ctrl-styles-list-button--active");
    }

    return button;
  }

  createContent(): HTMLElement {
    const content = document.createElement('div');

    const button = document.createElement('button');
    button.type = "button";
    button.title = 'Карты';
    button.ariaLabel = 'Карты';
    button.classList.add('maplibregl-ctrl-terrain');
    content.appendChild(button);

    const span = document.createElement('span');
    span.classList.add('maplibregl-ctrl-icon');
    span.ariaHidden = 'true';
    button.appendChild(span);

    button.addEventListener('mouseenter', () => {
      button.classList.add('maplibregl-ctrl-terrain--active');
      div.classList.add('maplibre-ctrl-styles-list--active');
    });

    const div = document.createElement('div');
    div.classList.add('maplibre-ctrl-styles-list');
    div.addEventListener('mouseleave', () => {
      button.classList.remove('maplibregl-ctrl-terrain--active');
      div.classList.remove('maplibre-ctrl-styles-list--active');
    });

    for (let i = 0; i < this._options.basemaps.length; i++) {
      const base = this._options.basemaps[i];
      const button = this.createButtonImage(base, (e: Event) => {
        e.preventDefault();

        const activeElement = content.querySelector(".maplibre-ctrl-styles-list-button--active");
        if (!activeElement) {
          return;
        }

        activeElement.classList.remove("maplibre-ctrl-styles-list-button--active");
        button.classList.add("maplibre-ctrl-styles-list-button--active");
        this._map!.setStyle(base.style, { diff: false });
      });
      div.appendChild(button);
    }
    content.appendChild(div);

    return content;
  }
}
