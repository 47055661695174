import { eventIsMemberStore, eventJoinLeaveStore } from "@biketravel/state";
import { Component, createSignal, onMount, Show } from "solid-js";
import { Button, Spinner } from "@biketravel/solid-ui";
import { toast } from "solid-toast";

type EventJoinButton = {
  event_id: number;
}

export const EventJoinButton: Component<EventJoinButton> = (props) => {
  onMount(async () => {
    await eventIsMemberStore.actions.fetch(props.event_id);
  });

  const [
    subscribe,
    setSubscribe,
  ] = createSignal(true);

  const joinCallback = async () => {
    const errors = await eventJoinLeaveStore.actions.join(props.event_id, subscribe());
    if (errors) {
      toast.error(Object.values(errors).join("\n"));
    } else {
      toast.success('Вы зарегистрировались как участник поездки');
    }
  };

  const leaveCallback = async () => {
    if (!window.confirm('Вы уверены, что хотите отказаться от участия?')) {
      return;
    }

    const errors = await eventJoinLeaveStore.actions.leave(props.event_id);
    if (errors) {
      toast.error(Object.values(errors).join("\n"));
    } else {
      toast.success('Вы перестали быть участником поездки');
    }
  };

  return (
    <div>
      <Show when={eventIsMemberStore.isMember()} fallback={(
        <div class='b-event-view__join'>
          <Button modifiers='primary' onClick={() => joinCallback()}>
            Принять участие
          </Button>
          <div>
            <input
              class='b-input b-input--checkbox'
              id='subscribe'
              type='checkbox'
              value='1'
              checked={subscribe()}
              onChange={() => setSubscribe(!subscribe)}/>
            <label for="subscribe" class='b-label'>Подписаться на
              уведомления</label>
          </div>
        </div>
      )}>
        <Button modifiers='default' onClick={() => leaveCallback()}>
          Отказаться от участия
        </Button>
      </Show>
      <Spinner
        overlay={true}
        show={eventJoinLeaveStore.loading()}
      />
    </div>
  );
}