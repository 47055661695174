import { Component, createSignal, Show } from "solid-js";
import type { TrackList } from '@biketravel/sdk';
import { Link } from "@biketravel/solid-router";
import { Attention, ButtonLink, Image, Segment, SegmentContent, SegmentFooter, Typeset } from "@biketravel/solid-ui";
import { TrackSummary } from "./TrackSummary";
import { TrackListItemPhotoList } from "./TrackListItemPhotoList";
import { trackGpx } from "./gpx";
import { TrackMenu } from "./TrackMenu";
import { trackName } from "./utils";

export type TrackListItemType = {
  track: TrackList["edge"][number]
}

export const TrackHeaderTitleLink: Component<TrackListItemType> = (props) => (
  <Link
    class='b-track-list-item__name'
    to="track_view"
    params={{ track_id: props.track.id }}
  >
    {trackName(props.track)}
  </Link>
)

export const TrackListItem: Component<TrackListItemType> = (props) => {
  const [
    removed,
    setRemoved,
  ] = createSignal(false);

  return (
    <Show when={!removed()} fallback={(
      <Attention>Маршрут удален</Attention>
    )}>
      <Segment class="b-track-list-item">
        <Link to="track_view" params={{ track_id: props.track.id }}>
          <Image
            class='b-map-static-image'
            src={props.track.static_map}
            alt={trackName(props.track)}
          />
        </Link>
        <SegmentContent>
          <TrackHeaderTitleLink track={props.track}/>
          <Typeset content={props.track.description ?? 'Описание маршрута отсутствует'}/>
          <TrackSummary track={props.track}/>
          <Show when={props.track.photo_list.length > 0}>
            <TrackListItemPhotoList
              alt={trackName(props.track)}
              photos={props.track.photo_list}
            />
          </Show>
        </SegmentContent>
        <SegmentFooter>
          <div class='b-segment__footer-buttons'>
            <ButtonLink
              modifiers="primary"
              to="track_view"
              params={{ track_id: props.track.id }}
            >
              Подробнее
            </ButtonLink>
            <ButtonLink
              modifiers='default'
              href={trackGpx(props.track.id)}
            >
              Скачать GPX
            </ButtonLink>
          </div>
          <TrackMenu
            onRemove={() => setRemoved(true)}
            track={props.track}
          />
        </SegmentFooter>
      </Segment>
    </Show>
  )
}