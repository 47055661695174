import { bem } from "classnames-bem";
import { Component, ParentProps } from "solid-js";
import { Link, LinkParamsProps } from "@biketravel/solid-router";

export type BreadcrumbWithIconType = {
    icon?: any
};
export type BreadcrumbItemType = Partial<LinkParamsProps> & BreadcrumbWithIconType;

export const BreadcrumbItem: Component<ParentProps<BreadcrumbItemType>> = ({
    to,
    params,
    query,
    icon,
    children,
}) => (
    <div class='b-breadcrumb__item'>
        {to ? (
            <BreadcrumbLink to={to} params={params} query={query} icon={icon}>
                {children}
            </BreadcrumbLink>
        ) : (
            <BreadcrumbText icon={icon}>
                {children}
            </BreadcrumbText>
        )}
    </div>
)

export const BreadcrumbLink: Component<ParentProps<LinkParamsProps & BreadcrumbWithIconType>> = ({
    to,
    params,
    query,
    children,
    icon,
}) => (
    <Link
        to={to}
        params={params}
        query={query}
        class={bem('b-breadcrumb__link', { icon: !!icon })}>
        {icon && (
            <BreadcrumbIcon>{icon}</BreadcrumbIcon>
        )}
        {children}
    </Link>
);

export const BreadcrumbText: Component<ParentProps & BreadcrumbWithIconType> = ({
    children,
    icon,
}) => (
    <span class={bem('b-breadcrumb__text', { icon: !!icon })}>
        {icon && (
            <BreadcrumbIcon>{icon}</BreadcrumbIcon>
        )}
        {children}
    </span>
);

export const BreadcrumbIcon: Component<ParentProps> = ({
    children
}) => (
    <span class='b-breadcrumb__icon'>
        {children}
    </span>
);

export const BreadcrumbSymbol: Component = () => (
    <span class='b-breadcrumb__symbol'>
        &rarr;
    </span>
);

export const Breadcrumbs: Component<ParentProps> = ({
    children
}) => (
    <div class='b-breadcrumb'>
        {children}
    </div>
);