import { Component } from "solid-js";
import { commentUpdateStore } from "@biketravel/state";
import type { Comment } from "@biketravel/sdk";
import { CommentForm } from "./CommentForm";
import { useForm } from "@biketravel/solid-ui";
import { CommentFormInput } from "./types";

type CommentUpdateFormProps = {
  comment: Comment;
  onSuccess(): void;
}

export const CommentUpdateForm: Component<CommentUpdateFormProps> = (props) => {
  const initial: CommentFormInput = {
    comment: props.comment.comment,
  }

  const {
    errors,
    setErrors,
    values,
    setValues,
    onChange,
  } = useForm<CommentFormInput>(initial);

  const onSubmit = async () => {
    const errors = await commentUpdateStore.actions.update(
      props.comment.id,
      values(),
    );
    if (errors) {
      setErrors(errors);
    } else {
      setErrors([]);
      setValues(initial);
      props.onSuccess();
    }
  };

  return (
    <CommentForm
      autofocus={true}
      onChange={onChange}
      loading={commentUpdateStore.loading}
      onSubmit={onSubmit}
      values={values}
      errors={errors}
    />
  );
}