import { TrackPhotoUpload } from "./TrackPhotoUpload";
import { TrackPhotoList } from "./TrackPhotoList";
import { Component, onMount, Show } from "solid-js";
import { jwtStore, trackPhotoListStore } from "@biketravel/state";
import { Spinner } from "@biketravel/solid-ui";
import type { Track } from "@biketravel/sdk";

export type TrackPhotoListComponentProps = {
  track: Track
}

export const TrackPhotoListComponent: Component<TrackPhotoListComponentProps> = (props) => {
  const onComplete = async () => {
    await trackPhotoListStore.actions.fetch(props.track.id, trackPhotoListStore.photos().cursor?.cursor, true)
  }

  onMount(async () => {
    await trackPhotoListStore.actions.fetch(props.track.id, 0)
  });

  const onVisible = async () => {
    const data = trackPhotoListStore.photos();
    if (!trackPhotoListStore.loading() && data?.cursor?.has_more) {
      await trackPhotoListStore.actions.fetch(props.track.id, data.cursor.cursor, true);
    }
  }

  return (
    <>
      <Show when={jwtStore.actions.isObjectOwner(props.track.user_id)}>
        <TrackPhotoUpload
          trackId={props.track.id}
          onComplete={onComplete}/>
      </Show>
      <Show when={!trackPhotoListStore.loading()} fallback={<Spinner/>}>
        <TrackPhotoList
          alt={props.track.name}
          onVisible={onVisible}
          photos={trackPhotoListStore.photos().edge || []}
        />
      </Show>
    </>
  );
};
