import type { GeoJSONFeature, Map as MapType, MapMouseEvent, MapTouchEvent } from "maplibre-gl";
import { Component, createSignal, Show } from "solid-js";
import { createEmptyRouteState, createStateFromRoute, RouteLayer, UndoRedoControl } from "@biketravel/map";
import { MapPopupMenu } from "./MapPopupMenu";
import { useMapEffect } from "./Map";
import type { TrackRoute } from "@biketravel/sdk";
import { Menu, MenuDivider, MenuItem } from "@biketravel/solid-ui";
import { routeStore } from "@biketravel/state";

export type ClickTargetType = 'place-places-symbol' | 'cluster' | 'cluster-count'

export type ContextMenuClickType = {
  feature?: GeoJSONFeature
  id?: number
  type?: ClickTargetType
  firstOrLast?: boolean
  waypointIndex?: number
  segmentIndex?: number
  hasWaypoints?: boolean
  menuType: 'map' | 'waypoint' | 'line'
  x: number
  y: number
  lng: number
  lat: number
}

export type onRouteUpdated = (route: TrackRoute) => Promise<void> | void;

type MapRouteType = {
  autoFitBounds?: boolean;
  editable?: boolean;
  currentRoute?: TrackRoute;
  onRouteUpdated?: onRouteUpdated;
}

export const MapRoute: Component<MapRouteType> = (props) => {
  const [popupContext, setPopupContext] = createSignal<ContextMenuClickType | undefined>(undefined);
  const [routeLayer, setRouteLayer] = createSignal<RouteLayer | undefined>(undefined);

  const onContextMenuCallback = (e: MapMouseEvent | MapTouchEvent, hasWaypoints: boolean) => {
    const { point, lngLat } = e;

    setPopupContext({
      lng: lngLat.lng,
      lat: lngLat.lat,
      x: point.x,
      y: point.y,
      hasWaypoints,
      menuType: 'map',
    });
  };

  const onWaypointContextMenuCallback = (e: MapMouseEvent | MapTouchEvent, index: number, firstOrLast: boolean) => {
    const { point, lngLat } = e;

    setPopupContext({
      lng: lngLat.lng,
      lat: lngLat.lat,
      x: point.x,
      y: point.y,
      waypointIndex: index,
      firstOrLast,
      menuType: 'waypoint',
    });
  };

  const onLineContextMenuCallback = (e: MapMouseEvent | MapTouchEvent, index: number) => {
    const { point, lngLat } = e;

    setPopupContext({
      lng: lngLat.lng,
      lat: lngLat.lat,
      x: point.x,
      y: point.y,
      segmentIndex: index,
      menuType: 'line',
    });
  };

  const initialState = props.currentRoute
    ? createStateFromRoute(props.currentRoute, true)
    : createEmptyRouteState();

  let undoControl: UndoRedoControl | undefined;
  useMapEffect((map: MapType) => {
    map.on('click', () => setPopupContext(undefined));

    const instance = new RouteLayer(map, {
      autoFitBounds: props.autoFitBounds,
      editable: props.editable,
      onRouteUpdated: props.onRouteUpdated,
      onContextMenu: onContextMenuCallback,
      onWaypointContextMenu: onWaypointContextMenuCallback,
      onLineContextMenu: onLineContextMenuCallback,
      route: routeStore.actions.route,
    }, initialState);

    if (props.editable) {
      if (!undoControl) {
        undoControl = new UndoRedoControl({
          onUndo() {
            instance.undoState();
          },
          onRedo() {
            instance.redoState();
          },
        });
      }

      if (!map.hasControl(undoControl)) {
        map.addControl(undoControl, 'top-left');
      }
    }

    setRouteLayer(instance);
  });

  return (
    <Show when={!!popupContext()}>
      <MapPopupMenu x={popupContext()?.x} y={popupContext()?.y}>
        <Menu>
          <Show when={popupContext()?.menuType === 'map' && !popupContext()?.type}>
            <Show when={popupContext()?.hasWaypoints}>
              <MenuItem
                onClick={async () => {
                  const popup = popupContext();
                  const route = routeLayer();
                  if (!popup || !route) {
                    return;
                  }

                  await route.addWaypoint([popup.lng, popup.lat], true);
                  setPopupContext(undefined);
                }}>
                Добавить маршрутную точку
              </MenuItem>
              <MenuDivider/>
              <MenuItem
                onClick={async () => {
                  const popup = popupContext();
                  const route = routeLayer();
                  if (!popup || !route) {
                    return;
                  }

                  await route.addWaypoint([popup.lng, popup.lat], false);
                  setPopupContext(undefined);
                }}>
                Добавить без привязки к дорогам
              </MenuItem>
            </Show>
            <Show when={!popupContext()?.hasWaypoints}>
              <MenuItem
                onClick={async () => {
                  const popup = popupContext();
                  const route = routeLayer();
                  if (!popup || !route) {
                    return;
                  }

                  await route.addWaypoint([popup.lng, popup.lat], true);
                  setPopupContext(undefined);
                }}>
                Добавить стартовую точку
              </MenuItem>
            </Show>
          </Show>
          <Show when={popupContext()?.menuType === 'line'}>
            <MenuItem
              onClick={() => {
                const popup = popupContext();
                const route = routeLayer();
                if (!popup || !route) {
                  return;
                }

                if (typeof popup.segmentIndex === "undefined") {
                  return;
                }

                routeLayer()?.segmentRoutable(popup.segmentIndex);
                setPopupContext(undefined);
              }}>
              Изменить тип построения маршрута
            </MenuItem>
          </Show>
          <Show when={popupContext()?.menuType === 'waypoint'}>
            <Show when={popupContext()?.firstOrLast}>
              <MenuItem
                onClick={async () => {
                  const popup = popupContext();
                  const route = routeLayer();
                  if (!popup || !route) {
                    return;
                  }

                  if (typeof popup.waypointIndex == "undefined") {
                    return;
                  }

                  await route.waypointRemove(popup.waypointIndex, false)
                  setPopupContext(undefined);
                }}>
                Удалить
              </MenuItem>
            </Show>
            <Show when={!popupContext()?.firstOrLast}>
              <MenuItem
                onClick={async () => {
                  const popup = popupContext();
                  const route = routeLayer();
                  if (!popup || !route) {
                    return;
                  }

                  if (typeof popup.waypointIndex == "undefined") {
                    return;
                  }

                  await route.waypointRemove(popup.waypointIndex, true)
                  setPopupContext(undefined);
                }}>
                Удалить и перестроить маршрут
              </MenuItem>
              <MenuDivider/>
              <MenuItem
                onClick={async () => {
                  const popup = popupContext();
                  const route = routeLayer();
                  if (!popup || !route) {
                    return;
                  }

                  if (typeof popup.waypointIndex == "undefined") {
                    return;
                  }

                  await route.waypointRemove(popup.waypointIndex, false)
                  setPopupContext(undefined);
                }}>
                Удалить без построения маршрута
              </MenuItem>
            </Show>
          </Show>
        </Menu>
      </MapPopupMenu>
    </Show>
  );
}