import { trackUploadStore } from "@biketravel/state";
import { Button, Spinner } from "@biketravel/solid-ui";
import { Component, Show } from "solid-js";
import { createFileUploader, UploadFile } from "@solid-primitives/upload";

type TrackUploadProps = {
  onSuccess(id: number): void;
}

export const TrackUpload: Component<TrackUploadProps> = (props) => {
  const {
    selectFiles,
  } = createFileUploader({
    multiple: false,
    accept: ".gpx",
  });

  const upload = async (files: UploadFile[]) => {
    if (files.length === 0) {
      return;
    }

    const first = files.shift() as UploadFile;
    const data = await trackUploadStore.actions.upload(first.file);

    if (data?.id) {
      props.onSuccess(data.id);
    } else if (data) {
      console.log(data);
      return
    }
  }

  return (
    <Show when={!trackUploadStore.loading()} fallback={<Spinner show={true}/>}>
      <Button
        modifiers='primary'
        onClick={() => selectFiles(upload)}>
        Выбрать файл
      </Button>
    </Show>
  );
}