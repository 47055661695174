import { BaseControl } from "./BaseControl";
import type { LayerSpecification, Map, RasterSourceSpecification } from "maplibre-gl";

export type StravaHeatmapOptions = {
  tiles: string[]
}

export class StravaHeatmapControl extends BaseControl {
  private options: StravaHeatmapOptions;
  private id = 'strava_heatmap';
  private enabled = false;
  private source: RasterSourceSpecification = {
    type: "raster",
    tileSize: 256,
  };

  private layer: LayerSpecification = {
    id: this.id,
    type: "raster",
    source: this.id,
    minzoom: 0,
    maxzoom: 20,
  };

  constructor(options: StravaHeatmapOptions) {
    super();
    this.options = options;
  }

  firstFirstLayerId(): string | undefined {
    const layers = this._map!.getStyle().layers;
    for (let i = 0; i < layers.length; i++) {
      // outdoor map fix
      if (layers[i].id.indexOf('contour') > -1) {
        continue;
      }

      // komoot map fix
      if (
        layers[i].id.indexOf('elevation') > -1 ||
        layers[i].id.indexOf('country') > -1
      ) {
        continue;
      }

      if (layers[i].id.indexOf('label') > -1) {
        return layers[i].id;
      }
    }

    return;
  }

  createContent() {
    const container = document.createElement('div');
    const button = this.createButton('heatmap', 'Heatmap', (e: Event) => {
      e.preventDefault();
      if (this.enabled) {
        this.disable();
        button.classList.remove('maplibregl-ctrl-heatmap--active');
        button.classList.remove('mapboxgl-ctrl-heatmap--active');
        this.enabled = false;
      } else {
        this.enable();
        button.classList.add('maplibregl-ctrl-heatmap--active');
        button.classList.add('mapboxgl-ctrl-heatmap--active');
        this.enabled = true;
      }
    });
    container.appendChild(button);

    return container;
  }

  onAdd(map: Map): HTMLElement {
    map.on('style.load', () => {
      if (this.enabled) {
        this.enable();
      }
    });
    return super.onAdd(map);
  }

  enable() {
    this._map?.addSource(this.id, {
      ...this.source,
      tiles: this.options.tiles,
    });
    this._map?.addLayer(
      this.layer,
      this.firstFirstLayerId(),
    );
  }

  disable() {
    this._map?.removeLayer(this.layer.id);
    this._map?.removeSource(this.id);
  }
}
