import { BreadcrumbItem, BreadcrumbSymbol, Spinner } from '@biketravel/solid-ui';
import { onMount, Show } from "solid-js";
import { Meta, Title } from "@solidjs/meta";
import { useCurrentRoute } from '@biketravel/solid-router';
import { AppBreadcrumbs, TrackViewMap } from "@biketravel/component";
import { trackViewStore } from "@biketravel/state";

import cls from './MarkerListPage.module.scss';

export const MarkerListPage = () => {
    const currentRoute = useCurrentRoute<{
        track_id: number
    }>();

    onMount(async () => {
        await trackViewStore.actions.fetch(currentRoute().params.track_id);
    });

    return (
        <>
            <Meta>
                <Title>Редактирование меток</Title>
            </Meta>
            <Show
                when={!trackViewStore.loading() && trackViewStore.track()}
                fallback={<Spinner show={true}/>}>
                <div class={cls.header}>
                    <div></div>
                    <div>
                        <AppBreadcrumbs>
                            <BreadcrumbItem to='track_list'>
                                Маршруты
                            </BreadcrumbItem>
                            <BreadcrumbSymbol/>
                            <BreadcrumbItem to="track_view" params={{ track_id: trackViewStore.track()!.id }}>
                                {trackViewStore.track()!.name || `Черновик маршрута #${trackViewStore.track()!.id}`}
                            </BreadcrumbItem>
                            <BreadcrumbSymbol/>
                            <BreadcrumbItem>
                                Метки на карте
                            </BreadcrumbItem>
                        </AppBreadcrumbs>
                    </div>
                    <div></div>
                </div>
                <div class={cls.container}>
                    <TrackViewMap
                        scrollZoom={true}
                        track={trackViewStore.track()!}
                    />
                </div>
            </Show>
        </>
    );
};
