export const getEnv = (key: string): string | undefined => {
  const processEnv = typeof process === 'undefined'
    ? {}
    : process.env;

  if (typeof window === 'undefined') {
    return (
      // if server side
      processEnv[key] ||
      // if vite
      processEnv[`VITE_${key}`] ||
      // if astro
      // @ts-ignore
      import.meta.env[`PUBLIC_${key}`]
    );
  }

  if (typeof window.__CONFIG__ !== 'undefined') {
    // @ts-ignore
    return window.__CONFIG__[key];
  }

  // if solidjs started in client-only mode
  return (
    // astro support
    // @ts-ignore
    import.meta.env[`PUBLIC_${key}`] ||
    // if server side
    processEnv[key] ||
    // if vite
    processEnv[`VITE_${key}`]
  )
};
