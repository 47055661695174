import { createSignal } from "solid-js";
import { AppStore, ExportableStore, LoadingType, sdk } from "..";
import { Event, EventUpdate } from "@biketravel/sdk";
import { withLoading } from "../decorator";

export type EventViewState = {
  event: Event | undefined;
}

export type EventViewClientState = EventViewState & LoadingType;

const initialState: EventViewClientState = {
  event: undefined,
  loading: undefined,
}

const [
  event,
  setEvent,
] = createSignal<EventViewState['event']>(initialState.event);

const [
  loading,
  setLoading,
] = createSignal<EventViewClientState['loading']>(initialState.loading);

const fetch = async (id: number) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.event.eventView(id);

  setEvent(data);
});

const update = (id: number, newEvent: Required<EventUpdate>) => {
  const currentEvent = event();
  if (!currentEvent) {
    return;
  }

  if (currentEvent.id !== id) {
    return;
  }

  setEvent({
    ...currentEvent,
    name: newEvent.name,
    description: newEvent.description,
    startAt: newEvent.start_at,
    startLat: newEvent.start_lat,
    startLng: newEvent.start_lng,
  });
};

export type EventViewActionsType = {
  setEvent: typeof setEvent,
  fetch: typeof fetch,
  update: typeof update,
}

export const eventViewStore = {
  event,
  loading,
  actions: {
    fetch,
    setEvent,
    update,
  },
  exportState() {
    return {
      event: event(),
    }
  },
  importState(value) {
    setEvent(value.event);
  }
} satisfies AppStore<EventViewClientState, EventViewActionsType> & ExportableStore<EventViewState>;