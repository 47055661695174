import type { BlogPost } from '@biketravel/sdk';
import { createSignal } from "solid-js";
import { AppStore, ExportableStore } from "..";
import { LoadingType } from "@biketravel/state";
import { sdk } from '../store';
import { withLoading } from "../decorator";

export type BlogPostState = {
  blogPost: BlogPost | undefined;
}

export type BlogPostClientState = BlogPostState & LoadingType;

const initialState: BlogPostClientState = {
  blogPost: undefined,
  loading: undefined,
}

const [
  blogPost,
  setBlogPost,
] = createSignal<BlogPostClientState['blogPost']>(initialState.blogPost);

const [
  loading,
  setLoading,
] = createSignal<BlogPostClientState['loading']>(initialState.loading);

const fetch = async (id: number) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.blog.blogView(id);

  setBlogPost(data.entity);
});

const fetchIfNotExists = async (id: number) => withLoading(setLoading, async () => {
  if (blogPost()?.id === id) {
    return;
  }

  await fetch(id);
});

export type BlogPostActions = {
  fetch: typeof fetch;
  fetchIfNotExists: typeof fetchIfNotExists;
  setBlogPost: typeof setBlogPost;
}

export const blogPostStore: AppStore<BlogPostClientState, BlogPostActions> & ExportableStore<BlogPostState> = {
  blogPost,
  loading,
  actions: {
    fetch,
    fetchIfNotExists,
    setBlogPost,
  },
  exportState() {
    return {
      blogPost: blogPost(),
    }
  },
  importState(value) {
    setBlogPost(value.blogPost);
  }
};
