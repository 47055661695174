import { createSignal, ParentComponent, Show } from "solid-js";

type CollapseProps = {
    open?: boolean;
    title: string;
}

export const Collapse: ParentComponent<CollapseProps> = (props) => {
    const [
        opened,
        setOpened
    ] = createSignal(props.open);

    return (
        <div class="b-collapse">
            <div class="b-collapse__title" onclick={() => setOpened(!opened())}>
                {props.title}
            </div>
            <Show when={opened()}>
                {props.children}
            </Show>
        </div>
    );
}