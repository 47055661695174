import { useNavigate } from "@biketravel/solid-router";
import { Component, For } from "solid-js";
import { Tag } from "@biketravel/solid-ui";

export type TrackTagsProps = {
  tags?: string[]
}

export const TrackTags: Component<TrackTagsProps> = ({
  tags,
}) => {
  const { navigate } = useNavigate();

  if (!tags) {
    return null;
  }

  return (
    <div class='b-track-tags'>
      <For each={tags}>
        {(tag, i) => (
          <Tag
            onClick={() => navigate('track_list', {}, { tag })}
            value={tag}
          />
        )}
      </For>
    </div>
  );
};
