import { ParentComponent } from "solid-js";
import cx from "classnames";
import { BemModifiers } from "./types";
import { bem } from "classnames-bem";

type FormType = BemModifiers & {
    onSubmit?: () => void;
    class?: string;
}

export const Form: ParentComponent<FormType> = (props) => {
    const callback = (e: SubmitEvent) => {
        e.preventDefault();
        props.onSubmit && props.onSubmit();
    }

    return (
        <form class={cx(bem("b-form", props.modifiers), props.class)} onSubmit={callback}>
            {props.children}
        </form>
    );
}