import { iterateMonths } from "./utils";
import { locale } from "./locale";
import { onChangeDate } from "./types";
import { DateTime } from "luxon";
import { Accessor, Component } from "solid-js";

export type CalendarHeaderProps = {
    selected: Accessor<DateTime>
    onChange: onChangeDate
    years: number[]
}

export const CalendarHeader: Component<CalendarHeaderProps> = (props) => (
    <div class="b-calendar__header">
        <select
            onChange={e => {
                props.onChange(props.selected().set({
                    year: parseInt((e.target as HTMLSelectElement).value, 10),
                }));
            }}
            name="year"
            id="year"
            value={props.selected().year}
            class='b-input b-input--select'>
            {props.years.map(year => (
                <option value={year}>
                    {year}
                </option>
            ))}
        </select>
        <select
            onChange={e => {
                props.onChange(props.selected().set({
                    month: parseInt((e.target as HTMLSelectElement).value, 10),
                }));
            }}
            value={props.selected().month}
            name="month"
            id="month"
            class='b-input b-input--select'>
            {iterateMonths().map(month => (
                <option value={month + 1}>
                    {locale.ru.months[month]}
                </option>
            ))}
        </select>
    </div>
);