import { jwtStore, locationStore } from "@biketravel/state";
import { createSignal, Show } from "solid-js";
import { bem } from 'classnames-bem';
import { Link } from "@biketravel/solid-router";

export const MobileMenu = () => {
  const [visible, setVisible] = createSignal(false);

  return (
    <>
      <Show when={visible()}>
        <div class={bem("b-mobile-menu__backdrop", { active: visible() })}></div>
        <div class={bem("b-mobile-menu__container", { active: visible() })}>
          <div class="b-mobile-menu__menu">
            <button class="b-mobile-menu__close" onClick={() => setVisible(false)}>
              <svg width="32" height="32" viewBox="0 0 27 27" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2.63 24.784a1 1 0 11-1.414-1.414L11.586 13 1.216 2.63A1 1 0 112.63 1.216L13 11.586l10.37-10.37a1 1 0 111.414 1.414L14.414 13l10.37 10.37a1 1 0 11-1.414 1.414L13 14.414 2.63 24.784z"
                      fill="#5C5C7B"></path>
              </svg>
            </button>
            <Link onClick={() => setVisible(false)} to="track_list"
                  class="b-mobile-menu__link">Маршруты</Link>
            <Link onClick={() => setVisible(false)} to="event_list"
                  class="b-mobile-menu__link">Поездки</Link>
            <Link onClick={() => setVisible(false)} to="event_calendar" class="b-mobile-menu__link">Календарь
              событий</Link>
            <Link onClick={() => setVisible(false)} to="place_map"
                  class="b-mobile-menu__link">Достопримечательности</Link>
            <Show when={locationStore.coordinates()}>
                            <span class="b-mobile-menu__link">
                                Город: {locationStore.coordinates()?.name}
                            </span>
            </Show>
            {jwtStore.jwt() ? (
              <Link onClick={() => setVisible(false)} class="b-mobile-menu__link"
                    to="user_profile">Профиль</Link>
            ) : (
              <Link onClick={() => setVisible(false)} class="b-mobile-menu__link"
                    to="auth_login">Авторизация</Link>
            )}
            <Link onClick={() => setVisible(false)} to="blog" class="b-mobile-menu__link">Блог</Link>
            <Link onClick={() => setVisible(false)} to="about" class="b-mobile-menu__link">О проекте</Link>
          </div>
        </div>
      </Show>
      <span class="b-mobile-menu__main" onClick={() => setVisible(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="feather feather-menu">
                    <path d="M4 6h24v2H4z" fill="currentColor"></path>
                    <path d="M4 24h24v2H4z" fill="currentColor"></path>
                    <path d="M4 12h24v2H4z" fill="currentColor"></path>
                    <path d="M4 18h24v2H4z" fill="currentColor"></path>
                </svg>
            </span>
    </>
  )
}