import { Title } from "@solidjs/meta";
import { BreadcrumbItem, BreadcrumbSymbol, H1, Segment, SegmentContent, Spinner } from '@biketravel/solid-ui';
import { onMount, Show } from "solid-js";
import { eventViewStore } from "@biketravel/state";
import { useCurrentRoute, useNavigate } from "@biketravel/solid-router";
import { AppBreadcrumbs, EventUpdateForm } from "@biketravel/component";

export const EventEditPage = () => {
    const { navigate } = useNavigate();
    const currentRoute = useCurrentRoute<{
        event_id: number,
    }>();

    onMount(async () => {
        await eventViewStore.actions.fetch(currentRoute().params.event_id)
    });

    return (
        <Show when={!eventViewStore.loading() && eventViewStore.event()?.entity} fallback={<Spinner/>}>
            <Title>Редактирование поездки {eventViewStore.event()?.entity.name}</Title>
            <AppBreadcrumbs>
                <BreadcrumbItem to='track_list'>
                    Маршруты
                </BreadcrumbItem>
                <BreadcrumbSymbol/>
                <BreadcrumbItem to='track_view' params={{ track_id: eventViewStore.event()!.entity.id }}>
                    {eventViewStore.event()!.entity.name}
                </BreadcrumbItem>
                <BreadcrumbSymbol/>
                <BreadcrumbItem>
                    Добавление поездки по маршруту
                </BreadcrumbItem>
            </AppBreadcrumbs>
            <H1>Добавление поездки по маршруту {eventViewStore.event()!.entity.name}</H1>
            <Segment>
                <SegmentContent>
                    <EventUpdateForm
                        event={eventViewStore.event()!.entity}
                        onSuccess={(event_id) => navigate('event_view', { event_id })}/>
                </SegmentContent>
            </Segment>
        </Show>
    );
}