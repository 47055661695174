import { Title } from "@solidjs/meta";
import { BreadcrumbItem, BreadcrumbSymbol, H1, Segment, SegmentContent } from "@biketravel/solid-ui";
import { AppBreadcrumbs, TrackUpload } from "@biketravel/component";
import { useNavigate } from '@biketravel/solid-router';
import { toast } from "solid-toast";

export const TrackUploadPage = () => {
    const { navigate } = useNavigate();

    const onSuccess = (id: number) => {
        toast.success('Маршрут сохранен');
        navigate('track_update', {
            track_id: id,
        });
    }

    return (
        <>
            <Title>Загрузка маршрута из GPX файла</Title>
            <AppBreadcrumbs>
                <BreadcrumbItem to='track_list'>
                    Маршруты
                </BreadcrumbItem>
                <BreadcrumbSymbol/>
                <BreadcrumbItem>
                    Загрузка маршрута из GPX файла
                </BreadcrumbItem>
            </AppBreadcrumbs>
            <H1>Загрузка маршрута из GPX файла</H1>
            <Segment>
                <SegmentContent>
                    <TrackUpload
                        onSuccess={onSuccess}
                    />
                </SegmentContent>
            </Segment>
        </>
    );
}