import type { Feature, GeoJSON, Point, Position } from "geojson";
import type {
  GeoJSONSource,
  Map,
  MapGeoJSONFeature,
  MapLayerMouseEvent,
  MapMouseEvent,
  MapTouchEvent,
  Popup,
} from "maplibre-gl";
import maplibre from "maplibre-gl";
import type { Marker, Place } from "@biketravel/sdk";
import type { DataLayerInterface } from "./baseLayer";
import { clusterCountLayer, clusterLayer, dataSourceId, textLayer } from "./mapboxLayers";
import { shallowEqual } from "shallow-equal-object";
import { loadImages } from "./utils";

export type DataLayerOptions<T> = {
  cursor: string
  icon?: 'red-marker' | 'orange-marker'
  onClick?: (item: T) => void
  onContextMenu?: (item: T) => void
  popupTemplate?: (item: T) => string
}

export class DataLayer<T extends Place | Marker> implements DataLayerInterface {
  protected features: { [key: string]: GeoJSON.Feature } = {};
  protected options: Partial<DataLayerOptions<T>> = {};
  protected popup: Popup;
  protected map: Map;
  protected id: string;
  protected initialized = false;

  constructor(map: Map, id: string, options?: Partial<DataLayerOptions<T>>) {
    this.map = map;
    this.id = id;
    this.options = {
      cursor: '',
      icon: 'orange-marker',
      ...options,
    };
    this.popup = new maplibre.Popup({
      closeButton: true,
      closeOnClick: true,
    });
    this.popup.setMaxWidth('360px');
    map.once('load', () => {
      this.initialize();
      this.initialized = true;
    });
    this.map.on('style.load', () => {
      if (this.initialized) {
        this.initialize();
      }
    });
  }

  initialize(): void {
    loadImages(this.map);

    const canvas = this.map.getCanvas();

    this.map.once("styledata", () => {
      const pointerCursor = () => {
        canvas.style.cursor = 'pointer';
      };
      const defaultCursor = () => {
        canvas.style.cursor = this.options.cursor ?? '';
      };

      const dataSourceIdInst = `${this.id}-${dataSourceId}`;
      this.map.addSource(dataSourceIdInst, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: Object.values(this.features),
        },
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      });

      const textLayerInst = {
        ...textLayer,
        source: dataSourceIdInst,
        id: `${this.id}-${textLayer.id}`,
        layout: {
          ...textLayer.layout,
          'icon-image': this.options.icon,
        },
      };
      this.map.addLayer(textLayerInst);

      const clusterLayerInst = {
        ...clusterLayer,
        source: dataSourceIdInst,
        id: `${this.id}-${clusterLayer.id}`,
      };
      this.map.addLayer(clusterLayerInst);

      const clusterCountLayerInst = {
        ...clusterCountLayer,
        source: dataSourceIdInst,
        id: `${this.id}-${clusterCountLayer.id}`,
      };
      this.map.addLayer(clusterCountLayerInst);

      this.map.on('mouseenter', clusterLayerInst.id, pointerCursor);
      this.map.on('mouseleave', clusterLayerInst.id, defaultCursor);

      this.map.on('mouseenter', dataSourceIdInst, pointerCursor);
      this.map.on('mouseleave', dataSourceIdInst, defaultCursor);

      this.map.on('mouseenter', textLayerInst.id, pointerCursor);
      this.map.on('mouseleave', textLayerInst.id, defaultCursor);

      this.map.on('click', textLayerInst.id, (e: MapLayerMouseEvent) => {
        const features: GeoJSON.Feature[] | undefined = e.features;
        if (features && features.length > 0) {
          const feature = features[0];
          const item = feature.properties as T;

          if (this.options.onClick) {
            this.options.onClick(item);
          } else if (this.options.popupTemplate) {
            const coordinates: Position = (feature.geometry as Point).coordinates.slice();
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
            const [lng, lat] = coordinates;
            this.popup.setLngLat([lng, lat]);
            this.popup.setHTML('Загрузка...');
            this.popup.addTo(this.map);

            this.popup.setHTML(this.options.popupTemplate(item));
          }
        }
      });

      this.map.on('click', clusterLayerInst.id, (e: MapLayerMouseEvent) => {
        const features: MapGeoJSONFeature[] = this.map.queryRenderedFeatures(e.point, {
          layers: [clusterLayerInst.id],
        });
        if (features.length > 0) {
          const feature = features[0] as Feature<Point>;
          if (feature) {
            const clusterId = (feature.properties || {}).cluster_id;
            const source = this.map.getSource(dataSourceIdInst) as GeoJSONSource;
            source.getClusterExpansionZoom(clusterId, (error?: Error | null, result?: number | null) => {
              if (error) {
                throw error;
              }

              const [lng, lat] = feature.geometry.coordinates;
              this.map.easeTo({
                center: [lng, lat],
                zoom: Number(result),
              });
            });
          }
        }
      });
    });
  }

  buildKey(id: number | string): string {
    return `${this.id}-${id}`;
  }

  setItems(items: T[]): void {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const key = this.buildKey(item.id);
      if (
        !this.features[key]
        || (
          this.features[key]
          && !shallowEqual(this.features[key], item)
        )
      ) {
        this.features[key] = {
          id: item.id,
          type: 'Feature',
          properties: item,
          geometry: {
            type: 'Point',
            coordinates: [item.lng, item.lat],
          },
        };
      }
    }
    const layer = this.map.getSource(`${this.id}-${dataSourceId}`) as GeoJSONSource;
    if (layer) {
      layer.setData({
        type: 'FeatureCollection',
        features: Object.values(this.features),
      });
    }
  }

  removeItem(item: T): void {
    delete this.features[this.buildKey(item.id)];
    const layer = this.map.getSource(`${this.id}-${dataSourceId}`) as GeoJSONSource;
    if (layer) {
      layer.setData({
        type: 'FeatureCollection',
        features: Object.values(this.features),
      });
    }
  }

  onContextMenu(e: MapMouseEvent | MapTouchEvent, feature: Feature): void {
    this.options.onContextMenu && this.options.onContextMenu(feature.properties as T);
  }
}
