import { useForm } from "@biketravel/solid-ui";
import { Component } from "solid-js";
import { MarkerForm, MarkerFormType } from "./MarkerForm";
import { markerUpdateStore } from "@biketravel/state/marker";
import type { Marker } from '@biketravel/sdk';

type MarkerCreateFormProps = {
  onSuccess(): void;
  marker: Marker;
}

export const MarkerUpdateForm: Component<MarkerCreateFormProps> = (props) => {
  const {
    errors,
    setErrors,
    values,
    onChange,
  } = useForm<MarkerFormType>({
    description: '',
    name: ''
  });

  const onSubmit = async () => {
    const formErrors = await markerUpdateStore.actions.update(
      props.marker.id,
      values(),
    );
    if (formErrors) {
      setErrors(formErrors);
    } else {
      props.onSuccess();
    }
  };

  return (
    <MarkerForm
      onChange={onChange}
      loading={markerUpdateStore.loading}
      onSubmit={onSubmit}
      values={values}
      errors={errors}
    />
  );
}