import type { User } from '@biketravel/sdk';
import { bem } from "classnames-bem";
import { getImageUrl } from "@biketravel/imgproxy";
import { Component, createSignal, Show } from "solid-js";
import { FiUpload } from "solid-icons/fi";
import { createDropzone } from "@solid-primitives/upload";
import { Avatar, Spinner } from "@biketravel/solid-ui";
import { useUserAvatarStore } from "@biketravel/state";

type UserAvatarUploadProps = {
  user: User;
}

export const UserAvatarUpload: Component<UserAvatarUploadProps> = (props) => {
  const [hover, setHover] = createSignal<boolean>(false);

  const userAvatarStore = useUserAvatarStore();

  const {
    setRef: dropzoneRef,
  } = createDropzone({
    onDrop: async files => {
      for (let i = 0; i < files.length; i++) {
        await userAvatarStore.actions.upload(props.user.id, files[i].file);
      }
    }
  });

  return (
    <div
      class='b-user-edit-avatar'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <Show when={!userAvatarStore.loading()} fallback={<Spinner/>}>
        <Avatar
          letters={props.user.name.substring(0, 2)}
          url={props.user.avatar ? getImageUrl(props.user.avatar, 240, 240) : undefined}
          modifiers='big'/>
      </Show>
      <div ref={dropzoneRef}
           class={bem("b-user-edit-avatar__description", { hover: hover() })}>
        <FiUpload/>
        <div>Загрузить аватар</div>
      </div>
    </div>
  );
};
