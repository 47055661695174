import { Route } from "@biketravel/solid-router";
import { EventViewPage, UserProfilePage } from "../page";
import { DefaultLayout } from "../Layout";
import { RouteProps } from "../renderer";

export const userRoutes: Route<RouteProps>[] = [
    {
        path: '/user',
        children: [
            {
                path: '/profile',
                name: 'user_profile',
                component: UserProfilePage,
                props: {
                    layout: DefaultLayout,
                },
            },
            {
                path: '/:user_id(\\d+)',
                name: 'user_view',
                component: EventViewPage,
                props: {
                    layout: DefaultLayout,
                },
            },
        ]
    },
];