import { Feature, GeoJSON, LineString } from "geojson";
import distance from "@turf/distance";
import { decodeMultiline } from "../encoder";

const makePoint = (lng: number, lat: number): GeoJSON.Feature<GeoJSON.Point> => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: [lng, lat],
  },
  properties: {},
});

export const ghostLinesBetweenSegments = (segments: string[]) => {
  const decodedSegments = decodeMultiline(segments);
  // ghost lines
  const lines: Feature<LineString>[] = [];

  // Segment ghost lines (GPX upload usecase)
  for (let i = 0; i < decodedSegments.length; i++) {
    const current = decodedSegments[i];
    const next = decodedSegments[i + 1];
    if (!next) {
      continue;
    }

    const currentPoint = current[current.length - 1];
    const nextPoint = next[0];

    const lineToLineDistance = distance(
      makePoint(...currentPoint),
      makePoint(...nextPoint),
      { units: 'meters' },
    );

    if (lineToLineDistance < 0.5) {
      continue;
    }

    lines.push({
      type: 'Feature',
      geometry: {
        type: "LineString",
        coordinates: [
          currentPoint,
          nextPoint,
        ],
      },
      properties: {},
    });
  }

  return lines;
};

export const ghostLinesBetweenWaypoints = (waypoints: [number, number][], userWaypoints: [number, number][]) => {
  const lines: Feature<LineString>[] = [];

  for (let i = 0; i < userWaypoints.length; i++) {
    const from = makePoint(...waypoints[i]);
    const to = makePoint(...userWaypoints[i]);

    const pointToPointDistance = distance(from, to, { units: 'meters' });
    if (pointToPointDistance < 0.5) {
      continue;
    }

    lines.push({
      type: 'Feature',
      geometry: {
        type: "LineString",
        coordinates: [
          waypoints[i],
          userWaypoints[i],
        ],
      },
      properties: {},
    });
  }

  return lines;
};
