import type { JSX, Component } from "solid-js";
import { bem } from 'classnames-bem';
import { BemModifiers } from "./types";

type SpinnerType = BemModifiers & {
    overlay?: boolean
    show?: boolean
}

export const Spinner: Component<SpinnerType> = (props) => {
    return props.overlay ? (
        <div class={bem("b-spinner-overlay", { show: props.show }, props.modifiers)}/>
    ) : (
        <div class={bem("b-spinner", { show: props.show }, props.modifiers)}/>
    );
}

export type SpinnerGlobalProps = {
    show?: boolean
    position?:
        | 'top-left'
        | 'top-right'
        | 'bottom-left'
        | 'bottom-right'
} & JSX.HTMLAttributes<HTMLDivElement>

export const SpinnerGlobal: Component<SpinnerGlobalProps> = (props) => (
    <div
        style={props.style}
        class={bem('b-spinner-global', props.position, { show: props.show })}
    >
        {props.show && <Spinner show={props.show} modifiers='white'/>}
    </div>
);
