import { createEffect, on, ParentComponent } from "solid-js";
import { createVisibilityObserver } from "@solid-primitives/intersection-observer";

export type InfinityScrollProps = {
    onVisible(): void;
}

export const InfinityScroll: ParentComponent<InfinityScrollProps> = (props) => {
    let ref: HTMLDivElement | undefined;

    const visible = createVisibilityObserver({ threshold: 0.8 })(() => ref);

    createEffect(on(visible, (a) => {
        if (!visible()) {
            return;
        }

        props.onVisible();
    }, { defer: true }));

    return (
        <>
            {props.children}
            <div ref={ref} style={{ width: "100%", height: "4px" }}/>
        </>
    )
}