import { bem } from "classnames-bem";
import { BemModifiers } from "@biketravel/solid-ui";
import { Component, For, JSX } from "solid-js";
import { Dynamic } from "solid-js/web";
import { config } from '@biketravel/config';

export type SocialAuthProps = BemModifiers & {
  targetUrl?: string
}

const socialUri = (provider: string, target: string | undefined | null = undefined): string => {
  if (!target && typeof window !== 'undefined') {
    target = window.location.pathname;
  }

  return `${config.AUTH_ENDPOINT}/auth/connect/${provider}?returnUrl=${target}`;
};

const networks = [
  'vkontakte',
  'google',
  'yandex',
];

export const AuthGoogle = () => (
  <svg viewBox="0 0 128 128" xml-space="preserve" xmlns="http://www.w3.org/2000/svg">
    <g clip-rule="evenodd">
      <path fill="none" d="M0 0h128v128H0z"/>
      <path
        d="M27.585 64c0-4.157.69-8.143 1.923-11.881L7.938 35.648C3.734 44.183 1.366 53.801 1.366 64c0 10.191 2.366 19.802 6.563 28.332l21.558-16.503A37.86 37.86 0 0 1 27.585 64"
        fill="#FBBC05" fill-rule="evenodd"/>
      <path
        d="M65.457 26.182c9.031 0 17.188 3.2 23.597 8.436L107.698 16C96.337 6.109 81.771 0 65.457 0 40.129 0 18.361 14.484 7.938 35.648l21.569 16.471a37.77 37.77 0 0 1 35.95-25.937"
        fill="#EA4335" fill-rule="evenodd"/>
      <path
        d="M65.457 101.818a37.77 37.77 0 0 1-35.949-25.937L7.938 92.349C18.361 113.516 40.129 128 65.457 128c15.632 0 30.557-5.551 41.758-15.951L86.741 96.221c-5.777 3.639-13.052 5.597-21.284 5.597"
        fill="#34A853" fill-rule="evenodd"/>
      <path
        d="M126.634 64c0-3.782-.583-7.855-1.457-11.636h-59.72v24.727h34.376c-1.719 8.431-6.397 14.912-13.092 19.13l20.474 15.828c11.766-10.92 19.419-27.188 19.419-48.049"
        fill="#4285F4" fill-rule="evenodd"/>
    </g>
  </svg>
);

export const AuthVkontakte = () => (
  <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#5181B8"
      d="m9.489.004.729-.003h3.564l.73.003.914.01.433.007.418.011.403.014.388.016.374.021.36.025.345.03.333.033c1.74.196 2.933.616 3.833 1.516.9.9 1.32 2.092 1.516 3.833l.034.333.029.346.025.36.02.373.025.588.012.41.013.644.009.915.004.98-.001 3.313-.003.73-.01.914-.007.433-.011.418-.014.403-.016.388-.021.374-.025.36-.03.345-.033.333c-.196 1.74-.616 2.933-1.516 3.833-.9.9-2.092 1.32-3.833 1.516l-.333.034-.346.029-.36.025-.373.02-.588.025-.41.012-.644.013-.915.009-.98.004-3.313-.001-.73-.003-.914-.01-.433-.007-.418-.011-.403-.014-.388-.016-.374-.021-.36-.025-.345-.03-.333-.033c-1.74-.196-2.933-.616-3.833-1.516-.9-.9-1.32-2.092-1.516-3.833l-.034-.333-.029-.346-.025-.36-.02-.373-.025-.588-.012-.41-.013-.644-.009-.915-.004-.98.001-3.313.003-.73.01-.914.007-.433.011-.418.014-.403.016-.388.021-.374.025-.36.03-.345.033-.333c.196-1.74.616-2.933 1.516-3.833.9-.9 2.092-1.32 3.833-1.516l.333-.034.346-.029.36-.025.373-.02.588-.025.41-.012.644-.013.915-.009ZM6.79 7.3H4.05c.13 6.24 3.25 9.99 8.72 9.99h.31v-3.57c2.01.2 3.53 1.67 4.14 3.57h2.84c-.78-2.84-2.83-4.41-4.11-5.01 1.28-.74 3.08-2.54 3.51-4.98h-2.58c-.56 1.98-2.22 3.78-3.8 3.95V7.3H10.5v6.92c-1.6-.4-3.62-2.34-3.71-6.92Z"/>
  </svg>
);

export const AuthYandex = () => (
  <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.04 12c0-5.523 4.476-10 10-10 5.522 0 10 4.477 10 10s-4.478 10-10 10c-5.524 0-10-4.477-10-10z"
      fill="#FC3F1D"
    />
    <path
      d="M13.32 7.666h-.924c-1.694 0-2.585.858-2.585 2.123 0 1.43.616 2.1 1.881 2.959l1.045.704-3.003 4.487H7.49l2.695-4.014c-1.55-1.111-2.42-2.19-2.42-4.015 0-2.288 1.595-3.85 4.62-3.85h3.003v11.868H13.32V7.666z"
      fill="#fff"
    />
  </svg>
);

export const iconsMap: { [key: string]: () => JSX.Element } = {
  yandex: AuthYandex,
  google: AuthGoogle,
  vkontakte: AuthVkontakte,
};


export const SocialAuth: Component<SocialAuthProps> = (props) => (
  <div class={bem("b-social-auth__list", props.modifiers)}>
    <For each={networks}>
      {(network) => (
        <a
          class={bem('b-social-auth__link', props.modifiers)}
          href={socialUri(network, props.targetUrl)}>
          <span class="b-social-auth__icon">
            <Dynamic component={iconsMap[network]}/>
          </span>
        </a>
      )}
    </For>
  </div>
);
