import { trackViewStore } from "@biketravel/state";
import { onMount, Show } from "solid-js";
import { useCurrentRoute } from "@biketravel/solid-router";
import { Spinner } from "@biketravel/solid-ui";
import { TrackContentPageContainer } from "./TrackContentPageContainer";

export const TrackContentPage = () => {
    const currentRoute = useCurrentRoute<{
        track_id: number;
    }, never>();

    onMount(async () => {
        await trackViewStore.actions.fetch(currentRoute().params.track_id);
    });

    return (
        <Show
            when={!trackViewStore.loading() && trackViewStore.track()}
            fallback={<Spinner show={true}/>}
        >
            <TrackContentPageContainer track={trackViewStore.track()!}/>
        </Show>
    );
};
