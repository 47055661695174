import { H1, Spinner } from "@biketravel/solid-ui";
import { useCurrentRoute, useNavigate } from "@biketravel/solid-router";
import { restoreCheckStore } from "@biketravel/state";
import { Title } from "@solidjs/meta";
import { onMount } from "solid-js";
import { CircleIcon } from "@biketravel/component";
import { FiClock } from "solid-icons/fi";

export const RestoreCheckPage = () => {
    const { navigate } = useNavigate();
    const getCurrentRoute = useCurrentRoute<{
        token: string;
    }>();
    const currentRoute = getCurrentRoute();

    onMount(async () => {
        const formErrors = await restoreCheckStore.actions.restoreCheck({
            token: currentRoute.params.token
        });
        if (formErrors) {
            navigate('auth_restore_fail');
        } else {
            navigate('auth_restore_confirm', {
                token: currentRoute.params.token,
            });
        }
    });

    return (
        <>
            <Title>Проверка данных</Title>
            <CircleIcon icon={<FiClock/>}/>
            <H1 modifiers='center'>Проверка данных</H1>
            <Spinner modifiers='center' show={true}/>
        </>
    )
}