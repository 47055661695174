import { Component, For } from "solid-js";
import { InfinityScroll, Spinner } from "@biketravel/solid-ui";
import { commentListStore } from "@biketravel/state";
import { CommentListItem } from "./CommentListItem";

type CommentListProps = {
  ownerId: number;
  ownerType: string;
}

export const CommentList: Component<CommentListProps> = (props) => {
  const onVisible = async () => {
    const data = commentListStore.data();
    await commentListStore.actions.fetch(
      props.ownerId,
      props.ownerType,
      data?.cursor?.cursor,
      true,
    );
  };

  return (
    <InfinityScroll onVisible={onVisible}>
      <For each={commentListStore.data().edge} fallback={'Комментарии не найдены'}>
        {(comment) => (
          <CommentListItem comment={comment}/>
        )}
      </For>
      <Spinner show={commentListStore.loading()}/>
    </InfinityScroll>
  )
}