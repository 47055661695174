import { Component, JSX } from "solid-js";
import { BemModifiers } from '@biketravel/solid-ui';
import cx from 'classnames';
import { Link } from "@biketravel/solid-router";
import cls from './CircleIcon.module.scss';

type CircleIconProps = BemModifiers & {
  icon: JSX.Element;
}

export const CircleIcon: Component<CircleIconProps> = (props) => (
  <Link to='auth_login' class={cx(cls.circle, cls.outer, props.modifiers)}>
    <span class={cx(cls.circle, cls.inner, props.modifiers)}>
      {props.icon}
    </span>
  </Link>
);