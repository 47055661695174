import type { BlogPostList } from '@biketravel/sdk';
import { createSignal } from "solid-js";
import { AppStore, ExportableStore, sdk } from "..";
import { withLoading } from "../decorator";

export type BlogPostListState = {
  blogPosts: BlogPostList | undefined;
}

export type BlogPostListStateClient = BlogPostListState & {
  loading: boolean;
}

const initialState: BlogPostListStateClient = {
  blogPosts: undefined,
  loading: false,
}

const [
  blogPosts,
  setBlogPosts,
] = createSignal<BlogPostListState['blogPosts']>(initialState.blogPosts);

const [
  loading,
  setLoading,
] = createSignal<boolean>(initialState.loading);

const blogPostsAction = async (page?: number, pageSize?: number): Promise<void> => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.blog.blogList(page, pageSize);

  setBlogPosts(data);
});

export type BlogPostListActionsType = {
  fetch: typeof blogPostsAction
}

export const blogPostsStore = {
  blogPosts,
  loading,
  actions: {
    fetch: blogPostsAction,
  },
  exportState() {
    return {
      blogPosts: blogPosts(),
    }
  },
  importState(value) {
    setBlogPosts(value.blogPosts);
  }
} satisfies AppStore<BlogPostListStateClient, BlogPostListActionsType> & ExportableStore<BlogPostListState>;