import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";

export type PlaceRemoveState = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<PlaceRemoveState["loading"]>(false);

const remove = async (id: number) => withLoading(setLoading, async () => {
  await sdk.place.placeRemove(id);
});

type PlaceRemoveActions = {
  remove: typeof remove,
}

export const placeRemoveStore = {
  loading,
  actions: {
    remove,
  }
} satisfies AppStore<PlaceRemoveState, PlaceRemoveActions>;