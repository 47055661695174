import type { User } from '@biketravel/sdk';
import { createSignal } from "solid-js";
import { AppStore, sdk } from "..";
import { LoadingType } from "@biketravel/state";
import { withLoading } from "../decorator";

export type MeState = {
  me: User | undefined;
} & LoadingType

const initialState: MeState = {
  me: undefined,
  loading: undefined,
}

const [
  me,
  setMe,
] = createSignal<MeState['me']>(initialState.me);

const [
  loading,
  setLoading,
] = createSignal<boolean>(false);

const meAction = async () => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.auth.me();

  setMe(data);
});

export type MeActionsType = {
  fetch(): void
}

export const meStore = {
  me,
  loading,
  actions: {
    fetch: meAction,
  },
} satisfies AppStore<MeState, MeActionsType>;
