import { Component, For } from "solid-js";
import { ValidationErrorsType } from "./types";

export const getErrors = (path: string, errors: ValidationErrorsType = []) => {
    for (let i = 0; i < errors.length; i++) {
        const validationErr = errors[i];
        if (validationErr.field === path) {
            return validationErr.errors
        }
    }

    return [];
};

type ValidationErrorProps = {
    path: string
    errors?: ValidationErrorsType
}

export const ValidationError: Component<ValidationErrorProps> = (props) => (
    <ValidationErrorList errors={getErrors(props.path, props.errors)}/>
)

type ValidationErrorListType = {
    errors?: string[]
}

export const ValidationErrorList: Component<ValidationErrorListType> = (props) => (
    <div class="b-errors">
        <For each={props.errors}>
            {(err, i) => (
                <div class="b-errors--error">{err}</div>
            )}
        </For>
    </div>
)