import { FiArrowUp } from "solid-icons/fi";
import { Button } from "@biketravel/solid-ui";
import { createEffect, createSignal, JSX, onCleanup, ParentComponent, Show } from "solid-js";
import { useWindowScrollPosition } from "@solid-primitives/scroll";
import { throttle } from "@solid-primitives/scheduled";
import { isServer } from "solid-js/web";

type SidebarListProps = {
  filter: JSX.Element;
}

export const SidebarList: ParentComponent<SidebarListProps> = (props) => {
  const [
    visible,
    setVisible,
  ] = createSignal(isServer);

  if (!isServer) {
    const scroll = useWindowScrollPosition();

    const threshold = 320;

    const updateVisible = (y: number) => {
      if (visible() && y < threshold) {
        setVisible(false);
      }
      if (!visible() && y > threshold) {
        setVisible(true);
      }
    };

    createEffect(() => {
      const trigger = throttle(updateVisible, 100);
      trigger(scroll.y);
      onCleanup(() => trigger.clear());
    });
  }

  return (
    <div class="b-sidebar-list">
      <div class="b-sidebar-list__sidebar">
        <div class="b-sidebar-list__sticky">
          {props.filter}
          <Show when={visible()}>
            <Button
              onClick={() => window.scrollTo({ top: 0 })}
              leftIcon={<FiArrowUp/>}
              modifiers={['block']}
              type="submit">
              Вернуться наверх
            </Button>
          </Show>
        </div>
      </div>
      <div class="b-sidebar-list__content">
        {props.children}
      </div>
    </div>
  );
}