import { createVisibilityObserver } from "@solid-primitives/intersection-observer";
import { createEffect, createSignal, on, ParentComponent, Show } from "solid-js";

export const OnClientVisible: ParentComponent = (props) => {
    // @todo unsubscribe from observer when once visible
    let ref: HTMLDivElement | undefined;

    const [rendered, setRendered] = createSignal(false);

    const visible = createVisibilityObserver({ threshold: 0.8 })(() => ref);

    createEffect(on(visible, (a) => {
        if (!visible()) {
            return;
        }

        if (rendered()) {
            return;
        }

        setTimeout(() => {
            setRendered(true);
        }, 700);
    }, { defer: true }));

    return (
        <div ref={ref}>
            <Show when={rendered()} fallback={'Загрузка...'}>
                {props.children}
            </Show>
        </div>
    );
}