import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { TrackCreateRequest, TrackPersistRequest } from "@biketravel/sdk";
import { withLoading } from "../decorator";

export type TrackCreateState = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<TrackCreateState["loading"]>(false);

const create = async (input: TrackCreateRequest) => withLoading(
  setLoading,
  async () => {
    const { data } = await sdk.track.trackCreate(input);

    return data;
  },
)

const persist = async (input: TrackPersistRequest) => withLoading(
  setLoading,
  async () => {
    const { data } = await sdk.track.trackPersist(input);

    return data;
  },
)

type TrackCreateActions = {
  create: typeof create,
  persist: typeof persist,
}

export const trackCreateStore = {
  loading,
  actions: {
    create,
    persist,
  }
} satisfies AppStore<TrackCreateState, TrackCreateActions>;
