import { clusterCountLayer, clusterLayer, DataLayer, popupFromPlace } from "@biketravel/map";
import type { GeoJSONFeature, Map as MapType } from "maplibre-gl";
import { createEffect, createSignal, Show } from "solid-js";
import { MapPopupMenu, useMap, useMapEffect } from "../index";
import { jwtStore, placeListStore, placeRemoveStore } from "@biketravel/state";
import { Dialog, Menu, MenuItem } from "@biketravel/solid-ui";
import { PlaceCreateForm } from "./PlaceCreateForm";
import { PlaceUpdateForm } from "./PlaceUpdateForm";
import { toast } from "solid-toast";
import type { Place } from '@biketravel/sdk';

type ClickTargetType =
  | 'place-places-clusters'
  | 'place-places-clusters-count'
  | 'place-places-symbol'
  | 'cluster'
  | 'cluster-count'

type ContextMenuClickType = {
  feature?: GeoJSONFeature
  id?: number
  type?: ClickTargetType
  x: number
  y: number
  lng: number
  lat: number
}

export const MapPlace = () => {
  const [
    dataLayer,
    setDataLayer,
  ] = createSignal<DataLayer<Place> | undefined>();

  const [
    location,
    setLocation,
  ] = createSignal<[number, number] | undefined>();

  const [
    selectedPlace,
    setSelectedPlace,
  ] = createSignal<Place>();

  const [
    updatePlace,
    setUpdatePlace,
  ] = createSignal<Place>();

  const [
    contextMenuPosition,
    setContextMenuPosition,
  ] = createSignal<ContextMenuClickType | undefined>(undefined);

  const onContextMenu = (e: any, feature: any) => {
    const { point, lngLat } = e;

    if (feature) {
      if (feature.layer.id === clusterLayer.id || feature.layer.id === clusterCountLayer.id) {
        setContextMenuPosition(undefined);
      } else {
        setContextMenuPosition({
          type: feature.layer.id as ClickTargetType,
          feature,
          id: Number(feature.id),
          lng: lngLat.lng,
          lat: lngLat.lat,
          x: point.x,
          y: point.y,
        });
      }
    } else {
      setContextMenuPosition({
        lng: lngLat.lng,
        lat: lngLat.lat,
        x: point.x,
        y: point.y,
      });
    }
  };

  createEffect(() => {
    const places = placeListStore.places();
    const layer = dataLayer();
    if (layer && places.edge) {
      layer.setItems(places.edge);
    }
  });

  const fetchPlaces = async (map: MapType) => {
    await placeListStore.actions.fetchMap(map);
    const places = placeListStore.places();
    const layer = dataLayer();
    if (layer && places.edge) {
      layer.setItems(places.edge);
    }
  }

  useMapEffect(async (map: MapType) => {
    const dataLayer = new DataLayer<Place>(map, 'place', {
      icon: 'orange-marker',
      onContextMenu: place => setSelectedPlace(place),
      popupTemplate: popupFromPlace,
    });
    setDataLayer(dataLayer);

    map.on('click', () => setContextMenuPosition(undefined));
    map.on('movestart', () => setContextMenuPosition(undefined));
    map.on('load', async () => {
      await fetchPlaces(map);
    });
    map.on('moveend', async () => {
      await fetchPlaces(map);
    });
    map.on('contextmenu', (e) => {
      const queryFeatures = map.queryRenderedFeatures(e.point);
      const realFeatures = queryFeatures.filter(feature => feature.source != 'composite');
      const feature = realFeatures.length > 0 ? realFeatures[0] : null;
      onContextMenu(e, feature);
    });
  });

  const map = useMap();
  const onSuccess = async () => {
    const instance = map && map();
    if (instance) {
      await fetchPlaces(instance);
    }
    setLocation(undefined);
    setUpdatePlace(undefined);
  }

  const onPlaceUpdateCallback = async () => {
    const menu = contextMenuPosition();
    if (menu) {
      setUpdatePlace(
        menu.feature?.properties as Place
      );
      setContextMenuPosition(undefined);
    }
  };

  const onPlaceRemoveCallback = async () => {
    const menu = contextMenuPosition();
    const layer = dataLayer();
    if (menu?.id && layer) {
      if (window.confirm('Удалить достопримечательность?')) {
        const place = menu.feature?.properties as Place;
        await placeRemoveStore.actions.remove(place.id);
        layer.removeItem(place);
        setContextMenuPosition(undefined);
        toast.success('Удалено');
      }
    }
  }

  return (
    <>
      <Show when={jwtStore.jwt() && !!contextMenuPosition()}>
        <MapPopupMenu x={contextMenuPosition()?.x} y={contextMenuPosition()?.y}>
          <Menu>
            <Show when={contextMenuPosition()?.type === 'place-places-symbol'}>
              <MenuItem onClick={() => onPlaceUpdateCallback()}>
                Изменить
              </MenuItem>
              <MenuItem onClick={() => onPlaceRemoveCallback()}>
                Удалить
              </MenuItem>
            </Show>
            <Show when={(
              contextMenuPosition()?.type !== 'place-places-symbol' &&
              contextMenuPosition()?.type !== 'place-places-clusters' &&
              contextMenuPosition()?.type !== 'place-places-clusters-count'
            )}>
              <MenuItem
                onClick={() => {
                  const ctxPos = contextMenuPosition();
                  if (!ctxPos) {
                    return;
                  }

                  setLocation([ctxPos.lng, ctxPos.lat]);
                  setContextMenuPosition(undefined);
                }}>
                Добавить достопримечательность
              </MenuItem>
            </Show>
          </Menu>
        </MapPopupMenu>
      </Show>
      <Dialog
        title='Добавить достопримечательность'
        isOpen={!!location()}
        onClose={() => setLocation(undefined)}
      >
        <PlaceCreateForm
          position={location()!}
          onSuccess={onSuccess}
        />
      </Dialog>
      <Dialog title='Редактирование достопримечательности' isOpen={!!updatePlace()}
              onClose={() => setUpdatePlace(undefined)}>
        <PlaceUpdateForm
          place={updatePlace()!}
          onSuccess={onSuccess}
        />
      </Dialog>
    </>
  );
}