import { createSignal } from "solid-js";
import { AppStore, sdk } from "..";
import type { RegistrationConfirmRequest } from "@biketravel/sdk";
import { LoadingType } from "@biketravel/state";
import { withLoading } from "../decorator";

export type RestoreCheckStore = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<RestoreCheckStore["loading"]>(false);

const restoreCheck = async (input: RegistrationConfirmRequest) => withLoading(setLoading, async () => {
  await sdk.auth.restoreCheck(input);
});

type RestoreCheckActions = {
  restoreCheck: typeof restoreCheck,
}

export const restoreCheckStore = {
  loading,
  actions: {
    restoreCheck,
  }
} satisfies AppStore<RestoreCheckStore, RestoreCheckActions>;