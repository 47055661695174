import { getDifficult } from "@biketravel/state";
import { FiCalendar, FiClock, FiMap, FiMapPin, FiSliders, FiUsers } from "solid-icons/fi";
import { dateTimeToMediumDate, dateTimeToMediumTime, Statistics, stringToDateTime } from "@biketravel/solid-ui";
import { Component } from "solid-js";
import type { Event, Track } from '@biketravel/sdk';
import { Link } from "@biketravel/solid-router";

type EventInfoBarProps = {
  event: Pick<Event, 'distance' | 'difficult' | 'member_count' | 'start_at' | 'author_id' | 'track_id'> & {
    track?: Pick<Track, "name">;
  };
}

export const EventInfoBar: Component<EventInfoBarProps> = (props) => (
  <div class='b-event-info-bar'>
    <Statistics icon={<FiMapPin/>} title='Дистанция'>
      {props.event.distance.toFixed(2)} км
    </Statistics>
    <Statistics icon={<FiSliders/>} title='Сложность'>
      {getDifficult(props.event.difficult)}
    </Statistics>
    <Statistics icon={<FiUsers/>} title='Кол-во участников'>
      {props.event.member_count || 0}
    </Statistics>
    <Statistics icon={<FiCalendar/>} title='Дата старта'>
      {dateTimeToMediumDate(stringToDateTime(props.event.start_at))}
    </Statistics>
    <Statistics icon={<FiClock/>} title='Время старта'>
      {dateTimeToMediumTime(stringToDateTime(props.event.start_at))}
    </Statistics>
    <Statistics icon={<FiMap/>} title='Маршрут'>
      <Link to='track_view' params={{ track_id: props.event.track_id }}>
        {props.event.track?.name}
      </Link>
    </Statistics>
  </div>
)