import { ParentComponent, Show } from "solid-js";
import cx from "classnames";
import cls from './Page.module.scss';
import { WithBuildUrl, WithOnChange } from "./types";

type PageProps = WithOnChange & WithBuildUrl & {
  disabled?: boolean;
  page: number;
  current: number;
}

export const Page: ParentComponent<PageProps> = (props) => {
  const onChange = (e: MouseEvent) => {
    e.preventDefault();
    props.onChange(props.page);
  }

  const className = cx(cls.page, cls.prev, {
    [cls["page--active"]]: props.current === props.page,
    [cls["page--disabled"]]: props.disabled,
  });

  return (
    <Show
      when={props.disabled}
      fallback={(
        <a
          href={props.buildUrl(props.page)}
          class={className}
          onClick={onChange}>
          {props.children}
        </a>
      )}
    >
      <span class={className}>
        {props.children}
      </span>
    </Show>
  );
}
