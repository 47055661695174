import { batch, createSignal } from "solid-js";
import { AppStore, captureError, LoadingType, sdk } from "..";
import { AxiosError } from "axios";
import type { TrackRoute } from "@biketravel/sdk";

export type RouteState = LoadingType & {
  route: TrackRoute | undefined
}

const [
  loading,
  setLoading,
] = createSignal<RouteState["loading"]>(false);

const [
  route,
  setRoute
] = createSignal<RouteState["route"]>();

const getRoute = async (waypoints: [number, number][], profile: string) => {
  setLoading(true);

  try {
    const resp = await sdk.route.buildRoute({
      profile,
      waypoints,
    });

    batch(() => {
      setLoading(false);
      setRoute(resp.data);
    });

    return resp.data;
  } catch (error: AxiosError | unknown) {
    setLoading(false);

    if (error instanceof AxiosError) {
      return error.response?.data.errors;
    }

    captureError(error);
  }
}

type RouteActions = {
  route: typeof getRoute,
}

export const routeStore = {
  loading,
  route,
  actions: {
    route: getRoute,
  }
} satisfies AppStore<RouteState, RouteActions>;