import { onChangeDate } from "./types";
import { CalendarHeader } from "./CalendarHeader";
import { CalendarDays } from "./CalendarDays";
import { DateTime } from "luxon";
import { iterateYears } from "./utils";
import { TimeSelect } from "./TimeSelect";
import { Component, createEffect, createSignal, mergeProps, Show } from "solid-js";

export type CalendarProps = {
    yearsCount?: number
    selectedDate?: DateTime
    confirmButton?: boolean
    onChange: onChangeDate
    onConfirm?: onChangeDate
}

const getValidDate = (date?: DateTime) => {
    if (!date) {
        date = DateTime.now();
    }

    date = date.isValid ? date : DateTime.now();

    return date.set({
        second: 0,
        millisecond: 0,
    })
}

export const Calendar: Component<CalendarProps> = (props) => {
    const newProps = mergeProps({
        yearsCount: 5,
        selectedDate: DateTime.now()
    }, props);

    const [
        selected,
        setSelected,
    ] = createSignal<DateTime>(getValidDate(newProps.selectedDate));

    createEffect(() => {
        setSelected(getValidDate(newProps.selectedDate));
    });

    const onChange = (date: DateTime) => {
        setSelected(date);
        props.onChange(date);
    }

    const onConfirm = () => {
        props.onConfirm && props.onConfirm(selected());
    }

    return (
        <div class='b-calendar'>
            <CalendarHeader
                years={iterateYears(selected(), newProps.yearsCount)}
                selected={selected}
                onChange={date => onChange(date)}
            />
            <CalendarDays
                selected={selected}
                onChange={date => onChange(date)}
            />
            <TimeSelect
                selected={selected}
                onChange={date => onChange(date)}
            />
            <Show when={props.confirmButton}>
                <div class="b-calendar__footer">
                    <button
                        class='b-button b-button--primary b-calendar__confirm'
                        onClick={() => onConfirm()}>
                        Подтвердить
                    </button>
                </div>
            </Show>
        </div>
    );
};
