import { Title } from "@solidjs/meta";
import { CenterType, MapRoute, MapSolid } from '@biketravel/component';
import { Button, OnClientVisible, Spinner } from "@biketravel/solid-ui";
import type { TrackRoute } from "@biketravel/sdk";
import { batch, createEffect, createSignal, on, Show } from "solid-js";
import { locationStore, trackCreateStore } from "@biketravel/state";
import { useNavigate } from "@biketravel/solid-router";
import { toast } from "solid-toast";

export const TrackCreatePage = () => {
    const { navigate } = useNavigate();

    const [hasRoute, setHasRoute] = createSignal(false);
    const [route, setRoute] = createSignal<TrackRoute | undefined>();

    const coords = locationStore.coordinates();
    if (!coords) {
        locationStore.actions.detect();
    }

    const [center, setCenter] = createSignal<CenterType>(coords ? [
        coords.lng,
        coords.lat,
    ] : undefined);

    createEffect(on(locationStore.coordinates, (a) => {
        if (!a?.lng || !a?.lat) {
            return;
        }

        setCenter([a.lng, a.lat]);
    }, { defer: true }));

    const save = async () => {
        const trackRoute = route();
        if (!trackRoute) {
            return;
        }

        const data = await trackCreateStore.actions.persist({
            route: trackRoute,
        });
        if (data?.id) {
            toast.success('Маршрут сохранен');
            navigate('track_update', {
                track_id: data.id,
            });
        } else if (data) {
            console.log(data);
            return
        }
    };

    return (
        <>
            <Title>Создание маршрута</Title>
            <OnClientVisible>
                <Show when={!locationStore.loading()} fallback={<Spinner/>}>
                    <MapSolid modifiers='fullscreen' center={center()}>
                        <MapRoute
                            onRouteUpdated={newRoute => {
                                batch(() => {
                                    setRoute(newRoute);
                                    setHasRoute(true);
                                })
                            }}
                            editable={true}
                        />
                    </MapSolid>
                    <div class='b-track-create__text'>
                        <Show when={hasRoute()} fallback={(
                            <div>
                                Для начала работы добавьте две маршрутные точки <strong>правой кнопкой мыши</strong>
                            </div>
                        )}>
                            <Button modifiers='primary' onClick={() => save()}>
                                Сохранить
                            </Button>
                        </Show>
                    </div>
                </Show>
            </OnClientVisible>
        </>
    );
}
