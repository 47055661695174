import { Info } from "luxon";

const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const locale = {
    ru: {
        days: Info.weekdays("short", { locale: "ru" }).map(name => capitalize(name)),
        months: Info.months("long", { locale: "ru" }).map(name => capitalize(name)),
        monthsShort: Info.months("short", { locale: "ru" }).map(name => capitalize(name)),
    },
};