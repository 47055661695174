import { CenterType, MapPlace, MapSolid } from "@biketravel/component";
import { createEffect, createSignal, on, Show } from "solid-js";
import { locationStore } from "@biketravel/state";
import { Spinner } from "@biketravel/solid-ui";

export const PlaceMapPage = () => {
    const coords = locationStore.coordinates();
    if (!coords) {
        locationStore.actions.detect();
    }

    const [
        center,
        setCenter
    ] = createSignal<CenterType | undefined>(coords ? [
        coords.lng,
        coords.lat
    ] : undefined);

    createEffect(on(locationStore.coordinates, (a) => {
        if (!a?.lng || !a?.lat) {
            return;
        }

        setCenter([a.lng, a.lat]);
    }, { defer: true }));

    return (
        <Show when={!locationStore.loading()} fallback={<Spinner/>}>
            <MapSolid modifiers='fullscreen' center={center()}>
                <MapPlace/>
            </MapSolid>
        </Show>
    );
}