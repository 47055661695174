import { findRouteCenter } from "./utils";
import { Component, mergeProps, Show } from "solid-js";
import type { Track } from "@biketravel/sdk";
import { MapRoute, MapSolid } from "../map";
import { decodeRoute } from "@biketravel/map";
import { BemModifiers } from "@biketravel/solid-ui";
import { MarkerMap } from "../marker";
import { MapPlace } from "../place";

type TrackViewMapProps = BemModifiers & {
  track: Track;
  place?: boolean;
  scrollZoom?: boolean;
}

export const TrackViewMap: Component<TrackViewMapProps> = (props) => {
  const merged = mergeProps({
    scrollZoom: false,
    place: true,
  }, props);

  const decodedRoute = decodeRoute(props.track.track_route);
  const center = findRouteCenter(decodedRoute);

  return (
    <MapSolid
      modifiers={props.modifiers} scrollZoom={merged.scrollZoom} center={center}>
      <Show when={props.place}>
        <MapPlace/>
      </Show>
      <MarkerMap track={props.track}/>
      <MapRoute
        autoFitBounds={true}
        currentRoute={props.track.track_route}
      />
    </MapSolid>
  )
}