import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk, trackViewStore } from "..";
import { withLoading } from "../decorator";

export type TrackDraftUpdate = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<TrackDraftUpdate["loading"]>(false);

const publish = async (id: number) => withLoading(setLoading, async () => {
  await sdk.track.trackPublish(id);

  trackViewStore.actions.setDraftStatus(id, false);
});

const unpublish = async (id: number) => withLoading(setLoading, async () => {
  await sdk.track.trackUnpublish(id);

  trackViewStore.actions.setDraftStatus(id, true);
})

type TrackDraftUpdateActions = {
  publish: typeof publish,
  unpublish: typeof unpublish,
}

export const trackDraftUpdateStore = {
  loading,
  actions: {
    publish,
    unpublish,
  }
} satisfies AppStore<TrackDraftUpdate, TrackDraftUpdateActions>;