import { ButtonLink, Divider, H1 } from "@biketravel/solid-ui";
import { useNavigate } from "@biketravel/solid-router";
import { CircleIcon, SignInForm, SocialAuth } from "@biketravel/component";
import { FiArrowLeft, FiKey } from "solid-icons/fi";

export const SignIn = () => {
    const { navigate, urlFor } = useNavigate();

    return (
        <>
            <CircleIcon icon={<FiKey/>}/>
            <H1 modifiers='center'>Авторизация</H1>
            <SocialAuth targetUrl={urlFor('home')}/>
            <Divider/>
            <SignInForm onSuccess={() => navigate('home')}/>
            <Divider/>
            <div class="b-block-grid">
                <div class="b-block-grid__item">
                    <ButtonLink modifiers={['default', 'block']} to="auth_registration">
                        Зарегистрироваться
                    </ButtonLink>
                </div>
                <div class="b-block-grid__item">
                    <ButtonLink modifiers={['default', 'block']} to="auth_restore">
                        Восстановить пароль
                    </ButtonLink>
                </div>
            </div>
            <Divider/>
            <div>
                <ButtonLink to='home' leftIcon={<FiArrowLeft/>} modifiers='block'>
                    Вернуться на сайт
                </ButtonLink>
            </div>
        </>
    );
}