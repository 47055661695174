import { BlogListItem } from "./BlogListItem";
import { Component, Index } from "solid-js";
import { Pagination } from "@biketravel/solid-ui";
import { BlogEmpty } from "./BlogEmpty";
import { BlogPostListState } from "@biketravel/state";
import { useCurrentRoute, useNavigate } from "@biketravel/solid-router";

type BlogListContainer = BlogPostListState["blogPosts"] & {
  onChange: (page: number) => void;
}

export const BlogListContainer: Component<BlogListContainer> = (props) => {
  const route = useCurrentRoute();
  const { urlFor } = useNavigate();

  return (
    <>
      <Index each={props.edge} fallback={<BlogEmpty/>}>
        {(blogPost) => (
          <BlogListItem blogPost={blogPost()}/>
        )}
      </Index>
      <Pagination
        buildUrl={(page: number) => urlFor(route().name, route().params, {
          ...route().query,
          page,
        })}
        pageCount={Math.ceil(props.pager.total / props.pager.page_size)}
        page={props.pager.page}
        onChange={props.onChange}
      />
    </>
  );
}