import type { Map as MapType } from 'maplibre-gl';
import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import type { PlaceList } from '@biketravel/sdk';
import { withLoading } from "../decorator";

export type PlaceListState = {
  places: PlaceList;
};

export type PlaceListClientState = PlaceListState & LoadingType;

const [
  loading,
  setLoading,
] = createSignal<PlaceListClientState["loading"]>(false);

const [
  places,
  setPlaces,
] = createSignal<PlaceListClientState["places"]>({
  edge: [],
});

const fetch = async (
  xmin: number,
  ymin: number,
  xmax: number,
  ymax: number,
  trackId?: number,
  radius?: number,
) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.place.placeList(
    xmin,
    ymin,
    xmax,
    ymax,
    trackId,
    radius
  );

  setPlaces(data);

  return data;
})

const fetchMap = async (map: MapType) => {
  const bounds = map.getBounds();
  const topRight = bounds.getNorthWest();
  const bottomLeft = bounds.getSouthEast();

  await fetch(
    bottomLeft.lng,
    bottomLeft.lat,
    topRight.lng,
    topRight.lat,
  )
}

type PlaceListActions = {
  fetch: typeof fetch,
  fetchMap: typeof fetchMap,
}

export const placeListStore = {
  loading,
  places,
  actions: {
    fetch,
    fetchMap,
  }
} satisfies AppStore<PlaceListClientState, PlaceListActions>;