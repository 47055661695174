import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";

export type TrackUploadState = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<TrackUploadState["loading"]>(false);

const upload = async (file: File) => withLoading(setLoading, async () => {
  const { data } = await sdk.track.trackUpload(file);

  return data;
});

type TrackUploadActions = {
  upload: typeof upload,
}

export const trackUploadStore = {
  loading,
  actions: {
    upload,
  }
} satisfies AppStore<TrackUploadState, TrackUploadActions>;