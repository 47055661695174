import { createSignal } from "solid-js";
import { AppStore, commentListStore, sdk } from "..";
import { withLoading } from "../decorator";

export type CommentRemoveState = {
  loading: boolean;
}

const initialState: CommentRemoveState = {
  loading: false,
}

const [
  loading,
  setLoading
] = createSignal<boolean>(initialState.loading);

const commentRemoveAction = async (
  id: number,
) => withLoading(setLoading, async () => {
  await sdk.comment.commentRemove(id);

  commentListStore.actions.removeComment(id);
});

export type CommentRemoveActionsType = {
  remove: typeof commentRemoveAction
}

export const commentRemoveStore = {
  loading,
  actions: {
    remove: commentRemoveAction,
  }
} satisfies AppStore<CommentRemoveState, CommentRemoveActionsType>;