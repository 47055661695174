import type { Place } from '@biketravel/sdk';
import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";

export type PlaceViewStore = LoadingType & {
  place?: Place;
};

const [
  loading,
  setLoading,
] = createSignal<PlaceViewStore["loading"]>(false);

const [
  place,
  setPlace,
] = createSignal<PlaceViewStore["place"]>(undefined);

const fetch = async (id: number) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.place.placeView(id);

  setPlace(data);
});

type PlaceViewActions = {
  fetch: typeof fetch,
}

export const placeViewStore = {
  loading,
  place,
  actions: {
    fetch,
  }
} satisfies AppStore<PlaceViewStore, PlaceViewActions>;