import { JSX, ParentComponent, Show } from "solid-js";
import { bem } from "classnames-bem";

type StatisticsProps = {
    title: string;
    icon?: JSX.Element;
}

export const Statistics: ParentComponent<StatisticsProps> = (props) => (
    <div class={bem("b-statistics", { icon: !!props.icon })}>
        <Show when={props.icon}>
            <div class="b-statistics__icon">
                {props.icon}
            </div>
        </Show>
        <div class="b-statistics__content">
            <div class="b-statistics__label">{props.title}</div>
            <div class="b-statistics__value">{props.children}</div>
        </div>
    </div>
);