import { Button, Form, FormRow, InputText, Label, Spinner, useForm, ValidationError } from "@biketravel/solid-ui";
import { registrationStore } from "@biketravel/state";
import type { RegistrationRequest } from "@biketravel/sdk";
import { Component } from "solid-js";

type RegistrationFormProps = {
  onSuccess(): void;
}

export const RegistrationForm: Component<RegistrationFormProps> = (props) => {
  const {
    errors,
    setErrors,
    values,
    onChange,
  } = useForm<RegistrationRequest>({
    username: '',
    name: '',
    email: '',
    password: '',
    password_confirm: '',
  });

  const onSubmit = async () => {
    const formErrors = await registrationStore.actions.registration(values());
    if (formErrors) {
      setErrors(formErrors);
    } else {
      props.onSuccess();
    }
  };

  return (
    <Form onSubmit={() => onSubmit()}>
      <FormRow modifiers='margin-bottom'>
        <Label for="name" required>Ваше имя</Label>
        <InputText
          autofocus={true}
          type='text'
          id='name'
          name='name'
          value={values().name}
          onChange={({ value }) => onChange('name', value)}
        />
        <ValidationError path='username' errors={errors()}/>
      </FormRow>
      <FormRow modifiers='margin-bottom'>
        <Label for="username" required>Имя пользователя / Никнейм</Label>
        <InputText
          type='text'
          id='username'
          name='username'
          value={values().username}
          onChange={({ value }) => onChange('username', value)}
        />
        <ValidationError path='username' errors={errors()}/>
      </FormRow>
      <FormRow modifiers='margin-bottom'>
        <Label for="email" required>Адрес электронной почты</Label>
        <InputText
          type='text'
          id='email'
          name='email'
          value={values().email}
          onChange={({ value }) => onChange('email', value)}
        />
        <ValidationError path='email' errors={errors()}/>
      </FormRow>
      <FormRow modifiers='margin-bottom'>
        <Label for="password" required>Пароль</Label>
        <InputText
          type='password'
          id='password'
          name='password'
          value={values().password}
          onChange={({ value }) => onChange('password', value)}
        />
        <ValidationError path='password' errors={errors()}/>
      </FormRow>
      <FormRow modifiers='margin-bottom'>
        <Label for="passwordConfirm" required>Подтверждение пароля</Label>
        <InputText
          type='password'
          id='passwordConfirm'
          name='passwordConfirm'
          value={values().password_confirm}
          onChange={({ value }) => onChange('password_confirm', value)}
        />
        <ValidationError path='passwordConfirm' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Button modifiers={['primary', 'block']} type="submit">
          Регистрация
        </Button>
      </FormRow>
      <Spinner overlay show={registrationStore.loading()}/>
    </Form>
  );
}