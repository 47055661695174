import { Title } from "@solidjs/meta";
import { BreadcrumbItem, H1, Spinner } from "@biketravel/solid-ui";
import { AppBreadcrumbs, UserAvatarUpload, UserSummary } from "@biketravel/component";
import { meStore } from "@biketravel/state";
import { onMount, Show } from "solid-js";

export const UserProfilePage = () => {
    onMount(async () => {
        await meStore.actions.fetch();
    });

    return (
        <>
            <Title>Мой профиль</Title>
            <AppBreadcrumbs>
                <BreadcrumbItem>
                    Мой профиль
                </BreadcrumbItem>
            </AppBreadcrumbs>
            <Show when={meStore.me() && !meStore.loading()} fallback={<Spinner/>}>
                <H1>
                    {meStore.me()!.name} ({meStore.me()!.username})
                </H1>
                <div class="b-user-view__main">
                    <div class="b-user-view__sidebar">
                        <UserAvatarUpload user={meStore.me()!}/>
                    </div>
                    <UserSummary user={meStore.me()!}/>
                </div>
            </Show>
        </>
    )
}