import { Title } from "@solidjs/meta";
import { BreadcrumbItem, H1 } from "@biketravel/solid-ui";
import { AppBreadcrumbs, EventFilterForm, EventList, SidebarList } from "@biketravel/component";
import { eventListStore, jwtStore } from "@biketravel/state";

export const EventMyListPage = () => {
    eventListStore.actions.reset();

    const jwt = jwtStore.jwt()!;

    return (
        <>
            <Title>Мои поездки</Title>
            <AppBreadcrumbs>
                <BreadcrumbItem>
                    Мои поездки
                </BreadcrumbItem>
            </AppBreadcrumbs>
            <H1>Мои поездки</H1>
            <SidebarList filter={<EventFilterForm filter={{ userId: jwt.id }}/>}>
                <EventList/>
            </SidebarList>
        </>
    );
}