import { useCurrentRoute } from "@biketravel/solid-router";
import { getImageUrl } from '@biketravel/imgproxy';
import { Title } from "@solidjs/meta";
import { BreadcrumbItem, BreadcrumbSymbol, H1, H3, Spinner, Typeset } from "@biketravel/solid-ui";
import { blogPostStore } from "@biketravel/state";
import { onMount, Show } from "solid-js";
import { AppBreadcrumbs, Comments } from "@biketravel/component";
import { NotFoundPage } from "../system";

export const BlogPostPage = () => {
    const currentRoute = useCurrentRoute<{
        blog_post_id: number | string,
    }>();

    // createEffect(async () => {
    //     const id = parseInt(String(currentRoute().params.blog_post_id), 10);
    //     await blogPostStore.actions.fetchIfNotExists(id);
    // });

    onMount(async () => {
        const id = parseInt(String(currentRoute().params.blog_post_id), 10);
        await blogPostStore.actions.fetchIfNotExists(id);
    });

    return (
        <Show when={!blogPostStore.loading()} fallback={<Spinner/>}>
            <Show when={blogPostStore.blogPost()} fallback={<NotFoundPage/>}>
                <Title>{blogPostStore.blogPost()?.name}</Title>
                <AppBreadcrumbs>
                    <BreadcrumbItem to='blog'>
                        Блог
                    </BreadcrumbItem>
                    <BreadcrumbSymbol/>
                    <BreadcrumbItem>
                        {blogPostStore.blogPost()?.name}
                    </BreadcrumbItem>
                </AppBreadcrumbs>
                <H1>{blogPostStore.blogPost()?.name}</H1>
                <div class="b-blog-short">
                    <img
                        class="b-blog-short__image"
                        src={getImageUrl(blogPostStore.blogPost()?.image!, 1248, 320)}
                        alt={blogPostStore.blogPost()?.name}/>
                    <div class="b-blog-short__content">
                        <Typeset content={blogPostStore.blogPost()?.content}/>
                    </div>
                </div>
                <H3>Комментарии</H3>
                <Comments
                    ownerId={blogPostStore.blogPost()!.id}
                    ownerType='blog'
                />
            </Show>
        </Show>
    );
}