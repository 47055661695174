import { Link } from '@biketravel/solid-router';
import { CommentMenu, UserAvatar } from "../index";
import {
  Attention,
  dateTimeToMediumDate,
  dateTimeToRelative,
  Dialog,
  Spinner,
  stringToDateTime,
  Typeset
} from "@biketravel/solid-ui";
import { commentRemoveStore, commentUpdateStore } from "@biketravel/state";
import { Component, createSignal, Show } from "solid-js";
import type { Comment } from '@biketravel/sdk';
import { CommentUpdateForm } from "./CommentUpdateForm";
import toast from "solid-toast";

type CommentListItemProps = {
  comment: Comment
}

export const CommentListItem: Component<CommentListItemProps> = (props) => {
  const [removed, setRemoved] = createSignal(false);
  const [editable, setEditable] = createSignal(false);

  const onUpdateSuccess = () => {
    setEditable(false);
    toast.success('Комментарий изменен');
  }

  return (
    <div class='b-comment-list-item'>
      <div class="b-comment-list-item__header">
        <UserAvatar
          class="b-comment-list-item__avatar"
          name={props.comment.author.name}
          avatar={props.comment.author.avatar}
        />
        <div>
          <Link class='b-comment-list-item__author'
                to='user_view'
                params={{ user_id: props.comment.author_id }}>
            {props.comment.author.name}
          </Link>
          <span class="b-comment-list-item__date">
                        {dateTimeToMediumDate(stringToDateTime(props.comment.created_at))} ({dateTimeToRelative(stringToDateTime(props.comment.created_at))})
                    </span>
        </div>
        <div>
          <CommentMenu
            comment={props.comment}
            onEdit={() => setEditable(true)}
          />
        </div>
      </div>
      <div class="b-comment-list-item__content">
        <Show when={!removed()} fallback={(
          <Attention modifiers='warning'>Комментарий удален</Attention>
        )}>
          <Typeset>
            {props.comment.comment}
          </Typeset>
        </Show>
      </div>
      <Spinner
        overlay={true}
        show={commentRemoveStore.loading() || commentUpdateStore.loading()}
      />
      <Dialog
        title='Редактирование комментария'
        onClose={() => setEditable(false)}
        isOpen={editable()}>
        <CommentUpdateForm
          onSuccess={onUpdateSuccess}
          comment={props.comment}
        />
      </Dialog>
    </div>
  );
};