import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";

type EventIsMemberState = LoadingType & {
  isMember: boolean;
}

const initialState: EventIsMemberState = {
  loading: undefined,
  isMember: false,
}

const [
  isMember,
  setIsMember,
] = createSignal<boolean>(false);

const [
  loading,
  setLoading,
] = createSignal<LoadingType["loading"]>(initialState.loading);

const fetch = async (id: number | string) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.event.eventIsMember(parseInt(String(id), 10));

  setIsMember(data.is_member);
});

export type EventIsMemberActionsType = {
  fetch: typeof fetch,
  setIsMember: typeof setIsMember,
}

export const eventIsMemberStore = {
  isMember,
  loading,
  actions: {
    fetch,
    setIsMember,
  }
} satisfies AppStore<EventIsMemberState, EventIsMemberActionsType>;