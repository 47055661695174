import { bem } from "classnames-bem";
import type { Component, JSX } from 'solid-js';
import { splitProps } from "solid-js";
import { BemModifiers } from "./types";
import cx from 'classnames';

type AvatarProps = BemModifiers & {
    url?: string
    letters?: string
} & JSX.HTMLAttributes<HTMLDivElement>

export const Avatar: Component<AvatarProps> = (props) => {
    const [
        local,
        other,
    ] = splitProps(props, ['letters', 'modifiers', 'style', 'url', 'class']);

    const style = {
        ...(typeof local.style === "object" ? local.style : {}),
        "background-image": local.url
            ? `url(${encodeURI(local.url)})`
            : undefined
    };

    return (
        <div
            {...other}
            class={cx(bem('b-avatar', { letters: local.letters && !local.url }, local.modifiers), props.class)}
            style={style}>
            {(local.letters && !local.url) && local.letters}
        </div>
    );
}