import { isServer } from "solid-js/web";

export const captureError = (e: Error | unknown) => {
  if (!isServer) {
    console.error(e);
  }

  throw e;
}

export type LoadingType = {
  loading: boolean | undefined;
}
