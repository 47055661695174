import { createSignal } from "solid-js";
import { AppStore, sdk } from "..";
import { LoadingType } from "@biketravel/state";
import type { CommentCreate } from "@biketravel/sdk";
import { withLoading } from "../decorator";

export type CommentCreateState = LoadingType

const [
  loading,
  setLoading,
] = createSignal<CommentCreateState["loading"]>();

const create = async (
  input: CommentCreate,
) => withLoading(setLoading, async () => {
  await sdk.comment.commentCreate(input);
});

type CommentCreateActions = {
  create: typeof create,
}

export const commentCreateStore = {
  loading,
  actions: {
    create,
  }
} satisfies AppStore<CommentCreateState, CommentCreateActions>;
