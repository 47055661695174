import { bem } from "classnames-bem";
import { InputChangeEvent, InputSharedType } from "./types";
import { Component, createEffect, createSignal } from "solid-js";
import { DOMElement } from "solid-js/types/jsx";

export type InputCheckboxRadioType = InputSharedType<HTMLInputElement> & {
    type: 'checkbox' | 'radio';
    checked: boolean;
    onChange(value: InputChangeEvent<HTMLInputElement, { checked: boolean; }>): void;
}

export const InputCheckboxRadio: Component<InputCheckboxRadioType> = (props) => {
    const [
        value,
        setValue,
    ] = createSignal<string>(props.value || '');

    let ref: HTMLInputElement | undefined;

    const onChange = (e: Event & { currentTarget: HTMLInputElement; target: DOMElement }) => {
        setValue(e.currentTarget.value);
        props.onChange({
            value: e.currentTarget.value,
            checked: e.currentTarget.checked,
            event: e,
        })
    }

    createEffect(() => setValue(props.value || ''));

    return (
        <input
            class={bem('b-input', 'checkbox', props.modifiers)}
            type={props.type}
            checked={props.checked}
            ref={ref}
            placeholder={props.placeholder}
            id={props.id}
            name={props.name}
            value={value()}
            onChange={e => onChange(e)}
        />
    );
}