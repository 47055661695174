import { MarkerList } from "@biketravel/sdk";
import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";

export type MarkerListState = {
  data: MarkerList;
} & LoadingType

const initialState: MarkerListState = {
  data: {
    edge: [],
    cursor: undefined
  },
  loading: true,
}

const [
  markerData,
  setMarkerData
] = createSignal<MarkerListState['data']>(initialState.data);

const [
  loading,
  setLoading
] = createSignal<MarkerListState["loading"]>(initialState.loading);

const fetch = async (
  trackId: number,
) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.marker.markerList(trackId);

  setMarkerData(data);
});

export type MarkerListActionsType = {
  fetch: typeof fetch
}

export const markerListStore = {
  data: markerData,
  loading,
  actions: {
    fetch: fetch,
  },
} satisfies AppStore<MarkerListState, MarkerListActionsType>;