import { Component } from "solid-js";

type LogoProps = {
  class?: string;
}

export const Logo: Component<LogoProps> = (props) => (
  <svg class={props.class} viewBox="0 0 517 63" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="BIKETRAVEL" transform="translate(0.654000, 0.878000)" fill="currentColor" fill-rule="nonzero">
        <path
          d="M0,5.68434189e-14 L27.501,5.68434189e-14 C32.663,5.68434189e-14 36.7125,1.45366667 39.6495,4.361 C42.5865,7.26833333 44.055,11.1546667 44.055,16.02 C44.055,18.334 43.7286667,20.3068333 43.076,21.9385 C42.4233333,23.5701667 41.5926667,24.9051667 40.584,25.9435 C39.5753333,26.9818333 38.4183333,27.7531667 37.113,28.2575 C35.8076667,28.7618333 34.532,29.0733333 33.286,29.192 L33.286,29.726 C34.532,29.7853333 35.9115,30.082 37.4245,30.616 C38.9375,31.15 40.3466667,31.9955 41.652,33.1525 C42.9573333,34.3095 44.055,35.7928333 44.945,37.6025 C45.835,39.4121667 46.28,41.6223333 46.28,44.233 C46.28,46.725 45.8795,49.0686667 45.0785,51.264 C44.2775,53.4593333 43.165,55.358 41.741,56.96 C40.317,58.562 38.626,59.8228333 36.668,60.7425 C34.71,61.6621667 32.574,62.122 30.26,62.122 L0,62.122 L0,5.68434189e-14 Z M10.057,53.489 L27.323,53.489 C29.9336667,53.489 31.9806667,52.8066667 33.464,51.442 C34.9473333,50.0773333 35.689,48.1193333 35.689,45.568 L35.689,42.542 C35.689,39.9906667 34.9473333,38.0326667 33.464,36.668 C31.9806667,35.3033333 29.9336667,34.621 27.323,34.621 L10.057,34.621 L10.057,53.489 Z M10.057,26.255 L25.632,26.255 C28.124,26.255 30.0523333,25.6171667 31.417,24.3415 C32.7816667,23.0658333 33.464,21.2413333 33.464,18.868 L33.464,16.02 C33.464,13.6466667 32.7816667,11.8221667 31.417,10.5465 C30.0523333,9.27083333 28.124,8.633 25.632,8.633 L10.057,8.633 L10.057,26.255 Z"/>
        <polygon
          points="55.981 62.122 55.981 53.934 64.436 53.934 64.436 8.188 55.981 8.188 55.981 5.68434189e-14 83.037 5.68434189e-14 83.037 8.188 74.493 8.188 74.493 53.934 83.037 53.934 83.037 62.122"/>
        <polygon
          points="114.276 33.108 105.732 42.898 105.732 62.122 95.675 62.122 95.675 5.68434189e-14 105.732 5.68434189e-14 105.732 31.506 106.088 31.506 114.988 20.203 131.72 5.68434189e-14 143.468 5.68434189e-14 121.307 26.166 145.248 62.122 133.144 62.122"/>
        <polygon
          points="154.415 62.122 154.415 5.68434189e-14 194.198 5.68434189e-14 194.198 8.9 164.472 8.9 164.472 26.166 191.439 26.166 191.439 35.066 164.472 35.066 164.472 53.222 194.198 53.222 194.198 62.122"/>
        <polygon
          points="230.243 8.9 230.243 62.122 220.186 62.122 220.186 8.9 201.674 8.9 201.674 5.68434189e-14 248.755 5.68434189e-14 248.755 8.9"/>
        <path
          d="M268.602,62.122 L258.545,62.122 L258.545,5.68434189e-14 L285.334,5.68434189e-14 C290.911333,5.68434189e-14 295.302,1.67616667 298.506,5.0285 C301.71,8.38083333 303.312,12.9346667 303.312,18.69 C303.312,23.14 302.2885,26.8335 300.2415,29.7705 C298.1945,32.7075 295.183333,34.7693333 291.208,35.956 L304.647,62.122 L293.433,62.122 L280.973,37.024 L268.602,37.024 L268.602,62.122 Z M284.622,28.569 C287.173333,28.569 289.161,27.9015 290.585,26.5665 C292.009,25.2315 292.721,23.318 292.721,20.826 L292.721,16.554 C292.721,14.062 292.009,12.1485 290.585,10.8135 C289.161,9.4785 287.173333,8.811 284.622,8.811 L268.602,8.811 L268.602,28.569 L284.622,28.569 Z"/>
        <path
          d="M355.644,62.122 L350.037,45.301 L326.808,45.301 L321.379,62.122 L311.144,62.122 L332.326,5.68434189e-14 L344.964,5.68434189e-14 L366.146,62.122 L355.644,62.122 Z M338.645,9.256 L338.2,9.256 L329.211,36.668 L347.545,36.668 L338.645,9.256 Z"/>
        <polygon
          points="385.904 62.122 365.523 5.68434189e-14 375.936 5.68434189e-14 385.815 30.705 391.778 52.332 392.134 52.332 398.186 30.705 408.065 5.68434189e-14 418.211 5.68434189e-14 397.563 62.122"/>
        <polygon
          points="427.467 62.122 427.467 5.68434189e-14 467.25 5.68434189e-14 467.25 8.9 437.524 8.9 437.524 26.166 464.491 26.166 464.491 35.066 437.524 35.066 437.524 53.222 467.25 53.222 467.25 62.122"/>
        <polygon
          points="480.244 62.122 480.244 5.68434189e-14 490.301 5.68434189e-14 490.301 53.222 515.577 53.222 515.577 62.122"/>
      </g>
    </g>
  </svg>
)