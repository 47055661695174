import { Route } from "@biketravel/solid-router";
import { RouteProps } from "../renderer";
import { blogRoutes } from './blog';
import { trackRoutes } from "./track";
import { userRoutes } from "./user";
import { authRoutes } from "./auth";
import { eventRoutes } from "./event";
import { placeRoutes } from "./place";
import { staticRoutes } from "./static";
import { systemRoutes } from "./system";

export const routes: Route<RouteProps>[] = [
    ...blogRoutes,
    ...trackRoutes,
    ...userRoutes,
    ...authRoutes,
    ...eventRoutes,
    ...placeRoutes,
    ...staticRoutes,
    ...systemRoutes,
];