import type { Jwt } from '@biketravel/sdk';
import { createSignal } from "solid-js";
import { AppStore, permissionAdmin } from "..";
import Cookie from "js-cookie";
import { isServer } from "solid-js/web";

export const AUTH_COOKIE_NAME = 'authorization';

export const loadUser = (): Jwt | undefined => {
  const base64token = Cookie.get(AUTH_COOKIE_NAME);
  if (!base64token) {
    return;
  }

  return loadUserFromString(base64token);
};

export const loadUserFromString = (value: string): Jwt | undefined => {
  try {
    return JSON.parse(atob(value));
  } catch (e) {
    console.error(e);
    return;
  }
};

export type JwtStore = {
  jwt: Jwt | undefined;
}

const [
  jwt,
  setJwt,
] = createSignal<Jwt | undefined>(loadUser());

const persistJwt = (jwt: Jwt) => {
  if (isServer) {
    throw new Error("supported on client only");
  }

  let hostname = '';
  if (typeof window !== 'undefined') {
    hostname = window.location.hostname;
  }

  Cookie.set(AUTH_COOKIE_NAME, btoa(JSON.stringify(jwt)), {
    domain: `.${hostname}` // subdomain support
  });

  setJwt(jwt);
}

const removeJwt = () => {
  Cookie.remove(AUTH_COOKIE_NAME);
  setJwt(undefined);
};

const hasPermission = (permission: string): boolean => {
  const token = jwt();
  if (!token) {
    return false;
  }

  if (!Array.isArray(token.permissions)) {
    return false;
  }

  if (token.permissions.length === 0) {
    return false;
  }

  return token.permissions.indexOf(permission) > -1;
};

const isAdmin = (): boolean => hasPermission(permissionAdmin);

const isObjectOwner = (userId?: number) => jwt()?.id === userId || isAdmin();

type JwtActions = {
  setJwt: typeof setJwt,
  persistJwt: typeof persistJwt,
  removeJwt: typeof removeJwt,
  hasPermission: typeof hasPermission,
  isAdmin: typeof isAdmin,
  isObjectOwner: typeof isObjectOwner,
}

export const jwtStore = {
  jwt,
  actions: {
    setJwt,
    isObjectOwner,
    isAdmin,
    hasPermission,
    persistJwt,
    removeJwt,
  }
} satisfies AppStore<JwtStore, JwtActions>;