import { Component, createEffect, createSignal, onMount } from "solid-js";
import { InputSharedType } from "./types";
import { bem } from "classnames-bem";
import { DOMElement } from "solid-js/types/jsx";

export type InputTextareaType = InputSharedType<HTMLTextAreaElement>

export const InputTextarea: Component<InputTextareaType> = (props) => {
    const [
        value,
        setValue,
    ] = createSignal<string>(props.value || '');

    let ref: HTMLTextAreaElement | undefined;

    onMount(() => {
        if (ref && props.autofocus) {
            ref.focus();
        }
    });

    createEffect(() => setValue(props.value || ''));

    const onChange = (e: Event & { currentTarget: HTMLTextAreaElement; target: DOMElement }) => {
        setValue(e.currentTarget.value);
        props.onChange({
            value: e.currentTarget.value,
            event: e,
        })
    }

    return (
        <textarea
            class={bem('b-input', 'textarea', props.modifiers)}
            id={props.id}
            ref={ref}
            placeholder={props.placeholder}
            name={props.name}
            value={value()}
            onChange={e => onChange(e)}
        />
    )
}