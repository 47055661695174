import { JSX, ParentComponent } from "solid-js";
import { BemModifiers } from "./types";
import { bem } from "classnames-bem";

type AttentionProps = BemModifiers & {
    icon?: JSX.Element;
}

export const Attention: ParentComponent<AttentionProps> = (props) => (
    <div class={bem("b-attention", props.modifiers)}>
        {props.children}
        <div class={bem("b-attention__icon", props.modifiers)}>!</div>
    </div>
);
