import { createEffect } from "solid-js";

export type SwipeHookProps = SwipeHookCallback & {
    threshold?: number
    isOpen?: boolean
};

export type SwipeHookCallback = {
    left?: () => void
    right?: () => void
    up?: () => void
    down?: () => void
};

export type calculateSwipeProps = {
    touchStartX: number
    touchStartY: number
    touchEndX: number
    touchEndY: number
    swipeSpeed?: number // sec
    touchStartTime: number
    threshold?: number
}

export type CalculateSwipeReturn = {
    isUp?: boolean
    isDown?: boolean
    isLeft?: boolean
    isRight?: boolean
}

export const calculateSwipe = ({
    threshold = 100,
    touchStartTime,
    swipeSpeed = 1,
    touchEndY,
    touchEndX,
    touchStartY,
    touchStartX,
}: calculateSwipeProps): CalculateSwipeReturn => {
    const elapsedTime = (Date.now() - touchStartTime) / 1000;
    if (elapsedTime > swipeSpeed) {
        return {};
    }
    const xDistance = touchStartX - touchEndX;
    const yDistance = touchStartY - touchEndY;

    if (Math.abs(xDistance) < threshold && Math.abs(yDistance) < threshold) {
        return {};
    }

    if (Math.abs(xDistance) >= Math.abs(yDistance)) {
        if (xDistance > 0) {
            return { isRight: true };
        } else {
            return { isLeft: true };
        }
    } else {
        if (yDistance > 0) {
            return { isDown: true };
        } else {
            return { isUp: true };
        }
    }
};

export const useSwipe = ({
    left,
    right,
    up,
    down,
    isOpen,
    threshold = 100,
}: SwipeHookProps) => {
    const touchCoordsRef = {
        touchStart: {
            x: 0,
            y: 0,
            time: Date.now(),
        },
    };

    createEffect(() => {
        const handleTouchStart = (e: TouchEvent) => {
            touchCoordsRef.touchStart.x = e.targetTouches[0].clientX;
            touchCoordsRef.touchStart.y = e.targetTouches[0].clientY;
            touchCoordsRef.touchStart.time = Date.now();
        };
        const handleTouchEnd = (e: TouchEvent) => {
            const {
                isLeft,
                isRight,
                isUp,
                isDown,
            } = calculateSwipe({
                touchStartX: touchCoordsRef.touchStart.x,
                touchStartY: touchCoordsRef.touchStart.y,
                touchEndX: e.changedTouches[0].clientX,
                touchEndY: e.changedTouches[0].clientY,
                touchStartTime: touchCoordsRef.touchStart.time,
                threshold,
            });

            if (isLeft) {
                left?.();
            }

            if (isRight) {
                right?.();
            }

            if (isUp) {
                up?.();
            }

            if (isDown) {
                down?.();
            }
        };

        if (isOpen) {
            window.addEventListener('touchstart', handleTouchStart);
            window.addEventListener('touchend', handleTouchEnd);

            return () => {
                window.removeEventListener('touchstart', handleTouchStart);
                window.removeEventListener('touchend', handleTouchEnd);
            };
        } else {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
        }
    });
};
