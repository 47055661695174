import { DateTime } from "luxon";

export const isEqual = (a: DateTime, b: DateTime | null): boolean => {
    if (!b) {
        return false;
    }

    return a.year === b.year && a.month === b.month && a.day === b.day;
};

export const iterateYears = (date: DateTime, count: number): number[] => {
    const years: number[] = [];
    for (let i = count; i > 0; i--) {
        years.push(date.year - i);
    }
    years.push(date.year);
    for (let i = 1; i <= count; i++) {
        years.push(date.year + i);
    }
    return years;
};

export const iterateMonths = (): number[] => Array.from(Array(12).keys());

export const iterateDays = (dt: DateTime): readonly DateTime[] => {
    const prevDt = dt.minus({ month: 1 });
    const nextDt = dt.plus({ month: 1 });

    const dates: DateTime[] = [];
    const prevDays: DateTime[] = [];
    const nextDays: DateTime[] = [];

    const dayOffset = dt.set({ day: 1 }).weekday - 1;
    for (let z = prevDt.daysInMonth - dayOffset; z < prevDt.daysInMonth; z++) {
        prevDays.push(prevDt.set({ day: z + 1 }));
    }

    for (let i = 0; i < dt.daysInMonth; i++) {
        dates.push(dt.set({ day: i + 1 }));
    }

    const lastOffset = (7 * 6) - (dates.length + dayOffset); // columns * rows
    for (let z = 0; z < lastOffset; z++) {
        nextDays.push(nextDt.set({ day: z + 1 }));
    }

    return [
        ...prevDays,
        ...dates,
        ...nextDays,
    ];
};