import {
  Button,
  Collapse,
  Form,
  FormRow,
  getErrors,
  InputCheckboxRadio,
  InputText,
  InputTextarea,
  Label,
  Spinner,
  TagInput,
  ValidationError,
  ValidationErrorsType
} from "@biketravel/solid-ui";
import { TRACK_COLLECTION } from "@biketravel/state";
import { Accessor, Component, For } from "solid-js";
import { Info } from "luxon";
import { changeCallback, monthRows } from "./utils";

export type TrackFormType = {
  name: string;
  description: string;
  collection: string[];
  tags: string[];
  month: number[];
}

type TrackCreateFormProps = {
  errors: Accessor<ValidationErrorsType>
  values: Accessor<TrackFormType>
  setValues(values: TrackFormType): void;
  loading: Accessor<boolean | undefined>;
  onChange(name: keyof TrackFormType, value: string): void;
  onSubmit(): void;
}

export const TrackForm: Component<TrackCreateFormProps> = (props) => {
  const collectionsChangeCallback = (value: string, state?: boolean) => {
    props.setValues({
      ...props.values(),
      collection: changeCallback<string>(props.values().collection, value, state),
    });
  };

  const monthChangeCallback = (value: string, state?: boolean) => {
    props.setValues({
      ...props.values(),
      month: changeCallback<number>(props.values().month, parseInt(value, 10), state),
    });
  };

  return (
    <Form onSubmit={() => props.onSubmit()}>
      <FormRow modifiers='margin-bottom'>
        <Label for="name" required>Название маршрута</Label>
        <InputText
          autofocus={true}
          type='text'
          id='name'
          name='name'
          modifiers={{ error: getErrors('name', props.errors()).length > 0 }}
          value={props.values().name}
          onChange={({ value }) => props.onChange('name', value)}
        />
        <ValidationError path='name' errors={props.errors()}/>
      </FormRow>
      <FormRow modifiers='margin-bottom'>
        <Label required>Коллекции</Label>
        {TRACK_COLLECTION.map((col) => (
          <div class='b-checkbox__group'>
            <InputCheckboxRadio
              type='checkbox'
              id={col.type}
              name='collections'
              checked={props.values().collection.indexOf(col.type) > -1}
              value={col.type}
              onChange={({ value, checked }) => collectionsChangeCallback(value, checked)}
            />
            <Label for={col.type}>{col.name}</Label>
          </div>
        ))}
        <ValidationError path='collections' errors={props.errors()}/>
      </FormRow>
      <FormRow modifiers='margin-bottom'>
        <Label for="description">Описание маршрута</Label>
        <InputTextarea
          id='description'
          name='description'
          value={props.values().description}
          modifiers={{ error: getErrors('description', props.errors()).length > 0 }}
          onChange={({ value }) => props.onChange('description', value)}
        />
        <ValidationError path='description' errors={props.errors()}/>
      </FormRow>
      <Collapse title='Дополнительная информация'>
        <FormRow modifiers='margin-bottom'>
          <Label>Благоприятное время года</Label>
          <div class='b-track-create-form-months'>
            <For each={monthRows}>
              {(month) => (
                <div class='b-checkbox__group'>
                  <InputCheckboxRadio
                    type='checkbox'
                    id={`month${month}`}
                    name='month'
                    checked={props.values().month.indexOf(month) > -1}
                    value={String(month)}
                    onChange={({ value, checked }) => monthChangeCallback(value, checked)}
                  />
                  <Label for={`month${month}`}>
                    {Info.months()[month]}
                  </Label>
                </div>
              )}
            </For>
          </div>
          <ValidationError path='month' errors={props.errors()}/>
        </FormRow>
        <FormRow modifiers='margin-bottom'>
          <Label>Теги</Label>
          <TagInput
            removeOnBackspace={true}
            tags={props.values().tags}
            onChange={value => props.setValues({ ...props.values(), tags: value })}
          />
          <ValidationError path='tags' errors={props.errors()}/>
        </FormRow>
      </Collapse>
      <FormRow>
        <Button modifiers={['primary']} type="submit">
          Сохранить
        </Button>
      </FormRow>
      <Spinner overlay show={props.loading()}/>
    </Form>
  );
}