import { HomepageCategoryLink } from "./HomepageCategoryLink";

export const HomepageCategoryList = () => (
  <div class="b-track-list-category">
    <HomepageCategoryLink
      title='Прогулка'
      text='Безопасные маршруты для непродолжительных прогулок семьей и детьми'
      collection='stroll'
    />
    <HomepageCategoryLink
      title='Большие дистанции'
      text='Длинные дистанции и ультрамарафоны'
      collection='randonneur'
    />
    <HomepageCategoryLink
      title='Трейлы'
      text='Рельефные маршруты по пересечённой и горной местности'
      collection='trail'
    />
    <HomepageCategoryLink
      title='Туризм и путешествия'
      text='Многодневные путешествия и туристические маршруты'
      collection='tour'
    />
  </div>
)