import { isEqual, iterateDays } from "./utils";
import { locale } from "./locale";
import { DateTime } from "luxon";
import { FullDayCell } from "./FullDayCell";
import { CalendarEvent } from "./types";
import { Component, createMemo } from "solid-js";

export type FullCalendarDaysProps = {
    today: DateTime
    onClick?: (calendarEvent: CalendarEvent) => void
    events: { [key: string]: CalendarEvent[] }
    selected: DateTime
}

export const FullCalendarDays: Component<FullCalendarDaysProps> = (props) => {
    const dayRange = iterateDays(props.selected);

    const dayNames = createMemo(() => locale.ru.days.map((dayOfWeek, i) => (
        <div class='b-full-calendar-dayofweek'>
            {dayOfWeek}
        </div>
    )));

    const dayCells = createMemo(() => dayRange.map((date, i) => (
        <FullDayCell
            onClick={props.onClick}
            events={props.events[date.set({ millisecond: 0, second: 0, minute: 0, hour: 0 }).toISODate()] || []}
            isPrevious={date.month > props.selected.month}
            isNext={date.month < props.selected.month}
            selected={isEqual(date, props.today)}
            date={date}/>
    )));

    return (
        <div class="b-full-calendar__days">
            {dayNames()}
            {dayCells()}
        </div>
    );
};
