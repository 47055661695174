import { BemModifiers } from "./types";
import type { ParentComponent } from "solid-js";
import { splitProps } from "solid-js";
import { bem } from "classnames-bem";
import { Link, LinkProps } from "@biketravel/solid-router";
import { ButtonInner, ButtonUiType } from "./Button";
import cx from 'classnames';

type ButtonLinkType = BemModifiers & LinkProps & ButtonUiType;

export const ButtonLink: ParentComponent<ButtonLinkType> = (props) => {
    const [
        local,
        other
    ] = splitProps(props, [
        "to",
        "class",
        "params",
        "query",
        "replace",
        "modifiers",
        "children",
        "leftIcon",
        "rightIcon",
    ]);

    return (
        <Link
            to={local.to}
            params={local.params}
            query={local.query}
            replace={local.replace}
            class={cx(bem('b-button', local.modifiers), local.class)}
            {...other}
        >
            <ButtonInner leftIcon={local.leftIcon} rightIcon={local.rightIcon}>
                {props.children}
            </ButtonInner>
        </Link>
    );
}