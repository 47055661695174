import { ButtonLink, Divider, H1 } from "@biketravel/solid-ui";
import { Title } from "@solidjs/meta";
import { CircleIcon, Text } from "@biketravel/component";
import { FiArrowLeft, FiCheck } from "solid-icons/fi";

export const RestoreSuccessPage = () => (
    <>
        <Title>Восстановление пароля</Title>
        <CircleIcon modifiers='green' icon={<FiCheck/>}/>
        <H1 modifiers='center'>Восстановление пароля</H1>
        <Text>
            На ваш адрес электронной почты отправлено письмо с дальнейшими инструкциями для восстановления пароля.
        </Text>
        <Divider/>
        <ButtonLink to='auth_login' leftIcon={<FiArrowLeft/>} modifiers='block'>
            Вернуться к форме авторизации
        </ButtonLink>
    </>
);