import { Button, Form, FormRow, InputTextarea, Label, Spinner, ValidationError } from "@biketravel/solid-ui";
import { Component } from "solid-js";
import { WrapperFormProps } from "./types";

type CommentFormType = {
  comment: string;
}

type CommentFormProps = WrapperFormProps<CommentFormType> & {
  autofocus?: boolean;
}

export const CommentForm: Component<CommentFormProps> = (props) => (
  <Form onSubmit={() => props.onSubmit()}>
    <FormRow modifiers='margin-bottom'>
      <Label for="comment" required>Комментарий</Label>
      <InputTextarea
        autofocus={props.autofocus}
        id='comment'
        name='comment'
        value={props.values().comment}
        onChange={({ value }) => props.onChange('comment', value)}
      />
      <ValidationError path='comment' errors={props.errors()}/>
    </FormRow>
    <FormRow>
      <Button modifiers={['primary', 'login']} type="submit">
        Сохранить
      </Button>
    </FormRow>
    <Spinner overlay={true} show={props.loading()}/>
  </Form>
);