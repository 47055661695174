import {
  AuthApi,
  BlogApi,
  CommentApi,
  Configuration,
  EventApi,
  LocationApi,
  MarkerApi,
  PlaceApi,
  RouteApi,
  TrackApi,
  TrackPhotoApi
} from "./gen";
import { config } from "@biketravel/config";
import type { AxiosInstance } from "axios";
import { injectAuthInterceptor } from "./interceptor";

export interface SDK {
  auth: AuthApi;
  blog: BlogApi;
  location: LocationApi;
  track: TrackApi;
  trackPhoto: TrackPhotoApi;
  place: PlaceApi;
  comment: CommentApi;
  marker: MarkerApi;
  event: EventApi;
  route: RouteApi;

  inject(
    getAccessToken: () => string | undefined,
    refreshToken: () => Promise<string | undefined> | string | undefined,
    logout: () => void,
  ): void;
}

const getEndpoint = (string?: string) => string || config.GLOBAL_ENDPOINT;

export const createSDK = (instance: AxiosInstance): SDK => {
  return {
    auth: new AuthApi(new Configuration({ basePath: getEndpoint(config.AUTH_ENDPOINT) })),
    blog: new BlogApi(new Configuration({ basePath: getEndpoint(config.BLOG_ENDPOINT) })),
    location: new LocationApi(new Configuration({ basePath: getEndpoint(config.LOCATION_ENDPOINT) })),
    track: new TrackApi(new Configuration({ basePath: getEndpoint(config.TRACK_ENDPOINT) })),
    trackPhoto: new TrackPhotoApi(new Configuration({ basePath: getEndpoint(config.TRACK_ENDPOINT) })),
    place: new PlaceApi(new Configuration({ basePath: getEndpoint(config.PLACE_ENDPOINT) })),
    comment: new CommentApi(new Configuration({ basePath: getEndpoint(config.COMMENT_ENDPOINT) })),
    marker: new MarkerApi(new Configuration({ basePath: getEndpoint(config.MARKER_ENDPOINT) })),
    event: new EventApi(new Configuration({ basePath: getEndpoint(config.EVENT_ENDPOINT) })),
    route: new RouteApi(new Configuration({ basePath: getEndpoint(config.ROUTE_ENDPOINT) })),

    inject(
      getAccessToken: () => string,
      refreshToken: () => (Promise<string> | string),
      logout: () => void,
    ) {
      injectAuthInterceptor(instance, getAccessToken, refreshToken, logout);
    }
  } satisfies SDK;
}
