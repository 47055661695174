import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";

export type TrackRemoveState = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<TrackRemoveState["loading"]>(false);

const remove = async (id: number) => withLoading(setLoading, async () => {
  await sdk.track.trackRemove(id);
});

type TrackRemoveActions = {
  remove: typeof remove,
}

export const trackRemoveStore = {
  loading,
  actions: {
    remove,
  }
} satisfies AppStore<TrackRemoveState, TrackRemoveActions>;
