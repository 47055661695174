import type { DataLayerInterface } from "./baseLayer";
import type { GeoJSONSource, Map } from "maplibre-gl";
import { PulsingMarker } from "./PulsingMarker";
import { featureCollection, point } from "@turf/helpers";
import type { Point } from "geojson";

const sourceId = 'dot-point';
const imageId = 'pulsing-dot';

export class PulsingLayer implements DataLayerInterface {
  protected map: Map;
  protected lnglat?: [number, number];

  constructor(map: Map) {
    this.map = map;
    map.once('load', () => {
      this.initialize();
    });
  }

  createLayers() {
    this.map.addImage(imageId, new PulsingMarker(this.map), { pixelRatio: 2 });

    this.map.addSource(sourceId, {
      type: 'geojson',
      data: featureCollection<Point>([]),
    });
    this.map.addLayer({
      id: sourceId,
      source: sourceId,
      type: 'symbol',
      layout: { 'icon-image': imageId },
    });
  }

  initialize(): void {
    this.createLayers();

    this.map.on('style.load', () => {
      this.createLayers();
      if (this.lnglat) {
        this.setPoint(this.lnglat);
      }
    });
  }

  setPoint(lnglat: [number, number]): void {
    this.lnglat = lnglat;
    const layer = this.map.getSource(sourceId) as GeoJSONSource;
    if (layer) {
      layer.setData(featureCollection<Point>([point(lnglat)]));
    }
  }
}
