import { Route } from "@biketravel/solid-router";
import { AboutPage, HomePage } from "../page";
import { DefaultLayout } from "../Layout";
import { RouteProps } from "../renderer";

export const staticRoutes: Route<RouteProps>[] = [
  {
    name: 'home',
    path: '/',
    component: HomePage,
    props: {
      layout: DefaultLayout,
    }
  },
  {
    name: 'about',
    path: '/about',
    component: AboutPage,
    props: {
      layout: DefaultLayout,
    }
  },
];