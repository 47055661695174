import { createSignal } from "solid-js";
import type { Comment, CommentList } from "@biketravel/sdk";
import { CommentUpdate } from "@biketravel/sdk";
import { AppStore, removeFromArray, sdk } from "..";
import { withLoading } from "../decorator";

export type CommentListState = {
  data: CommentList;
  loading: boolean;
}

const initialState: CommentListState = {
  data: {
    edge: [],
    cursor: undefined
  },
  loading: false,
}

type FetchFilter = {
  ownerId?: number;
  ownerType?: string;
}

const [
  filter,
  setFilter
] = createSignal<FetchFilter>({});

const [
  comments,
  setComments
] = createSignal<CommentListState['data']>(initialState.data);

const [
  loading,
  setLoading
] = createSignal<boolean>(initialState.loading);

const resetAction = () => {
  setLoading(initialState.loading);
  setComments(initialState.data);
}

const commentListAction = async (
  ownerId: number,
  ownerType: string,
  cursor?: number,
  merge = false,
) => withLoading(setLoading, async () => {
  const isChanged = entityChanged(ownerType, ownerId);
  if (isChanged) {
    resetAction();
  }

  const needFetch = !isChanged ? fetchIfHasMore() : true;

  if (!needFetch) {
    return;
  }

  setFilter({ ownerType, ownerId });

  const {
    data,
  } = await sdk.comment.commentList(
    ownerId,
    ownerType,
    cursor,
  );

  if (!isChanged && merge && data.edge) {
    setComments({
      edge: [
        ...(comments().edge ?? []),
        ...data.edge,
      ],
      cursor: data.cursor,
    });
  } else {
    setComments(data);
  }
});

const setUpdatedComment = (id: number, comment: CommentUpdate) => {
  const data = comments().edge!;
  const index = data.findIndex((c) => c.id === id);
  if (index === -1) {
    return;
  }

  const newArray = [...data];
  newArray.splice(index, 1, {
    ...data[index],
    ...comment,
  });

  setComments({
    cursor: comments().cursor,
    edge: newArray,
  });
}

const entityChanged = (newOwnerType: string, newOwnerId: number): boolean => {
  const {
    ownerType,
    ownerId
  } = filter();

  if (newOwnerType != ownerType) {
    return true;
  }

  return newOwnerId != ownerId;
}

const fetchIfHasMore = (): boolean => {
  const {
    cursor,
  } = comments();

  return !cursor || !!cursor?.has_more;
}

const removeComment = (id: number) => {
  const newArray = removeFromArray<Comment>(comments().edge!, id);
  if (typeof newArray === 'undefined') {
    return;
  }

  setComments({
    cursor: comments().cursor,
    edge: newArray,
  });
}

export type CommentListActionsType = {
  fetch: typeof commentListAction;
  reset: typeof resetAction;
  setUpdatedComment: typeof setUpdatedComment;
  removeComment: typeof removeComment;
}

export const commentListStore = {
  data: comments,
  loading,
  actions: {
    reset: resetAction,
    fetch: commentListAction,
    setUpdatedComment: setUpdatedComment,
    removeComment: removeComment,
  }
} satisfies AppStore<CommentListState, CommentListActionsType>;