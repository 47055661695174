import { createSignal } from "solid-js";
import { commentListStore, LoadingType, sdk } from "@biketravel/state";
import { CommentUpdate } from "@biketravel/sdk";
import { AppStore } from "..";
import { withLoading } from "../decorator";

export type CommentUpdateState = LoadingType

const [
  loading,
  setLoading,
] = createSignal<CommentUpdateState["loading"]>();

const update = async (id: number, input: CommentUpdate) => withLoading(setLoading, async () => {
  await sdk.comment.commentUpdate(id, input);

  commentListStore.actions.setUpdatedComment(id, input);
});

type CommentUpdateActions = {
  update: typeof update,
}

export const commentUpdateStore = {
  loading,
  actions: {
    update,
  }
} satisfies AppStore<CommentUpdateState, CommentUpdateActions>;