import type { RestoreConfirmRequest } from '@biketravel/sdk';
import { createSignal } from "solid-js";
import { AppStore, jwtStore, sdk } from "..";
import { LoadingType } from "@biketravel/state";
import { withLoading } from "../decorator";

export type RestoreConfirmStore = LoadingType

const [
  loading,
  setLoading,
] = createSignal<RestoreConfirmStore["loading"]>(false);

const restoreConfirm = async (input: RestoreConfirmRequest) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.auth.restoreConfirm(input);

  jwtStore.actions.persistJwt(data);
});

type RestoreConfirmActions = {
  restoreConfirm: typeof restoreConfirm,
}

export const restoreConfirmStore = {
  loading,
  actions: {
    restoreConfirm,
  }
} satisfies AppStore<RestoreConfirmStore, RestoreConfirmActions>;