import {
  Button,
  Form,
  FormRow,
  InputSelect,
  InputText,
  Label,
  Spinner,
  useForm,
  ValidationError
} from "@biketravel/solid-ui";
import { useCurrentRoute, useNavigate } from '@biketravel/solid-router';
import { Component, createEffect } from "solid-js";
import { EventFilterFormType, eventListStore } from "@biketravel/state";

type EventFilterFormProps = {
  filter?: {
    userId?: number
  }
}

export const EventFilterForm: Component<EventFilterFormProps> = (props) => {
  const { navigate } = useNavigate();
  const currentRoute = useCurrentRoute<never, EventFilterFormType>();

  const {
    errors,
    values,
    onChange,
  } = useForm<EventFilterFormType>(currentRoute().query);

  createEffect(async () => {
    const value: EventFilterFormType = {
      ...currentRoute().query,
      ...props.filter,
    };

    const newValues = eventListStore.actions.filterConvert(value);
    if (eventListStore.actions.shouldUpdate(newValues)) {
      await eventListStore.actions.fetch(newValues);
    }
  })

  const onSubmit = async () => {
    const current = currentRoute();
    const newValues = eventListStore.actions.filterConvert(values());
    if (current.name && eventListStore.actions.shouldUpdate(newValues)) {
      navigate(current.name, current.params, values());
    }
  };

  return (
    <Form modifiers='filter' onSubmit={() => onSubmit()}>
      <FormRow>
        <Label for='name'>Поиск по названию</Label>
        <InputText
          type='text'
          placeholder='Название события'
          name='name'
          id='name'
          value={values().name}
          onChange={({ value }) => onChange('name', value)}
        />
        <ValidationError path='name' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Label for='distanceMin'>Расстояние от</Label>
        <InputText
          type='text'
          placeholder='От N километров'
          name='distanceMin'
          id='distanceMin'
          value={values().distance_min}
          onChange={({ value }) => onChange('distance_min', value)}
        />
        <ValidationError path='distanceMin' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Label for='distanceMax'>Расстояние до</Label>
        <InputText
          type='number'
          placeholder='До N километров'
          name='distanceMax'
          id='distanceMax'
          value={values().distance_max}
          onChange={({ value }) => onChange('distance_max', value)}
        />
        <ValidationError path='distanceMax' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Label for='membersCount'>Кол-во участников (от)</Label>
        <InputText
          type='number'
          placeholder='Количество участников от'
          name='membersCount'
          id='membersCount'
          value={values().members_count}
          onChange={({ value }) => onChange('members_count', value)}
        />
        <ValidationError path='membersCount' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Label for='collection'>Подборка</Label>
        <InputSelect
          placeholder='Подборка'
          options={[
            ['', ''],
            ['trail', 'Трейл'],
            ['randonneur', 'Бревет (официальные спортивные мероприятия)'],
            ['tour', 'Туризм'],
            ['stroll', 'Прогулка'],
          ]}
          id='collection'
          name='collection'
          value={values().collection}
          onChange={({ value }) => onChange('collection', value)}
        />
        <ValidationError path='collection' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Label for='expired'>Показать завершенные</Label>
        <InputSelect
          placeholder='Подборка'
          options={[
            ['', ''],
            ['1', 'Да'],
            ['0', 'Нет'],
          ]}
          id='expired'
          name='expired'
          value={values().expired}
          onChange={({ value }) => onChange('expired', value)}
        />
        <ValidationError path='expired' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Button modifiers={["primary", 'block']} type="submit">
          Поиск
        </Button>
      </FormRow>
      <Spinner overlay show={eventListStore.loading()}/>
    </Form>
  );
}