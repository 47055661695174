import { BreadcrumbItem, H1 } from "@biketravel/solid-ui";
import { AppBreadcrumbs, SidebarList, TrackFilterForm, TrackList } from "@biketravel/component";
import { Title } from "@solidjs/meta";

export const TrackListPage = () => (
    <>
        <Title>Маршруты</Title>
        <AppBreadcrumbs>
            <BreadcrumbItem>
                Маршруты
            </BreadcrumbItem>
        </AppBreadcrumbs>
        <H1>Маршруты</H1>
        <SidebarList filter={<TrackFilterForm/>}>
            <TrackList/>
        </SidebarList>
    </>
);