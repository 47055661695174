import { bem } from "classnames-bem";
import { InputSharedType } from "./types";
import { Component, createEffect, createSignal, JSX, onMount, splitProps } from "solid-js";
import { DOMElement } from "solid-js/types/jsx";

type WithoutOnChangeEvent = Omit<JSX.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>

type InputTextType =
  Partial<WithoutOnChangeEvent> &
  InputSharedType<HTMLInputElement> &
  {
    type: 'password' | 'text' | 'email' | 'search' | 'number';
  }

export const InputText: Component<InputTextType> = (props) => {
  const [
    mainProps,
    rest
  ] = splitProps(props, [
    'type',
    'value',
    'autofocus',
    'modifiers',
    'placeholder',
    'id',
    'name',
  ]);

  const [
    value,
    setValue,
  ] = createSignal<string>(mainProps.value || '');

  let ref: HTMLInputElement | undefined;

  onMount(() => {
    if (ref && mainProps.autofocus) {
      ref.focus();
    }
  });

  const onChange = (e: Event & { currentTarget: HTMLInputElement; target: DOMElement }) => {
    setValue(e.currentTarget.value);
    props.onChange({
      value: e.currentTarget.value,
      event: e,
    })
  }

  createEffect(() => {
    setValue(mainProps.value || '');
  });

  return (
    <input
      {...rest}
      class={bem('b-input', 'text', mainProps.modifiers)}
      type={mainProps.type}
      ref={ref}
      placeholder={mainProps.placeholder}
      id={mainProps.id}
      name={mainProps.name}
      value={value()}
      onChange={e => onChange(e)}
    />
  );
}