import { Menu, MenuDivider, MenuItem, Popover } from '@biketravel/solid-ui';
import { jwtStore } from '@biketravel/state';
import { Component, createSignal, Show } from "solid-js";
import { useNavigate } from '@biketravel/solid-router';
import cls from "../global/Header.module.scss";
import type { User } from '@biketravel/sdk';

type UserProfileMenuProps = {
  user: User;
}

export const UserProfileMenu: Component<UserProfileMenuProps> = (props) => {
  const { navigate } = useNavigate();

  const [
    visible,
    setVisible,
  ] = createSignal(false);

  const [
    targetRef,
    setTargetRef,
  ] = createSignal<Element | undefined>();

  const onLogout = () => {
    setVisible(false);
    if (!window.confirm('Вы действительно хотите выйти?')) {
      return;
    }

    jwtStore.actions.removeJwt();
    navigate('home');
  };

  return (
    <Show when={jwtStore.jwt()}>
      <button
        class={cls.link}
        ref={setTargetRef}
        onclick={() => setVisible(!visible())}>
        {props.user.name}
      </button>
      <Popover
        targetRef={targetRef()}
        visible={visible}
        setVisible={setVisible}>
        <Menu>
          <MenuItem to='user_profile'>
            Профиль
          </MenuItem>
          <MenuDivider/>
          <MenuItem onClick={() => onLogout()}>
            Выход
          </MenuItem>
        </Menu>
      </Popover>
    </Show>
  );
}