import { Link } from '@biketravel/solid-router';
import { jwtStore, meStore } from "@biketravel/state";
import { MobileMenu } from "./MobileMenu";
import { Dropdown, DropdownContent, Menu, MenuDivider, MenuItem, Spinner } from "@biketravel/solid-ui";
import { Logo } from "./Logo";
import { UserProfileMenu } from "../user";
import cls from "./Header.module.scss";
import { onMount, Show } from "solid-js";

export const Header = () => {
  onMount(async () => {
    if (!meStore.me()) {
      await meStore.actions.fetch();
    }
  });

  return (
    <div class={cls.header}>
      <Link to="home" title="BikeTravel" class="b-logo__img">
        <Logo class='b-logo__svg'/>
      </Link>
      <div class={cls.menu}>
        <Dropdown>
          <Link to="track_list" class={cls.link}>Маршруты</Link>
          <DropdownContent>
            <Menu>
              <MenuItem to="track_create">
                Создать маршрут
              </MenuItem>
              <MenuItem to="track_upload">
                Загрузить GPX
              </MenuItem>
              <MenuDivider/>
              <MenuItem to="track_my_list">
                Мои маршруты
              </MenuItem>
              <MenuItem to="track_draft_list">
                Черновики маршрутов
              </MenuItem>
            </Menu>
          </DropdownContent>
        </Dropdown>
        <Dropdown>
          <Link to="event_list" class={cls.link}>Поездки</Link>
          <DropdownContent>
            <Menu>
              <MenuItem to="event_calendar">
                Календарь поездок
              </MenuItem>
              <MenuItem to="event_my_list">
                Мои поездки
              </MenuItem>
            </Menu>
          </DropdownContent>
        </Dropdown>
        <Link to="place_map" class={cls.link}>Места</Link>
        <Link to="blog" class={cls.link}>Блог</Link>
        <Link to="about" class={cls.link}>О проекте</Link>
      </div>
      <div class={cls.menu}>
        <Show
          when={!meStore.loading()}
          fallback={<Spinner show={true}/>}
        >
          <Show
            when={meStore.me() && jwtStore.jwt()}
            fallback={(
              <Link to="auth_login" class={cls.link}>Авторизация</Link>
            )}
          >
            <UserProfileMenu
              user={meStore.me()!}
            />
          </Show>
        </Show>
      </div>
      <MobileMenu/>
    </div>
  );
}