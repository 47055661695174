import { UserAvatar } from "../user";
import { Link } from "@biketravel/solid-router";
import { SegmentContent } from "@biketravel/solid-ui";
import { Component, For, onMount, Show } from "solid-js";
import { eventMembersStore } from "@biketravel/state";

export type EventMembersProps = {
  event_id: number;
}

export const EventMembers: Component<EventMembersProps> = (props) => {
  onMount(async () => {
    await eventMembersStore.actions.fetch(props.event_id);
  });

  return (
    <Show when={eventMembersStore.members().length > 0} fallback={(
      <SegmentContent>Нет участников</SegmentContent>
    )}>
      <table class='b-table'>
        <thead class="b-table__thead">
        <tr class="b-table__tr">
          <th class="b-table__th" style={{ width: '32px' }}></th>
          <th class="b-table__th">Пользователь</th>
        </tr>
        </thead>
        <tbody class="b-table__tbody">
        <For each={eventMembersStore.members()}>
          {(user, i) => (
            <tr class="b-table__tr">
              <td class="b-table__td">
                <UserAvatar
                  modifiers=''
                  width={32}
                  height={32}
                  name={user.name}
                  avatar={user.avatar}
                />
              </td>
              <td class="b-table__td">
                <Link
                  class='b-user-card'
                  to='user_view'
                  params={{ user_id: user.id }}>
                  {user.name}
                </Link>
              </td>
            </tr>
          )}
        </For>
        </tbody>
      </table>
    </Show>
  );
}
