import cx from 'classnames';
import { Overlay } from './Overlay';
import { bem } from 'classnames-bem';
import { Component, ParentComponent, splitProps } from "solid-js";
import { BemModifiers } from "./types";

export type DialogProps = BemModifiers & {
    title: string;
    isOpen: boolean;
    class?: string;
    onClose?: () => void;
}

export const Dialog: ParentComponent<DialogProps> = (props) => {
    const [
        local,
        others,
    ] = splitProps(props, ["modifiers", "class"]);

    return (
        <Overlay
            {...others}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            onClose={props.onClose}
            isOpen={props.isOpen}
            class='b-overlay--scroll'
            hasBackdrop={true}>
            <div class={bem('b-dialog__container', local.modifiers)}>
                <div class={cx(bem('b-dialog__dialog', local.modifiers), local.class)}>
                    <DialogHeader onClose={props.onClose}>
                        {props.title}
                    </DialogHeader>
                    <DialogBody>
                        {props.children}
                    </DialogBody>
                </div>
            </div>
        </Overlay>
    );
}

export type DialogHeaderProps = BemModifiers & {
    onClose?: () => void;
}

export const DialogHeader: ParentComponent<DialogHeaderProps> = (props) => (
    <div class={bem('b-dialog__header', props.modifiers)}>
        <div class={bem('b-dialog__title', props.modifiers)}>
            {props.children}
        </div>
        <DialogClose onClick={() => props.onClose && props.onClose()}/>
    </div>
);

type DialogBodyProps = BemModifiers

export const DialogBody: ParentComponent<DialogBodyProps> = (props) => (
    <div class={bem('b-dialog__body', props.modifiers)}>
        {props.children}
    </div>
);

export const DialogFooter: ParentComponent = (props) => (
    <div class="b-dialog__footer">
        {props.children}
    </div>
);

type DialogCloseProps = {
    width?: number
    height?: number
    class?: string
    onClick?: () => void
}

export const DialogClose: Component<DialogCloseProps> = (props) => (
    <svg
        onClick={props.onClick}
        class='b-dialog__close'
        width={props.width || 27}
        height={props.height || 27}
        viewBox="0 0 27 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.63 24.784a1 1 0 11-1.414-1.414L11.586 13 1.216 2.63A1 1 0 112.63 1.216L13 11.586l10.37-10.37a1 1 0 111.414 1.414L14.414 13l10.37 10.37a1 1 0 11-1.414 1.414L13 14.414 2.63 24.784z"
            fill="#5C5C7B"
        />
    </svg>
);
