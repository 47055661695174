import { Component, mergeProps } from "solid-js";
import cls from './Pagination.module.scss';
import { Pages } from "./Pages";
import { WithBuildUrl, WithOnChange } from "./types";

type PaginationProps = WithOnChange & WithBuildUrl & {
  page: number | string;
  pageCount?: number;
  scrollToTop?: boolean;
};

export const Pagination: Component<PaginationProps> = (props) => {
  const mergedProps = mergeProps({
    page: 1,
    pageCount: 1,
    scrollToTop: true,
  }, props);

  const page = parseInt(String(mergedProps.page), 10);

  const onPageChangeHandle = (newPage: number) => {
    if (newPage === page) {
      return;
    }

    if (mergedProps.scrollToTop) {
      window.scrollTo(0, 0);
    }

    mergedProps.onChange && mergedProps.onChange(Number(newPage));
  };

  return (
    <div class={cls.pager}>
      <Pages
        buildUrl={props.buildUrl}
        page={page}
        totalPages={mergedProps.pageCount}
        onChange={onPageChangeHandle}
      />
    </div>
  );
};
