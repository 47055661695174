import cx from 'classnames';
import { bem, Modifiers } from 'classnames-bem';
import { BemModifiers } from "./types";
import { ParentComponent } from "solid-js";

type HeadingProps = BemModifiers & {
    class?: string;
}

const bemBaseClass = 'b-heading';

const headingClass = (size: string, modifiers?: Modifiers, className?: string): string => (
    cx(bem(bemBaseClass, size, modifiers), className)
);

export type headingSizes =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'

export type headingModifiers = { [key in headingSizes]: string }

export const headingClasses: headingModifiers = {
    h1: 'xxlarge',
    h2: 'xlarge',
    h3: 'large',
    h4: 'medium',
    h5: 'small',
    h6: 'xsmall',
};

export const H1: ParentComponent<HeadingProps> = (props) => (
    <h1 class={headingClass(headingClasses['h1'], props.modifiers, props.class)}>
        {props.children}
    </h1>
);

export const H2: ParentComponent<HeadingProps> = (props) => (
    <h2 class={headingClass(headingClasses['h2'], props.modifiers, props.class)}>
        {props.children}
    </h2>
);

export const H3: ParentComponent<HeadingProps> = (props) => (
    <h3 class={headingClass(headingClasses['h3'], props.modifiers, props.class)}>
        {props.children}
    </h3>
);

export const H4: ParentComponent<HeadingProps> = (props) => (
    <h4 class={headingClass(headingClasses['h4'], props.modifiers, props.class)}>
        {props.children}
    </h4>
);

export const H5: ParentComponent<HeadingProps> = (props) => (
    <h5 class={headingClass(headingClasses['h5'], props.modifiers, props.class)}>
        {props.children}
    </h5>
);

export const H6: ParentComponent<HeadingProps> = (props) => (
    <h6 class={headingClass(headingClasses['h6'], props.modifiers, props.class)}>
        {props.children}
    </h6>
);
