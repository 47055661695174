import { Route } from "@biketravel/solid-router";
import {
    RegistrationConfirmPage,
    RegistrationFailPage,
    RegistrationPage,
    RegistrationSuccessPage,
    RestoreCheckPage,
    RestoreConfirmPage,
    RestoreFailPage,
    RestorePage,
    RestoreSuccessPage,
    SignIn
} from "../page";
import { AuthLayout } from "../Layout";
import { RouteProps } from "../renderer";

export const authRoutes: Route<RouteProps>[] = [
    {
        path: '/auth',
        children: [
            {
                name: 'auth_login',
                path: '/login',
                component: SignIn,
                props: {
                    layout: AuthLayout,
                }
            },
            {
                path: '/registration',
                children: [
                    {
                        name: 'auth_registration',
                        path: '',
                        component: RegistrationPage,
                        props: {
                            layout: AuthLayout,
                        }
                    },
                    {
                        name: 'auth_registration_success',
                        path: '/success',
                        component: RegistrationSuccessPage,
                        props: {
                            layout: AuthLayout,
                        }
                    },
                    {
                        name: 'auth_registration_fail',
                        path: '/fail',
                        component: RegistrationFailPage,
                        props: {
                            layout: AuthLayout,
                        }
                    },
                    {
                        name: 'auth_registration_confirm',
                        path: '/confirm/:token',
                        component: RegistrationConfirmPage,
                        props: {
                            layout: AuthLayout,
                        }
                    },
                ]
            },
            {
                path: '/restore',
                children: [
                    {
                        name: 'auth_restore',
                        path: '',
                        component: RestorePage,
                        props: {
                            layout: AuthLayout,
                        }
                    },
                    {
                        name: 'auth_restore_success',
                        path: '/success',
                        component: RestoreSuccessPage,
                        props: {
                            layout: AuthLayout,
                        }
                    },
                    {
                        name: 'auth_restore_check',
                        path: '/check/:token',
                        component: RestoreCheckPage,
                        props: {
                            layout: AuthLayout,
                        }
                    },
                    {
                        name: 'auth_restore_confirm',
                        path: '/confirm/:token',
                        component: RestoreConfirmPage,
                        props: {
                            layout: AuthLayout,
                        }
                    },
                    {
                        name: 'auth_restore_fail',
                        path: '/fail',
                        component: RestoreFailPage,
                        props: {
                            layout: AuthLayout,
                        }
                    },
                ]
            }
        ]
    },
];