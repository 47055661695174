import { BaseControl } from "./BaseControl";

export type UndoRedoControlOptions = {
  onUndo(): void
  onRedo(): void
}

export class UndoRedoControl extends BaseControl {
  private options: UndoRedoControlOptions;

  constructor(options: UndoRedoControlOptions) {
    super();
    this.options = options;
  }

  createContent() {
    const container = document.createElement('div');
    const undoButton = this.createButton('undo', 'Undo', () => {
      this.options.onUndo();
    });
    const redoButton = this.createButton('redo', 'Redo', () => {
      this.options.onRedo();
    });
    container.appendChild(undoButton);
    container.appendChild(redoButton);

    return container;
  }
}
