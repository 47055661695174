import { Route } from "@biketravel/solid-router";
import {
    MarkerListPage,
    TrackCreatePage,
    TrackDraftListPage,
    TrackListPage,
    TrackMyListPage,
    TrackUpdatePage,
    TrackUploadPage,
    TrackViewPage,
} from "../page";
import { DefaultLayout, WideLayout } from "../Layout";
import { RouteProps } from "../renderer";
import { TrackFilterFormType, trackListStore } from "@biketravel/state";
import { TrackContentPage } from "../page/track/TrackContentPage";

export const trackRoutes: Route<RouteProps>[] = [
    {
        path: '/track',
        children: [
            {
                name: 'track_list',
                path: '',
                component: TrackListPage,
                props: {
                    layout: DefaultLayout,
                    fetchServer: async ({ query }: { query: TrackFilterFormType }) => {
                        const newValues = trackListStore.actions.filterConvert(query);
                        await trackListStore.actions.fetch(newValues);
                    }
                }
            },
            {
                name: 'track_my_list',
                path: '/my',
                component: TrackMyListPage,
                props: {
                    auth: true,
                    layout: DefaultLayout,
                }
            },
            {
                name: 'track_draft_list',
                path: '/draft',
                component: TrackDraftListPage,
                props: {
                    auth: true,
                    layout: DefaultLayout,
                }
            },
            {
                name: 'track_create',
                path: '/create',
                component: TrackCreatePage,
                props: {
                    layout: WideLayout,
                }
            },
            {
                name: 'track_upload',
                path: '/upload',
                component: TrackUploadPage,
                props: {
                    auth: true,
                    layout: DefaultLayout,
                }
            },
            {
                path: '/:track_id(\\d+)',
                children: [
                    {
                        path: '',
                        name: 'track_view',
                        component: TrackViewPage,
                        props: {
                            layout: DefaultLayout,
                        },
                    },
                    {
                        path: '/edit',
                        name: 'track_edit',
                        component: TrackUpdatePage,
                        props: {
                            auth: true,
                            layout: WideLayout,
                        },
                    },
                    {
                        path: '/update',
                        name: 'track_update',
                        component: TrackContentPage,
                        props: {
                            auth: true,
                            layout: DefaultLayout,
                        },
                    },
                    {
                        path: '/marker/edit',
                        name: 'track_marker_edit',
                        component: MarkerListPage,
                        props: {
                            auth: true,
                            layout: WideLayout,
                        },
                    },
                ]
            },
        ]
    },
];
