import { createSignal } from "solid-js";
import { AppStore, jwtStore, sdk } from "..";
import { LoadingType } from "@biketravel/state";
import { LoginRequest } from "@biketravel/sdk";
import { withLoading } from "../decorator";

export type LoginStore = LoadingType

const [
  loading,
  setLoading,
] = createSignal<LoginStore["loading"]>(false);

const login = async (input: LoginRequest) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.auth.login(input);

  jwtStore.actions.persistJwt(data);
});

type LoginActions = {
  login: typeof login,
}

export const loginStore = {
  loading,
  actions: {
    login,
  }
} satisfies AppStore<LoginStore, LoginActions>;