import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";
import type { EventMembers } from "@biketravel/sdk";

export type EventMembersState = EventMembers & LoadingType;

const initialState: EventMembersState = {
  loading: undefined,
  members: []
}

const [
  loading,
  setLoading,
] = createSignal<EventMembersState["loading"]>(initialState.loading);

const [
  members,
  setMembers,
] = createSignal<EventMembersState["members"]>(initialState.members);

const fetch = async (id: number | string) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.event.eventMembers(parseInt(String(id), 10));

  setMembers(data.members);
});

export type EventMembersActionsType = {
  fetch: typeof fetch,
}

export const eventMembersStore = {
  members,
  loading,
  actions: {
    fetch,
  }
} satisfies AppStore<EventMembersState, EventMembersActionsType>;