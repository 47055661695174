import { ImageProxyConfig } from "@biketravel/imgproxy";
import { getEnv } from "./load";
import { validateConfig } from "./validate";

export type EndpointConfig = {
  GLOBAL_ENDPOINT: string;
  TRACK_ENDPOINT: string;
  AUTH_ENDPOINT: string;
  BLOG_ENDPOINT: string;
  EVENT_ENDPOINT: string;
  ROUTE_ENDPOINT: string;
  PLACE_ENDPOINT: string;
  MARKER_ENDPOINT: string;
  COMMENT_ENDPOINT: string;
  LOCATION_ENDPOINT: string;
  IMGPROXY_URL: string;
  CDN_URL: string;
  TILE_CACHE_URL: string;
  SENTRY_DSN: string;
  CI_COMMIT_SHORT_SHA: string;
  STRAVA_HEATMAP: string;
}

export type AppConfig = Partial<& EndpointConfig & ImageProxyConfig>;

declare global {
  interface Window {
    __CONFIG__: AppConfig;
  }
}

const buildConfig = (): AppConfig => {
  return validateConfig<AppConfig>({
    GLOBAL_ENDPOINT: getEnv('GLOBAL_ENDPOINT'),
    TRACK_ENDPOINT: getEnv('TRACK_ENDPOINT'),
    AUTH_ENDPOINT: getEnv('AUTH_ENDPOINT'),
    BLOG_ENDPOINT: getEnv('BLOG_ENDPOINT'),
    EVENT_ENDPOINT: getEnv('EVENT_ENDPOINT'),
    ROUTE_ENDPOINT: getEnv('ROUTE_ENDPOINT'),
    PLACE_ENDPOINT: getEnv('PLACE_ENDPOINT'),
    MARKER_ENDPOINT: getEnv('MARKER_ENDPOINT'),
    COMMENT_ENDPOINT: getEnv('COMMENT_ENDPOINT'),
    LOCATION_ENDPOINT: getEnv('LOCATION_ENDPOINT'),
    IMGPROXY_URL: getEnv('IMGPROXY_URL'),
    CDN_URL: getEnv('CDN_URL'),
    TILE_CACHE_URL: getEnv('TILE_CACHE_URL'),
    SENTRY_DSN: getEnv('SENTRY_DSN'),
    CI_COMMIT_SHORT_SHA: getEnv('CI_COMMIT_SHORT_SHA'),
    STRAVA_HEATMAP: getEnv('STRAVA_HEATMAP'),
  } satisfies AppConfig);
}

export const config: AppConfig = buildConfig();

