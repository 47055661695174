import type { PlaceUpdate } from '@biketravel/sdk';
import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";

export type PlaceUpdateState = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<PlaceUpdateState["loading"]>(false);

const update = async (id: number, input: PlaceUpdate) => withLoading(setLoading, async () => {
  await sdk.place.placeUpdate(id, input);
})

type PlaceUpdateActions = {
  update: typeof update,
}

export const placeUpdateStore = {
  loading,
  actions: {
    update,
  }
} satisfies AppStore<PlaceUpdateState, PlaceUpdateActions>;