export const clean = (value: string) => {
  return value.replace(/\/\//g, "/");
}

export const removeTrailingSlash = (value: string) => {
  return value.substring(value.length - 1) === '/'
    ? value.substring(0, value.length - 1)
    : value;
}

export const removePrefixedSlash = (value: string) => {
  return value.substring(0, 1) === '/'
    ? value.substring(1)
    : value;
}
