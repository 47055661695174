import { Component } from "solid-js";
import { bem } from 'classnames-bem';
import { Link } from "@biketravel/solid-router";

type HomepageCategoryLinkType = {
  title: string;
  text: string;
  collection: string;
}

export const HomepageCategoryLink: Component<HomepageCategoryLinkType> = (props) => (
  <Link
    to="track_list"
    query={{ "collection": props.collection }}
    class={bem("b-track-list-category__item", props.collection)}
  >
        <span class="b-track-list-category__title">
            {props.title}
        </span>
    <span class="b-track-list-category__text">
            {props.text}
        </span>
  </Link>
)