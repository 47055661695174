import { bem } from "classnames-bem";
import { DateTime } from "luxon";
import { onChangeDate } from "./types";
import { Component } from "solid-js";

export type DayCellProps = {
    date: DateTime,
    selected?: boolean
    isPrevious: boolean
    isNext: boolean
    onChange: onChangeDate
}

export const DayCell: Component<DayCellProps> = (props) => {
    const isWeekend = props.date.weekday > 5;

    const cls = bem('b-calendar-cell', {
        selected: props.selected,
        next: props.isNext,
        previous: props.isPrevious,
        weekend: !props.isNext && !props.isPrevious && isWeekend,
    });

    return (
        <div
            onClick={() => props.onChange(props.date)}
            class={cls}>
            {props.date.day}
        </div>
    );
};