import { blogPostsStore } from "@biketravel/state";
import { Component, createEffect, Show } from "solid-js";
import { Spinner } from "@biketravel/solid-ui";
import { BlogListContainer } from "./BlogListContainer";
import { useCurrentRoute, useNavigate } from "@biketravel/solid-router";

type BlogListProps = {
  page?: number;
  pageSize?: number;
}

export const BlogList: Component<BlogListProps> = (props) => {
  const route = useCurrentRoute();
  const { navigate } = useNavigate();

  createEffect(async () => {
    await blogPostsStore.actions.fetch(props.page, props.pageSize);
  });

  const onChange = (page: number) => {
    navigate(route().name, route().params, {
      ...route().query,
      page,
    })
  }

  return (
    <Show when={!blogPostsStore.loading()} fallback={<Spinner show={true}/>}>
      <Show when={blogPostsStore.blogPosts()}>
        {posts => (
          <BlogListContainer
            edge={posts().edge}
            pager={posts().pager}
            onChange={onChange}
          />
        )}
      </Show>
    </Show>
  );
}