import { Title } from "@solidjs/meta";
import { BreadcrumbItem, H1 } from "@biketravel/solid-ui";
import { AppBreadcrumbs, BlogList } from "@biketravel/component";
import { useCurrentRoute } from '@biketravel/solid-router';

export const BlogPostListPage = () => {
  const route = useCurrentRoute<{}, {
    page?: number;
    pageSize?: number;
  }>();

  const {
    page,
    pageSize
  } = route().query;

  return (
    <>
      <Title>Блог</Title>
      <AppBreadcrumbs>
        <BreadcrumbItem>
          Блог
        </BreadcrumbItem>
      </AppBreadcrumbs>
      <H1>Блог</H1>
      <BlogList
        page={page}
        pageSize={pageSize}
      />
    </>
  );
}