import { createSignal } from "solid-js";
import { AppStore, ExportableStore, LoadingType, sdk } from "..";
import type { Track } from "@biketravel/sdk";
import { withLoading } from "../decorator";

export type TrackViewState = {
  track: Track | undefined;
}

export type TrackViewClientState = TrackViewState & LoadingType;

const initialState: TrackViewClientState = {
  track: undefined,
  loading: undefined,
}

const [
  track,
  setTrack,
] = createSignal<TrackViewState['track']>(initialState.track);

const [
  loading,
  setLoading,
] = createSignal<TrackViewClientState['loading']>(initialState.loading);

const trackViewAction = async (id: number) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.track.trackView(id);

  setTrack(data);
});

const setDraftStatus = (id: number, is_draft: boolean) => {
  const entity = track();
  if (entity?.id !== id) {
    return;
  }

  setTrack({
    ...entity,
    is_draft
  } satisfies Track);
};


export type TrackViewActionsType = {
  setTrack: typeof setTrack,
  setDraftStatus: typeof setDraftStatus,
  fetch(id: number): Promise<void>
}

export const trackViewStore = {
  track,
  loading,
  actions: {
    setDraftStatus: setDraftStatus,
    fetch: trackViewAction,
    setTrack: setTrack,
  },
  exportState() {
    return {
      track: track(),
    }
  },
  importState(value) {
    setTrack(value.track);
  }
} satisfies AppStore<TrackViewClientState, TrackViewActionsType> & ExportableStore<TrackViewState>;
