import { config } from '@biketravel/config';
import { clean, removePrefixedSlash, removeTrailingSlash } from "./utils";

export const getCDNUrl = (path: string): string => {
  if (!config.CDN_URL) {
    throw new Error("config.cdn is missing or empty");
  }

  return [
    removeTrailingSlash(config.CDN_URL),
    removePrefixedSlash(clean(path)),
  ].join('/');
};
