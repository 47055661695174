import { AppStore, captureError, jwtStore, sdk } from "..";
import { AxiosError } from "axios";

const refreshAction = async () => {
  const refreshToken = jwtStore.jwt()?.refresh_token;
  if (!refreshToken) {
    return
  }

  try {
    const resp = await sdk.auth.refreshToken({
      headers: {
        Authorization: `Bearer ${jwtStore.jwt()?.refresh_token}`
      }
    });

    jwtStore.actions.persistJwt(resp.data);

    return resp.data;
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      return error.response?.data.errors;
    }

    captureError(error);
  }
};

export type RefreshActionsType = {
  refresh: typeof refreshAction
}

export const refreshStore = {
  actions: {
    refresh: refreshAction,
  },
} satisfies AppStore<{}, RefreshActionsType>;