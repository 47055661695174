import { Component } from "solid-js";
import { commentCreateStore } from "@biketravel/state";
import { CommentForm } from "./CommentForm";
import { useForm } from "@biketravel/solid-ui";
import { CommentFormInput } from "./types";

type CommentCreateFormProps = {
  ownerType: string;
  ownerId: number;
  onSuccess(): void;
}

export const CommentCreateForm: Component<CommentCreateFormProps> = (props) => {
  const initial: CommentFormInput = {
    comment: '',
  }

  const {
    errors,
    setErrors,
    values,
    setValues,
    onChange,
  } = useForm<CommentFormInput>(initial);

  const onSubmit = async () => {
    const errors = await commentCreateStore.actions.create(
      {
        ...values(),
        owner_type: props.ownerType,
        owner_id: props.ownerId,
      },
    );

    if (errors) {
      setErrors(errors);
    } else {
      setErrors([]);
      setValues(initial);
      props.onSuccess();
    }
  };

  return (
    <CommentForm
      onChange={onChange}
      loading={commentCreateStore.loading}
      onSubmit={onSubmit}
      values={values}
      errors={errors}
    />
  );
}