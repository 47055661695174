import type { Position } from "geojson";
import { createEmptyRoute } from "./routeUtils";
import { createGhostFeatureCollection } from "../route/helper";
import type { TrackRoute } from "@biketravel/sdk";

export type RouteState = {
  route: TrackRoute
  routeHistory: TrackRoute[]
  points: Position[]
  pointsHistory: Position[][]
  ghostLines: Position[][],
  ghostLinesHistory: Position[][][],
  index: number
}

export enum RouteActionType {
  UNDO = 'undo',
  REDO = 'redo',
  ADD = 'add',
}

export type RouteAction = {
  type: RouteActionType
  route?: TrackRoute
  points?: Position[]
  ghostLines?: Position[][]
}

const emptyRoute = createEmptyRoute();

export const createStateFromRoute = (route: TrackRoute, editable: boolean): RouteState => {
  const points = (
    editable
      ? [route.user_waypoints[0], route.user_waypoints[route.user_waypoints.length - 1]]
      : route.user_waypoints
  );
  const ghost = createGhostFeatureCollection(
    route.waypoints as [number, number][],
    route.user_waypoints as [number, number][],
    route.segments,
  ).features.map(f => f.geometry.coordinates);

  return {
    route: route,
    index: 0,
    routeHistory: [route],
    points: points,
    pointsHistory: [points],
    ghostLines: ghost,
    ghostLinesHistory: [ghost],
  };
};

export const createEmptyRouteState = (): RouteState => ({
  index: 0,
  route: emptyRoute,
  routeHistory: [emptyRoute],
  points: [],
  pointsHistory: [[]],
  ghostLines: [],
  ghostLinesHistory: [[]],
});

export const reducer = (state: RouteState, action: RouteAction) => {
  switch (action.type) {
    case RouteActionType.ADD:
      if (!action.route || !action.points || !action.ghostLines) {
        throw new Error();
      }

      if (action.points.length < 2) {
        throw new Error();
      }

      return {
        index: state.index + 1,
        route: { ...action.route },
        routeHistory: [...state.routeHistory.slice(0, state.index + 1), { ...action.route }],
        points: [...action.points],
        pointsHistory: [...state.pointsHistory.slice(0, state.index + 1), [...action.points]],
        ghostLines: [...action.ghostLines],
        ghostLinesHistory: [...state.ghostLinesHistory.slice(0, state.index + 1), [...action.ghostLines]],
      };

    case RouteActionType.UNDO:
      if ((state.index - 1) < 0) {
        return state;
      }

      return {
        ...state,
        index: state.index - 1,
        route: state.routeHistory[state.index - 1],
        points: state.pointsHistory[state.index - 1],
        ghostLines: state.ghostLinesHistory[state.index - 1],
      };

    case RouteActionType.REDO:
      if ((state.index + 1) === state.routeHistory.length) {
        return state;
      }

      return {
        ...state,
        index: state.index + 1,
        route: state.routeHistory[state.index + 1],
        points: state.pointsHistory[state.index + 1],
        ghostLines: state.ghostLinesHistory[state.index + 1],
      };

    default:
      throw new Error();
  }
};
