export function validateConfig<T extends object>(config: T) {
  const keys = Object.keys(config);
  const values = Object.keys(config);

  for (let i = 0; i < keys.length; i++) {
    const value = values[i];
    if (!value || String(value).length === 0) {
      throw new Error(`${keys[i]} is empty`);
    }
  }

  return config;
}