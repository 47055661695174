import { Component } from "solid-js";
import { Meta, Title } from "@solidjs/meta";
import { AppBreadcrumbs, TrackUpdateForm } from "@biketravel/component";
import { toast } from "solid-toast";
import { useNavigate } from "@biketravel/solid-router";
import { BreadcrumbItem, BreadcrumbSymbol } from "@biketravel/solid-ui";
import type { Track } from "@biketravel/sdk";

type TrackContentPageContainerProps = {
    track: Track;
}

export const TrackContentPageContainer: Component<TrackContentPageContainerProps> = (props) => {
    const { navigate } = useNavigate();

    const onSuccess = () => {
        toast.success('Маршрут сохранен');
        navigate('track_view', {
            track_id: props.track.id,
        });
    }

    return (
        <>
            <Meta>
                <Title>Редактирование маршрута: {props.track.name || `Черновик маршрута #${props.track.id}`}</Title>
            </Meta>
            <AppBreadcrumbs>
                <BreadcrumbItem to='track_list'>
                    Маршруты
                </BreadcrumbItem>
                <BreadcrumbSymbol/>
                <BreadcrumbItem to="track_view" params={{ track_id: props.track.id }}>
                    {props.track.name || `Черновик маршрута #${props.track.id}`}
                </BreadcrumbItem>
                <BreadcrumbSymbol/>
                <BreadcrumbItem>
                    Редактирование маршрута
                </BreadcrumbItem>
            </AppBreadcrumbs>
            <TrackUpdateForm
                track={props.track}
                onSuccess={onSuccess}
            />
        </>
    );
};
