import { ParentComponent } from "solid-js";
import { removeExtraWhitespace } from "./blogUtils";

type TypesetType = {
    content?: string
}

export const Typeset: ParentComponent<TypesetType> = (props) => props.content ? (
    <div class="b-typeset" innerHTML={removeExtraWhitespace(props.content)}/>
) : (
    <div class="b-typeset">
        {props.children}
    </div>
);