import { Title } from "@solidjs/meta";
import { BreadcrumbItem, H1 } from "@biketravel/solid-ui";
import { AppBreadcrumbs, EventFilterForm, EventList, SidebarList } from "@biketravel/component";

export const EventListPage = () => (
    <>
        <Title>Поездки</Title>
        <AppBreadcrumbs>
            <BreadcrumbItem>
                Поездки
            </BreadcrumbItem>
        </AppBreadcrumbs>
        <H1>Поездки</H1>
        <SidebarList filter={<EventFilterForm/>}>
            <EventList/>
        </SidebarList>
    </>
);