import { Link } from "@biketravel/solid-router";
import { dateTimeToMediumDate, stringToDateTime } from "@biketravel/solid-ui";
import { Info } from "luxon";
import { Component, For } from "solid-js";
import { getCollectionNames, getDifficult } from "@biketravel/state";
import type { Track } from "@biketravel/sdk";

type TrackSummaryProps = {
  track: Omit<Track, 'route'>
}

export const TrackSummary: Component<TrackSummaryProps> = (props) => (
  <div class="b-event-summary-content">
    <dl class="b-event-summary">
      <dt class="b-event-summary__label">Сложность:</dt>
      <dd class="b-event-summary__value">
        {getDifficult(props.track.difficult)}
      </dd>
    </dl>
    <dl class="b-event-summary">
      <dt class="b-event-summary__label">Дистанция:</dt>
      <dd class="b-event-summary__value">
        {props.track.distance.toFixed(2)} км
      </dd>
    </dl>
    <dl class="b-event-summary">
      <dt class="b-event-summary__label">Подборки:</dt>
      <dd class="b-event-summary__value">
        <For each={getCollectionNames(props.track.collection)}>
          {(col, i) => (
            <Link
              class='b-track-list-item__collection'
              to='track_list'
              query={{ collection: col.type }}>
              {col.name}
            </Link>
          )}
        </For>
      </dd>
    </dl>
    <dl class="b-event-summary">
      <dt class="b-event-summary__label">Месяца для маршрута:</dt>
      <dd class="b-event-summary__value">
        {props.track.month.length > 0 ? props.track.month.map(month => Info.months()[month - 1]).join(', ') : 'Не указано'}
      </dd>
    </dl>
    <dl class="b-event-summary">
      <dt class="b-event-summary__label">Дата создания:</dt>
      <dd class="b-event-summary__value">
        {dateTimeToMediumDate(stringToDateTime(props.track.created_at))}
      </dd>
    </dl>
  </div>
);
