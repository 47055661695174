import { Component, For, onMount, Show } from "solid-js";
import { eventListStore } from "@biketravel/state";
import {
  dateTimeToMediumDate,
  dateTimeToMediumTime,
  Spinner,
  SpinnerGlobal,
  stringToDateTime
} from "@biketravel/solid-ui";
import { EventEmpty } from "./EventEmpty";
import { Link } from '@biketravel/solid-router';

type EventTrackListComponentProps = {
  track_id: number;
}

export const EventTrackListComponent: Component<EventTrackListComponentProps> = (props) => {
  onMount(async () => {
    await eventListStore.actions.fetch({
      track_id: props.track_id,
    });
  });

  return (
    <>
      <Show
        when={!eventListStore.loading() && eventListStore.events()?.edge && eventListStore.events()!.edge?.length === 0}
        fallback={(
          <table class='b-table'>
            <thead class="b-table__thead">
            <tr class="b-table__tr">
              <th class="b-table__th">Название</th>
              <th class="b-table__th">Кол-во участников</th>
              <th class="b-table__th">Дата старта</th>
              <th class="b-table__th">Время старта</th>
            </tr>
            </thead>
            <For each={eventListStore.events().edge}>
              {(event) => (
                <tr class='b-table__tr'>
                  <td class='b-table__td'>
                    <Link to='event_view' params={{ event_id: event.id }}>
                      {event.name}
                    </Link>
                  </td>
                  <td class='b-table__td'>
                    {event.member_count || 0}
                  </td>
                  <td class='b-table__td'>
                    {dateTimeToMediumDate(stringToDateTime(event.start_at))}
                  </td>
                  <td class='b-table__td'>
                    {dateTimeToMediumTime(stringToDateTime(event.start_at))}
                  </td>
                </tr>
              )}
            </For>
          </table>
        )}>
        <EventEmpty/>
      </Show>
      <Spinner show={eventListStore.loading()} overlay={true}/>
      <SpinnerGlobal show={eventListStore.loading()}/>
    </>
  );
}
