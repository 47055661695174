import type { Accessor } from 'solid-js';
import { batch, createSignal } from 'solid-js';
import { ValidationErrorsType } from "./types";

type ValuesType<T> = {
    values: Accessor<T>;
    setValues(newValue: T): void;
}

type ErrorsType = {
    errors: Accessor<ValidationErrorsType>;
    setErrors(newValue: ValidationErrorsType): void;
}

type UtilsType<T> = {
    onChange(name: keyof T, value: string): void;
    onCmdEnterSubmit(callback: () => void): (e: KeyboardEvent) => void;
}

type FormHookType<T> = ValuesType<T> & ErrorsType & UtilsType<T>;

export function useForm<T>(
    initialValue: T
): FormHookType<T> {
    const [
        errors,
        setErrors,
    ] = createSignal<ValidationErrorsType>([]);

    const [
        values,
        setValues,
    ] = createSignal<T>(initialValue);

    const onChange = (name: keyof T, value: unknown) => {
        batch(() => {
            setValues(values => ({
                ...values,
                [name]: value
            }));
            setErrors([]);
        });
    };

    const onCmdEnterSubmit = (callback: () => void) => (e: KeyboardEvent) => {
        const isEnter = e.code === "Enter" || e.code === "NumpadEnter";
        const isCtrl = e.ctrlKey || e.metaKey;
        if (isEnter && isCtrl) {
            e.preventDefault();
            e.stopPropagation();
            callback();
        }
    };

    return {
        errors,
        setErrors,
        values,
        setValues,
        onChange,
        onCmdEnterSubmit,
    };
}