import {
  Button,
  Form,
  FormRow,
  InputSelect,
  InputText,
  Label,
  Spinner,
  useForm,
  ValidationError
} from "@biketravel/solid-ui";
import { useCurrentRoute, useNavigate } from '@biketravel/solid-router';
import { Component, createEffect } from "solid-js";
import { TrackFilterFormType, trackListStore } from "@biketravel/state";

export const fromQueryToFormValues = (values: TrackFilterFormType): TrackFilterFormType => ({
  name: values.name ?? '',
  distance_min: values.distance_min ? String(values.distance_min) : '',
  distance_max: values.distance_max ? String(values.distance_max) : '',
  collection: values.collection ?? '',
});

export const TrackFilterForm: Component = (props) => {
  const { navigate } = useNavigate();
  const currentRoute = useCurrentRoute<never, TrackFilterFormType>();

  const {
    errors,
    values,
    onChange,
  } = useForm<TrackFilterFormType>(fromQueryToFormValues(currentRoute().query));

  createEffect(async () => {
    const value = fromQueryToFormValues(values());
    const newValues = trackListStore.actions.filterConvert(value);
    if (trackListStore.actions.filterChanged(newValues)) {
      await trackListStore.actions.fetch(newValues);
    }
  });

  const onSubmit = async () => {
    const current = currentRoute();
    const newValues = trackListStore.actions.filterConvert(values());
    if (current.name && trackListStore.actions.filterChanged(newValues)) {
      navigate(current.name, current.params, newValues);
    }
  };

  return (
    <Form modifiers='filter' onSubmit={() => onSubmit()}>
      <FormRow>
        <Label for='name'>Поиск по названию</Label>
        <InputText
          type='text'
          placeholder='Название события'
          name='name'
          value={values().name}
          onChange={({ value }) => onChange('name', value)}
        />
        <ValidationError path='name' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Label for='distanceMin'>Расстояние от</Label>
        <InputText
          type='number'
          placeholder='От N километров'
          name='distanceMin'
          value={values().distance_min}
          onChange={({ value }) => onChange('distance_min', value)}
        />
        <ValidationError path='distanceMin' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Label for='distanceMax'>Расстояние до</Label>
        <InputText
          type='number'
          placeholder='До N километров'
          name='distanceMax'
          value={values().distance_max}
          onChange={({ value }) => onChange('distance_max', value)}
        />
        <ValidationError path='distanceMax' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Label for='collection'>Подборка</Label>
        <InputSelect
          placeholder='Подборка'
          options={[
            ['', ''],
            ['trail', 'Трейл'],
            ['randonneur', 'Бревет (официальные спортивные мероприятия)'],
            ['tour', 'Туризм'],
            ['stroll', 'Прогулка'],
          ]}
          name='collection'
          value={values().collection}
          onChange={({ value }) => onChange('collection', value)}
        />
        <ValidationError path='collection' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Button modifiers={["primary", 'block']} type="submit">
          Поиск
        </Button>
      </FormRow>
      <Spinner overlay show={trackListStore.loading()}/>
    </Form>
  );
}