import { TrackListItem } from "./TrackListItem";
import { For, Show } from "solid-js";
import { trackListStore } from "@biketravel/state";
import { InfinityScroll, Spinner, SpinnerGlobal } from "@biketravel/solid-ui";
import { TrackEmpty } from "./TrackEmpty";

export const TrackList = () => {
  const onVisible = async () => {
    const data = trackListStore.data();
    if (!trackListStore.loading() && data?.cursor?.has_more) {
      await trackListStore.actions.fetch(
        trackListStore.filter(),
        data.cursor.cursor,
        true,
      );
    }
  };

  const isEmpty = () => !trackListStore.loading() && (trackListStore.data()?.edge || []).length === 0;

  return (
    <InfinityScroll onVisible={onVisible}>
      <Show when={isEmpty()}>
        <TrackEmpty/>
      </Show>
      <For each={trackListStore.data().edge}>
        {(track, i) => (
          <TrackListItem track={track}/>
        )}
      </For>
      <Spinner overlay={true} show={trackListStore.loading()}/>
      <SpinnerGlobal show={trackListStore.loading()}/>
    </InfinityScroll>
  )
}