import { ButtonLink, Divider, H1 } from "@biketravel/solid-ui";
import { Title } from "@solidjs/meta";
import { CircleIcon, Text } from "@biketravel/component";
import { FiArrowLeft, FiX } from "solid-icons/fi";

export const RestoreFailPage = () => (
    <>
        <Title>Ошибка восстановления пароля</Title>
        <CircleIcon modifiers='red' icon={<FiX/>}/>
        <H1 modifiers='center'>Ошибка восстановления пароля</H1>
        <Text>
            Некорректный или устаревший токен
        </Text>
        <Divider/>
        <ButtonLink to='auth_login' leftIcon={<FiArrowLeft/>} modifiers='block'>
            Вернуться к форме авторизации
        </ButtonLink>
    </>
);