import { Component, createEffect, createSignal, For, onMount } from "solid-js";
import { InputSharedType } from "./types";
import { bem } from "classnames-bem";
import { DOMElement } from "solid-js/types/jsx";

export type InputSelectType = InputSharedType<HTMLSelectElement> & {
    options?: [string, string][];
}

export const InputSelect: Component<InputSelectType> = (props) => {
    const [
        value,
        setValue,
    ] = createSignal<string>(props.value || '');

    let ref: HTMLSelectElement | undefined;

    onMount(() => {
        if (ref && props.autofocus) {
            ref.focus();
        }
    });

    const onChange = (e: Event & { currentTarget: HTMLSelectElement; target: DOMElement }) => {
        setValue(e.currentTarget.value);
        props.onChange({
            value: e.currentTarget.value,
            event: e,
        })
    }

    createEffect(() => setValue(props.value || ''));

    return (
        <select
            name={props.name}
            ref={ref}
            class={bem('b-input', 'select', props.modifiers)}
            id={props.id}
            onChange={e => onChange(e)}
            value={value()}
        >
            <For each={props.options}>
                {(option, i) => {
                    const [key, value] = option;

                    return (
                        <option value={key}>{value}</option>
                    );
                }}
            </For>
        </select>
    );
}