import { points, Position } from "@turf/helpers";
import { DecodedRoute } from '@biketravel/map';
import center from "@turf/center";
import type { Track } from "@biketravel/sdk";
import { CenterType } from "../map";

export const trackName = (track: Track): string => (
  track.name ? track.name : `Черновик маршрута #${track.id}`
);

export const monthRows = [11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const changeCallback = <T, >(values: T[], value: T, state?: boolean): T[] => {
  if (state) {
    return [...values, value];
  }

  const idx = values.indexOf(value);
  if (idx > -1) {
    const newValues = [...values];
    newValues.splice(idx, 1);
    return newValues;
  }

  return values;
};

export const findRouteCenter = (route: DecodedRoute): CenterType => {
  const coords: Position[] = [];
  for (let i = 0; i < route.segments.length; i++) {
    coords.push(...route.segments[i]);
  }

  // lng, lat
  const [
    lng,
    lat
  ] = center(points(coords)).geometry.coordinates;

  return [lng, lat];
}