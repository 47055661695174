import { Route } from "@biketravel/solid-router";
import { PlaceMapPage, PlaceViewPage } from "../page";
import { DefaultLayout, WideLayout } from "../Layout";
import { RouteProps } from "../renderer";
import { placeViewStore } from "@biketravel/state";
import { NotFoundError } from "./errors";

export const placeRoutes: Route<RouteProps>[] = [
    {
        path: '/place',
        children: [
            {
                name: 'place_map',
                path: '',
                component: PlaceMapPage,
                props: {
                    layout: WideLayout,
                }
            },
            {
                path: '/:place_id(\\d+)',
                name: 'place_view',
                component: PlaceViewPage,
                props: {
                    layout: DefaultLayout,
                    fetchServer: async ({ params }: { params: { place_id: number | string } }) => {
                        const id = parseInt(String(params.place_id), 10);

                        const resp = await placeViewStore.actions.fetch(id);
                        if (!resp?.entity) {
                            throw new NotFoundError();
                        }
                    }
                },
            },
        ],
    },
];