import { eventRemoveStore, jwtStore } from "@biketravel/state";
import { Component, createSignal, Show } from "solid-js";
import { Menu, MenuDivider, MenuItem, Popover } from "@biketravel/solid-ui";
import { FiMoreVertical } from "solid-icons/fi";
import { BsPencil, BsTrash } from "solid-icons/bs";
import type { Event, User } from '@biketravel/sdk';

export type EventMenuProps = {
  user?: User
  event: Omit<Event, 'route' | 'track'>
  onRemove?: () => void
}

export const EventMenu: Component<EventMenuProps> = (props) => {
  const [
    visible,
    setVisible,
  ] = createSignal(false);

  const [
    targetRef,
    setTargetRef,
  ] = createSignal<Element | undefined>();

  const deleteCallback = async (id: number) => {
    if (window.confirm('Вы действительно хотите удалить поездку?')) {
      await eventRemoveStore.actions.remove(id);
      props.onRemove && props.onRemove();
    }
  };

  return (
    <Show when={jwtStore.actions.isObjectOwner(props.event.author_id)}>
      <button
        onclick={() => setVisible(!visible())}
        ref={setTargetRef}
        class='b-more-button'>
        <FiMoreVertical/>
      </button>
      <Popover
        targetRef={targetRef()}
        visible={visible}
        setVisible={setVisible}
      >
        <Menu>
          <MenuItem
            icon={<BsPencil/>}
            to='event_edit'
            params={{ event_id: props.event.id }}>
            Редактировать
          </MenuItem>
          <MenuDivider/>
          <MenuItem
            icon={<BsTrash/>}
            onClick={() => deleteCallback(props.event.id)}
            params={{ event_id: props.event.id }}>
            Удалить
          </MenuItem>
        </Menu>
      </Popover>
    </Show>
  );
};
