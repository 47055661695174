import { BreadcrumbItem, BreadcrumbSymbol, H1 } from "@biketravel/solid-ui";
import { AppBreadcrumbs, SidebarList, TrackFilterForm, TrackList } from "@biketravel/component";
import { Title } from "@solidjs/meta";
import { jwtStore, trackListStore } from "@biketravel/state";

export const TrackMyListPage = () => {
    trackListStore.actions.reset();

    const jwt = jwtStore.jwt()!;

    return (
        <>
            <Title>Мои маршруты</Title>
            <AppBreadcrumbs>
                <BreadcrumbItem to='track_list'>
                    Маршруты
                </BreadcrumbItem>
                <BreadcrumbSymbol/>
                <BreadcrumbItem>
                    Мои маршруты
                </BreadcrumbItem>
            </AppBreadcrumbs>
            <H1>Мои маршруты</H1>
            <SidebarList filter={<TrackFilterForm filter={{ user_id: jwt.id }}/>}>
                <TrackList/>
            </SidebarList>
        </>
    );
}