import { Component, ParentProps } from "solid-js";
import { Header } from "@biketravel/component";

export const WideLayout: Component<ParentProps> = (props) => (
    <div class='b-app__container'>
        <header class='b-app__header'>
            <Header/>
        </header>
        <main class='b-app__main'>
            {props.children}
        </main>
    </div>
)