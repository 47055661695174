import { Component } from "solid-js";
import { Link } from "@biketravel/solid-router";
import { ButtonLink, Image, Typeset } from "@biketravel/solid-ui";
import type { BlogPostList } from "@biketravel/sdk";

type BlogListItemType = {
  blogPost: BlogPostList["edge"][number]
}

export const BlogListItem: Component<BlogListItemType> = (props) => (
  <div class="b-blog-short">
    <Link to='blog_post' params={{ blog_post_id: props.blogPost.id }} class="b-blog-short__image-link">
      <Image
        width={1248}
        height={320}
        class="b-blog-short__image"
        src={props.blogPost.image}
        alt={props.blogPost.name}
      />
    </Link>
    <Link to='blog_post' params={{ blog_post_id: props.blogPost.id }} class="b-blog-short__name">
      {props.blogPost.name}
    </Link>
    <div class="b-blog-short__content">
      <Typeset content={props.blogPost.contentShort}/>
    </div>
    <div class="b-blog-short__footer">
      <ButtonLink
        to='blog_post'
        params={{ blog_post_id: props.blogPost.id }}
        modifiers='primary'
        rightIcon={(<>&rarr;</>)}
      >
        Читать далее
      </ButtonLink>
    </div>
  </div>
)