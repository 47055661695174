import type { IControl, Map } from "maplibre-gl";

export abstract class BaseControl implements IControl {
  protected _map?: Map;
  protected _container?: HTMLDivElement;

  onAdd(map: Map): HTMLElement {
    this._map = map;
    return this.createContainer(this.createContent());
  }

  abstract createContent(): HTMLElement;

  protected createContainer(node: Node): HTMLDivElement {
    this._container = document.createElement('div');
    this._container.className = 'maplibregl-ctrl maplibregl-ctrl-group mapboxgl-ctrl mapboxgl-ctrl-group';
    this._container.appendChild(node);

    return this._container;
  }

  protected createButton(icon: string, label: string, onClick: (e: Event) => void): HTMLButtonElement {
    const button = document.createElement('button');
    button.type = "button";
    button.title = label;
    button.ariaLabel = label;
    button.className = `maplibregl-ctrl-${icon} mapboxgl-ctrl-${icon}`;
    button.onclick = onClick;

    const span = document.createElement('span');
    span.className = 'maplibregl-ctrl-icon mapboxgl-ctrl-icon';
    span.ariaHidden = 'true';
    button.appendChild(span);

    return button;
  }

  onRemove() {
    this._container?.parentNode?.removeChild(this._container);
    this._map = undefined;
  }
}
