import { Header, Footer } from "@biketravel/component";
import { Component, ParentProps } from "solid-js";

export const DefaultLayout: Component<ParentProps> = (props) => (
    <div class='b-app__container'>
        <header class='b-app__header'>
            <Header/>
        </header>
        <main class='b-app__main'>
            <div class='b-app__content'>
                {props.children}
            </div>
        </main>
        <footer class="b-app__footer">
            <Footer />
        </footer>
    </div>
)