import { useForm } from "@biketravel/solid-ui";
import { Component } from "solid-js";
import { placeUpdateStore } from "@biketravel/state";
import { PlaceForm, PlaceFormType } from "./PlaceForm";
import { toast } from "solid-toast";
import type { Place } from '@biketravel/sdk';

type PlaceUpdateFormProps = {
  place: Place;
  onSuccess(): void;
}

export const PlaceUpdateForm: Component<PlaceUpdateFormProps> = (props) => {
  const {
    errors,
    setErrors,
    values,
    onChange,
  } = useForm<PlaceFormType>({
    description: props.place.description,
    name: props.place.name,
  });

  const onSubmit = async () => {
    const formErrors = await placeUpdateStore.actions.update(props.place.id, {
      ...values(),
      lat: props.place.lat,
      lng: props.place.lng,
    });
    if (formErrors) {
      setErrors(formErrors);
    } else {
      toast.success('Выполнено');
      props.onSuccess();
    }
  };

  return (
    <PlaceForm
      onChange={onChange}
      loading={placeUpdateStore.loading}
      onSubmit={onSubmit}
      values={values}
      errors={errors}
    />
  );
}