import { Spinner } from "@biketravel/solid-ui";
import { onMount, Show } from "solid-js";
import { useCurrentRoute } from "@biketravel/solid-router";
import { trackViewStore } from "@biketravel/state";
import { TrackViewPageContainer } from "./TrackViewPageContainer";

export const TrackViewPage = () => {
    const currentRoute = useCurrentRoute<{
        track_id: number,
    }>();

    onMount(async () => {
        await trackViewStore.actions.fetch(currentRoute().params.track_id)
    });

    return (
        <Show when={!trackViewStore.loading() && trackViewStore.track()} fallback={<Spinner/>}>
            <TrackViewPageContainer
                track={trackViewStore.track()!}
            />
        </Show>
    );
}
