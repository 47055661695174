import { onChangeDate } from "./types";
import { DateTime } from "luxon";
import { Accessor, Component, mergeProps } from "solid-js";

export type TimeSelectProps = {
    selected: Accessor<DateTime>;
    onChange: onChangeDate;
    interval?: number;
}

export const TimeSelect: Component<TimeSelectProps> = (props) => {
    const merged = mergeProps({
        interval: 5,
    }, props);

    const hours = Array.from(
        { length: 23 },
        (v, i) => String(i).padStart(2, '0'),
    );
    const minutes = Array.from(
        { length: 60 / merged.interval },
        (v, i) => String(i * merged.interval).padStart(2, '0'),
    );

    return (
        <div class="b-calendar__time">
            <select
                class='b-input b-input--select'
                value={String(props.selected().hour).padStart(2, '0')}
                onChange={e => {
                    let hour = parseInt((e.target as HTMLSelectElement).value);
                    if (hour < 0) {
                        hour = 23;
                    }
                    if (hour >= 24) {
                        hour = 0;
                    }
                    props.onChange(props.selected().set({ hour }));
                }}>
                {hours.map(i => (
                    <option value={i}>{i}</option>
                ))}
            </select>
            <span class="b-calendar__time_divider">:</span>
            <select
                class='b-input b-input--select'
                value={String(Math.ceil(props.selected().minute / merged.interval) * merged.interval).padStart(2, '0')}
                onChange={e => {
                    let minute = parseInt((e.target as HTMLSelectElement).value);
                    if (minute < 0) {
                        minute = 59;
                    }
                    if (minute >= 60) {
                        minute = 0;
                    }
                    props.onChange(props.selected().set({ minute }));
                }}>
                {minutes.map(i => (
                    <option value={i}>{i}</option>
                ))}
            </select>
        </div>
    );
};