import type { JSX, ParentComponent } from "solid-js";
import { splitProps } from "solid-js";
import { bem } from "classnames-bem";

type LabelType = {
    for?: string;
    required?: boolean;
} & JSX.LabelHTMLAttributes<HTMLLabelElement>

export const Label: ParentComponent<LabelType> = (props) => {
    const [
        local,
        other,
    ] = splitProps(props, [
        'for',
        'required',
        'children',
    ])
    return (
        <label
            for={local.for}
            class={bem('b-label', { required: local.required })}
            {...other}
        >
            {local.children}
        </label>
    )
}