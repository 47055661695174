import { useForm } from "@biketravel/solid-ui";
import { Component } from "solid-js";
import { MarkerForm, MarkerFormType } from "./MarkerForm";
import { markerCreateStore } from "@biketravel/state/marker";

type MarkerCreateFormProps = {
  onSuccess(): void;
  lat: number;
  lng: number;
  trackId: number;
}

export const MarkerCreateForm: Component<MarkerCreateFormProps> = (props) => {
  const {
    errors,
    setErrors,
    values,
    onChange,
  } = useForm<MarkerFormType>({
    description: '',
    name: ''
  });

  const onSubmit = async () => {
    const data = await markerCreateStore.actions.create({
      ...values(),
      lng: props.lng,
      lat: props.lat,
      track_id: props.trackId,
    });
    if (data?.id) {
      props.onSuccess();
    } else {
      setErrors(data);
    }
  };

  return (
    <MarkerForm
      onChange={onChange}
      loading={markerCreateStore.loading}
      onSubmit={onSubmit}
      values={values}
      errors={errors}
    />
  );
}