import type { CircleLayerSpecification, LineLayerSpecification, SymbolLayerSpecification } from "maplibre-gl";
import type { TrackRoute } from "@biketravel/sdk";
import type { FeatureCollection } from "geojson";
import type { LineString } from "@turf/helpers";
import { featureCollection, lineString } from "@turf/helpers";
import { ghostLinesBetweenSegments, ghostLinesBetweenWaypoints } from "../layers/ghost";
import type { LineProps } from "../layers";
import { decodeMultiline } from "../encoder";

export const createRouteLayerStyle = (id: string): LineLayerSpecification => ({
  id: id,
  source: id,
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-opacity': 1,
    'line-width': ['coalesce', ['get', 'width'], 5],
    'line-color': ['coalesce', ['get', 'color'], '#e74c3c'],
  },
});

export const createGhostLayerStyle = (id: string): LineLayerSpecification => ({
  id: id,
  source: id,
  type: 'line',
  layout: {
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#797979',
    'line-width': 3,
    'line-dasharray': [0, 2],
  },
});

export const createArrowRouteLayerStyle = (id: string, source: string): SymbolLayerSpecification => ({
  id,
  source,
  type: 'symbol',
  layout: {
    'symbol-placement': 'line',
    'symbol-spacing': 50,
    'icon-image': 'arrow-icon',
    'icon-size': .5,
    // "text-font": ["Open Sans Regular"],
    // "text-field": 'test',
    // "text-size": 14,
  },
});

export const createMarkerLayerStyle = (id: string): SymbolLayerSpecification => ({
  id,
  source: id,
  type: 'symbol',
  layout: {
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-image': 'pin-marker',
    'icon-size': .5,
    'icon-offset': [0, -38],
  },
});

export const createMarkerCircleLayerStyle = (id: string): CircleLayerSpecification => ({
  id,
  source: id,
  type: 'circle',
  paint: {
    'circle-radius': 8,
    'circle-color': '#000',
  },
});

export const createFeatureCollectionFromCoords = (route: TrackRoute): FeatureCollection => {
  const decoded = decodeMultiline(route.segments);

  return featureCollection<LineString>(decoded.map((segment, i) => {
    const isRoutable = typeof route.routable[i] === 'undefined' ? true : route.routable[i];

    return lineString<LineProps>(segment, {
      index: i,
      width: 5,
      color: isRoutable ? '#e74c3c' : '#797979',
    });
  }));
};

export const createGhostFeatureCollection = (
  waypoints: [number, number][],
  user_waypoints: [number, number][],
  segments: string[],
) => featureCollection<LineString>([
  ...ghostLinesBetweenWaypoints(waypoints, user_waypoints),
  ...ghostLinesBetweenSegments(segments),
]);
