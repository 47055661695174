import { Component, Show } from "solid-js";
import { trackPhotoUploadStore } from "@biketravel/state";
import { Spinner } from "@biketravel/solid-ui";
import { createDropzone } from "@solid-primitives/upload";

export type TrackPhotoUploadProps = {
  trackId: number
  onComplete(): void
}

export const TrackPhotoUpload: Component<TrackPhotoUploadProps> = (props) => {
  const {
    setRef: dropzoneRef,
  } = createDropzone({
    onDrop: async files => {
      for (let i = 0; i < files.length; i++) {
        await trackPhotoUploadStore.actions.upload(props.trackId, files[i].file);
      }
      props.onComplete();
    }
  });

  return (
    <Show when={trackPhotoUploadStore.loading()} fallback={<Spinner/>}>
      <div ref={dropzoneRef} class='b-track-photo__upload'>
        <div>Загрузить фотографии маршрута</div>
      </div>
    </Show>
  );
};
