import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";
import type { TrackUpdateRequest } from "@biketravel/sdk";

export type TrackUpdateState = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<TrackUpdateState["loading"]>(false);

const update = async (id: number, input: TrackUpdateRequest) => withLoading(setLoading, async () => {
  await sdk.track.trackUpdate(id, input);
})

type TrackUpdateActions = {
  update: typeof update,
}

export const trackUpdateStore = {
  loading,
  actions: {
    update,
  }
} satisfies AppStore<TrackUpdateState, TrackUpdateActions>;