import type { CircleLayerSpecification, SymbolLayerSpecification } from "maplibre-gl";

const placeColor = '#FF7E00';

export const dataSourceId = 'places';

export const textLayer: SymbolLayerSpecification = {
  id: `${dataSourceId}-symbol`,
  source: dataSourceId,
  filter: ['!', ['has', 'point_count']],
  type: 'symbol',
  layout: {
    "icon-size": 0.5,
    'icon-image': 'orange-marker',
    'icon-allow-overlap': true,
    "icon-ignore-placement": true,
    'icon-offset': [0, -22],
    'text-field': ["get", "name"],
    'text-font': [
      'DIN Offc Pro Medium',
      'Open Sans Bold',
      'Arial Unicode MS Bold',
    ],
    'text-size': 16,
    'text-transform': 'uppercase',
    // 'text-letter-spacing': 0.05,
    // 'text-offset': [0, .5],
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'text-radial-offset': 0.2,
    'text-justify': 'auto',
  },
  paint: {
    'text-color': '#202',
    'text-halo-color': '#fff',
    'text-halo-width': 1,
  },
};

export const clusterLayer: CircleLayerSpecification = {
  id: `${dataSourceId}-clusters`,
  source: dataSourceId,
  type: 'circle',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': placeColor,
    'circle-radius': [
      'step',
      ['get', 'point_count'],
      20,
      100,
      30,
      750,
      40,
    ],
  },
};

export const clusterCountLayer: SymbolLayerSpecification = {
  id: `${dataSourceId}-clusters-count`,
  source: dataSourceId,
  type: 'symbol',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': [
      'DIN Offc Pro Medium',
      'Open Sans Bold',
      'Arial Unicode MS Bold',
    ],
    'text-size': 16,
  },
  paint: {
    'text-color': '#fff',
  },
};
