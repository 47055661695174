import { Redirect, RedirectProps, RendererFunction } from "@biketravel/solid-router";
import { Component, JSX, Show } from "solid-js";
import { jwtStore } from "@biketravel/state";
import { Dynamic } from "solid-js/web";

export type RouteProps = {
  auth?: boolean
  layout?: Component
}

type ServerRedirectProps = Omit<RedirectProps, "replace">

class RedirectError extends Error {
  params?: object;
  query?: object;
  to: string;

  constructor(props: ServerRedirectProps) {
    super();
    this.params = props.params;
    this.query = props.query;
    this.to = props.to;
  }
}

const redirectFunc = (props: ServerRedirectProps): JSX.Element => {
  // if (isServer) {
  //     throw new RedirectError(props)
  // }

  return (
    <Redirect {...props} replace/>
  );
}

export const renderer: RendererFunction<RouteProps> = (match) => {
  const props = {
    query: match.query,
    params: match.params,
  }

  return (
    <Show
      when={!jwtStore.jwt() && match.props?.auth}
      fallback={(
        <Show
          when={match.props?.layout}
          fallback={(
            <Dynamic
              component={match.component}
              {...props}
            />
          )}>
          <Dynamic
            component={match.props?.layout}
            {...props}>
            <Dynamic
              component={match.component}
              {...props}
            />
          </Dynamic>
        </Show>
      )}>
      {redirectFunc({ to: 'auth_login' })}
    </Show>
  );
};