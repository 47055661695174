import type { RegistrationRequest } from '@biketravel/sdk';
import { createSignal } from "solid-js";
import { AppStore, sdk } from "..";
import { LoadingType } from "@biketravel/state";
import { withLoading } from "../decorator";

export type RegistrationStore = LoadingType

const [
  loading,
  setLoading,
] = createSignal<RegistrationStore["loading"]>(false);

const registration = async (input: RegistrationRequest) => withLoading(setLoading, async () => {
  await sdk.auth.registration(input);
});

type RegistrationActions = {
  registration: typeof registration,
}

export const registrationStore = {
  loading,
  actions: {
    registration,
  }
} satisfies AppStore<RegistrationStore, RegistrationActions>;
