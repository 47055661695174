interface IDEntity {
  id: number;
}

export function removeFromArray<T extends IDEntity>(data: T[], id: number) {
  const index = data.findIndex((c) => c.id === id);
  if (index === -1) {
    return;
  }

  const dataCopy = [...data];
  dataCopy.splice(index, 1);
  return dataCopy;
}

export function updateArrayElement<T extends IDEntity>(data: T[], newElement: T) {
  const index = data.findIndex((c) => c.id === newElement.id);
  if (index === -1) {
    return;
  }

  const dataCopy = [...data];
  dataCopy.splice(index, 1, newElement);
  return dataCopy;
}

export const getDifficult = (difficult: number): string => {
  if (difficult == 1) {
    return 'Легкая';
  }

  if (difficult == 2) {
    return 'Средняя';
  }

  return 'Сложная';
};

export type TrackCollection = {
  type: string
  name: string
}

export const TRACK_COLLECTION: TrackCollection[] = [
  {
    type: 'trail',
    name: 'Трейл',
  },
  {
    type: 'randonneur',
    name: 'Бревет (официальные спортивные мероприятия)',
  },
  {
    type: 'tour',
    name: 'Туризм',
  },
  {
    type: 'stroll',
    name: 'Прогулка',
  },
];

export const getCollectionNames = (types: string[]): TrackCollection[] => {
  const map: { [key: string]: string } = {};
  for (let i = 0; i < TRACK_COLLECTION.length; i++) {
    const col = TRACK_COLLECTION[i];
    map[col.type] = col.name;
  }
  return TRACK_COLLECTION.filter(col => types.indexOf(col.type) > -1);
};
