import type { PlaceCreate } from '@biketravel/sdk';
import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";

export type PlaceCreateStore = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<PlaceCreateStore["loading"]>(false);

const create = async (input: PlaceCreate) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.place.placeCreate(input);

  return data;
});

type PlaceCreateActions = {
  create: typeof create,
}

export const placeCreateStore = {
  loading,
  actions: {
    create,
  }
} satisfies AppStore<PlaceCreateStore, PlaceCreateActions>;
