import { batch, createSignal } from "solid-js";
import { AxiosError } from 'axios';
import { AppStore, captureError, ExportableStore, LoadingType, removeFromArray, sdk, updateArrayElement } from "..";
import { shallowEqual } from "shallow-equal-object";
import type { Event, EventList, EventListFilterParameter } from "@biketravel/sdk";

type SomeFields = Pick<
  EventListFilterParameter,
  "name" | "expired" | "members_count" | "distance_min" | "distance_max" | "collection"
>

export type EventFilterFormType = {
  [K in keyof SomeFields]: string;
};

export type EventListState = {
  events: EventList;
  filter?: EventListFilterParameter | undefined;
}

export type EventListClientState = EventListState & LoadingType;

const initialState: EventListClientState = {
  events: {
    edge: [],
    cursor: undefined
  },
  filter: undefined,
  loading: undefined,
}

const filterValues = (filter: EventFilterFormType): EventListFilterParameter => ({
  name: filter.name ? filter.name : undefined,
  distance_min: filter.distance_min ? parseInt(String(filter.distance_min), 10) : undefined,
  distance_max: filter.distance_max ? parseInt(String(filter.distance_max), 10) : undefined,
  collection: filter.collection ? filter.collection : undefined,
  expired: typeof filter.expired === 'undefined' ? undefined : filter.expired === '1',
  members_count: filter.members_count ? parseInt(String(filter.members_count), 10) : undefined,
})

const shouldUpdate = (values: EventListFilterParameter) => !shallowEqual(values, filter());

const filterConvert = (values: EventFilterFormType) => filterValues(values);

const [
  events,
  setEvents
] = createSignal<EventListClientState['events']>(initialState.events);

const [
  filter,
  setFilter
] = createSignal<EventListClientState['filter']>(initialState.filter);

const [
  loading,
  setLoading
] = createSignal<EventListClientState["loading"]>(initialState.loading);

const fetch = async (
  filter?: EventListFilterParameter,
  cursor?: number,
  merge: boolean = false,
  limit = 10
) => {
  filter = filter || {};
  batch(() => {
    setLoading(true);
    setFilter(filter);
  });


  try {
    const { data } = await sdk.event.eventList(filter, cursor, limit);

    batch(() => {
      setLoading(false);

      if (merge) {
        if (data?.edge) {
          setEvents({
            edge: [
              ...(events().edge || []),
              ...data.edge,
            ],
            cursor: data.cursor,
          });
        }
      } else {
        setEvents(data ?? initialState.events);
      }
    })
  } catch (error: AxiosError | unknown) {
    setLoading(false);
    captureError(error);
  }
}

const reset = () => {
  setEvents(initialState.events);
  setLoading(initialState.loading);
  setFilter(initialState.filter);
};

const remove = (id: number) => {
  const newArray = removeFromArray<EventListState["events"]["edge"][number]>(events().edge!, id);
  if (typeof newArray === 'undefined') {
    return;
  }

  setEvents({
    cursor: events().cursor,
    edge: newArray,
  });
}

const update = (event: Event) => {
  const newArray = updateArrayElement<EventListState["events"]["edge"][number]>(events().edge!, event);
  if (typeof newArray === 'undefined') {
    return;
  }

  setEvents({
    cursor: events().cursor,
    edge: newArray,
  });
}

export type EventListActionsType = {
  fetch: typeof fetch;
  reset: typeof reset;
  remove: typeof remove;
  update: typeof update;
  shouldUpdate: typeof shouldUpdate;
  filterConvert: typeof filterConvert;
}

export const eventListStore = {
  events,
  filter,
  loading,
  actions: {
    fetch,
    shouldUpdate,
    filterConvert,
    reset,
    remove,
    update,
  },
  exportState() {
    return {
      filter: filter(),
      events: events(),
    }
  },
  importState(value) {
    setEvents(value.events);
    setFilter(value.filter);
  }
} satisfies AppStore<EventListClientState, EventListActionsType> & ExportableStore<EventListState>;
