import { Title } from "@solidjs/meta";
import { BlockGrid, BlockGridItem, BreadcrumbItem, H1, H2, Segment, SegmentContent } from "@biketravel/solid-ui";
import { AppBreadcrumbs } from "@biketravel/component";

export const AboutPage = () => (
    <>
        <Title>О проекте</Title>
        <AppBreadcrumbs>
            <BreadcrumbItem>
                О проекте
            </BreadcrumbItem>
        </AppBreadcrumbs>
        <H1>О проекте</H1>
        <Segment>
            <SegmentContent>
                <div class="b-typeset"><p>Сайт-сообщество работающий в формате коллективной публикации информации по
                    теме активного туризма.</p><p>Проект ориентирован на популяризацию вело-туризма и подходит как для
                    новичков, так и для опытных велосипедистов.</p><p>Проект содержит базу велосипедных маршрутов,
                    достопримечательностей и редактор маршрутов! У нас есть трейлы, большие дистанции, туристические
                    маршруты, а так же маршруты предназначенные для прогулки с семьей и детьми. Каждый маршрут делится
                    на сезонность.</p><p>Проект так же направлен на благоустройство таких мест и повышение культуры
                    загородного отдыха.</p><p>Рядом с каждым населенным пунктом есть любимые места жителей куда они
                    любят приходить отдохнуть, искупаться в озере или устроить пикник. Как правило, вдоль таких мест
                    проходят прогулочные тропы или туристические маршруты, лыжни или велодорожки. Мы составляем карту
                    вело-маршрутов, чтобы наши пользователи могли выбрать для себя наиболее подходящий по сложности,
                    продолжительности, оценить его по фото и видео материалам до начала поездки.</p><p>Мы объединяем
                    людей, готовых помогать развивать сеть маршрутов и улучшать их.</p><p>Каждый участник проекта может
                    внести свой вклад в развитие проекта создав маршрут или добавив в уже существующий фото и видео
                    материалы.</p>
                    <ul>
                        <li>Сбор информации об интересных местах и вело-маршрутах;</li>
                        <li>Проверка и описание вело-маршрутов;</li>
                        <li>Разработка удобных онлайн инструментов для планирования маршрута;</li>
                        <li>Формирование сообщества вело-любителей и профессиональных велосипедистов.</li>
                    </ul>
                </div>
            </SegmentContent>
        </Segment>
        <H2>Контакты</H2>
        <Segment>
            <SegmentContent>
                <BlockGrid>
                    <BlockGridItem>
                        <div class="b-info__label">Электронная почта</div>
                        <a href="mailto:mail@biketravel.space" class="b-info__value">mail@biketravel.space</a>
                    </BlockGridItem>
                    <BlockGridItem>
                        <div class="b-info__label">Мы ВКонтакте</div>
                        <a href="https://vk.com/biketravel.space" target="_blank" class="b-info__value"
                           rel="noreferrer">https://vk.com/biketravel.space</a>
                    </BlockGridItem>
                    <BlockGridItem>
                        <div class="b-info__label">Мы в Telegram</div>
                        <a href="https://t.me/biketravelspace" target="_blank" class="b-info__value"
                           rel="noreferrer">https://t.me/biketravelspace</a>
                    </BlockGridItem>
                </BlockGrid>
            </SegmentContent>
        </Segment>
    </>
);