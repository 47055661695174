import { H2, Segment, SegmentContent, Typeset } from "@biketravel/solid-ui";

export const HomeAboutBlock = () => (
  <>
    <H2>О проекте</H2>
    <Segment>
      <SegmentContent>
        <Typeset>
          <p>
            Мы создали этот ресурс, чтобы помочь людям, увлеченным велосипедом, найти новых друзей,
            соревноваться и открывать для себя новые места.
          </p>
          <p>
            Если вы увлечены велосипедом, то вы знаете, что это не просто средство передвижения, а образ
            жизни.
            Велосипед позволяет вам ощутить свободу и природу, насладиться скоростью и адреналином. Наш сайт
            создан для тех, кто разделяет эту страсть.
          </p>
          <p>
            Здесь вы сможете находить новых друзей, обмениваться опытом и участвовать в соревнованиях. Мы
            поддерживаем все виды велосипедного спорта.
          </p>
          <p>
            Проект содержит базу велосипедных маршрутов для экстремального катания или для спокойной
            прогулки с
            семьей и детьми. Рядом с каждым населенным пунктом есть любимые места жителей куда они любят
            приходить отдохнуть, искупаться в озере или устроить пикник. Как правило, вдоль таких мест
            проходят
            прогулочные тропы или туристические маршруты, лыжни или велодорожки. Мы составляем карту
            вело-маршрутов, чтобы наши пользователи могли выбрать для себя наиболее подходящий по сложности,
            продолжительности, оценить его по фото и видео материалам до начала поездки. Каждый маршрут
            делится
            на сезонность.
          </p>
          <p>
            Наш сайт также предоставляет возможность планировать свои маршруты и делиться ими с другими
            участниками. Вы можете найти новые интересные места для катания и поделиться своими открытиями с
            другими.
          </p>
          {/*<p>*/}
          {/*    Мы также предлагаем функцию отслеживания своих достижений и прогресса в тренировках. Вы сможете*/}
          {/*    записывать свои результаты, устанавливать новые цели и следить за своим прогрессом.*/}
          {/*</p>*/}
          <p>
            Присоединяйтесь к нашему сообществу и откройте для себя новые возможности в мире велосипедного
            спорта и туризма!
          </p>
          <p>
            Мы объединяем людей, готовых помогать развивать сеть маршрутов и улучшать их. Каждый участник
            проекта может внести свой вклад в развитие и улучшение вело маршрутов.
          </p>
        </Typeset>
      </SegmentContent>
    </Segment>
  </>
)