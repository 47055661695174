import { Component, createSignal, Show } from "solid-js";
import { Tag } from "./Tag";

type Tags = string[];

export type TagInputProps = {
    tags: Tags;
    onChange: (tags: Tags) => void;
    placeholder?: string;
    maxTags?: number;
    readOnly?: boolean;
    removeOnBackspace?: boolean;
}

export const TagInput: Component<TagInputProps> = (props) => {
    const [value, setValue] = createSignal('');

    const onInputKeyDown = (e: KeyboardEvent) => {
        setValue((e.target as HTMLInputElement).value);

        if (e.code === 'Enter' || e.key === ',') {
            e.preventDefault();

            if (value() === "") {
                return;
            }

            addTag(value());
            return;
        }

        if (e.code === 'Backspace' && props.removeOnBackspace) {
            // On backspace or delete

            // If currently typing, do nothing
            if (value() !== "") {
                return;
            }

            // If input is blank, remove previous tag
            removeTag(props.tags.length - 1);
            return;
        }
    };

    const addTag = (value: string) => {
        if (!props.tags.includes(value)) {
            props.onChange([...props.tags, value]);
        }
        setValue('');
    };

    const removeTag = (i: number) => {
        const newtags = [...props.tags];
        newtags.splice(i, 1);
        props.onChange(newtags);
    };

    const maxTagsReached = props.maxTags !== undefined ? props.tags.length >= props.maxTags : false;

    return (
        <div class="b-tag-input">
            {props.tags.map((tag, i) => (
                <Tag value={tag} onRemove={() => removeTag(i)}/>
            ))}
            <Show when={!props.readOnly && !maxTagsReached}>
                <input
                    value={value()}
                    class="b-tag-input__input"
                    placeholder={props.placeholder || "Type and press enter"}
                    onkeydown={onInputKeyDown}
                />
            </Show>
        </div>
    );
};
