import {
  Button,
  Calendar,
  dateTimeToIso,
  dateTimeToMediumDate,
  Form,
  FormHelp,
  FormRow,
  getErrors,
  InputText,
  InputTextarea,
  Label,
  Popover,
  Spinner,
  stringToDateTime,
  ValidationError,
  ValidationErrorList,
  ValidationErrorsType,
} from "@biketravel/solid-ui";
import { Accessor, Component, createSignal, Show } from "solid-js";
import { EventCreateStartMap } from "./EventCreateStartMap";
import type { EventCreate, TrackRoute } from '@biketravel/sdk';

export type EventFormType = {
  [K in keyof Omit<EventCreate, "track_id">]: string;
}

type EventFormProps = {
  route: TrackRoute;
  errors: Accessor<ValidationErrorsType>;
  values: Accessor<EventFormType>;
  loading: Accessor<boolean | undefined>;
  onChange(name: keyof EventFormType, value: string): void;
  onSubmit(): Awaited<void>;
}

export const EventForm: Component<EventFormProps> = (props) => {
  const [
    targetRef,
    setTargetRef,
  ] = createSignal<Element | undefined>();

  const [
    visible,
    setVisible,
  ] = createSignal(false);

  return (
    <Form class='b-event-create-form' onSubmit={() => props.onSubmit()}>
      <div>
        <FormRow modifiers='margin-bottom'>
          <Label for="name" required>Название</Label>
          <InputText
            autofocus={true}
            type='text'
            id='name'
            name='name'
            value={props.values().name}
            onChange={({ value }) => props.onChange('name', value)}
          />
          <ValidationError path='name' errors={props.errors()}/>
        </FormRow>
        <FormRow modifiers='margin-bottom'>
          <div class='b-event-create-form__date'>
            <Button
              type='button'
              modifiers='default'
              ref={setTargetRef}
              onclick={() => setVisible(!visible())}>Выбрать дату и время</Button>
            {
              stringToDateTime(props.values().start_at).isValid
                ? dateTimeToMediumDate(stringToDateTime(props.values().start_at))
                : 'Дата и время не указаны'
            }
          </div>
          <Popover
            targetRef={targetRef()}
            visible={visible}
            setVisible={setVisible}
          >
            <Calendar
              confirmButton={true}
              onConfirm={date => {
                props.onChange('start_at', dateTimeToIso(date));
                setVisible(false);
              }}
              onChange={date => props.onChange('start_at', dateTimeToIso(date))}
              selectedDate={stringToDateTime(props.values().start_at)}/>
          </Popover>
          <ValidationError path='startAt' errors={props.errors()}/>
        </FormRow>
        <FormRow modifiers='margin-bottom'>
          <Label for="description" required>Описание</Label>
          <InputTextarea
            id='description'
            name='description'
            value={props.values().description}
            onChange={({ value }) => props.onChange('description', value)}
          />
          <FormHelp>
            Пожалуйста постарайтесь заполнить информацию о поездке и мероприятию максимально подробно. Не
            забывайте
            указать рекомендации к экипировке, освещению если поездка проходит в вечернее или ночное время,
            средний
            темп. Укажите точку сбора если она отличается от точки на карте, время которое вы готовы ожидать
            опаздавших и любую другую информацию которая поможет участникам пройти маршрут.
          </FormHelp>
          <ValidationError path='description' errors={props.errors()}/>
        </FormRow>
        <FormRow modifiers='margin-bottom'>
          <Label for="start" required>Точка сбора</Label>
          <EventCreateStartMap
            onChange={(startLng, startLat) => {
              props.onChange('start_lng', String(startLng));
              props.onChange('start_lat', String(startLat));
            }}
            route={props.route}/>
          <FormHelp>
            Для выбора точки сбора нажмите <strong>левой кнопкой мыши на карте</strong>. Пульсирующий круг
            обозначает точку сбора.
          </FormHelp>
          <Show when={[
            ...getErrors('start_lng', props.errors()),
            ...getErrors('start_lat', props.errors())
          ].length > 0}>
            <ValidationErrorList errors={[
              'Не указана точка старта'
            ]}/>
          </Show>
        </FormRow>
        <FormRow>
          <Button modifiers='primary' type="submit">
            Сохранить
          </Button>
        </FormRow>
      </div>
      <Spinner overlay show={props.loading()}/>
    </Form>
  );
}