import type { Surface, TrackRoute } from "@biketravel/sdk";
import { encodeLine } from "../encoder";

export const surfaceUnknown = 'missing';
export const surfaceDict: { [key: string]: string } = {
  asphalt: 'Асфальт',
  cobblestone: 'Камни',
  compacted: 'Каменная дорога',
  concrete: 'Цемент, бетонные плиты',
  dirt: 'Грунты, земля',
  fine_gravel: 'Гравийная дорога (мелкий гравий)',
  grass: 'Трава',
  gravel: 'Гравийная дорога',
  ground: 'Грунтовая дорога',
  missing: 'Не определено',
  other: 'Другой тип покрытия',
  paved: 'Брусчатка',
  paving_stones: 'Брусчатка (камни)',
  sand: 'Песок',
  unpaved: 'Бездорожье',
};
export const emptySurface: Surface = { value: 0, surface: surfaceUnknown };

export const createEmptyRoute = (): TrackRoute => ({
  segments: [],
  user_waypoints: [],
  waypoints: [],
  ascend: [],
  routable: [],
  descend: [],
  surface: [],
  elevation: [],
});

export const routeAddWaypoint = (
  route: TrackRoute,
  newRoute: TrackRoute,
): TrackRoute => {
  // Если в оригинальном уже существующем маршруте
  // нет маршрутных точек, значит маршрут чист (новый)
  // В таком случае указываем новый маршрут основным
  if (route.waypoints.length === 0) {
    return structuredClone(newRoute);
  }

  // Клонируем существующий основной маршрут
  const clone = structuredClone(route);

  // Добавляем предыдущую маршрутную точку пользователя из нового маршрута.
  // Первая точка не требуется, так как она уже присутствует в
  // маршруте (первая точка - это предыдущая из уже существующей предыдущей)
  clone.user_waypoints.push(newRoute.user_waypoints[newRoute.user_waypoints.length - 1]);
  // Все по аналогии с userWaypoints
  clone.waypoints.push(newRoute.waypoints[newRoute.waypoints.length - 1]);
  // Безусловно добавляем последний (он же единственный) полученный
  // маршрут от системы роутинга
  clone.segments.push(newRoute.segments[newRoute.segments.length - 1]);
  // По аналогии с сегментами добавляем массив высот
  clone.elevation.push(newRoute.elevation[newRoute.elevation.length - 1]);
  // Добавляем метку о том, что роут не является статическим
  clone.routable.push(newRoute.routable[newRoute.routable.length - 1]);
  // Набор высоты
  clone.ascend.push(newRoute.ascend[newRoute.ascend.length - 1]);
  // Спуск
  clone.descend.push(newRoute.descend[newRoute.descend.length - 1]);
  // Тип покрытия
  clone.surface.push(newRoute.surface[newRoute.surface.length - 1]);

  return clone;
};

export function insertAtIndex<T>(src: T[], dst: T[], index: number) {
  for (let i = 0; i < src.length; i++) {
    dst.splice(index + i, 0, src[i]);
  }
}

export function replaceAtIndex<T>(src: T[], dst: T[], index: number) {
  for (let i = 0; i < src.length; i++) {
    dst.splice(index + i, 1, src[i]);
  }
}

export const routeAddWaypointOnLine = (
  route: TrackRoute,
  newRoute: TrackRoute,
  segmentUpdateIndex: number,
  newPointLngLat: [number, number],
  routable: boolean,
) => {
  // Клонируем существующий основной маршрут
  const clone = structuredClone(route);

  clone.user_waypoints.splice(segmentUpdateIndex + 1, 0, newPointLngLat);
  for (let i = 0; i < newRoute.waypoints.length; i++) {
    if (i === 0 || i === newRoute.waypoints.length - 1) {
      continue;
    }
    clone.waypoints.splice(segmentUpdateIndex + 1, 0, newRoute.waypoints[i]);
  }
  clone.segments.splice(segmentUpdateIndex, 1);
  insertAtIndex<string>(newRoute.segments, clone.segments, segmentUpdateIndex);
  insertAtIndex<number[]>(newRoute.elevation, clone.elevation, segmentUpdateIndex);
  // Набор высоты
  insertAtIndex<number>(newRoute.ascend, clone.ascend, segmentUpdateIndex);
  // Спуск
  insertAtIndex<number>(newRoute.descend, clone.descend, segmentUpdateIndex);
  // Тип покрытия
  insertAtIndex<Surface[]>(newRoute.surface, clone.surface, segmentUpdateIndex);

  clone.routable.splice(segmentUpdateIndex + 1, 0, routable);

  return clone;
};

export const routeUpdate = (
  route: TrackRoute,
  newRoute: TrackRoute,
  segmentUpdateIndex: number,
  pointUpdateIndex: number,
): TrackRoute => {
  // Роутинг и логика работы рассчитанная под 2 маршрутных точки не
  // предусматривает возможности принять более 3 точек в ответе от сервера.
  // 3 точки являются частным случаем перетаскивания B маршрутного маркера
  // на маршруте A---B---C. Сделано исключительно ради упрощения кода
  // и удобства обновления маршрута.
  if (newRoute.user_waypoints.length > 3 || newRoute.waypoints.length > 3) {
    throw new Error('invalid waypoint length received');
  }

  // Клонируем существующий основной маршрут
  const clone = structuredClone(route);

  // Добавляем предыдущую маршрутную точку пользователя из нового маршрута.
  // Первая точка не требуется, так как она уже присутствует в
  // маршруте (первая точка - это предыдущая из уже существующей предыдущей)
  replaceAtIndex(newRoute.user_waypoints, clone.user_waypoints, pointUpdateIndex);
  // Все по аналогии с user_waypoints
  replaceAtIndex(newRoute.waypoints, clone.waypoints, pointUpdateIndex);
  // Безусловно добавляем последний (он же единственный) полученный
  // маршрут от системы роутинга
  replaceAtIndex(newRoute.segments, clone.segments, segmentUpdateIndex);
  // По аналогии с сегментами добавляем массив высот
  replaceAtIndex(newRoute.elevation, clone.elevation, segmentUpdateIndex);
  // По аналогии с сегментами добавляем метки типа построения маршрута
  replaceAtIndex(newRoute.routable, clone.routable, segmentUpdateIndex);

  // Набор высоты
  replaceAtIndex(newRoute.ascend, clone.ascend, segmentUpdateIndex);
  // Спуск
  replaceAtIndex(newRoute.descend, clone.descend, segmentUpdateIndex);
  // Тип покрытия
  replaceAtIndex(newRoute.surface, clone.surface, segmentUpdateIndex);

  return clone;
};

export const createStaticRoute = (coordinates: [number, number][]): TrackRoute => ({
  waypoints: coordinates,
  user_waypoints: coordinates,
  segments: [
    encodeLine(coordinates),
  ],
  routable: [false],
  elevation: [[0, 0]],
  ascend: [0],
  descend: [0],
  surface: [[emptySurface]],
});

export const routeAddStaticWaypoint = (
  route: TrackRoute,
  from: [number, number],
  to: [number, number],
): TrackRoute => routeAddWaypoint(route, createStaticRoute([from, to]));
