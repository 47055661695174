import { ParentComponent } from "solid-js";

export type MapPopupMenuType = {
  x?: number
  y?: number
}

export const MapPopupMenu: ParentComponent<MapPopupMenuType> = (props) => (
  <div class="b-context-menu" style={{
    left: `${props.x}px`,
    top: `${props.y}px`,
  }}>
    {props.children}
  </div>
);
