import { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from "axios";

const withToken = (
  config: InternalAxiosRequestConfig,
  token?: string | undefined | null,
): InternalAxiosRequestConfig => {
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}

const isInternalError = (err: AxiosError) => {
  const {
    response,
  } = err;

  if (!response) {
    return false;
  }

  const {
    error_message
  } = response?.data as any;

  return [
    'security requirement is not satisfied',
    'invalid audience',
    'expired token',
  ].indexOf(error_message) > -1;
}

const isExpired = (err: AxiosError) => {
  return err.response?.status === 401;
}

let isRefreshing = false;

export const injectAuthInterceptor = (
  instance: AxiosInstance,
  getAccessToken: () => string,
  refreshToken: () => Promise<string> | string,
  logout: () => void,
) => {
  instance.interceptors.request.use(
    (config) => withToken(config, getAccessToken()),
    error => Promise.reject(error),
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (!(error instanceof AxiosError)) {
        isRefreshing = false;
        return Promise.reject(error);
      }

      if (isInternalError(error)) {
        return Promise.reject(error);
      }

      const originalRequest = error.config;
      if (!originalRequest || isRefreshing) {
        isRefreshing = false;
        console.error(error);
        logout();
        return Promise.reject(error);
      }

      if (false === isExpired(error)) {
        isRefreshing = false;
        return Promise.reject(error);
      }

      isRefreshing = true;

      try {
        return instance(
          withToken(originalRequest, await refreshToken())
        );
      } catch (e: Error | unknown) {
        return Promise.reject(error);
      }
    },
  );
}