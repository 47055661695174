import { batch, createSignal } from "solid-js";
import { AppStore, ExportableStore, LoadingType, sdk } from "..";
import { TrackPhotoList } from "@biketravel/sdk";
import { withLoading } from "../decorator";

export type TrackPhotoListState = {
  photos: TrackPhotoList;
}

export type TrackPhotoListClientState = TrackPhotoListState & LoadingType;

const initialState: TrackPhotoListClientState = {
  photos: {
    edge: [],
    cursor: undefined
  },
  loading: false,
}

const [
  photos,
  setPhotos
] = createSignal<TrackPhotoListClientState['photos']>(initialState.photos);

const [
  loading,
  setLoading
] = createSignal<TrackPhotoListClientState["loading"]>(initialState.loading);

const trackPhotoListAction = async (
  trackID: number,
  cursor?: number,
  merge = false,
  limit = 10
) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.trackPhoto.trackPhotoList(cursor, limit, trackID);

  batch(() => {
    if (data.edge) {
      if (merge) {
        setPhotos({
          edge: [
            ...(photos().edge || []),
            ...data.edge,
          ],
          cursor: data.cursor,
        });
      } else {
        setPhotos(data);
      }
    }
  });
})

export type TrackPhotoListActionsType = {
  fetch: typeof trackPhotoListAction
}

export const trackPhotoListStore = {
  photos,
  loading,
  actions: {
    fetch: trackPhotoListAction,
  },
  exportState() {
    return {
      photos: photos(),
    }
  },
  importState(value) {
    setPhotos(value.photos);
  }
} satisfies AppStore<TrackPhotoListClientState, TrackPhotoListActionsType> & ExportableStore<TrackPhotoListState>;
