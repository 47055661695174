import { BreadcrumbItem, Breadcrumbs, BreadcrumbSymbol } from "@biketravel/solid-ui";
import { ParentComponent } from "solid-js";
import { AiOutlineHome } from "solid-icons/ai";

export const AppBreadcrumbs: ParentComponent = (props) => (
  <Breadcrumbs>
    <BreadcrumbItem to='home' icon={<AiOutlineHome/>}/>
    <BreadcrumbSymbol/>
    {props.children}
  </Breadcrumbs>
)