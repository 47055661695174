import { getImageUrl } from "@biketravel/imgproxy";
import { Avatar, BemModifiers } from "@biketravel/solid-ui";
import { Component } from "solid-js";

type UserAvatarProps = {
  name: string;
  avatar?: string | null | undefined;
  width?: number;
  height?: number;
  class?: string;
} & BemModifiers

export const UserAvatar: Component<UserAvatarProps> = (props) => (
  <Avatar
    class={props.class}
    style={{ width: `${props.width}px`, height: `${props.height}px` }}
    letters={props.name.substring(0, 2)}
    url={props.avatar ? getImageUrl(props.avatar, props.width || 32, props.height || 32) : undefined}
    modifiers={props.modifiers}/>
);