import type { Component, JSX } from "solid-js";
import { splitProps } from "solid-js";
import { getCDNUrl, getImageUrl } from '@biketravel/imgproxy';

type ImageProps = {
  src: string;
  alt: string;
  width?: number;
  height?: number;
} & Omit<JSX.ImgHTMLAttributes<HTMLImageElement>, "src" | "alt">

export const Image: Component<ImageProps> = (props) => {
  const [
    local,
    other,
  ] = splitProps(props, ["src", "alt", "width", "height"]);

  const src = props.width && props.height
    ? getImageUrl(props.src, props.width, props.height)
    : getCDNUrl(props.src);

  return (
    <img
      loading="lazy"
      decoding="async"
      src={src}
      alt={props.alt}
      {...other}
    />
  );
}