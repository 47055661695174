import { Accessor } from "solid-js";
import { createSDK, SDK } from '@biketravel/sdk';
import axios from 'axios';
import { jwtStore, refreshStore } from "./auth";

export type ExportableStore<T> = {
  exportState(): T;
  importState(val: T): void;
}

export type AppActions<K = undefined> = K extends undefined ? never : {
  actions: K;
};

type AppState<T extends object> = {
  [K in keyof T]: Accessor<T[K]>;
}

export type AppStore<T extends object, K = undefined> = AppState<T> & AppActions<K>;

export const sdk: SDK = createSDK(axios);

const getAccessToken = () => jwtStore.jwt()?.access_token;

const getNewJwtToken = async () => {
  const newToken = await refreshStore.actions.refresh();

  return newToken?.refresh_token;
}

sdk.inject(getAccessToken, getNewJwtToken, () => {
  if (typeof window !== 'undefined') {
    jwtStore.actions.removeJwt();
    window.location.pathname = '/';
  }
});