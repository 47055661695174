import type { Map as MapType } from "maplibre-gl";
import cx from 'classnames';
import { createMap } from "./mapUtils";
import {
  Accessor,
  createContext,
  createEffect,
  createSignal,
  mergeProps,
  onCleanup,
  onMount,
  ParentComponent,
  useContext
} from "solid-js";
import { bem } from 'classnames-bem';
import { BemModifiers } from "@biketravel/solid-ui";

type MapSolidType = BemModifiers & {
  class?: string;
  scrollZoom?: boolean;
} & ViewportType;

export type ViewportType = {
  center?: CenterType;
  zoom?: number;
}

export type CenterType = [number, number] | undefined;

export const MapContext = createContext<Accessor<MapType | undefined>>();

export const useMap = () => {
  return useContext(MapContext);
}

export const useMapEffect = (f: (map: MapType) => void) => {
  return createEffect(() => {
    const map = useMap()?.();
    if (map) {
      f(map);
    }
  });
};

export const MapSolid: ParentComponent<MapSolidType> = (props) => {
  const merged = mergeProps({
    scrollZoom: true,
  }, props);

  const [
    map,
    setMap,
  ] = createSignal<MapType | undefined>();

  let mapEl: HTMLDivElement | undefined;

  createEffect(() => {
    const mapInstance = map();
    if (!mapInstance || !merged.center) {
      return;
    }

    mapInstance.setCenter(merged.center);
  });

  onMount(() => {
    if (!mapEl) {
      return;
    }

    const mapInstance = createMap(mapEl, {
      center: merged.center,
      zoom: merged.zoom,
    });

    if (!merged.scrollZoom) {
      mapInstance.scrollZoom.disable();
    }

    setMap(mapInstance);
  });

  onCleanup(() => {
    const mapInstance = map();
    if (!mapInstance) {
      return;
    }

    mapInstance.remove();
  });

  return (
    <MapContext.Provider value={map}>
      <div
        ref={mapEl}
        class={cx(bem("b-map", merged.modifiers), merged.class)}/>
      {merged.children}
    </MapContext.Provider>
  )
}