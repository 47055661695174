import { BemModifiers } from "./types";
import { Component, ParentComponent } from "solid-js";
import { JSX } from "solid-js/types/jsx";
import { bem } from "classnames-bem";
import cx from "classnames";

type SegmentContent = BemModifiers;

export const SegmentContent: ParentComponent<SegmentContent> = (props) => (
    <div class={bem('b-segment__content', props.modifiers)}>
        {props.children}
    </div>
);

type SegmentHeaderProps = {
    title: string | JSX.Element
    description?: string | JSX.Element
    menu?: null | JSX.Element
};

export const SegmentHeader: Component<SegmentHeaderProps> = (props) => (
    <div class={bem("b-segment__header", { 'menu': !!props.menu })}>
        <div>
            <div class="b-segment__title">
                {props.title}
            </div>
        </div>
        {props.menu}
    </div>
);

type SegmentType = BemModifiers & {
    class?: string;
}

export const Segment: ParentComponent<SegmentType> = (props) => (
    <div class={cx(bem('b-segment', props.modifiers), props.class)}>
        {props.children}
    </div>
)

type SegmentFooterType = BemModifiers & {
    class?: string;
}

export const SegmentFooter: ParentComponent<SegmentFooterType> = (props) => (
    <div class={cx(bem('b-segment__footer', props.modifiers), props.class)}>
        {props.children}
    </div>
)