import { BemModifiers } from "../types";
import { Accessor, batch, createEffect, createSignal, JSX, on, onCleanup, ParentComponent, Show } from "solid-js";
import { bem } from "classnames-bem";
import { arrow, autoPlacement, autoUpdate, computePosition, flip, offset, Placement, shift } from "@floating-ui/dom";
import { Portal } from "solid-js/web";
import { clickOutside } from "../clickOutside";

type PopoverProps = {
    placement?: Placement;
    targetRef?: Element;
    visible: Accessor<boolean>;
    setVisible(v: boolean): void;
} & BemModifiers;

export const Popover: ParentComponent<PopoverProps> = (props) => {
    let floatingRef: HTMLDivElement | undefined;
    let arrowRef: HTMLDivElement | undefined;

    const [
        css,
        setCss
    ] = createSignal<JSX.CSSProperties>({});

    const [
        arrowCss,
        setArrowCss
    ] = createSignal<JSX.CSSProperties>({});

    const [
        placement,
        setPlacement
    ] = createSignal<string | undefined>();

    const update = async () => {
        if (!floatingRef) {
            return;
        }

        if (!arrowRef) {
            return;
        }

        if (!props.targetRef) {
            return;
        }

        if (!props.visible()) {
            return;
        }

        const {
            x,
            y,
            placement,
            middlewareData
        } = await computePosition(props.targetRef, floatingRef, {
            placement: props.placement,
            middleware: [
                offset(12),
                flip(),
                shift({ padding: 16 }),
                arrow({ element: arrowRef }),
            ],
        });

        const {
            x: arrowX,
            y: arrowY,
        } = middlewareData.arrow || {};

        const cleanClass = placement.split('-')[0];
        setPlacement(cleanClass);

        const staticSide = {
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right',
        }[cleanClass] || '';

        batch(() => {
            setCss({
                left: `${x}px`,
                top: `${y}px`,
            });

            setArrowCss({
                left: arrowX != null ? `${arrowX}px` : '',
                top: arrowY != null ? `${arrowY}px` : '',
                right: '',
                bottom: '',
                [staticSide]: '-11px',
            });
        });
    }

    createEffect(on(props.visible, (a) => {
        if (!props.visible() || !props.targetRef) {
            return;
        }

        update();

        const cleanup = autoUpdate(
            props.targetRef,
            floatingRef!,
            update
        );

        onCleanup(() => cleanup());

        clickOutside(
            props.targetRef,
            floatingRef!,
            () => props.setVisible(false),
        );
    }, { defer: true }));

    return (
        <Show when={props.visible()}>
            <Portal>
                <div
                    ref={floatingRef}
                    style={css()}
                    class={bem('b-popover', props.modifiers)}>
                    <div class="b-popover__content">
                        {props.children}
                    </div>
                    <div class={bem('b-popover-arrow', placement())} ref={arrowRef} style={arrowCss()}>
                        <PopowerArrow/>
                    </div>
                </div>
            </Portal>
        </Show>
    );
};

const PopowerArrow = () => (
    <svg viewBox="0 0 30 30">
        <path class="b-popover-arrow__border"
              d="M8.11 6.302c1.015-.936 1.887-2.922 1.887-4.297v26c0-1.378-.868-3.357-1.888-4.297L.925 17.09c-1.237-1.14-1.233-3.034 0-4.17L8.11 6.302z"/>
        <path class="b-popover-arrow__fill"
              d="M8.787 7.036c1.22-1.125 2.21-3.376 2.21-5.03V0v30-2.005c0-1.654-.983-3.9-2.21-5.03l-7.183-6.616c-.81-.746-.802-1.96 0-2.7l7.183-6.614z"/>
    </svg>
)
