import { Title } from "@solidjs/meta";
import {
    BreadcrumbItem,
    BreadcrumbSymbol,
    CalendarEvent,
    FullCalendar,
    H1,
    Spinner,
    stringToDateTime
} from "@biketravel/solid-ui";
import { useCurrentRoute, useNavigate } from '@biketravel/solid-router';
import { DateTime } from "luxon";
import { createEffect, createMemo, createSignal } from "solid-js";
import { eventCalendarStore } from "@biketravel/state";
import { AppBreadcrumbs } from "@biketravel/component";

type EventCalendarRouteProps = {
    params: {
        year?: number
        month?: number
    }
    query: {}
}

export const EventCalendarPage = () => {
    const { navigate } = useNavigate();
    const currentRoute = useCurrentRoute<EventCalendarRouteProps['params'], EventCalendarRouteProps['query']>();

    let now = DateTime.now();
    if (currentRoute().params?.year) {
        now = now.set({ year: currentRoute().params.year });
    }
    if (currentRoute().params?.month) {
        now = now.set({ month: currentRoute().params.month });
    }

    const [date, setDate] = createSignal<DateTime>(now);

    createEffect(async () => {
        await eventCalendarStore.actions.fetch(date().year, date().month);
    });

    const onChangeDate = (newDate: DateTime) => {
        navigate('event_calendar_year_month', {
            year: newDate.year,
            month: newDate.month,
        });
    }

    const calendarMemoEvents = createMemo(() => {
        const calendarEvents: { [key: string]: CalendarEvent[] } = {};
        const events = eventCalendarStore.events().edge || [];
        for (let i = 0; i < events.length; i++) {
            const event = events[i];
            const date = stringToDateTime(event.start_at);
            const key = date.set({ millisecond: 0, second: 0, minute: 0, hour: 0 }).toISODate();
            if (!key) {
                continue;
            }

            if (!calendarEvents[key]) {
                calendarEvents[key] = [];
            }
            calendarEvents[key].push({
                date,
                name: event.name,
                id: event.id,
            });
        }

        return calendarEvents;
    });

    return (
        <>
            <Title>Календарь поездок</Title>
            <AppBreadcrumbs>
                <BreadcrumbItem to='event_list'>
                    Поездки
                </BreadcrumbItem>
                <BreadcrumbSymbol/>
                <BreadcrumbItem>
                    Календарь поездок
                </BreadcrumbItem>
            </AppBreadcrumbs>
            <H1>Календарь поездок</H1>
            <FullCalendar
                onClick={calendarEvent => navigate('event_view', { event_id: calendarEvent.id })}
                selectedDate={date()}
                onChangeDate={newDate => onChangeDate(newDate)}
                events={calendarMemoEvents()}
            />
            <Spinner overlay show={eventCalendarStore.loading()}/>
        </>
    );
}