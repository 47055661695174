import { Component, createSignal } from "solid-js";
import type { EventList } from "@biketravel/sdk";
import { Link } from '@biketravel/solid-router';
import { ButtonLink, Image, Segment, SegmentContent, SegmentFooter } from "@biketravel/solid-ui";
import { EventMenu } from "./EventMenu";
import { EventSummary } from "./EventSummary";
import { EventInfoBar } from "./EventInfoBar";
import { eventGpx } from "./gpx";

export type EventListItemType = {
  event: EventList["edge"][number]
}

export const EventListItem: Component<EventListItemType> = (props) => {
  const [
    removed,
    setRemoved,
  ] = createSignal(false);

  return (
    <Segment>
      <Link to='event_view' params={{ event_id: props.event.id }}>
        <Image
          width={1248}
          height={320}
          class='b-map-static-image'
          src={props.event.static_map}
          alt={props.event.name}
        />
      </Link>
      <SegmentContent>
        <Link
          to='event_view'
          params={{ event_id: props.event.id }}
          class="b-event-list-item__name">
          {props.event.name}
        </Link>
        <EventInfoBar event={props.event}/>
        <EventSummary event={props.event}/>
      </SegmentContent>
      <SegmentFooter>
        <div class='b-segment__footer-buttons'>
          <ButtonLink
            modifiers='primary'
            to='event_view'
            params={{ event_id: props.event.id }}>
            Подробнее
          </ButtonLink>
          <ButtonLink
            modifiers='default'
            href={eventGpx(props.event.id)}
          >
            Скачать GPX
          </ButtonLink>
        </div>
        <EventMenu
          onRemove={() => setRemoved(true)}
          event={props.event}/>
      </SegmentFooter>
    </Segment>
  )
}