import { BaseLayer } from "./control";
import { config } from "@biketravel/config";

// "https://core-sat.maps.yandex.net/tiles?l=sat&v=3.1026.0&x={x}&y={y}&z={z}&lang=ru_RU",

// const rasterStyle: BaseLayer = {
//     id: "openstreetmap",
//     name: "OSM",
//     style: {
//         version: 8,
//         sources: {
//             basemap: {
//                 type: "raster",
//                 tiles: [
//                     // "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
//                     "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
//                 ],
//                 tileSize: 256,
//             },
//         },
//         layers: [
//             {
//                 id: "basemap",
//                 type: "raster",
//                 source: "basemap",
//                 minzoom: 0,
//                 maxzoom: 20,
//             },
//         ],
//     },
// };

const styleURL = (name: string): string => `${config.TILE_CACHE_URL}/style/${name}/style.json`;

export const baseLayers: BaseLayer[] = [
  {
    isDefault: true,
    id: "maptiler-street",
    name: "Улицы",
    style: styleURL('maptiler-street'),
  },
  {
    id: "maptiler-outdoor",
    name: "Карта местности",
    style: styleURL('maptiler-outdoor'),
  },
  {
    id: "komoot",
    name: "Komoot",
    style: styleURL('komoot'),
  },
  {
    id: "mapbox-hybrid",
    name: "Спутник",
    style: styleURL('mapbox-hybrid'),
  },
];
