import { bem } from "classnames-bem";
import { DateTime } from "luxon";
import { CalendarEvent } from "./types";
import { Component } from "solid-js";
import { dateTimeToShortTime } from "../utils";

export type FullDayCellProps = {
    date: DateTime,
    onClick?: (calendarEvent: CalendarEvent) => void
    events: CalendarEvent[]
    selected?: boolean
    isPrevious: boolean
    isNext: boolean
}

export const FullDayCell: Component<FullDayCellProps> = (props) => {
    const isWeekend = props.date.weekday > 5;

    const cls = bem('b-full-calendar-cell', {
        selected: props.selected,
        next: props.isNext,
        previous: props.isPrevious,
        weekend: !props.isNext && !props.isPrevious && isWeekend,
    });

    return (
        <div class={cls}>
            <div class={bem('b-full-calendar__day', {
                selected: props.selected,
                next: props.isNext,
                previous: props.isPrevious,
                weekend: !props.isNext && !props.isPrevious && isWeekend,
            })}>
                {props.date.day}
            </div>
            <div class="b-full-calendar-content">
                {props.events.map((event) => (
                    <div class='b-full-calendar__event' onClick={() => props.onClick && props.onClick(event)}>
                        {dateTimeToShortTime(event.date)} &mdash; {event.name}
                    </div>
                ))}
            </div>
        </div>
    );
};
