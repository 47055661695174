import { config } from "@biketravel/config";
import { clean, removeTrailingSlash } from "./utils";

export const getImageUrl = (
  path: string,
  width: number,
  height: number,
): string => {
  if (!config.IMGPROXY_URL || !config.CDN_URL) {
    return '';
  }

  const cleanUri = clean(path);
  const uri = cleanUri.substring(0, 1) === '/' ? cleanUri.substring(1) : cleanUri;
  const host = removeTrailingSlash(config.IMGPROXY_URL);

  return `${host}/image?path=${uri}&width=${width}&height=${height}`;
};