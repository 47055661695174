import {
  BlogPostListState,
  blogPostsStore,
  BlogPostState,
  blogPostStore,
  EventListState,
  eventListStore,
  EventViewState,
  eventViewStore,
  LocationState,
  locationStore,
  TrackListState,
  trackListStore,
  TrackViewState,
  trackViewStore,
} from ".";
import { ExportableStore } from "./store";

export type StoreRegistry = {
  blogPostList: BlogPostListState;
  blogPost: BlogPostState;
  eventList: EventListState;
  location: LocationState;
  trackList: TrackListState;
  trackView: TrackViewState;
  eventView: EventViewState;
}

declare global {
  interface Window {
    __STATE__: StoreRegistry;
  }
}

export const bridge: ExportableStore<StoreRegistry> = {
  exportState() {
    return {
      blogPostList: blogPostsStore.exportState(),
      blogPost: blogPostStore.exportState(),
      eventList: eventListStore.exportState(),
      location: locationStore.exportState(),
      trackList: trackListStore.exportState(),
      trackView: trackViewStore.exportState(),
      eventView: eventViewStore.exportState(),
    }
  },

  importState(state?: StoreRegistry) {
    if (!state) {
      return;
    }

    blogPostsStore.importState(state.blogPostList);
    blogPostStore.importState(state.blogPost);
    eventListStore.importState(state.eventList);
    locationStore.importState(state.location);
    trackListStore.importState(state.trackList);
    trackViewStore.importState(state.trackView);
    eventViewStore.importState(state.eventView);
  }
}