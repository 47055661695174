import { createSignal } from "solid-js";
import { AppStore, captureError, LoadingType, sdk } from "..";
import { AxiosError } from "axios";

export type EventRemoveState = LoadingType;

const initialState: EventRemoveState = {
  loading: false,
}

const [
  loading,
  setLoading
] = createSignal(initialState.loading);

const removeAction = async (
  id: number,
) => {
  setLoading(true);

  try {
    const resp = await sdk.event.eventRemove(id);

    setLoading(false);

    return resp.data;
  } catch (error: AxiosError | unknown) {
    setLoading(false);

    if (error instanceof AxiosError) {
      return error.response?.data.errors;
    }

    captureError(error);
  }
}

export type EventRemoveActionsType = {
  remove: typeof removeAction
}

export const eventRemoveStore = {
  loading,
  actions: {
    remove: removeAction,
  }
} satisfies AppStore<EventRemoveState, EventRemoveActionsType>;