import { BreadcrumbItem, BreadcrumbSymbol, H1 } from "@biketravel/solid-ui";
import { AppBreadcrumbs, SidebarList, TrackDraftFilterForm, TrackDraftList } from "@biketravel/component";
import { Title } from "@solidjs/meta";
import { trackListStore } from "@biketravel/state";

export const TrackDraftListPage = () => {
    trackListStore.actions.reset();

    return (
        <>
            <Title>Черновики маршрутов</Title>
            <AppBreadcrumbs>
                <BreadcrumbItem to='track_list'>
                    Маршруты
                </BreadcrumbItem>
                <BreadcrumbSymbol/>
                <BreadcrumbItem>
                    Черновики маршрутов
                </BreadcrumbItem>
            </AppBreadcrumbs>
            <H1>Черновики маршрутов</H1>
            <SidebarList filter={<TrackDraftFilterForm/>}>
                <TrackDraftList/>
            </SidebarList>
        </>
    );
}