import { ButtonLink, Divider, H1 } from "@biketravel/solid-ui";
import { CircleIcon, RegistrationForm } from "@biketravel/component";
import { useNavigate } from "@biketravel/solid-router";
import { Title } from "@solidjs/meta";
import { FiArrowLeft, FiKey } from "solid-icons/fi";

export const RegistrationPage = () => {
    const { navigate } = useNavigate();

    return (
        <>
            <Title>Регистрация</Title>
            <CircleIcon icon={<FiKey/>}/>
            <H1 modifiers='center'>Регистрация</H1>
            <RegistrationForm
                onSuccess={() => navigate('auth_registration_success')}
            />
            <Divider/>
            <ButtonLink to='auth_login' leftIcon={<FiArrowLeft/>} modifiers='block'>
                Вернуться к форме авторизации
            </ButtonLink>
        </>
    )
}