import { Lightbox } from "@biketravel/solid-ui";
import { getImageUrl } from "@biketravel/imgproxy";
import { Accessor, Component } from "solid-js";
import type { TrackPhoto } from "@biketravel/sdk";

type TrackPhotoLightboxProps = {
  photos: TrackPhoto[];
  width: number;
  height: number;
  index: Accessor<number>;
  onClose(): void;
}

export const TrackPhotoLightbox: Component<TrackPhotoLightboxProps> = (props) => (
  <Lightbox
    onClose={props.onClose}
    images={props.photos.map(photo => getImageUrl(photo.path, props.width, props.height))}
    index={props.index}
  />
);
