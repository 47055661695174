import { useForm } from "@biketravel/solid-ui";
import { eventUpdateStore } from "@biketravel/state";
import { Component } from "solid-js";
import { EventForm, EventFormType } from "./EventForm";
import type { Event } from '@biketravel/sdk';

type EventUpdateFormProps = {
  event: Event;
  onSuccess(eventId: number): void;
}

export const EventUpdateForm: Component<EventUpdateFormProps> = (props) => {
  const {
    errors,
    setErrors,
    values,
    onChange,
  } = useForm<EventFormType>({
    description: props.event.description,
    start_lng: String(props.event.start_lng),
    start_lat: String(props.event.start_lat),
    start_at: props.event.start_at,
    name: props.event.name,
  });

  const onSubmit = async () => {
    const {
      event,
      errors,
    } = await eventUpdateStore.actions.update(props.event.id, {
      name: values().name,
      description: values().description,
      start_at: values().start_at,
      start_lat: parseFloat(values().start_lat),
      start_lng: parseFloat(values().start_lng),
    });
    if (errors) {
      setErrors(errors);
    } else if (event) {
      props.onSuccess(event.id);
    }
  };

  return (
    <EventForm
      route={props.event.route}
      onChange={onChange}
      loading={eventUpdateStore.loading}
      onSubmit={onSubmit}
      values={values}
      errors={errors}
    />
  );
}