import type { RegistrationConfirmRequest } from '@biketravel/sdk';
import { createSignal } from "solid-js";
import { AppStore, jwtStore, sdk } from "..";
import { LoadingType } from "@biketravel/state";
import { withLoading } from "../decorator";

export type RegistrationConfirmStore = LoadingType

const [
  loading,
  setLoading,
] = createSignal<RegistrationConfirmStore["loading"]>(false);

const registrationConfirm = async (input: RegistrationConfirmRequest) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.auth.registrationConfirm(input);

  jwtStore.actions.persistJwt(data);
});

type RegistrationConfirmActions = {
  registrationConfirm: typeof registrationConfirm,
}

export const registrationConfirmStore = {
  loading,
  actions: {
    registrationConfirm,
  }
} satisfies AppStore<RegistrationConfirmStore, RegistrationConfirmActions>;
