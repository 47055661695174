import type { Map as MapType, MapOptions } from "maplibre-gl";
import maplibre from "maplibre-gl";
import { baseLayers, StravaHeatmapControl, StyleSwitcherControl } from "@biketravel/map";
import { config } from "@biketravel/config";

export const createMap = (
  container: string | HTMLElement,
  options?: Partial<MapOptions>,
): MapType => {
  options = {
    ...options,
  };
  if (!options.zoom) {
    options.zoom = 14;
  }

  const initialLayer = Object.values(baseLayers).find(layer => layer.isDefault);
  if (!initialLayer) {
    throw new Error('initial layer not found');
  }

  const map = new maplibre.Map({
    container,
    style: initialLayer.style,
    // style: 'https://api.maptiler.com/maps/streets/style.json?key=FoKaj01lxa9BqZAOC1YO',
    // style: '/static/komoot.json',
    ...options,
  });

  map.addControl(new maplibre.NavigationControl({
    showCompass: true,
    showZoom: true,
    visualizePitch: true,
  }), 'top-right');

  if (config.STRAVA_HEATMAP) {
    map.addControl(new StravaHeatmapControl({
      tiles: [config.STRAVA_HEATMAP],
    }), 'top-right');
  }

  map.addControl(new maplibre.FullscreenControl({}), 'top-right');
  map.addControl(new maplibre.ScaleControl({ unit: 'metric' }), 'bottom-right');
  map.addControl(new maplibre.GeolocateControl({
    trackUserLocation: true,
    showAccuracyCircle: true,
    showUserLocation: true,
  }), 'top-right');
  map.addControl(new StyleSwitcherControl({
    basemaps: baseLayers,
    initial: initialLayer.id,
  }), 'top-right');

  return map;
};