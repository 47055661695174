import { createSignal } from "solid-js";
import { AppStore, eventIsMemberStore, eventMembersStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";

export type EventJoinLeaveState = LoadingType;

export type EventJoinLeaveClientState = EventJoinLeaveState & LoadingType;

const initialState: EventJoinLeaveClientState = {
  loading: undefined,
}

const [
  loading,
  setLoading,
] = createSignal<EventJoinLeaveClientState['loading']>(initialState.loading);

const join = async (id: number | string, subscribe: boolean) => withLoading(setLoading, async () => {
  const validId = parseInt(String(id), 10);

  await sdk.event.eventJoin({
    id: validId,
    subscribe
  });

  eventIsMemberStore.actions.setIsMember(true);

  await eventMembersStore.actions.fetch(validId);
});

const leave = async (id: number | string) => withLoading(setLoading, async () => {
  const validId = parseInt(String(id), 10);

  await sdk.event.eventLeave({
    id: validId,
  });

  eventIsMemberStore.actions.setIsMember(false);

  await eventMembersStore.actions.fetch(validId);
});

export type EventJoinLeaveActionsType = {
  join: typeof join,
  leave: typeof leave,
}

export const eventJoinLeaveStore = {
  loading,
  actions: {
    join,
    leave,
  }
} satisfies AppStore<EventJoinLeaveClientState, EventJoinLeaveActionsType>;