import { isEqual, iterateDays } from "./utils";
import { DayCell } from "./DayCell";
import { onChangeDate } from "./types";
import { locale } from "./locale";
import { DateTime } from "luxon";
import { Accessor, Component, For } from "solid-js";

export type CalendarDaysProps = {
    selected: Accessor<DateTime>
    onChange: onChangeDate
}

export const CalendarDays: Component<CalendarDaysProps> = (props) => (
    <div class="b-calendar__days">
        <For each={locale.ru.days}>
            {(dayOfWeek, i) => (
                <div class='b-calendar-dayofweek'>
                    {dayOfWeek}
                </div>
            )}
        </For>
        <For each={iterateDays(props.selected())}>
            {(date, i) => (
                <DayCell
                    isPrevious={date.month > props.selected().month}
                    isNext={date.month < props.selected().month}
                    onChange={props.onChange}
                    selected={isEqual(date, props.selected())}
                    date={date}/>
            )}
        </For>
    </div>
);