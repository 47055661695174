import { Title } from "@solidjs/meta";
import {
    Attention,
    BreadcrumbItem,
    BreadcrumbSymbol,
    ButtonLink,
    dateTimeToMediumDate,
    dateTimeToMediumTime,
    Divider,
    H1,
    H3,
    Segment,
    SegmentContent,
    SegmentFooter,
    Spinner,
    stringToDateTime
} from "@biketravel/solid-ui";
import { DateTime } from "luxon";
import { eventViewStore, jwtStore } from '@biketravel/state';
import { useCurrentRoute } from '@biketravel/solid-router';
import { createSignal, onMount, Show } from "solid-js";
import {
    AppBreadcrumbs,
    Comments,
    eventGpx,
    EventInfoBar,
    EventJoinButton,
    EventMembers,
    EventMenu,
    EventSummary,
    EventViewMap
} from "@biketravel/component";

export const EventViewPage = () => {
    const currentRoute = useCurrentRoute<{
        event_id: number,
    }>();

    const [
        removed,
        setRemoved,
    ] = createSignal(false);

    onMount(async () => {
        await eventViewStore.actions.fetch(currentRoute().params.event_id);
    });

    return (
        <Show when={!eventViewStore.loading() && eventViewStore.event()} fallback={<Spinner/>}>
            <Title>Поездки</Title>
            <AppBreadcrumbs>
                <BreadcrumbItem to='event_list'>
                    Поездки
                </BreadcrumbItem>
                <BreadcrumbSymbol/>
                <BreadcrumbItem>
                    {eventViewStore.event()!.name}
                </BreadcrumbItem>
            </AppBreadcrumbs>
            <H1>{eventViewStore.event()!.name}</H1>
            <Show when={stringToDateTime(eventViewStore.event()!.start_at) <= DateTime.now()}>
                <Attention modifiers='warning'>
                    Мероприятие
                    завершено {dateTimeToMediumDate(stringToDateTime(eventViewStore.event()!.start_at))}, {dateTimeToMediumTime(stringToDateTime(eventViewStore.event()!.start_at))}
                </Attention>
            </Show>
            <Show when={removed() || eventViewStore.event()!.deleted_at}>
                <Attention modifiers='warning'>
                    Поездка удалена
                </Attention>
            </Show>
            <Segment>
                <EventViewMap
                    startLng={eventViewStore.event()!.start_lng}
                    startLat={eventViewStore.event()!.start_lat}
                    route={eventViewStore.event()!.route}
                    modifiers='event'
                />
                <SegmentContent>
                    <EventInfoBar event={eventViewStore.event()!}/>
                    <Divider/>
                    <EventSummary event={eventViewStore.event()!}/>
                </SegmentContent>
                <SegmentFooter>
                    <ButtonLink
                        modifiers='default'
                        href={eventGpx(eventViewStore.event()!.id)}
                    >
                        Скачать GPX
                    </ButtonLink>
                    <Show when={jwtStore.jwt()}>
                        <EventJoinButton event_id={eventViewStore.event()!.id}/>
                        <EventMenu
                            onRemove={() => setRemoved(true)}
                            event={eventViewStore.event()!}
                        />
                    </Show>
                </SegmentFooter>
            </Segment>
            <Divider modifiers='dashed'/>
            <H3>Участники</H3>
            <Show when={jwtStore.jwt()} fallback={(
                <Attention>Для участия требуется авторизация</Attention>
            )}>
                <EventMembers event_id={eventViewStore.event()!.id}/>
            </Show>
            <Divider modifiers='dashed'/>
            <H3>Комментарии</H3>
            <Segment>
                <SegmentContent>
                    <Comments
                        ownerType='event'
                        ownerId={eventViewStore.event()!.id}
                    />
                </SegmentContent>
            </Segment>
        </Show>
    );
}
