import center from "@turf/center";
import { points } from "@turf/helpers";
import type { Map } from 'maplibre-gl';
import { Component, createSignal, onMount } from "solid-js";
import { createMap } from "../map";
import { createStateFromRoute, Profiles, PulsingLayer, RouteLayer } from "@biketravel/map";
import { routeStore } from "@biketravel/state";
import { TrackRoute } from "@biketravel/sdk";

type EventCreateStartMapProps = {
  route: TrackRoute;
  onChange(lng: number, lat: number): void;
};

export const EventCreateStartMap: Component<EventCreateStartMapProps> = (props) => {
  let mapRef: Map | undefined;
  let mapContainer: HTMLDivElement | undefined;
  const [lng, lat] = center(points(props.route.user_waypoints)).geometry.coordinates;
  const [startLngLat, setStartLngLat] = createSignal<[number, number]>(props.route.user_waypoints[0] as [number, number]);

  onMount(() => {
    if (mapRef) {
      // initialize map only once
      return;
    }

    if (!mapContainer) {
      return;
    }

    const map = createMap(mapContainer, {
      center: [lng, lat],
      scrollZoom: false,
    });
    const routeLayer = new RouteLayer(map, {
      autoFitBounds: true,
      editable: false,
      route: async (waypoints: [number, number][], profile: Profiles) => {
        const newRoute = await routeStore.actions.route(waypoints, profile);

        if (!newRoute) {
          throw new Error("route dispatch error");
        }

        return newRoute;
      },
    }, createStateFromRoute(props.route, false));
    const pulsingLayer = new PulsingLayer(map);

    map.on('load', () => {
      pulsingLayer.setPoint([lng, lat]);

      map.on('click', e => {
        const { lng, lat } = e.lngLat;
        const point: [number, number] = [lng, lat];
        setStartLngLat(point);
        pulsingLayer.setPoint([
          lng,
          lat,
        ]);
        props.onChange(lng, lat);
      });
    });

    mapRef = map;
  });

  return (
    <div ref={mapContainer} style={{ height: '400px' }} class="b-map"/>
  );
};
