import cx from 'classnames';
import { bem } from "classnames-bem";
import { createEffect, mergeProps, ParentComponent, Show } from "solid-js";
import { Portal } from "solid-js/web";
import { findFirstFocusableElement } from "./utils";

type OverlayProps = {
    canEscapeKeyClose?: boolean
    hasBackdrop?: boolean
    canOutsideClickClose?: boolean
    isOpen?: boolean
    onClose?: () => void
    class?: string
    backdropClass?: string
}

const overflowClass = 'b-overlay--open';

const enableOverflow = () => {
    document.body.classList.remove(overflowClass);
}

const disableOverflow = () => {
    document.body.classList.add(overflowClass);
}

export const Overlay: ParentComponent<OverlayProps> = (props) => {
    const merged = mergeProps({
        hasBackdrop: true,
        canOutsideClickClose: true,
        canEscapeKeyClose: true,
    }, props);

    if (typeof document === "undefined") {
        return null;
    }

    let ref: HTMLDivElement | undefined;

    const onCloseCallback = () => {
        enableOverflow();
        merged.onClose && merged.onClose();
    };

    const handleClick = (e: MouseEvent) => {
        const target = e.target as HTMLDivElement;
        if (target.classList.contains('b-overlay__content') && merged.canOutsideClickClose) {
            onCloseCallback();
        }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.code === 'Escape' && merged.canEscapeKeyClose) {
            onCloseCallback();
            // prevent browser-specific escape key behavior (Safari exits fullscreen)
            e.preventDefault();
        }
    };

    createEffect(() => {
        if (!merged.isOpen) {
            if (merged.hasBackdrop) {
                // add a class to the body to prevent scrolling of content below the overlay
                enableOverflow();
            }
            return;
        }

        if (merged.hasBackdrop) {
            // add a class to the body to prevent scrolling of content below the overlay
            disableOverflow();
        }

        if (ref) {
            (findFirstFocusableElement(ref) || ref).focus();
        }
    });

    return (
        <Show when={merged.isOpen}>
            <Portal>
                <div class={cx(bem('b-overlay', { open: merged.isOpen }), merged.class)}>
                    <Show when={merged.hasBackdrop}>
                        <div class={cx('b-overlay__backdrop', merged.backdropClass)}/>
                    </Show>
                    <div
                        class="b-overlay__container"
                        onClick={handleClick}
                        onKeyDown={handleKeyDown}
                    >
                        <div ref={ref} tabindex='0' class='b-overlay__content'>
                            {merged.children}
                        </div>
                    </div>
                </div>
            </Portal>
        </Show>
    );
};
