import { Button, Form, FormRow, InputText, Label, Spinner, useForm, ValidationError } from "@biketravel/solid-ui";
import { restoreConfirmStore } from "@biketravel/state";
import type { RestoreConfirmRequest } from "@biketravel/sdk";
import { Component } from "solid-js";

type RestoreConfirmFormProps = {
  token: string;
  onSuccess(): void;
}

export const RestoreConfirmForm: Component<RestoreConfirmFormProps> = (props) => {
  const {
    errors,
    setErrors,
    values,
    onChange,
  } = useForm<RestoreConfirmRequest>({
    token: props.token,
    password: '',
    password_confirm: '',
  });

  const onSubmit = async () => {
    const formErrors = await restoreConfirmStore.actions.restoreConfirm(values());
    if (formErrors) {
      setErrors(formErrors);
    } else {
      props.onSuccess();
    }
  };

  return (
    <Form onSubmit={() => onSubmit()}>
      <FormRow modifiers='margin-bottom'>
        <Label for="password" required>Пароль</Label>
        <InputText
          autofocus={true}
          type='password'
          id='password'
          name='password'
          value={values().password}
          onChange={({ value }) => onChange('password', value)}
        />
        <ValidationError path='password' errors={errors()}/>
      </FormRow>
      <FormRow modifiers='margin-bottom'>
        <Label for="passwordConfirm" required>Подтверждение пароля</Label>
        <InputText
          type='password'
          id='passwordConfirm'
          name='passwordConfirm'
          value={values().password_confirm}
          onChange={({ value }) => onChange('password_confirm', value)}
        />
        <ValidationError path='passwordConfirm' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Button modifiers={['primary', 'block']} type="submit">
          Изменить пароль
        </Button>
      </FormRow>
      <Spinner overlay show={restoreConfirmStore.loading()}/>
    </Form>
  );
}