import * as polyline from "@mapbox/polyline";
import type { TrackRoute } from '@biketravel/sdk';

export type DecodedRoute = Omit<TrackRoute, 'segments'> & {
  segments: [number, number][][]
}

export const encodeLine = (line: [number, number][]): string => polyline.encode(line);
export const encodeMultiline = (lines: [number, number][][]): string[] => (
  lines.map(line => encodeLine(line))
);

export const decodeMultiline = (multiline: string[]): [number, number][][] => {
  const segments: [number, number][][] = [];
  for (let i = 0; i < multiline.length; i++) {
    const decodedSegment = polyline.decode(multiline[i]);
    const segment: [number, number][] = [];
    for (let z = 0; z < decodedSegment.length; z++) {
      segment.push(decodedSegment[z].reverse() as [number, number]);
    }
    segments.push(segment);
  }

  return segments;
};

export const decodeRoute = (route: TrackRoute): DecodedRoute => ({
  ...route,
  segments: decodeMultiline(route.segments),
});