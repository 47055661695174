import { createSignal } from "solid-js";
import { AppStore, sdk } from "..";
import type { RestoreRequest } from "@biketravel/sdk";
import { LoadingType } from "@biketravel/state";
import { withLoading } from "../decorator";

export type RestoreStore = LoadingType

const [
  loading,
  setLoading,
] = createSignal<RestoreStore["loading"]>(false);

const restore = async (input: RestoreRequest) => withLoading(setLoading, async () => {
  await sdk.auth.restore(input);
});

type RestoreActions = {
  restore: typeof restore,
}

export const restoreStore = {
  loading,
  actions: {
    restore,
  }
} satisfies AppStore<RestoreStore, RestoreActions>;
