import { Route } from "@biketravel/solid-router";
import {
    EventCalendarPage,
    EventCreatePage,
    EventEditPage,
    EventListPage,
    EventMyListPage,
    EventViewPage
} from "../page";
import { DefaultLayout } from "../Layout";
import { RouteProps } from "../renderer";
import { EventFilterFormType, eventListStore, eventViewStore } from "@biketravel/state";

export const eventRoutes: Route<RouteProps>[] = [
    {
        path: '/event',
        children: [
            {
                name: 'event_list',
                path: '',
                component: EventListPage,
                props: {
                    layout: DefaultLayout,
                    fetchServer: async ({ query }: { query: EventFilterFormType }) => {
                        await eventListStore.actions.fetch(
                            eventListStore.actions.filterConvert(query)
                        );
                    }
                }
            },
            {
                name: 'event_my_list',
                path: '/my',
                component: EventMyListPage,
                props: {
                    auth: true,
                    layout: DefaultLayout,
                }
            },
            {
                name: 'event_create',
                path: '/create/:track_id(\\d+)',
                component: EventCreatePage,
                props: {
                    auth: true,
                    layout: DefaultLayout,
                }
            },
            {
                path: '/calendar',
                children: [
                    {
                        name: 'event_calendar',
                        path: '',
                        component: EventCalendarPage,
                        props: {
                            layout: DefaultLayout,
                        },
                    },
                    {
                        path: '/:year(\\d+)/:month(\\d+)',
                        name: 'event_calendar_year_month',
                        component: EventCalendarPage,
                        props: {
                            layout: DefaultLayout,
                        },
                    },
                ]
            },
            {
                path: '/:event_id(\\d+)',
                name: 'event_view',
                component: EventViewPage,
                props: {
                    layout: DefaultLayout,
                    fetchServer: async ({ params }: { params: { event_id: number | string } }) => {
                        await eventViewStore.actions.fetch(parseInt(String(params.event_id), 10));
                    }
                },
            },
            {
                path: '/:event_id(\\d+)/edit',
                name: 'event_edit',
                component: EventEditPage,
                props: {
                    layout: DefaultLayout,
                },
            },
        ]
    },
];