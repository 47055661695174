import { Link } from "@biketravel/solid-router";
import { Component, Index } from "solid-js";
import type { Event } from '@biketravel/sdk';
import { getCollectionNames } from '@biketravel/state';

type EventSummaryProps = {
  event: Event
}

export const EventSummary: Component<EventSummaryProps> = (props) => (
  <div class="b-event-summary-content">
    <dl class="b-event-summary">
      <dt class="b-event-summary__label">Подборки:</dt>
      <dd class="b-event-summary__value">
        <Index each={getCollectionNames(props.event.collection)} fallback='-'>
          {(col, i) => (
            <Link
              class='b-track-list-item__collection'
              to='event_list'
              query={{ collection: col().type }}
            >
              {col().name}
            </Link>
          )}
        </Index>
      </dd>
    </dl>
    <dl class="b-event-summary">
      <dt class="b-event-summary__label">Автор:</dt>
      <dd class="b-event-summary__value">
        <Link class='b-user-avatar__link' to='user_view' params={{ user_id: props.event.author_id }}>
          {props.event.author?.username}
        </Link>
      </dd>
    </dl>
    <dl class="b-event-summary">
      <dt class="b-event-summary__label">Описание:</dt>
      <dd class="b-event-summary__value">
        {props.event.description}
      </dd>
    </dl>
  </div>
);
