import type { Map } from "maplibre-gl";

export class PulsingMarker implements ImageData {
  protected context: CanvasRenderingContext2D | null = null;
  protected map: Map;
  protected size = 200;
  colorSpace: PredefinedColorSpace = "srgb";
  width: number = this.size;
  height: number = this.size;
  data: Uint8ClampedArray = new Uint8ClampedArray(this.size * this.size * 4);

  constructor(map: Map, size = 200) {
    this.map = map;
    this.size = size;
    this.width = size;
    this.height = size;

    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    this.context = canvas.getContext('2d');
  }

  /**
   * Call once before every frame where the icon will be used.
   */
  render() {
    const duration = 1000;
    const t = (performance.now() % duration) / duration;

    const radius = (this.size / 2) * 0.3;
    const outerRadius = (this.size / 2) * 0.7 * t + radius;

    // Draw the outer circle.
    if (this.context) {
      this.context.clearRect(0, 0, this.width, this.height);
      this.context.beginPath();
      this.context.arc(
        this.width / 2,
        this.height / 2,
        outerRadius,
        0,
        Math.PI * 2
      );
      this.context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
      this.context.fill();

      // Draw the inner circle.
      this.context.beginPath();
      this.context.arc(
        this.width / 2,
        this.height / 2,
        radius,
        0,
        Math.PI * 2
      );
      this.context.fillStyle = 'rgba(255, 100, 100, 1)';
      this.context.strokeStyle = 'white';
      this.context.lineWidth = 2 + 4 * (1 - t);
      this.context.fill();
      this.context.stroke();

      // Update this image's data with data from the canvas.
      this.data = this.context.getImageData(
        0,
        0,
        this.width,
        this.height
      ).data;

      // Continuously repaint the map, resulting
      // in the smooth animation of the dot.
      this.map.triggerRepaint();
    }

    // Return `true` to let the map know that the image was updated.
    return true;
  }
}
