import { toast } from "solid-toast";
import { trackUpdateStore } from "@biketravel/state";
import { TrackForm, TrackFormType } from "./TrackForm";
import { useForm } from "@biketravel/solid-ui";
import { Component } from "solid-js";
import type { Track } from '@biketravel/sdk';

type TrackUpdateFormProps = {
  track: Track;
  onSuccess(): void;
}

export const TrackUpdateForm: Component<TrackUpdateFormProps> = (props) => {
  const {
    errors,
    setErrors,
    setValues,
    values,
    onChange,
  } = useForm<TrackFormType>({
    name: props.track.name,
    description: props.track.description,
    collection: props.track.collection,
    tags: props.track.tags,
    month: props.track.month,
  });

  const onSubmit = async () => {
    const errors = await trackUpdateStore.actions.update(props.track.id, {
      ...values(),
    });
    if (errors) {
      setErrors(errors);
      toast.error('Пожалуйста исправьте ошибки в форме');
    } else {
      props.onSuccess();
    }
  };

  return (
    <TrackForm
      errors={errors}
      values={values}
      setValues={setValues}
      onChange={onChange}
      loading={trackUpdateStore.loading}
      onSubmit={onSubmit}
    />
  );
}