import { useForm } from "@biketravel/solid-ui";
import { placeCreateStore } from "@biketravel/state";
import { Component } from "solid-js";
import { PlaceForm, PlaceFormType } from "./PlaceForm";

type PlaceCreateFormProps = {
  position: [number, number];
  onSuccess(): void;
}

export const PlaceCreateForm: Component<PlaceCreateFormProps> = (props) => {
  const {
    errors,
    setErrors,
    values,
    onChange,
  } = useForm<PlaceFormType>({
    description: '',
    name: ''
  });

  const onSubmit = async () => {
    const formErrors = await placeCreateStore.actions.create({
      ...values(),
      lng: props.position[0],
      lat: props.position[1],
    });
    if (formErrors) {
      setErrors(formErrors);
    } else {
      props.onSuccess();
    }
  };

  return (
    <PlaceForm
      onChange={onChange}
      loading={placeCreateStore.loading}
      onSubmit={onSubmit}
      values={values}
      errors={errors}
    />
  );
}