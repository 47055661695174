import { onCleanup } from "solid-js";

export const clickOutside = (triggerEl: Element, containerEl: Element, accessor: () => void) => {
    const clickHandler = (e: MouseEvent) => {
        const target = e.target;
        if (!target) {
            return accessor();
        }

        if (containerEl.contains(e.target as Element)) {
            return;
        }

        if (triggerEl.contains(e.target as Element)) {
            return;
        }

        accessor();
    }

    document.body.addEventListener("click", clickHandler);

    onCleanup(() => {
        document.body.removeEventListener("click", clickHandler);
    });
}
