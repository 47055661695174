import { ParentComponent } from "solid-js";
import { bem } from "classnames-bem";

export const Dropdown: ParentComponent = (props) => (
    <div class="b-dropdown">
        {props.children}
    </div>
);

type DropdownContentProps = {
    right?: boolean;
    left?: boolean;
}

export const DropdownContent: ParentComponent<DropdownContentProps> = (props) => (
    <div class={bem("b-dropdown__content", { right: props.right, left: props.left })}>
        {props.children}
    </div>
);