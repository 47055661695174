import { batch, createSignal } from "solid-js";
import { AppStore, ExportableStore, LoadingType, sdk } from "..";
import { shallowEqual } from "shallow-equal-object";
import { TrackList, TrackListFilterParameter } from "@biketravel/sdk";
import { withLoading } from "../decorator";

export type TrackListState = {
  data: TrackList;
  filter: TrackListFilter | undefined;
}

export type TrackListClientState = TrackListState & LoadingType;

const initialState: TrackListClientState = {
  data: {
    edge: [],
    cursor: undefined
  },
  filter: undefined,
  loading: false,
}

export type TrackListFilter = TrackListFilterParameter;

const [
  trackData,
  setData
] = createSignal<TrackListClientState['data']>(initialState.data);

const [
  filter,
  setFilter
] = createSignal<TrackListClientState['filter']>(initialState.filter);

const [
  loading,
  setLoading
] = createSignal<TrackListClientState["loading"]>(initialState.loading);

type SomeFields = Pick<TrackListFilter, "name" | "distance_min" | "distance_max" | "collection">

export type TrackFilterFormType = {
  [K in keyof SomeFields]: string;
};

export const filterValues = (filter: TrackFilterFormType): TrackListFilterParameter => ({
  name: filter.name ? filter.name : undefined,
  distance_min: filter.distance_min ? parseInt(filter.distance_min, 0) : undefined,
  distance_max: filter.distance_max ? parseInt(filter.distance_max, 0) : undefined,
  collection: filter.collection ? filter.collection : undefined,
})

const filterChanged = (values: TrackListFilterParameter) => !shallowEqual(values, filter());
const filterConvert = (values: TrackFilterFormType) => filterValues(values);

const fetch = async (
  filter?: TrackListFilterParameter,
  cursor?: number,
  merge: boolean = false,
  limit = 10,
) => withLoading(setLoading, async () => {
  setFilter(filter || {});

  const {
    data
  } = await sdk.track.trackList(cursor, limit, filter);

  batch(() => {
    if (merge) {
      if (data?.edge) {
        setData({
          edge: [
            ...(trackData().edge || []),
            ...data.edge,
          ],
          cursor: data.cursor,
        });
      }
    } else {
      setData(data);
    }
  })
})

const reset = () => {
  setLoading(initialState.loading);
  setFilter(initialState.filter);
  setData(initialState.data);
}

type TrackListActionsType = {
  fetch: typeof fetch;
  reset: typeof reset;
  filterChanged: typeof filterChanged;
  filterConvert: typeof filterConvert;
}

export const trackListStore = {
  data: trackData,
  filter,
  loading,
  actions: {
    fetch,
    filterChanged,
    filterConvert,
    reset,
  },
  exportState() {
    return {
      data: trackData(),
      filter: filter(),
    }
  },
  importState(value) {
    setData(value.data);
    setFilter(value.filter);
  }
} satisfies AppStore<TrackListClientState, TrackListActionsType> & ExportableStore<TrackListState>;
