import {
  Button,
  Form,
  FormRow,
  InputText,
  InputTextarea,
  Label,
  Spinner,
  ValidationError,
  ValidationErrorsType
} from "@biketravel/solid-ui";
import { Accessor, Component } from "solid-js";

export type PlaceFormType = {
  description: string;
  name: string;
}

type PlaceFormProps = {
  errors: Accessor<ValidationErrorsType>
  values: Accessor<PlaceFormType>
  loading: Accessor<boolean | undefined>;
  onChange(name: keyof PlaceFormType, value: string): void;
  onSubmit(): Awaited<void>;
}

export const PlaceForm: Component<PlaceFormProps> = (props) => (
  <Form onSubmit={() => props.onSubmit()}>
    <FormRow modifiers='margin-bottom'>
      <Label for="name" required>Название</Label>
      <InputText
        autofocus={true}
        type='text'
        id='name'
        name='name'
        value={props.values().name}
        onChange={({ value }) => props.onChange('name', value)}
      />
      <ValidationError path='name' errors={props.errors()}/>
    </FormRow>
    <FormRow modifiers='margin-bottom'>
      <Label for="description" required>Описание</Label>
      <InputTextarea
        id='description'
        name='description'
        value={props.values().description}
        onChange={({ value }) => props.onChange('description', value)}
      />
      <ValidationError path='description' errors={props.errors()}/>
    </FormRow>
    <FormRow>
      <Button modifiers={['primary']} type="submit">
        Сохранить
      </Button>
    </FormRow>
    <Spinner overlay show={props.loading()}/>
  </Form>
);