import { batch, createSignal } from "solid-js";
import { EventCalendar } from "@biketravel/sdk";
import { AppStore, captureError, ExportableStore, LoadingType, sdk } from "..";
import { AxiosError } from "axios";

export type EventCalendarState = {
  events: EventCalendar;
}

export type EventCalendarClientState = EventCalendarState & LoadingType;

const initialState: EventCalendarClientState = {
  events: {
    edge: []
  },
  loading: undefined,
}

const [
  events,
  setEvents
] = createSignal<EventCalendarClientState['events']>(initialState.events);

const [
  loading,
  setLoading
] = createSignal<EventCalendarClientState["loading"]>(initialState.loading);

const eventCalendarAction = async (
  year: number,
  month: number,
) => {
  setLoading(true);

  try {
    const resp = await sdk.event.eventCalendar(year, month);

    batch(() => {
      setLoading(false);
      setEvents(resp.data);
    })
  } catch (error: AxiosError | unknown) {
    setLoading(false);

    if (error instanceof AxiosError) {
      return error.response?.data.errors;
    }

    captureError(error);
  }
}

const resetAction = () => {
  setEvents(initialState.events);
  setLoading(initialState.loading);
};

export type EventCalendarActionsType = {
  fetch: typeof eventCalendarAction
  reset: typeof resetAction
}

export const eventCalendarStore = {
  events,
  loading,
  actions: {
    fetch: eventCalendarAction,
    reset: resetAction,
  },
  exportState() {
    return {
      events: events(),
    }
  },
  importState(value) {
    setEvents(value.events);
  }
} satisfies AppStore<EventCalendarClientState, EventCalendarActionsType> & ExportableStore<EventCalendarState>;