import type { DataLayerInterface } from "./baseLayer";
import type { FitBoundsOptions, GeoJSONSource, Map, MapMouseEvent, MapTouchEvent, Popup, Source, } from 'maplibre-gl';
import maplibre from 'maplibre-gl';
import type { Feature, FeatureCollection, GeoJSON, Geometry, LineString, Point, Position } from "geojson";
import { defaultProfile, Profiles } from "../engine";
import { createTooltipContent } from "../route";
import type { Marker, Place, TrackRoute } from '@biketravel/sdk';
import { isMobile, loadImages } from "./utils";
import {
  createArrowRouteLayerStyle,
  createFeatureCollectionFromCoords,
  createGhostFeatureCollection,
  createGhostLayerStyle,
  createMarkerLayerStyle,
  createRouteLayerStyle,
} from "../route/helper";
import {
  createStaticRoute,
  emptySurface,
  routeAddStaticWaypoint,
  routeAddWaypoint,
  routeAddWaypointOnLine,
  routeUpdate,
} from "./routeUtils";
import length from "@turf/length";
import along from "@turf/along";
import { featureCollection, lineString, point } from "@turf/helpers";
import { createEmptyRouteState, reducer, RouteAction, RouteActionType, RouteState } from "./reducer";
import { decodeMultiline, encodeMultiline } from "../encoder";

export type LineProps = {
  index: number
  width: number
  color: string
}

export type onRouteUpdated = (route: TrackRoute) => Promise<void> | void;

type RouteMapOptions = {
  route?(waypoints: [number, number][], profile: string): Promise<TrackRoute | undefined>
  onContextMenu?(e: MapMouseEvent | MapTouchEvent, hasWaypoints: boolean): void
  onWaypointContextMenu?(e: MapMouseEvent | MapTouchEvent, index: number, firstOrLast: boolean): void
  onLineContextMenu?(e: MapMouseEvent | MapTouchEvent, index: number): void
  autoFitBounds?: boolean
  onRouteUpdated?: onRouteUpdated
  profile: Profiles
  editable?: boolean
  onMarkerClick?: (marker: Marker) => void
  onPlaceClick?: (place: Place) => void
}

type PointProps = {
  index: number
}

export class RouteLayer implements DataLayerInterface {
  private readonly options: RouteMapOptions;
  protected readonly map: Map;
  protected popup: Popup;
  protected linePopup: Popup;
  protected points: FeatureCollection<Point> = featureCollection<Point>([]);
  private readonly idGhost = 'ghost';
  private readonly idPlay = 'play';
  private readonly idPoint = 'point';
  private readonly idData = 'data';
  private state: RouteState;
  private currentPointProps?: PointProps;

  // @todo посчитать ghost линии в итоговом километраже
  constructor(map: Map, options?: Partial<RouteMapOptions>, state: Partial<RouteState> = createEmptyRouteState()) {
    this.map = map;
    this.state = {
      ...createEmptyRouteState(),
      ...state,
    };
    this.popup = new maplibre.Popup({
      closeButton: true,
      closeOnClick: true,
    });
    this.popup.setMaxWidth('360px');
    this.options = {
      profile: defaultProfile,
      ...options,
    };
    this.linePopup = new maplibre.Popup({
      closeButton: false,
      closeOnClick: true,
    });

    map.once('load', () => this.initialize());
  }

  play() {
    const dataSource = this.getSource(this.idData);
    const geodata = { ...dataSource._data as FeatureCollection<LineString> };
    const features: Feature<LineString>[] = [...geodata.features];

    this.setSourceValues(this.idData, featureCollection([]));

    const rectCollection = featureCollection([]);

    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      const distance = length(feature, { units: 'kilometers' });
      const rects = distance > 10 ? 100 / distance : 100;
      const segments = distance / rects;

      for (let z = 0; z <= rects; z++) {
        rectCollection.features.push(along(feature, segments * z));
      }
    }

    const geojsonPoint = featureCollection<LineString>([]);

    let x = 0;
    const animateLine = () => {
      if (x < rectCollection.features.length) {
        geojsonPoint.features[0].geometry.coordinates.push(
          rectCollection.features[x].geometry.coordinates as [number, number]
        );
        this.setSourceValues(this.idPlay, geojsonPoint);
        x++;
        requestAnimationFrame(animateLine);
      } else {
        this.setSourceValues(this.idPlay, featureCollection<LineString>([]));
        // Восстанавливаем оригинальный маршрут
        this.parseResponse(this.state.route);
      }
    };
    animateLine();
  }

  dispatch(action: RouteAction) {
    const newState = reducer(this.state, action);
    if (this.state.index != newState.index) {
      this.state = newState;
    }
  }

  async addLineWaypoint(lngLat: [number, number], index: number) {
    this.createUserMarker(index + 1, lngLat);

    const coordinates = [
      this.state.route.user_waypoints[index],
      lngLat,
      this.state.route.user_waypoints[index + 1],
    ];

    const routable = this.state.route.routable[index];
    if (routable) {
      this.setLastResponse(routeAddWaypointOnLine(
        this.state.route,
        await this.routeCallback(coordinates as [number, number][]),
        index,
        lngLat,
        routable,
      ));
    } else {
      this.setLastResponse(routeAddWaypointOnLine(
        this.state.route,
        this.createTemporaryRoute(coordinates as [number, number][]),
        index,
        lngLat,
        routable,
      ));
    }
    this.parseResponse(this.state.route);
  }

  createTemporaryRoute(coordinates: [number, number][]): TrackRoute {
    return {
      segments: encodeMultiline([
        [coordinates[0], coordinates[1]],
        [coordinates[1], coordinates[2]],
      ]),
      routable: [false, false],
      elevation: [[0, 0, 0]],
      waypoints: coordinates,
      user_waypoints: coordinates,
      ascend: [0],
      descend: [0],
      surface: [[emptySurface]],
    };
  }

  createLayers(): void {
    const canvas = this.map.getCanvasContainer();

    // ghost
    this.map.addSource(this.idGhost, {
      type: 'geojson',
      data: featureCollection([]),
    });
    this.map.addLayer(createGhostLayerStyle(this.idGhost));

    // data
    this.map.addSource(this.idData, {
      type: 'geojson',
      // lineMetrics: true,
      data: featureCollection([]),
    });
    this.map.addLayer(createRouteLayerStyle(this.idData));
    this.map.addLayer(createArrowRouteLayerStyle('arrow', this.idData));

    if (this.options.editable) {
      this.map.on('click', this.idData, (e: MapMouseEvent & { features?: GeoJSON.Feature[] }): void => {
        if (!e.features) {
          return;
        }

        if (e.features.length === 0) {
          return;
        }

        const currentLineProps: LineProps = {
          ...e.features[0].properties as LineProps,
        };

        this.addLineWaypoint([
          e.lngLat.lng,
          e.lngLat.lat,
        ], currentLineProps.index);
      });

      // this.map.on('contextmenu', this.idData, (e: MapMouseEvent & { features?: GeoJSON.Feature[] }): void => {
      //     if (!e.features) {
      //         return;
      //     }
      //
      //     if (e.features.length === 0) {
      //         return;
      //     }
      //
      //     const currentLineProps: LineProps = {
      //         ...e.features[0].properties as LineProps,
      //     };
      //
      //     this.options.onLineContextMenu && this.options.onLineContextMenu(
      //         e,
      //         currentLineProps.index,
      //     );
      // });
    }

    const mouseenterListener = (e: MapMouseEvent & { features?: GeoJSON.Feature[] }): void => {
      if (!e.features) {
        return;
      }

      if (e.features.length === 0) {
        return;
      }

      const {
        index,
      } = e.features[0].properties as LineProps;

      canvas.style.cursor = 'pointer';
      this.linePopup
        .setLngLat(e.lngLat)
        .setHTML(createTooltipContent(this.state.route, index))
        .addTo(this.map);
    };
    const mouseleaveListener = (): void => {
      this.linePopup?.remove();
      canvas.style.cursor = '';
    };
    this.map.on('mouseenter', this.idData, mouseenterListener);
    this.map.on('mouseleave', this.idData, mouseleaveListener);

    // play
    this.map.addSource(this.idPlay, {
      type: 'geojson',
      data: featureCollection([]),
    });

    this.map.addLayer(createRouteLayerStyle(this.idPlay));
    this.map.addLayer(createArrowRouteLayerStyle('line-animation-arrow', this.idPlay));

    // point
    this.map.addSource(this.idPoint, {
      type: 'geojson',
      data: featureCollection<Point>([]),
    });
    this.map.addLayer(createMarkerLayerStyle(this.idPoint));

    if (this.options.editable) {
      this.map.on(isMobile() ? 'click' : 'contextmenu', e => {
        const pointFeatures = this.map.queryRenderedFeatures(e.point, {
          layers: [
            this.idPoint,
          ],
        });
        if (this.options.onWaypointContextMenu && pointFeatures.length > 0) {
          const { index } = pointFeatures[0].properties as PointProps;

          this.options.onWaypointContextMenu(
            e,
            index,
            index === 0 || index === this.state.route.user_waypoints.length - 1,
          );
          return;
        }

        const lineFeatures = this.map.queryRenderedFeatures(e.point, {
          layers: [
            this.idData,
          ],
        });
        if (this.options.onLineContextMenu && lineFeatures.length > 0) {
          const currentLineProps: LineProps = {
            ...lineFeatures[0].properties as LineProps,
          };
          this.options.onLineContextMenu(
            e,
            currentLineProps.index,
          );
          return;
        }

        if (this.options.onContextMenu) {
          const hasWaypoints = this.getSourceValues(this.idPoint).features.length > 0;
          this.options.onContextMenu(e, hasWaypoints);
          return;
        }
      });
      this.map.on('mouseenter', this.idPoint, () => {
        canvas.style.cursor = 'move';
      });
      this.map.on('mouseleave', this.idPoint, () => {
        canvas.style.cursor = '';
      });
      const onMove = (e: MapTouchEvent) => {
        if (!this.currentPointProps) {
          return;
        }

        const currentPoint = { ...this.currentPointProps };

        const values = { ...this.getSourceValues<Point>(this.idPoint) };
        values.features[currentPoint.index].geometry.coordinates = [
          e.lngLat.lng,
          e.lngLat.lat,
        ];
        this.setSourceValues(this.idPoint, values);
      };
      const onUp = (e: MapTouchEvent) => {
        if (!this.currentPointProps) {
          return;
        }

        const currentPointProps = { ...this.currentPointProps };

        canvas.style.cursor = '';
        if (isMobile()) {
          this.map.off('touchmove', onMove);
        } else {
          this.map.off('mousemove', onMove);
        }

        this.handleOnMouseUp([
          e.lngLat.lng,
          e.lngLat.lat,
        ], currentPointProps.index);
      };

      if (isMobile()) {
        this.map.on('touchstart', this.idPoint, (e: MapTouchEvent & { features?: GeoJSON.Feature[] }) => {
          e.preventDefault();
          if (!e.features) {
            return;
          }

          if (e.features.length === 0) {
            return;
          }

          this.currentPointProps = e.features[0].properties as PointProps;

          this.map.on('touchmove', onMove);
          this.map.once('touchend', onUp);
        });
      } else {
        this.map.on('mousedown', this.idPoint, (e: MapMouseEvent & { features?: GeoJSON.Feature[] }) => {
          if (e.originalEvent.button === 0) {
            e.preventDefault();

            if (!e.features) {
              return;
            }

            if (e.features.length === 0) {
              return;
            }

            this.currentPointProps = { ...e.features[0].properties } as PointProps;

            canvas.style.cursor = 'grab';
            this.map.on('mousemove', onMove);
            this.map.once('mouseup', onUp);
          }
        });
      }
    }
  }

  /**
   * Конвертация сегмента маршрута из routable (построенного graphhopper)
   * в статический маршрут и обратно по индексу
   * @param segmentIndex
   */
  async segmentRoutable(segmentIndex: number) {
    const routable = typeof this.state.route.routable[segmentIndex] === 'undefined'
      ? true
      : this.state.route.routable[segmentIndex];

    const prevPoint = this.state.route.user_waypoints[segmentIndex] as [number, number];
    const nextPoint = this.state.route.user_waypoints[segmentIndex + 1] as [number, number];

    await this.updateSegment(
      segmentIndex,
      segmentIndex,
      !routable,
      [
        prevPoint,
        nextPoint,
      ],
    );
  }

  protected pointRemove(index: number) {
    const points = this.getSourceValues<Point>(this.idPoint);
    points.features.splice(index, 1);
    const indexedPoints = this.updateIndexesUserMarker(points);
    this.setSourceValues(this.idPoint, indexedPoints);
  }

  async waypointRemove(index: number, routable: boolean) {
    this.pointRemove(index);

    this.state.route.waypoints.splice(index, 1);
    this.state.route.user_waypoints.splice(index, 1);

    const segmentIndex = Math.max(0, index - 1);
    if (index === 0 || index === this.state.route.segments.length) {
      this.state.route.segments.splice(segmentIndex, 1);
      this.state.route.elevation.splice(segmentIndex, 1);
      this.state.route.routable.splice(segmentIndex, 1);

      this.parseResponse(this.state.route);
      return;
    }

    this.state.route.segments.splice(segmentIndex, 1);
    this.state.route.elevation.splice(segmentIndex, 1);
    this.state.route.routable.splice(segmentIndex, 1);
    this.parseResponse(this.state.route);

    const coordinates = [
      this.state.route.user_waypoints[index - 1],
      this.state.route.user_waypoints[index],
    ];

    await this.updateSegment(
      // Сегмент для обновления при наличии предыдущего сегмента
      // всегда является индексом маршрутной точки минус один
      segmentIndex,
      // Маршрутная точка с которой должно начаться обновление
      // маршрута в данном случае индекс маршрутной точки
      // минус один, так как координаты для пересчета маршрута
      // начинаются с предыдущего сегмента.
      index - 1,
      routable,
      coordinates as [number, number][],
    );
  }

  async updateSegment(
    segmentUpdateIndex: number,
    pointUpdateIndex: number,
    routable: boolean,
    coordinates: [number, number][],
  ) {
    if (coordinates.length < 1 || coordinates.length > 3) {
      throw new Error('invalid coordinates length');
    }

    if (routable) {
      await this.routeCallback(coordinates).then(route => {
        this.setLastResponse(routeUpdate(
          this.state.route,
          route,
          segmentUpdateIndex,
          pointUpdateIndex,
        ));
        this.parseResponse(this.state.route);
      });
    } else {
      if (coordinates.length === 3) {
        // Данный кейс является частным случаем и создан исключительно
        // ради оптимизации перестроения маршрутов, когда оба маршрута
        // являются с одинаковым значением routable и их можно обновить
        // за один запрос
        this.setLastResponse(routeUpdate(
          this.state.route,
          this.createTemporaryRoute(coordinates),
          segmentUpdateIndex,
          pointUpdateIndex,
        ));
      } else {
        this.setLastResponse(routeUpdate(
          this.state.route,
          createStaticRoute(coordinates),
          segmentUpdateIndex,
          pointUpdateIndex,
        ));
      }
      this.parseResponse(this.state.route);
    }
  }

  async handleOnMouseUp(lngLat: [number, number], index: number) {
    this.currentPointProps = undefined;

    // Проверяем наличие предыдущего сегмента относительно индекса
    // маршрутной точки - 1
    const prevSegment = this.state.route.segments[index - 1];
    // Проверяем наличие следующего сегмента относительно индекса
    // маршрутной точки
    const nextSegment = this.state.route.segments[index];

    // Обратная совместимость с маршрутами созданными до
    // появления возможности перестраивать маршрут в обход
    // graphhopper
    const prevRoutable = typeof this.state.route.routable[index - 1] === 'undefined'
      ? true
      : this.state.route.routable[index - 1];
    const nextRoutable = typeof this.state.route.routable[index] === 'undefined'
      ? true
      : this.state.route.routable[index];

    if (prevSegment && nextSegment && prevRoutable === nextRoutable) {
      // Данный кейс является частным случаем и создан исключительно
      // ради оптимизации перестроения маршрутов, когда оба маршрута
      // являются с одинаковым значением routable и их можно обновить
      // за один запрос.

      const coordinates = [
        this.state.route.user_waypoints[index - 1],
        lngLat,
        this.state.route.user_waypoints[index + 1],
      ];

      await this.updateSegment(
        // Сегмент для перестроения маршрута начинается с
        // предыдущего сегмента
        index - 1,
        // Маршрутная точка для обновления является так
        // же предыдущей точкой
        index - 1,
        prevRoutable,
        coordinates as [number, number][],
      );
      return;
    }

    if (prevSegment) {
      const coordinates = [
        this.state.route.user_waypoints[index - 1],
        lngLat,
      ];

      await this.updateSegment(
        // Сегмент для обновления при наличии предыдущего сегмента
        // всегда является индексом маршрутной точки минус один
        index - 1,
        // Маршрутная точка с которой должно начаться обновление
        // маршрута в данном случае индекс маршрутной точки
        // минус один, так как координаты для пересчета маршрута
        // начинаются с предыдущего сегмента.
        index - 1,
        prevRoutable,
        coordinates as [number, number][],
      );
    }

    if (nextSegment) {
      const segmentUpdateIndex = prevSegment
        // Если есть предыдущий сегмент, то сегмент для обновления
        // будет индексом маршрутной точки, за исключением
        ? index
        // данного случая, когда у нас есть следующий сегмент
        // и мы перетаскиваем первую точку на маршруте (точку старта)
        // в таком случае индекс сегмента может быть нулем.
        : Math.max(index - 1, 0);

      const coordinates = [
        lngLat,
        this.state.route.user_waypoints[index + 1],
      ];

      await this.updateSegment(
        segmentUpdateIndex,
        // При наличии следующего сегмента, мы обновляем следующий
        // сегмент, который идентичен индексу маршрутной точки
        index,
        nextRoutable,
        coordinates as [number, number][],
      );
    }
  }

  initialize(): void {
    loadImages(this.map);

    this.map.once("styledata", () => {
      this.createLayers();
      if (this.options.editable) {
        for (let i = 0; i < this.state.route.user_waypoints.length; i++) {
          this.createUserMarker(i, this.state.route.user_waypoints[i] as [number, number]);
        }
      } else {
        // show only first and last waypoint
        this.createUserMarker(0, this.state.route.user_waypoints[0] as [number, number]);
        this.createUserMarker(1, this.state.route.user_waypoints[this.state.route.user_waypoints.length - 1] as [number, number]);
      }

      if (this.state.route.waypoints.length > 0) {
        this.parseResponse(this.state.route);
        if (this.options.autoFitBounds) {
          this.fitBounds();
        }
      }
    });

    this.map.on('style.load', () => {
      loadImages(this.map);
      this.createLayers();
      if (this.state.route) {
        if (this.options.editable) {
          for (let i = 0; i < this.state.route.user_waypoints.length; i++) {
            this.createUserMarker(i, this.state.route.user_waypoints[i] as [number, number]);
          }
        } else {
          // show only first and last waypoint
          this.createUserMarker(0, this.state.route.user_waypoints[0] as [number, number]);
          this.createUserMarker(1, this.state.route.user_waypoints[this.state.route.user_waypoints.length - 1] as [number, number]);
        }
        this.parseResponse(this.state.route);
      }
    });
  }

  async addWaypoint(lnglat: [number, number], route = true) {
    const points = this.getSourceValues<Point>(this.idPoint);
    // Создаем маркер на карте в слое данных maplibre
    this.createUserMarker(
      // Маркер создается по конкретному индексу, в данном случае это
      // свободный слот последнего добавленного элемента
      points.features.length,
      // Указываем координаты создания маркера
      lnglat
    );

    // Если общее кол-во координат на текущий момент
    // меньше 2, то пропускаем построение маршрута
    if (points.features.length < 2) {
      return;
    }

    // Находим предпоследний элемент в слое данных maplibre и используем его как
    // предыдущую точку откуда строить маршрут. Предыдущий элемент, так как
    // только что добавленная координата уже встала в последний слот.
    const [lng, lat] = points.features[points.features.length - 2].geometry.coordinates;
    const prevWaypoint: [number, number] = [lng, lat];

    // Если выбрана точка с прокладыванием маршрута через graphhopper
    // Обновление маршрута
    const newRoute = route
      // Попытка построения маршрута
      ? routeAddWaypoint(this.state.route, await this.routeCallback([prevWaypoint, lnglat]))
      : routeAddStaticWaypoint(this.state.route, prevWaypoint, lnglat);

    this.setLastResponse(newRoute);

    // Добавление обновленного маршрута на карту
    this.parseResponse(this.state.route);
  }

  async routeCallback(waypoints: [number, number][]) {
    // Если функция построения маршрута не передана - падаем
    if (!this.options.route) {
      throw new Error('no route func available');
    }

    const resp = await this.options.route(waypoints, this.options.profile);
    if (!resp) {
      throw new Error('no response received');
    }

    return resp;
  }

  renderGhostLine(
    waypoints: [number, number][],
    user_waypoints: [number, number][],
    segments: string[],
  ): void {
    // renderGhostLine ghost lines
    // Если на карте осталась только одна единственная
    // маршрутная точка, то следует удалить все ghost линии
    // иначе они будут вести от пользовательской
    // маршрутной точки, до точки на ближайшей дороге и
    // выглядеть это будет, будто точка ведет в пустоту.
    // Это частный случай когда первая маршрутная точка
    // поставлена внутри дома или в поле, а первая найденная
    // точка на дороге находится рядом на дороге.
    const data: FeatureCollection = user_waypoints.length === 1
      ? featureCollection([])
      : createGhostFeatureCollection(waypoints, user_waypoints, segments);

    this.setSourceValues(this.idGhost, data);
  }

  parseResponse(route: TrackRoute) {
    if (route.segments.length > 0 && typeof route.segments[0] !== "string") {
      console.error(route);
      throw new Error("broken route");
    }
    // main data lines
    this.setSourceValues(this.idData, createFeatureCollectionFromCoords(route));

    // render ghost lines
    this.renderGhostLine(
      route.waypoints as [number, number][],
      route.user_waypoints as [number, number][],
      route.segments,
    );

    // Если нет первоначальных данных, значит в момент их обработки событие
    // на routeUpdated бросать не нужно
    if (this.options.onRouteUpdated) {
      this.options.onRouteUpdated(route);
    }
  }

  undoState() {
    this.dispatch({
      type: RouteActionType.UNDO,
    });
    this.onHistoryChange();
  }

  redoState() {
    this.dispatch({
      type: RouteActionType.REDO,
    });
    this.onHistoryChange();
  }

  protected updateIndexesUserMarker(points: FeatureCollection<Point>): FeatureCollection<Point> {
    // Renew points in store
    // Filter and completely remove deleted points and then
    // Update all indexes in points
    return {
      ...points,
      features: points.features.map((feature, index) => ({
        ...feature,
        id: index,
        properties: { index },
      })),
    };
  }

  onHistoryChange() {
    this.points = featureCollection<Point>(this.state.points.map((c, index) => point(c, { index })));
    this.setSourceValues(this.idPoint, featureCollection<Point>(this.state.points.map((c, index) => point(c, { index }))));
    this.setSourceValues(this.idGhost, featureCollection<LineString>(this.state.ghostLines.map(c => lineString(c))));
    this.parseResponse(this.state.route);
  }

  protected getPointCoordinates(): Position[] {
    return this.getSourceValues<Point>(this.idPoint).features.map(f => f.geometry.coordinates);
  }

  protected getGhostLinesCoordinates(): Position[][] {
    return this.getSourceValues<LineString>(this.idGhost).features.map(f => f.geometry.coordinates);
  }

  protected setLastResponse(route: TrackRoute) {
    this.dispatch({
      type: RouteActionType.ADD,
      route: route,
      points: this.getPointCoordinates(),
      ghostLines: this.getGhostLinesCoordinates(),
    });
  }

  reset(): void {
    this.setSourceValues(this.idPoint, featureCollection<Point>([]));
    this.setSourceValues(this.idData, featureCollection<LineString>([]));
    this.setSourceValues(this.idGhost, featureCollection<LineString>([]));
  }

  protected setSourceValues(id: string, data: GeoJSON) {
    this.getSource(id).setData(data);
  }

  protected createUserMarker(index: number, lngLat: [number, number]) {
    const points = this.getSourceValues<Point>(this.idPoint);
    points.features.splice(index, 0, point<PointProps>(
      lngLat,
      { index },
      { id: index },
    ));
    const result = this.updateIndexesUserMarker(points);
    this.setSourceValues(
      this.idPoint,
      result,
    );
  }

  protected getSource(name: string): GeoJSONSource {
    const routeSource: Source | undefined = this.map.getSource(name);
    if (!routeSource) {
      throw new Error(`source not found: ${name}`);
    }

    return routeSource as GeoJSONSource;
  }

  protected getSourceValues<T extends Geometry>(name: string): FeatureCollection<T> {
    return this.getSource(name)._data as FeatureCollection<T>;
  }

  protected fitBounds(options?: Partial<FitBoundsOptions>): void {
    const segments = this.state.route?.segments;
    if (!segments) {
      return;
    }
    const decodedSegments = decodeMultiline(segments);
    const fitCoordinates: [number, number][] = [];
    for (let z = 0; z < decodedSegments.length; z++) {
      fitCoordinates.push(...decodedSegments[z]);
    }

    const bounds = new maplibre.LngLatBounds(
      fitCoordinates[0],
      fitCoordinates[0],
    );
    for (let i = 0; i < fitCoordinates.length; i++) {
      bounds.extend(fitCoordinates[i]);
    }

    this.map.fitBounds(bounds, {
      ...options,
      padding: 80,
      duration: 0,
    });
  }
}
