import { Settings } from "luxon";
import { HistoryInterface } from "@biketravel/solid-history";
import { Router } from "@biketravel/solid-router";
import { router } from "./router";
import { renderer } from "./renderer";
import { MetaProvider } from "@solidjs/meta";
import { TagDescription } from "./meta";
import { bridge, StoreRegistry } from "@biketravel/state";
import { Attention, Spinner } from "@biketravel/solid-ui";
import { Toaster } from 'solid-toast';
import { ErrorBoundary, Suspense } from "solid-js";

Settings.defaultLocale = "ru";

export const createApp = (
    history: HistoryInterface,
    tags?: TagDescription[],
    state?: StoreRegistry,
) => {
    if (state) {
        bridge.importState(state);
    }

    return (
        <MetaProvider tags={tags}>
            <ErrorBoundary fallback={err => {
                console.error(err);
                return (
                    <Attention modifiers='warning'>
                        {err.toString()}
                    </Attention>
                );
            }}>
                <Suspense fallback={<Spinner show={true} />}>
                    <Router
                        history={history}
                        router={router}
                        renderer={renderer}
                    />
                </Suspense>
            </ErrorBoundary>
            <Toaster
                position="bottom-right"
                toastOptions={{
                    style: {
                        background: '#1f2937',
                        color: '#f3f4f6'
                    },
                }}
            />
        </MetaProvider>
    )
}