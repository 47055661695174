import type { Marker, Place, Surface, TrackRoute } from "@biketravel/sdk";
import length from "@turf/length";
import { lineString } from "@turf/helpers";
import { surfaceDict } from "../layers/routeUtils";
import { decodeMultiline } from "../encoder";

const validRound = (v: number) => Math.round((v + Number.EPSILON) * 100) / 100;

const calculateSurfaces = (surfaces: Surface[][]): [string, number][] => {
  const data: { [key: string]: number } = {};

  // обратная совместимость с роутами без
  // определенного типа покрытия
  for (let i = 0; i < surfaces.length; i++) {
    const surfaceSegment = surfaces[i];
    for (let z = 0; z < surfaceSegment.length; z++) {
      const surface = surfaceSegment[z];
      const name = surfaceDict[surface.surface] ?? surface.surface;
      if (typeof data[name] === 'undefined') {
        data[name] = 0;
      }
      data[name] += surface.value;
    }
  }

  return Object
    .keys(data)
    .map((key): [string, number] => [key, data[key]])
    .sort((first, second) => second[1] - first[1]);
};

export const createTooltipContent = (route: TrackRoute, index: number): string => {
  const decodedSegments = decodeMultiline(route.segments).flat();

  const ascend = route.ascend.reduce((partialSum, a) => partialSum + a, 0);
  const descend = route.descend.reduce((partialSum, a) => partialSum + a, 0);

  const totalDistance = length(lineString(decodedSegments), { units: 'kilometers' });

  const items = calculateSurfaces(route.surface);
  const surfacesStr = [];
  for (let i = 0; i < items.length; i++) {
    const [name, value] = items[i];
    surfacesStr.push(`
            <div class="b-map-tooltip__line">
                <div class="b-map-tooltip__title">${name}:</div>
                <div class="b-map-tooltip__value">${validRound(value)} км</div>
            </div>
        `);
  }

  const surfaceContent = surfacesStr.length > 0 ? `
        <div class="b-map-tooltip__divider"></div>
        ${surfacesStr.join('')}
    ` : '';

  const distanceContent = (`
        <div class="b-map-tooltip__line">
            <div class="b-map-tooltip__title">Дистанция:</div>
            <div class="b-map-tooltip__value">${totalDistance.toFixed(2)} км</div>
        </div>
    `);

  const ascendDescendContent = (typeof ascend === 'undefined' && typeof descend === 'undefined') ? '' : (`
        <div class="b-map-tooltip__line">
            <div class="b-map-tooltip__title">Подъем / Спуск:</div>
            <div class="b-map-tooltip__value">${ascend ?? '?'}м / ${descend ?? '?'}м</div>
        </div>
    `);

  return (`
        ${distanceContent}
        ${ascendDescendContent}
        ${surfaceContent}
    `);
};

export const popupFromPlace = (place: Place): string => {
  const description = place.description.length > 255
    ? `${place.description.substring(0, 255)}...`
    : place.description;

  return `<div class="b-place-popup">
    <div class="b-place-popup__name">${place.name}</div>
    <div class="b-place-popup__description">${description}</div>
    <a target="_blank" href="/place/${place.id}" class="b-place-popup__link">Подробнее...</a>
</div>`;
};

export const popupFromMarker = (marker: Marker): string => `
    <div class="b-marker-popup">
        <div class="b-marker-popup__name">
            ${marker.name}
        </div>
        <div class="b-marker-popup__description">
            ${marker.description}
        </div>
    </div>
`;
