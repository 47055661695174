import { Dialog, Menu, MenuDivider, MenuItem } from '@biketravel/solid-ui';
import type { GeoJSONFeature } from "maplibre-gl";
import { Component, createEffect, createSignal, onMount, Show } from "solid-js";
import { toast } from "solid-toast";
import { MapPopupMenu, MarkerCreateForm, MarkerUpdateForm, useMapEffect } from "../index";
import { clusterCountLayer, clusterLayer, DataLayer, popupFromMarker } from "@biketravel/map";
import { markerListStore, markerRemoveStore } from "@biketravel/state/marker";
import { jwtStore } from "@biketravel/state";
import type { Marker, Track } from '@biketravel/sdk';

type MarkerMapProps = {
  track: Track;
};

type ClickTargetType = 'places-symbol' | 'marker-places-symbol' | 'cluster' | 'cluster-count'

type ContextMenuClickType = {
  feature?: GeoJSONFeature
  id?: number
  type?: ClickTargetType
  x: number
  y: number
  lng: number
  lat: number
}

export const MarkerMap: Component<MarkerMapProps> = (props) => {
  const [init, setInit] = createSignal(false);

  const [placeDataLayer, setPlaceDataLayer] = createSignal<DataLayer<Marker> | null>(null);
  const [location, setLocation] = createSignal<[number, number] | null>(null);
  const [updateMarker, setUpdateMarker] = createSignal<Marker | null>(null);
  const [contextMenuPosition, setContextMenuPosition] = createSignal<ContextMenuClickType | null>(null);

  const loadMarkers = async () => {
    await markerListStore.actions.fetch(props.track.id);
  }

  onMount(async () => {
    await loadMarkers();
  });

  const onContextMenu = (e: any, feature: any) => {
    const { point, lngLat } = e;

    if (feature) {
      if (feature.layer.id === clusterLayer.id || feature.layer.id === clusterCountLayer.id) {
        setContextMenuPosition(null);
      } else {
        setContextMenuPosition({
          type: feature.layer.id as ClickTargetType,
          feature,
          id: Number(feature.id),
          lng: lngLat.lng,
          lat: lngLat.lat,
          x: point.x,
          y: point.y,
        });
      }
    } else {
      setContextMenuPosition({
        lng: lngLat.lng,
        lat: lngLat.lat,
        x: point.x,
        y: point.y,
      });
    }
  };

  useMapEffect((map) => {
    if (init()) {
      return;
    }

    const placeLayer = new DataLayer<Marker>(map, 'marker', {
      icon: 'red-marker',
      onContextMenu: place => setUpdateMarker(place),
      popupTemplate: popupFromMarker,
    });
    setPlaceDataLayer(placeLayer);

    map.on('click', () => {
      setContextMenuPosition(null);
    });
    map.on('movestart', () => {
      setContextMenuPosition(null);
    });
    map.on('contextmenu', (e) => {
      const queryFeatures = map.queryRenderedFeatures(e.point);
      const realFeatures = queryFeatures.filter(feature => feature.source != 'composite');
      const feature = realFeatures.length > 0 ? realFeatures[0] : null;
      onContextMenu(e, feature);
    });

    const data = markerListStore.data();
    if (data.edge) {
      placeDataLayer()?.setItems(data.edge);
    }

    setInit(true);
  });

  createEffect(() => {
    const data = markerListStore.data();
    if (data.edge) {
      placeDataLayer()?.setItems(data.edge);
    }
  });

  const onMarkerUpdateCallback = async () => {
    const ctx = contextMenuPosition();
    if (ctx) {
      setUpdateMarker(ctx.feature?.properties as Marker);
      setContextMenuPosition(null);
    }
  };

  const onMarkerRemoveCallback = async () => {
    const ctx = contextMenuPosition();
    const places = placeDataLayer();
    if (ctx && ctx.id && places) {
      if (!window.confirm('Удалить метку?')) {
        return
      }

      const marker = ctx.feature?.properties as Marker;
      await markerRemoveStore.actions.remove(marker.id);
      places.removeItem(marker);
      setContextMenuPosition(null);
      toast.success('Удалено');
    }
  };

  const onCreateSuccess = async () => {
    await loadMarkers();
    setLocation(null);
    toast.success('Метка добавлена');
  };

  const onUpdateSuccess = async () => {
    await loadMarkers();
    setUpdateMarker(null);
    toast.success('Выполнено');
  };

  return (
    <>
      <Dialog title='Редактирование метки' isOpen={!!updateMarker()} onClose={() => setUpdateMarker(null)}>
        <Show when={updateMarker()}>
          <MarkerUpdateForm
            marker={updateMarker()!}
            onSuccess={() => onUpdateSuccess()}
          />
        </Show>
      </Dialog>
      <Dialog
        title='Добавить метку'
        isOpen={!!location()}
        onClose={() => setLocation(null)}>
        <MarkerCreateForm
          lng={location()![0]}
          lat={location()![1]}
          trackId={props.track.id}
          onSuccess={() => onCreateSuccess()}
        />
      </Dialog>
      <Show when={contextMenuPosition() && jwtStore.jwt()}>
        <MapPopupMenu
          x={contextMenuPosition()!.x ?? 0}
          y={contextMenuPosition()!.y ?? 0}>
          <Menu>
            <Show when={contextMenuPosition()?.type === 'marker-places-symbol'}>
              <MenuItem
                onClick={() => onMarkerUpdateCallback()}>
                Изменить
              </MenuItem>
              <MenuDivider/>
              <MenuItem onClick={() => onMarkerRemoveCallback()}>Удалить</MenuItem>
            </Show>
            <Show when={contextMenuPosition()?.type !== 'marker-places-symbol'}>
              <MenuItem
                onClick={() => {
                  const ctx = contextMenuPosition();
                  if (ctx) {
                    setLocation([ctx.lng, ctx.lat]);
                    setContextMenuPosition(null);
                  }
                }}
              >
                Добавить метку
              </MenuItem>
            </Show>
          </Menu>
        </MapPopupMenu>
      </Show>
    </>
  );
};
