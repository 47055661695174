import { LoadingType } from "@biketravel/state";
import { createSignal } from "solid-js";
import { AppStore } from "..";

export type UserAvatarStore = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<UserAvatarStore["loading"]>(false);

const upload = async (id: number, avatar: File) => {
  // @todo
}

type UserAvatarActions = {
  upload: typeof upload,
}

const userAvatarStore: AppStore<UserAvatarStore, UserAvatarActions> = {
  loading,
  actions: {
    upload,
  }
};

export const useUserAvatarStore = () => userAvatarStore;