import { Component, Show } from "solid-js";
import { OnClientVisible } from '@biketravel/solid-ui';
import { CommentCreateForm } from "./CommentCreateForm";
import { CommentList } from "./CommentList";
import { commentListStore, jwtStore } from "@biketravel/state";
import { toast } from "solid-toast";

type CommentsProps = {
  ownerId: number;
  ownerType: string;
}

export const Comments: Component<CommentsProps> = (props) => {
  const onSuccess = async () => {
    toast.success('Комментарий создан');
    await commentListStore.actions.fetch(props.ownerId, props.ownerType);
  }

  return (
    <OnClientVisible>
      <CommentList
        ownerType={props.ownerType}
        ownerId={props.ownerId}
      />
      <Show when={jwtStore.jwt()}>
        <CommentCreateForm
          ownerType={props.ownerType}
          ownerId={props.ownerId}
          onSuccess={onSuccess}
        />
      </Show>
    </OnClientVisible>
  );
}