import type { Map } from 'maplibre-gl';
import { points } from '@turf/helpers';
import center from '@turf/center';
import { bem } from "classnames-bem";
import { routeStore } from "@biketravel/state";
import type { TrackRoute } from "@biketravel/sdk";
import { BemModifiers } from "@biketravel/solid-ui";
import { Component, onMount } from "solid-js";
import { createMap } from "../map";
import { createStateFromRoute, PulsingLayer, RouteLayer } from "@biketravel/map";

type EventViewMapProps = BemModifiers & {
  startLng: number
  startLat: number
  route: TrackRoute
}

export const EventViewMap: Component<EventViewMapProps> = (props) => {
  let mapRef: Map | undefined;
  let mapContainer: HTMLDivElement | undefined;

  onMount(() => {
    if (mapRef) {
      // initialize map only once
      return;
    }

    if (!mapContainer) {
      return;
    }

    const calculatedCenter = center(points(props.route.user_waypoints)).geometry.coordinates;
    const [lng, lat] = calculatedCenter;

    const map = createMap(mapContainer, {
      center: [lng, lat],
      scrollZoom: false,
    });

    new RouteLayer(map, {
      autoFitBounds: true,
      editable: false,
      route: routeStore.actions.route,
    }, createStateFromRoute(props.route, false));

    const pulsingLayer = new PulsingLayer(map);

    map.once('load', () => {
      pulsingLayer.setPoint([props.startLng, props.startLat]);
    });

    mapRef = map;
  });

  return (
    <div ref={mapContainer} class={bem("b-map", props.modifiers)}/>
  );
};
