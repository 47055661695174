import type { MarkerCreate } from '@biketravel/sdk';
import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";

export type MarkerCreateState = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<MarkerCreateState["loading"]>(false);

const create = async (input: MarkerCreate) => withLoading(setLoading, async () => {
  const {
    data
  } = await sdk.marker.markerCreate(input);

  return data;
})

type MarkerCreateActions = {
  create: typeof create,
}

export const markerCreateStore = {
  loading,
  actions: {
    create,
  }
} satisfies AppStore<MarkerCreateState, MarkerCreateActions>;