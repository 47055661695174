import { BemModifiers } from "./types";
import type { JSX } from 'solid-js';
import { ParentComponent, Show, splitProps } from "solid-js";
import { bem } from "classnames-bem";

export type ButtonUiType = {
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
}

type ButtonType = BemModifiers & ButtonUiType & {
  type?: 'submit' | 'reset' | 'button';
} & JSX.HTMLAttributes<HTMLButtonElement>;

export const Button: ParentComponent<ButtonType> = (props) => {
  const [
    local,
    others,
  ] = splitProps(props, ["modifiers", "leftIcon", "rightIcon"]);

  return (
    <button
      class={bem('b-button', local.modifiers, { left_icon: !!local.leftIcon, right_icon: !!local.rightIcon })}
      {...others}>
      <ButtonInner leftIcon={local.leftIcon} rightIcon={local.rightIcon}>
        {props.children}
      </ButtonInner>
    </button>
  );
}

export const ButtonInner: ParentComponent<ButtonUiType> = (props) => (
  <>
    <Show when={props.leftIcon}>
            <span class={bem("b-button__icon", { left: !!props.leftIcon, right: !!props.rightIcon })}>
                {props.leftIcon}
            </span>
    </Show>
    {props.children}
    <Show when={props.rightIcon}>
            <span class={bem("b-button__icon", { left: !!props.leftIcon, right: !!props.rightIcon })}>
                {props.rightIcon}
            </span>
    </Show>
  </>
);