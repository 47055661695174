import { calculateSwipe } from "./swipeHook";
import { bem } from "classnames-bem";
import { Accessor, Component, createEffect, createSignal, Show } from "solid-js";
import { Overlay } from "./Overlay";
import { SpinnerGlobal } from "./Spinner";
import { FiChevronLeft, FiChevronRight, FiX } from "solid-icons/fi";
import { Link } from "@solidjs/meta";

export type LightboxProps = {
    images: string[];
    index: Accessor<number>;
    onClose(): void;
}

export const Lightbox: Component<LightboxProps> = (props) => {
    const [index, setIndex] = createSignal(-1);
    const [isLoading, setIsLoading] = createSignal(true);
    const [startTime, setStartTime] = createSignal<null | number>(null);
    const [clientX, setClientX] = createSignal<null | number>(null);
    const [clientY, setClientY] = createSignal<null | number>(null);
    const [preloaded, setPreloaded] = createSignal<string[]>([]);

    createEffect(() => {
        setIndex(props.index());
    });

    const getPrevSrc = () => {
        return props.images[(index() + props.images.length - 1) % props.images.length];
    }

    const getNextSrc = () => {
        return props.images[(index() + 1) % props.images.length];
    }

    const prevCallback = () => {
        const prevSrc = getPrevSrc();
        if (prevSrc) {
            setIndex((index() + props.images.length - 1) % props.images.length);
        }
    };

    const nextCallback = () => {
        const nextSrc = getNextSrc();
        if (nextSrc) {
            setIndex((index() + 1) % props.images.length);
        }
    };

    const onPrevCallback = (e: MouseEvent) => {
        e.preventDefault();
        prevCallback();
    };

    const onNextCallback = (e: MouseEvent) => {
        e.preventDefault();
        nextCallback();
    };

    const onKeyDownCallback = (e: KeyboardEvent) => {
        if (e.code === 'ArrowRight' || e.code === 'ArrowUp' || e.code === 'Space') {
            nextCallback();
        }

        if (e.code === 'ArrowLeft' || e.code === 'ArrowDown') {
            prevCallback();
        }
    };

    const onClose = () => {
        setIndex(-1);
        props.onClose();
    };

    const onLoadCallback = (src: string) => {
        setIsLoading(false);
        setPreloaded([
            ...preloaded(),
            src
        ]);
    };

    const onTouchStartCallback = (evt: TouchEvent) => {
        const firstTouch = evt.touches[0];
        setClientX(firstTouch.clientX);
        setClientY(firstTouch.clientY);
        setStartTime(Date.now());
    };

    const onTouchEndCallback = (e: TouchEvent) => {
        const x = clientX();
        const y = clientY();
        const time = startTime();

        if (!x || !y || !time) {
            return;
        }

        const {
            isLeft,
            isRight,
        } = calculateSwipe({
            touchStartX: x,
            touchStartY: y,
            touchStartTime: time,
            touchEndX: e.changedTouches[0].clientX,
            touchEndY: e.changedTouches[0].clientY,
            threshold: 100,
        });

        if (isLeft) {
            prevCallback();
        }

        if (isRight) {
            nextCallback();
        }
    };

    const allowPreload = (src: string) => {
        return index() > -1 && preloaded().findIndex(pre => pre === src) === -1;
    }

    return (
        <Overlay
            class='b-lightbox__overlay'
            isOpen={index() > -1}
            onClose={onClose}
        >
            <Show when={allowPreload(getNextSrc())}>
                <Link
                    rel="preload"
                    as="image"
                    href={getNextSrc()}
                />
            </Show>
            <Show when={allowPreload(getPrevSrc())}>
                <Link
                    rel="preload"
                    as="image"
                    href={getPrevSrc()}
                />
            </Show>
            <div
                tabindex="0"
                onKeyDown={onKeyDownCallback}
                class="b-lightbox__container"
                onTouchStart={onTouchStartCallback}
                onTouchEnd={onTouchEndCallback}
            >
                <a href="#" onClick={onClose} class="b-lightbox__close">
                    <FiX/>
                </a>
                <div class="b-lightbox__content">
                    <div
                        class='b-lightbox__wrapper'>
                        <img
                            class={bem('b-lightbox__image')}
                            src={props.images[index()]}
                            onLoad={() => onLoadCallback(props.images[index()])}
                            alt=''
                        />
                    </div>
                    <SpinnerGlobal show={isLoading()}/>
                    <a href="#"
                       onClick={onPrevCallback}
                       class={bem('b-lightbox__click', 'prev')}
                    />
                    <a href="#"
                       onClick={onNextCallback}
                       class={bem('b-lightbox__click', 'next')}
                    />
                    <Show when={getPrevSrc()}>
                        <a
                            href="#"
                            onClick={onPrevCallback}
                            class={bem('b-lightbox__link', 'prev')}>
                            <FiChevronLeft/>
                        </a>
                    </Show>
                    <Show when={getNextSrc()}>
                        <a
                            href="#"
                            onClick={onNextCallback}
                            class={bem('b-lightbox__link', 'next')}>
                            <FiChevronRight/>
                        </a>
                    </Show>
                </div>
            </div>
        </Overlay>
    );
};
