import { Component } from "solid-js";
import { Page } from "./Page";
import { Separator } from "./Separator";
import { WithBuildUrl, WithOnChange } from "./types";
import cls from "./Pages.module.scss";

type PagesProps = WithOnChange & WithBuildUrl & {
  page: number;
  totalPages: number;
}

export const Pages: Component<PagesProps> = (props) => (
  <nav class={cls.pages}>
    <Page
      buildUrl={props.buildUrl}
      disabled={props.page === 1}
      current={props.page}
      page={props.page - 1}
      onChange={props.onChange}>
      &larr;
    </Page>

    <Page
      buildUrl={props.buildUrl}
      current={props.page}
      page={1}
      onChange={props.onChange}
    >
      1
    </Page>

    {props.page > 3 && <Separator/>}

    {props.page === props.totalPages && props.totalPages > 3 && (
      <Page
        buildUrl={props.buildUrl}
        current={props.page}
        page={props.page - 2}
        onChange={props.onChange}
      >
        {props.page - 2}
      </Page>
    )}

    {props.page > 2 && (
      <Page
        buildUrl={props.buildUrl}
        current={props.page}
        page={props.page - 1}
        onChange={props.onChange}
      >
        {props.page - 1}
      </Page>
    )}

    {props.page !== 1 && props.page !== props.totalPages && (
      <Page
        buildUrl={props.buildUrl}
        current={props.page}
        page={props.page}
        onChange={props.onChange}
      >
        {props.page}
      </Page>
    )}

    {props.page < props.totalPages - 1 && (
      <Page
        buildUrl={props.buildUrl}
        current={props.page}
        page={props.page + 1}
        onChange={props.onChange}
      >
        {props.page + 1}
      </Page>
    )}

    {props.page === 1 && props.totalPages > 3 && (
      <Page
        buildUrl={props.buildUrl}
        current={props.page}
        page={props.page + 2}
        onChange={props.onChange}
      >
        {props.page + 2}
      </Page>
    )}

    {props.page < props.totalPages - 2 && <Separator/>}

    <Page
      buildUrl={props.buildUrl}
      current={props.page}
      page={props.totalPages}
      onChange={props.onChange}
    >
      {props.totalPages}
    </Page>

    <Page
      buildUrl={props.buildUrl}
      disabled={props.page === props.totalPages}
      current={props.page}
      page={props.page + 1}
      onChange={props.onChange}
    >
      &rarr;
    </Page>
  </nav>
);