import { batch, createSignal } from "solid-js";
import { AppStore, ExportableStore, filterValues, LoadingType, sdk, TrackListFilter } from "..";
import type { TrackDraftListFilterParameter, TrackList } from "@biketravel/sdk";
import { shallowEqual } from "shallow-equal-object";
import { withLoading } from "../decorator";

export type TrackDraftListState = {
  data: TrackList;
  filter: TrackDraftListFilter | undefined;
}

export type TrackDraftListClientState = TrackDraftListState & LoadingType;

const initialState: TrackDraftListClientState = {
  data: {
    edge: [],
    cursor: undefined
  },
  filter: undefined,
  loading: false,
}

export type TrackDraftListFilter = TrackDraftListFilterParameter;

const [
  trackData,
  setData
] = createSignal<TrackDraftListClientState['data']>(initialState.data);

const [
  filter,
  setFilter
] = createSignal<TrackDraftListClientState['filter']>(initialState.filter);

const [
  loading,
  setLoading
] = createSignal<TrackDraftListClientState["loading"]>(initialState.loading);

type SomeFields = Pick<TrackListFilter, "name" | "distance_min" | "distance_max" | "collection">

export type TrackFilterDraftFormType = {
  [K in keyof SomeFields]: string;
};

const filterChanged = (values: TrackDraftListFilter) => !shallowEqual(values, filter());
const filterConvert = (values: TrackFilterDraftFormType) => filterValues(values);

const fetch = async (
  filter?: TrackDraftListFilter,
  cursor?: number,
  merge: boolean = false,
  limit = 10,
) => withLoading(setLoading, async () => {
  setFilter(filter || {});

  const {
    data
  } = await sdk.track.trackDraftList(cursor, limit, filter);

  batch(() => {
    if (merge) {
      if (data?.edge) {
        setData({
          edge: [
            ...(trackData().edge || []),
            ...data.edge,
          ],
          cursor: data.cursor,
        });
      }
    } else {
      setData(data);
    }
  })
});

const reset = () => {
  setLoading(initialState.loading);
  setFilter(initialState.filter);
  setData(initialState.data);
}

type TrackListActionsType = {
  fetch: typeof fetch;
  reset: typeof reset;
  filterChanged: typeof filterChanged;
  filterConvert: typeof filterConvert;
}

export const trackDraftListStore = {
  data: trackData,
  filter,
  loading,
  actions: {
    fetch,
    filterChanged,
    filterConvert,
    reset,
  },
  exportState() {
    return {
      data: trackData(),
      filter: filter(),
    }
  },
  importState(value) {
    setData(value.data);
    setFilter(value.filter);
  }
} satisfies AppStore<TrackDraftListClientState, TrackListActionsType> & ExportableStore<TrackDraftListState>;
