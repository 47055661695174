import { ButtonLink, Divider, H1 } from "@biketravel/solid-ui";
import { Title } from "@solidjs/meta";
import { CircleIcon, Text } from "@biketravel/component";
import { FiArrowLeft, FiCheck } from "solid-icons/fi";

export const RegistrationSuccessPage = () => (
    <>
        <Title>Завершение регистрации</Title>
        <CircleIcon modifiers='green' icon={<FiCheck/>}/>
        <H1 modifiers='center'>Завершение регистрации</H1>
        <Text>
            <p>
                На указанный вами адрес электронной почты отправлена ссылка для
                подтверждения учетной записи.
            </p>
            <p><strong>Пожалуйста перейдите по ссылке из письма.</strong></p>
        </Text>
        <Divider/>
        <ButtonLink to='auth_login' leftIcon={<FiArrowLeft/>} modifiers='block'>
            Вернуться к форме авторизации
        </ButtonLink>
    </>
);