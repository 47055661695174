import { Title } from "@solidjs/meta";
import {
    Attention,
    BreadcrumbItem,
    BreadcrumbSymbol,
    ButtonLink,
    Divider,
    H1,
    H3,
    OnClientVisible,
    Segment,
    SegmentContent,
    SegmentFooter,
    Typeset
} from "@biketravel/solid-ui";
import { Component, createSignal, Show } from "solid-js";
import { Track } from "@biketravel/sdk";
import { jwtStore } from "@biketravel/state";
import {
    AppBreadcrumbs,
    Comments,
    EventTrackListComponent,
    trackGpx,
    TrackMenu,
    TrackPhotoListComponent,
    TrackSummary,
    TrackTags,
    TrackViewMap
} from "@biketravel/component";

import cls from './TrackViewPageContainer.module.scss';
import { trackName } from "@biketravel/component/Component/track/utils";

type TrackViewPageContainerProps = {
    track: Track;
}

export const TrackViewPageContainer: Component<TrackViewPageContainerProps> = (props) => {
    const [
        removed,
        setRemoved,
    ] = createSignal(false);

    const name = trackName(props.track);

    return (
        <>
            <Title>
                {name}
            </Title>
            <AppBreadcrumbs>
                <BreadcrumbItem to='track_list'>
                    Маршруты
                </BreadcrumbItem>
                <BreadcrumbSymbol/>
                <BreadcrumbItem>
                    {name}
                </BreadcrumbItem>
            </AppBreadcrumbs>
            <H1>{name}</H1>
            <div>
                <Show when={props.track.deleted_at || removed()}>
                    <Attention modifiers='warning'>
                        Маршрут удален
                    </Attention>
                </Show>
                <Show when={props.track.is_draft}>
                    <Attention modifiers='warning'>
                        Вы смотрите черновик маршрута. Данный маршрут не опубликован.
                    </Attention>
                </Show>
                <Segment>
                    <div class={cls.map}>
                        <TrackViewMap track={props.track}/>
                    </div>
                    <SegmentContent>
                        <TrackSummary track={props.track}/>
                        <Typeset content={props.track.description ?? 'Описание маршрута отсутствует'}/>
                        <TrackTags tags={props.track.tags}/>
                    </SegmentContent>
                    <SegmentFooter>
                        <div>
                            <ButtonLink
                                href={trackGpx(props.track.id)}
                                target='_blank'
                                class='b-button b-button--primary' rel="noreferrer">
                                Скачать GPX
                            </ButtonLink>
                        </div>

                        <TrackMenu
                            onRemove={() => setRemoved(true)}
                            track={props.track}
                        />
                    </SegmentFooter>
                </Segment>

                <Divider modifiers='dashed'/>

                <Segment>
                    <SegmentContent>
                        <OnClientVisible>
                            <H3>Фотографии</H3>
                            <TrackPhotoListComponent
                                track={props.track}
                            />
                        </OnClientVisible>
                    </SegmentContent>
                </Segment>

                <Divider modifiers='dashed'/>

                <OnClientVisible>
                    <Segment>
                        <SegmentContent>
                            <H3>Ближайшие поездки по маршруту</H3>
                            <EventTrackListComponent
                                track_id={props.track.id}
                            />
                        </SegmentContent>
                        <Show when={jwtStore.jwt()}>
                            <SegmentFooter>
                                <ButtonLink
                                    to='event_create'
                                    params={{ track_id: props.track.id }}
                                    modifiers={['primary', 'event-create']}>
                                    Создать поездку
                                </ButtonLink>
                            </SegmentFooter>
                        </Show>
                    </Segment>
                </OnClientVisible>

                <OnClientVisible>
                    <Divider modifiers='dashed'/>
                    <H3>Комментарии</H3>
                    <Segment>
                        <SegmentContent>
                            <Comments
                                ownerType='track'
                                ownerId={props.track.id}
                            />
                        </SegmentContent>
                    </Segment>
                </OnClientVisible>
            </div>
        </>
    );
}
