import { Route } from "@biketravel/solid-router";
import { BlogPostListPage, BlogPostPage } from "../page";
import { DefaultLayout } from "../Layout";
import { blogPostsStore, blogPostStore } from "@biketravel/state";
import { RouteProps } from "../renderer";
import { NotFoundError } from "./errors";

export const blogRoutes: Route<RouteProps>[] = [
  {
    path: '/blog',
    children: [
      {
        name: 'blog',
        path: '',
        component: BlogPostListPage,
        props: {
          layout: DefaultLayout,
          fetchServer: async ({ query }: { query: { page?: number } }) => {
            const page = parseInt(String(query.page), 10);

            await blogPostsStore.actions.fetch(page ? page : undefined);
          }
        }
      },
      {
        path: '/:blog_post_id(\\d+)',
        name: 'blog_post',
        component: BlogPostPage,
        props: {
          layout: DefaultLayout,
          fetchServer: async ({ params }: { params: { blog_post_id: number | string } }) => {
            const id = parseInt(String(params.blog_post_id), 10);

            await blogPostStore.actions.fetch(id);
            if (!blogPostStore.blogPost()) {
              throw new NotFoundError();
            }
          }
        },
      },
    ],
  },
];