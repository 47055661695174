/* tslint:disable */
/* eslint-disable */
/**
 * api
 * OpenAPI 3.0 specification
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, BaseAPI, COLLECTION_FORMATS, RequiredError } from './base';

/**
 *
 * @export
 * @interface BlogPost
 */
export interface BlogPost {
  /**
   *
   * @type {number}
   * @memberof BlogPost
   */
  'id': number;
  /**
   *
   * @type {string}
   * @memberof BlogPost
   */
  'name': string;
  /**
   *
   * @type {string}
   * @memberof BlogPost
   */
  'image': string;
  /**
   *
   * @type {string}
   * @memberof BlogPost
   */
  'content': string;
  /**
   *
   * @type {string}
   * @memberof BlogPost
   */
  'contentShort': string;
}

/**
 *
 * @export
 * @interface BlogPostList
 */
export interface BlogPostList {
  /**
   *
   * @type {Array<BlogPost>}
   * @memberof BlogPostList
   */
  'edge': Array<BlogPost>;
  /**
   *
   * @type {Pager}
   * @memberof BlogPostList
   */
  'pager': Pager;
}

/**
 *
 * @export
 * @interface BlogPostResp
 */
export interface BlogPostResp {
  /**
   *
   * @type {BlogPost}
   * @memberof BlogPostResp
   */
  'entity': BlogPost;
}

/**
 *
 * @export
 * @interface BlogUpload200Response
 */
export interface BlogUpload200Response {
  /**
   *
   * @type {string}
   * @memberof BlogUpload200Response
   */
  'url': string;
}

/**
 *
 * @export
 * @interface BuildRouteRequest
 */
export interface BuildRouteRequest {
  /**
   *
   * @type {string}
   * @memberof BuildRouteRequest
   */
  'profile': string;
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof BuildRouteRequest
   */
  'waypoints': Array<Array<number>>;
}

/**
 *
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  'password_current': string;
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  'password': string;
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  'password_confirm': string;
}

/**
 *
 * @export
 * @interface Comment
 */
export interface Comment {
  /**
   *
   * @type {number}
   * @memberof Comment
   */
  'id': number;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  'comment': string;
  /**
   *
   * @type {number}
   * @memberof Comment
   */
  'author_id': number;
  /**
   *
   * @type {User}
   * @memberof Comment
   */
  'author': User;
  /**
   *
   * @type {number}
   * @memberof Comment
   */
  'owner_id': number;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  'owner_type': string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  'created_at': string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  'updated_at': string;
}

/**
 *
 * @export
 * @interface CommentCreate
 */
export interface CommentCreate {
  /**
   *
   * @type {string}
   * @memberof CommentCreate
   */
  'comment': string;
  /**
   *
   * @type {string}
   * @memberof CommentCreate
   */
  'owner_type': string;
  /**
   *
   * @type {number}
   * @memberof CommentCreate
   */
  'owner_id': number;
}

/**
 *
 * @export
 * @interface CommentList
 */
export interface CommentList {
  /**
   *
   * @type {Array<Comment>}
   * @memberof CommentList
   */
  'edge': Array<Comment>;
  /**
   *
   * @type {Cursor}
   * @memberof CommentList
   */
  'cursor'?: Cursor;
}

/**
 *
 * @export
 * @interface CommentUpdate
 */
export interface CommentUpdate {
  /**
   *
   * @type {string}
   * @memberof CommentUpdate
   */
  'comment'?: string;
}

/**
 *
 * @export
 * @interface Cursor
 */
export interface Cursor {
  /**
   *
   * @type {number}
   * @memberof Cursor
   */
  'cursor': number;
  /**
   *
   * @type {boolean}
   * @memberof Cursor
   */
  'has_more': boolean;
}

/**
 *
 * @export
 * @interface Event
 */
export interface Event {
  /**
   *
   * @type {number}
   * @memberof Event
   */
  'id': number;
  /**
   *
   * @type {number}
   * @memberof Event
   */
  'distance': number;
  /**
   *
   * @type {number}
   * @memberof Event
   */
  'difficult': number;
  /**
   *
   * @type {number}
   * @memberof Event
   */
  'member_count': number;
  /**
   *
   * @type {number}
   * @memberof Event
   */
  'track_id': number;
  /**
   *
   * @type {Array<string>}
   * @memberof Event
   */
  'collection': Array<string>;
  /**
   *
   * @type {User}
   * @memberof Event
   */
  'author'?: User;
  /**
   *
   * @type {number}
   * @memberof Event
   */
  'author_id': number;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  'name': string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  'gpx': string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  'static_map': string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  'description': string | null;
  /**
   *
   * @type {number}
   * @memberof Event
   */
  'start_lng': number;
  /**
   *
   * @type {number}
   * @memberof Event
   */
  'start_lat': number;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  'start_at': string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  'created_at': string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  'updated_at': string;
  /**
   *
   * @type {TrackRoute}
   * @memberof Event
   */
  'route': TrackRoute;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  'deleted_at': string | null;
}

/**
 *
 * @export
 * @interface EventCalendar
 */
export interface EventCalendar {
  /**
   *
   * @type {Array<Event>}
   * @memberof EventCalendar
   */
  'edge': Array<Event>;
}

/**
 *
 * @export
 * @interface EventCreate
 */
export interface EventCreate {
  /**
   *
   * @type {number}
   * @memberof EventCreate
   */
  'track_id': number;
  /**
   *
   * @type {string}
   * @memberof EventCreate
   */
  'name': string;
  /**
   *
   * @type {string}
   * @memberof EventCreate
   */
  'description': string;
  /**
   *
   * @type {number}
   * @memberof EventCreate
   */
  'start_lng': number;
  /**
   *
   * @type {number}
   * @memberof EventCreate
   */
  'start_lat': number;
  /**
   *
   * @type {string}
   * @memberof EventCreate
   */
  'start_at': string;
}

/**
 *
 * @export
 * @interface EventIsMember
 */
export interface EventIsMember {
  /**
   *
   * @type {boolean}
   * @memberof EventIsMember
   */
  'is_member': boolean;
}

/**
 *
 * @export
 * @interface EventJoinRequest
 */
export interface EventJoinRequest {
  /**
   *
   * @type {number}
   * @memberof EventJoinRequest
   */
  'id': number;
  /**
   *
   * @type {boolean}
   * @memberof EventJoinRequest
   */
  'subscribe': boolean;
}

/**
 *
 * @export
 * @interface EventLeaveRequest
 */
export interface EventLeaveRequest {
  /**
   *
   * @type {number}
   * @memberof EventLeaveRequest
   */
  'id': number;
}

/**
 *
 * @export
 * @interface EventList
 */
export interface EventList {
  /**
   *
   * @type {Array<Event>}
   * @memberof EventList
   */
  'edge': Array<Event>;
  /**
   *
   * @type {Cursor}
   * @memberof EventList
   */
  'cursor'?: Cursor;
}

/**
 *
 * @export
 * @interface EventListFilterParameter
 */
export interface EventListFilterParameter {
  /**
   *
   * @type {number}
   * @memberof EventListFilterParameter
   */
  'track_id'?: number;
  /**
   *
   * @type {string}
   * @memberof EventListFilterParameter
   */
  'name'?: string;
  /**
   *
   * @type {number}
   * @memberof EventListFilterParameter
   */
  'distance_min'?: number;
  /**
   *
   * @type {number}
   * @memberof EventListFilterParameter
   */
  'distance_max'?: number;
  /**
   *
   * @type {number}
   * @memberof EventListFilterParameter
   */
  'members_count'?: number;
  /**
   *
   * @type {string}
   * @memberof EventListFilterParameter
   */
  'collection'?: string;
  /**
   *
   * @type {number}
   * @memberof EventListFilterParameter
   */
  'user_id'?: number;
  /**
   *
   * @type {boolean}
   * @memberof EventListFilterParameter
   */
  'expired'?: boolean;
}

/**
 *
 * @export
 * @interface EventMembers
 */
export interface EventMembers {
  /**
   *
   * @type {Array<EventMembersMembersInner>}
   * @memberof EventMembers
   */
  'members': Array<EventMembersMembersInner>;
}

/**
 *
 * @export
 * @interface EventMembersMembersInner
 */
export interface EventMembersMembersInner {
  /**
   *
   * @type {number}
   * @memberof EventMembersMembersInner
   */
  'id': number;
  /**
   *
   * @type {string}
   * @memberof EventMembersMembersInner
   */
  'name': string;
  /**
   *
   * @type {string}
   * @memberof EventMembersMembersInner
   */
  'avatar'?: string | null;
}

/**
 *
 * @export
 * @interface EventUpdate
 */
export interface EventUpdate {
  /**
   *
   * @type {number}
   * @memberof EventUpdate
   */
  'track_id'?: number;
  /**
   *
   * @type {string}
   * @memberof EventUpdate
   */
  'name'?: string;
  /**
   *
   * @type {string}
   * @memberof EventUpdate
   */
  'description'?: string;
  /**
   *
   * @type {number}
   * @memberof EventUpdate
   */
  'start_lng'?: number;
  /**
   *
   * @type {number}
   * @memberof EventUpdate
   */
  'start_lat'?: number;
  /**
   *
   * @type {string}
   * @memberof EventUpdate
   */
  'start_at'?: string;
}

/**
 *
 * @export
 * @interface HttpError
 */
export interface HttpError {
  /**
   *
   * @type {number}
   * @memberof HttpError
   */
  'code': number;
  /**
   *
   * @type {string}
   * @memberof HttpError
   */
  'message': string;
}

/**
 *
 * @export
 * @interface Jwt
 */
export interface Jwt {
  /**
   *
   * @type {number}
   * @memberof Jwt
   */
  'id': number;
  /**
   *
   * @type {string}
   * @memberof Jwt
   */
  'access_token': string;
  /**
   *
   * @type {string}
   * @memberof Jwt
   */
  'refresh_token': string;
  /**
   *
   * @type {Array<string>}
   * @memberof Jwt
   */
  'permissions': Array<string>;
}

/**
 *
 * @export
 * @interface Location
 */
export interface Location {
  /**
   *
   * @type {number}
   * @memberof Location
   */
  'id': number;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  'city': string;
  /**
   *
   * @type {string}
   * @memberof Location
   */
  'country': string;
  /**
   *
   * @type {number}
   * @memberof Location
   */
  'lat': number;
  /**
   *
   * @type {number}
   * @memberof Location
   */
  'lng': number;
  /**
   *
   * @type {boolean}
   * @memberof Location
   */
  'is_default': boolean;
}

/**
 *
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  'email': string;
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  'password': string;
}

/**
 *
 * @export
 * @interface Marker
 */
export interface Marker {
  /**
   *
   * @type {number}
   * @memberof Marker
   */
  'id': number;
  /**
   *
   * @type {string}
   * @memberof Marker
   */
  'name': string;
  /**
   *
   * @type {string}
   * @memberof Marker
   */
  'description': string;
  /**
   *
   * @type {number}
   * @memberof Marker
   */
  'lng': number;
  /**
   *
   * @type {number}
   * @memberof Marker
   */
  'lat': number;
  /**
   *
   * @type {number}
   * @memberof Marker
   */
  'track_id': number;
}

/**
 *
 * @export
 * @interface MarkerCreate
 */
export interface MarkerCreate {
  /**
   *
   * @type {string}
   * @memberof MarkerCreate
   */
  'name': string;
  /**
   *
   * @type {string}
   * @memberof MarkerCreate
   */
  'description': string;
  /**
   *
   * @type {number}
   * @memberof MarkerCreate
   */
  'lng': number;
  /**
   *
   * @type {number}
   * @memberof MarkerCreate
   */
  'lat': number;
  /**
   *
   * @type {number}
   * @memberof MarkerCreate
   */
  'track_id': number;
}

/**
 *
 * @export
 * @interface MarkerList
 */
export interface MarkerList {
  /**
   *
   * @type {Array<Marker>}
   * @memberof MarkerList
   */
  'edge': Array<Marker>;
  /**
   *
   * @type {Cursor}
   * @memberof MarkerList
   */
  'cursor'?: Cursor;
}

/**
 *
 * @export
 * @interface MarkerUpdate
 */
export interface MarkerUpdate {
  /**
   *
   * @type {string}
   * @memberof MarkerUpdate
   */
  'name'?: string;
  /**
   *
   * @type {string}
   * @memberof MarkerUpdate
   */
  'description'?: string;
  /**
   *
   * @type {number}
   * @memberof MarkerUpdate
   */
  'lng'?: number;
  /**
   *
   * @type {number}
   * @memberof MarkerUpdate
   */
  'lat'?: number;
  /**
   *
   * @type {number}
   * @memberof MarkerUpdate
   */
  'track_id'?: number;
}

/**
 *
 * @export
 * @interface Pager
 */
export interface Pager {
  /**
   *
   * @type {number}
   * @memberof Pager
   */
  'page': number;
  /**
   *
   * @type {number}
   * @memberof Pager
   */
  'page_size': number;
  /**
   *
   * @type {number}
   * @memberof Pager
   */
  'total': number;
}

/**
 *
 * @export
 * @interface Place
 */
export interface Place {
  /**
   *
   * @type {number}
   * @memberof Place
   */
  'id': number;
  /**
   *
   * @type {string}
   * @memberof Place
   */
  'name': string;
  /**
   *
   * @type {string}
   * @memberof Place
   */
  'description': string;
  /**
   *
   * @type {number}
   * @memberof Place
   */
  'lng': number;
  /**
   *
   * @type {number}
   * @memberof Place
   */
  'lat': number;
  /**
   *
   * @type {string}
   * @memberof Place
   */
  'created_at': string;
  /**
   *
   * @type {string}
   * @memberof Place
   */
  'updated_at': string;
}

/**
 *
 * @export
 * @interface PlaceCreate
 */
export interface PlaceCreate {
  /**
   *
   * @type {string}
   * @memberof PlaceCreate
   */
  'name': string;
  /**
   *
   * @type {string}
   * @memberof PlaceCreate
   */
  'description': string;
  /**
   *
   * @type {number}
   * @memberof PlaceCreate
   */
  'lng': number;
  /**
   *
   * @type {number}
   * @memberof PlaceCreate
   */
  'lat': number;
}

/**
 *
 * @export
 * @interface PlaceList
 */
export interface PlaceList {
  /**
   *
   * @type {Array<Place>}
   * @memberof PlaceList
   */
  'edge': Array<Place>;
}

/**
 *
 * @export
 * @interface PlaceUpdate
 */
export interface PlaceUpdate {
  /**
   *
   * @type {string}
   * @memberof PlaceUpdate
   */
  'name'?: string;
  /**
   *
   * @type {string}
   * @memberof PlaceUpdate
   */
  'description'?: string;
  /**
   *
   * @type {number}
   * @memberof PlaceUpdate
   */
  'lng'?: number;
  /**
   *
   * @type {number}
   * @memberof PlaceUpdate
   */
  'lat'?: number;
}

/**
 *
 * @export
 * @interface RegistrationConfirmRequest
 */
export interface RegistrationConfirmRequest {
  /**
   *
   * @type {string}
   * @memberof RegistrationConfirmRequest
   */
  'token': string;
}

/**
 *
 * @export
 * @interface RegistrationRequest
 */
export interface RegistrationRequest {
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  'name': string;
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  'username': string;
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  'email': string;
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  'password': string;
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  'password_confirm': string;
}

/**
 *
 * @export
 * @interface RespCreate
 */
export interface RespCreate {
  /**
   *
   * @type {number}
   * @memberof RespCreate
   */
  'id': number;
}

/**
 *
 * @export
 * @interface RestoreConfirmRequest
 */
export interface RestoreConfirmRequest {
  /**
   *
   * @type {string}
   * @memberof RestoreConfirmRequest
   */
  'token': string;
  /**
   *
   * @type {string}
   * @memberof RestoreConfirmRequest
   */
  'password': string;
  /**
   *
   * @type {string}
   * @memberof RestoreConfirmRequest
   */
  'password_confirm': string;
}

/**
 *
 * @export
 * @interface RestoreRequest
 */
export interface RestoreRequest {
  /**
   *
   * @type {string}
   * @memberof RestoreRequest
   */
  'email': string;
}

/**
 *
 * @export
 * @interface Surface
 */
export interface Surface {
  /**
   *
   * @type {number}
   * @memberof Surface
   */
  'value': number;
  /**
   *
   * @type {string}
   * @memberof Surface
   */
  'surface': string;
}

/**
 *
 * @export
 * @interface Track
 */
export interface Track {
  /**
   *
   * @type {number}
   * @memberof Track
   */
  'id': number;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  'name': string | null;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  'description': string | null;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  'gpx': string;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  'static_map': string;
  /**
   *
   * @type {boolean}
   * @memberof Track
   */
  'is_draft': boolean;
  /**
   *
   * @type {number}
   * @memberof Track
   */
  'distance': number;
  /**
   *
   * @type {number}
   * @memberof Track
   */
  'difficult': number;
  /**
   *
   * @type {number}
   * @memberof Track
   */
  'user_id': number;
  /**
   *
   * @type {Array<string>}
   * @memberof Track
   */
  'collection': Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof Track
   */
  'tags': Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof Track
   */
  'month': Array<number>;
  /**
   *
   * @type {number}
   * @memberof Track
   */
  'version': number;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  'created_at': string;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  'updated_at': string;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  'deleted_at': string | null;
  /**
   *
   * @type {Array<TrackPhoto>}
   * @memberof Track
   */
  'photo_list': Array<TrackPhoto>;
  /**
   *
   * @type {TrackRoute}
   * @memberof Track
   */
  'track_route': TrackRoute;
}

/**
 *
 * @export
 * @interface TrackCreate
 */
export interface TrackCreate {
  /**
   *
   * @type {number}
   * @memberof TrackCreate
   */
  'id': number;
}

/**
 *
 * @export
 * @interface TrackCreateRequest
 */
export interface TrackCreateRequest {
  /**
   *
   * @type {string}
   * @memberof TrackCreateRequest
   */
  'name': string;
  /**
   *
   * @type {TrackRoute}
   * @memberof TrackCreateRequest
   */
  'route': TrackRoute;
  /**
   *
   * @type {string}
   * @memberof TrackCreateRequest
   */
  'description'?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TrackCreateRequest
   */
  'tags'?: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof TrackCreateRequest
   */
  'month'?: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof TrackCreateRequest
   */
  'collection'?: Array<string>;
}

/**
 *
 * @export
 * @interface TrackDraftListFilterParameter
 */
export interface TrackDraftListFilterParameter {
  /**
   *
   * @type {string}
   * @memberof TrackDraftListFilterParameter
   */
  'name'?: string;
  /**
   *
   * @type {number}
   * @memberof TrackDraftListFilterParameter
   */
  'distance_min'?: number;
  /**
   *
   * @type {number}
   * @memberof TrackDraftListFilterParameter
   */
  'distance_max'?: number;
  /**
   *
   * @type {string}
   * @memberof TrackDraftListFilterParameter
   */
  'tag'?: string;
  /**
   *
   * @type {string}
   * @memberof TrackDraftListFilterParameter
   */
  'month'?: string;
  /**
   *
   * @type {string}
   * @memberof TrackDraftListFilterParameter
   */
  'collection'?: string;
}

/**
 *
 * @export
 * @interface TrackList
 */
export interface TrackList {
  /**
   *
   * @type {Array<Track>}
   * @memberof TrackList
   */
  'edge': Array<Track>;
  /**
   *
   * @type {Cursor}
   * @memberof TrackList
   */
  'cursor'?: Cursor;
}

/**
 *
 * @export
 * @interface TrackListFilterParameter
 */
export interface TrackListFilterParameter {
  /**
   *
   * @type {string}
   * @memberof TrackListFilterParameter
   */
  'name'?: string;
  /**
   *
   * @type {number}
   * @memberof TrackListFilterParameter
   */
  'user_id'?: number;
  /**
   *
   * @type {number}
   * @memberof TrackListFilterParameter
   */
  'distance_min'?: number;
  /**
   *
   * @type {number}
   * @memberof TrackListFilterParameter
   */
  'distance_max'?: number;
  /**
   *
   * @type {string}
   * @memberof TrackListFilterParameter
   */
  'tag'?: string;
  /**
   *
   * @type {string}
   * @memberof TrackListFilterParameter
   */
  'month'?: string;
  /**
   *
   * @type {string}
   * @memberof TrackListFilterParameter
   */
  'collection'?: string;
}

/**
 *
 * @export
 * @interface TrackPersistRequest
 */
export interface TrackPersistRequest {
  /**
   *
   * @type {TrackRoute}
   * @memberof TrackPersistRequest
   */
  'route': TrackRoute;
}

/**
 *
 * @export
 * @interface TrackPhoto
 */
export interface TrackPhoto {
  /**
   *
   * @type {number}
   * @memberof TrackPhoto
   */
  'id': number;
  /**
   *
   * @type {string}
   * @memberof TrackPhoto
   */
  'path': string;
}

/**
 *
 * @export
 * @interface TrackPhotoList
 */
export interface TrackPhotoList {
  /**
   *
   * @type {Array<TrackPhoto>}
   * @memberof TrackPhotoList
   */
  'edge': Array<TrackPhoto>;
  /**
   *
   * @type {Cursor}
   * @memberof TrackPhotoList
   */
  'cursor'?: Cursor;
}

/**
 *
 * @export
 * @interface TrackRoute
 */
export interface TrackRoute {
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof TrackRoute
   */
  'waypoints': Array<Array<number>>;
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof TrackRoute
   */
  'user_waypoints': Array<Array<number>>;
  /**
   *
   * @type {Array<string>}
   * @memberof TrackRoute
   */
  'segments': Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof TrackRoute
   */
  'ascend': Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof TrackRoute
   */
  'descend': Array<number>;
  /**
   *
   * @type {Array<boolean>}
   * @memberof TrackRoute
   */
  'routable': Array<boolean>;
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof TrackRoute
   */
  'elevation': Array<Array<number>>;
  /**
   *
   * @type {Array<Array<Surface>>}
   * @memberof TrackRoute
   */
  'surface': Array<Array<Surface>>;
}

/**
 *
 * @export
 * @interface TrackUpdateRequest
 */
export interface TrackUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof TrackUpdateRequest
   */
  'name'?: string;
  /**
   *
   * @type {TrackRoute}
   * @memberof TrackUpdateRequest
   */
  'route'?: TrackRoute;
  /**
   *
   * @type {string}
   * @memberof TrackUpdateRequest
   */
  'description'?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TrackUpdateRequest
   */
  'tags'?: Array<string> | null;
  /**
   *
   * @type {Array<number>}
   * @memberof TrackUpdateRequest
   */
  'month'?: Array<number> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TrackUpdateRequest
   */
  'collection'?: Array<string> | null;
}

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  'id': number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  'email': string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  'name': string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  'username': string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  'avatar'?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  'created_at': string;
}

/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  'field': string;
  /**
   *
   * @type {Array<string>}
   * @memberof ValidationError
   */
  'errors': Array<string>;
}

/**
 *
 * @export
 * @interface ValidationErrors
 */
export interface ValidationErrors {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof ValidationErrors
   */
  'errors': Array<ValidationError>;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ChangePasswordRequest} [changePasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword: async (changePasswordRequest?: ChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/change_password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {LoginRequest} [loginRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (loginRequest?: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    me: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {'google' | 'yandex' | 'facebook' | 'vkontakte'} provider oauth provider name
     * @param {string} code oauth code
     * @param {string} state oauth state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthCheck: async (provider: 'google' | 'yandex' | 'facebook' | 'vkontakte', code: string, state: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'provider' is not null or undefined
      assertParamExists('oauthCheck', 'provider', provider)
      // verify required parameter 'code' is not null or undefined
      assertParamExists('oauthCheck', 'code', code)
      // verify required parameter 'state' is not null or undefined
      assertParamExists('oauthCheck', 'state', state)
      const localVarPath = `/auth/connect/check/{provider}`
        .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {'google' | 'yandex' | 'facebook' | 'vkontakte'} provider oauth provider name
     * @param {string} [returnUrl] return url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthRedirect: async (provider: 'google' | 'yandex' | 'facebook' | 'vkontakte', returnUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'provider' is not null or undefined
      assertParamExists('oauthRedirect', 'provider', provider)
      const localVarPath = `/auth/connect/{provider}`
        .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (returnUrl !== undefined) {
        localVarQueryParameter['returnUrl'] = returnUrl;
      }


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RegistrationRequest} [registrationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registration: async (registrationRequest?: RegistrationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/registration`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(registrationRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RegistrationConfirmRequest} [registrationConfirmRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationConfirm: async (registrationConfirmRequest?: RegistrationConfirmRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/registration/confirm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(registrationConfirmRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RestoreRequest} [restoreRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restore: async (restoreRequest?: RestoreRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/restore`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(restoreRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RegistrationConfirmRequest} [registrationConfirmRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreCheck: async (registrationConfirmRequest?: RegistrationConfirmRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/restore/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(registrationConfirmRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RestoreConfirmRequest} [restoreConfirmRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreConfirm: async (restoreConfirmRequest?: RestoreConfirmRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/restore/confirm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(restoreConfirmRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {ChangePasswordRequest} [changePasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changePassword(changePasswordRequest?: ChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Jwt>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(changePasswordRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {LoginRequest} [loginRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(loginRequest?: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Jwt>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async me(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.me(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {'google' | 'yandex' | 'facebook' | 'vkontakte'} provider oauth provider name
     * @param {string} code oauth code
     * @param {string} state oauth state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async oauthCheck(provider: 'google' | 'yandex' | 'facebook' | 'vkontakte', code: string, state: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpError>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.oauthCheck(provider, code, state, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {'google' | 'yandex' | 'facebook' | 'vkontakte'} provider oauth provider name
     * @param {string} [returnUrl] return url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async oauthRedirect(provider: 'google' | 'yandex' | 'facebook' | 'vkontakte', returnUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpError>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.oauthRedirect(provider, returnUrl, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Jwt>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RegistrationRequest} [registrationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registration(registrationRequest?: RegistrationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registration(registrationRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RegistrationConfirmRequest} [registrationConfirmRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registrationConfirm(registrationConfirmRequest?: RegistrationConfirmRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Jwt>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registrationConfirm(registrationConfirmRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RestoreRequest} [restoreRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restore(restoreRequest?: RestoreRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.restore(restoreRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RegistrationConfirmRequest} [registrationConfirmRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restoreCheck(registrationConfirmRequest?: RegistrationConfirmRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.restoreCheck(registrationConfirmRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {RestoreConfirmRequest} [restoreConfirmRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async restoreConfirm(restoreConfirmRequest?: RestoreConfirmRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Jwt>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.restoreConfirm(restoreConfirmRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthApiFp(configuration)
  return {
    /**
     *
     * @param {ChangePasswordRequest} [changePasswordRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword(changePasswordRequest?: ChangePasswordRequest, options?: any): AxiosPromise<Jwt> {
      return localVarFp.changePassword(changePasswordRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {LoginRequest} [loginRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(loginRequest?: LoginRequest, options?: any): AxiosPromise<Jwt> {
      return localVarFp.login(loginRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    me(options?: any): AxiosPromise<User> {
      return localVarFp.me(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {'google' | 'yandex' | 'facebook' | 'vkontakte'} provider oauth provider name
     * @param {string} code oauth code
     * @param {string} state oauth state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthCheck(provider: 'google' | 'yandex' | 'facebook' | 'vkontakte', code: string, state: string, options?: any): AxiosPromise<HttpError> {
      return localVarFp.oauthCheck(provider, code, state, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {'google' | 'yandex' | 'facebook' | 'vkontakte'} provider oauth provider name
     * @param {string} [returnUrl] return url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthRedirect(provider: 'google' | 'yandex' | 'facebook' | 'vkontakte', returnUrl?: string, options?: any): AxiosPromise<HttpError> {
      return localVarFp.oauthRedirect(provider, returnUrl, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken(options?: any): AxiosPromise<Jwt> {
      return localVarFp.refreshToken(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RegistrationRequest} [registrationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registration(registrationRequest?: RegistrationRequest, options?: any): AxiosPromise<void> {
      return localVarFp.registration(registrationRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RegistrationConfirmRequest} [registrationConfirmRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationConfirm(registrationConfirmRequest?: RegistrationConfirmRequest, options?: any): AxiosPromise<Jwt> {
      return localVarFp.registrationConfirm(registrationConfirmRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RestoreRequest} [restoreRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restore(restoreRequest?: RestoreRequest, options?: any): AxiosPromise<void> {
      return localVarFp.restore(restoreRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RegistrationConfirmRequest} [registrationConfirmRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreCheck(registrationConfirmRequest?: RegistrationConfirmRequest, options?: any): AxiosPromise<void> {
      return localVarFp.restoreCheck(registrationConfirmRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RestoreConfirmRequest} [restoreConfirmRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    restoreConfirm(restoreConfirmRequest?: RestoreConfirmRequest, options?: any): AxiosPromise<Jwt> {
      return localVarFp.restoreConfirm(restoreConfirmRequest, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @param {ChangePasswordRequest} [changePasswordRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public changePassword(changePasswordRequest?: ChangePasswordRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration).changePassword(changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {LoginRequest} [loginRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public login(loginRequest?: LoginRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public me(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration).me(options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {'google' | 'yandex' | 'facebook' | 'vkontakte'} provider oauth provider name
   * @param {string} code oauth code
   * @param {string} state oauth state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public oauthCheck(provider: 'google' | 'yandex' | 'facebook' | 'vkontakte', code: string, state: string, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration).oauthCheck(provider, code, state, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {'google' | 'yandex' | 'facebook' | 'vkontakte'} provider oauth provider name
   * @param {string} [returnUrl] return url
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public oauthRedirect(provider: 'google' | 'yandex' | 'facebook' | 'vkontakte', returnUrl?: string, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration).oauthRedirect(provider, returnUrl, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public refreshToken(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration).refreshToken(options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RegistrationRequest} [registrationRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public registration(registrationRequest?: RegistrationRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration).registration(registrationRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RegistrationConfirmRequest} [registrationConfirmRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public registrationConfirm(registrationConfirmRequest?: RegistrationConfirmRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration).registrationConfirm(registrationConfirmRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RestoreRequest} [restoreRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public restore(restoreRequest?: RestoreRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration).restore(restoreRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RegistrationConfirmRequest} [registrationConfirmRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public restoreCheck(registrationConfirmRequest?: RegistrationConfirmRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration).restoreCheck(registrationConfirmRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RestoreConfirmRequest} [restoreConfirmRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public restoreConfirm(restoreConfirmRequest?: RestoreConfirmRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration).restoreConfirm(restoreConfirmRequest, options).then((request) => request(this.axios, this.basePath));
  }
}


/**
 * BlogApi - axios parameter creator
 * @export
 */
export const BlogApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} name
     * @param {File} image
     * @param {string} content
     * @param {string} contentShort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blogCreate: async (name: string, image: File, content: string, contentShort: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'name' is not null or undefined
      assertParamExists('blogCreate', 'name', name)
      // verify required parameter 'image' is not null or undefined
      assertParamExists('blogCreate', 'image', image)
      // verify required parameter 'content' is not null or undefined
      assertParamExists('blogCreate', 'content', content)
      // verify required parameter 'contentShort' is not null or undefined
      assertParamExists('blogCreate', 'contentShort', contentShort)
      const localVarPath = `/blog/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      if (name !== undefined) {
        localVarFormParams.append('name', name as any);
      }

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      if (content !== undefined) {
        localVarFormParams.append('content', content as any);
      }

      if (contentShort !== undefined) {
        localVarFormParams.append('content_short', contentShort as any);
      }


      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [page] blog post page
     * @param {number} [pageSize] posts per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blogList: async (page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/blog/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id blog post id
     * @param {string} [name]
     * @param {File} [image]
     * @param {string} [content]
     * @param {string} [contentShort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blogUpdate: async (id: number, name?: string, image?: File, content?: string, contentShort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('blogUpdate', 'id', id)
      const localVarPath = `/blog/update/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      if (name !== undefined) {
        localVarFormParams.append('name', name as any);
      }

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      if (content !== undefined) {
        localVarFormParams.append('content', content as any);
      }

      if (contentShort !== undefined) {
        localVarFormParams.append('content_short', contentShort as any);
      }


      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blogUpload: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/blog/file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }


      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id blog post id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blogView: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('blogView', 'id', id)
      const localVarPath = `/blog/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  }
};

/**
 * BlogApi - functional programming interface
 * @export
 */
export const BlogApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BlogApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} name
     * @param {File} image
     * @param {string} content
     * @param {string} contentShort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blogCreate(name: string, image: File, content: string, contentShort: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogPostResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.blogCreate(name, image, content, contentShort, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [page] blog post page
     * @param {number} [pageSize] posts per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blogList(page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogPostList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.blogList(page, pageSize, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id blog post id
     * @param {string} [name]
     * @param {File} [image]
     * @param {string} [content]
     * @param {string} [contentShort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blogUpdate(id: number, name?: string, image?: File, content?: string, contentShort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.blogUpdate(id, name, image, content, contentShort, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blogUpload(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogUpload200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.blogUpload(file, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id blog post id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blogView(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlogPostResp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.blogView(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  }
};

/**
 * BlogApi - factory interface
 * @export
 */
export const BlogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = BlogApiFp(configuration)
  return {
    /**
     *
     * @param {string} name
     * @param {File} image
     * @param {string} content
     * @param {string} contentShort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blogCreate(name: string, image: File, content: string, contentShort: string, options?: any): AxiosPromise<BlogPostResp> {
      return localVarFp.blogCreate(name, image, content, contentShort, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [page] blog post page
     * @param {number} [pageSize] posts per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blogList(page?: number, pageSize?: number, options?: any): AxiosPromise<BlogPostList> {
      return localVarFp.blogList(page, pageSize, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id blog post id
     * @param {string} [name]
     * @param {File} [image]
     * @param {string} [content]
     * @param {string} [contentShort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blogUpdate(id: number, name?: string, image?: File, content?: string, contentShort?: string, options?: any): AxiosPromise<void> {
      return localVarFp.blogUpdate(id, name, image, content, contentShort, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blogUpload(file?: File, options?: any): AxiosPromise<BlogUpload200Response> {
      return localVarFp.blogUpload(file, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id blog post id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blogView(id: number, options?: any): AxiosPromise<BlogPostResp> {
      return localVarFp.blogView(id, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * BlogApi - object-oriented interface
 * @export
 * @class BlogApi
 * @extends {BaseAPI}
 */
export class BlogApi extends BaseAPI {
  /**
   *
   * @param {string} name
   * @param {File} image
   * @param {string} content
   * @param {string} contentShort
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BlogApi
   */
  public blogCreate(name: string, image: File, content: string, contentShort: string, options?: AxiosRequestConfig) {
    return BlogApiFp(this.configuration).blogCreate(name, image, content, contentShort, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [page] blog post page
   * @param {number} [pageSize] posts per page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BlogApi
   */
  public blogList(page?: number, pageSize?: number, options?: AxiosRequestConfig) {
    return BlogApiFp(this.configuration).blogList(page, pageSize, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id blog post id
   * @param {string} [name]
   * @param {File} [image]
   * @param {string} [content]
   * @param {string} [contentShort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BlogApi
   */
  public blogUpdate(id: number, name?: string, image?: File, content?: string, contentShort?: string, options?: AxiosRequestConfig) {
    return BlogApiFp(this.configuration).blogUpdate(id, name, image, content, contentShort, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BlogApi
   */
  public blogUpload(file?: File, options?: AxiosRequestConfig) {
    return BlogApiFp(this.configuration).blogUpload(file, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id blog post id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BlogApi
   */
  public blogView(id: number, options?: AxiosRequestConfig) {
    return BlogApiFp(this.configuration).blogView(id, options).then((request) => request(this.axios, this.basePath));
  }
}


/**
 * CommentApi - axios parameter creator
 * @export
 */
export const CommentApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CommentCreate} [commentCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentCreate: async (commentCreate?: CommentCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/comment/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(commentCreate, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [ownerId] id
     * @param {string} [ownerType] type
     * @param {number} [cursor] cursor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentList: async (ownerId?: number, ownerType?: string, cursor?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/comment/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (ownerId !== undefined) {
        localVarQueryParameter['owner_id'] = ownerId;
      }

      if (ownerType !== undefined) {
        localVarQueryParameter['owner_type'] = ownerType;
      }

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor;
      }


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentRemove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('commentRemove', 'id', id)
      const localVarPath = `/comment/remove/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id id
     * @param {CommentUpdate} [commentUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentUpdate: async (id: number, commentUpdate?: CommentUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('commentUpdate', 'id', id)
      const localVarPath = `/comment/update/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(commentUpdate, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  }
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CommentApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CommentCreate} [commentCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentCreate(commentCreate?: CommentCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentCreate(commentCreate, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [ownerId] id
     * @param {string} [ownerType] type
     * @param {number} [cursor] cursor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentList(ownerId?: number, ownerType?: string, cursor?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentList(ownerId, ownerType, cursor, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentRemove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentRemove(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id id
     * @param {CommentUpdate} [commentUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentUpdate(id: number, commentUpdate?: CommentUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentUpdate(id, commentUpdate, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  }
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CommentApiFp(configuration)
  return {
    /**
     *
     * @param {CommentCreate} [commentCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentCreate(commentCreate?: CommentCreate, options?: any): AxiosPromise<Comment> {
      return localVarFp.commentCreate(commentCreate, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [ownerId] id
     * @param {string} [ownerType] type
     * @param {number} [cursor] cursor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentList(ownerId?: number, ownerType?: string, cursor?: number, options?: any): AxiosPromise<CommentList> {
      return localVarFp.commentList(ownerId, ownerType, cursor, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentRemove(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.commentRemove(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id id
     * @param {CommentUpdate} [commentUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentUpdate(id: number, commentUpdate?: CommentUpdate, options?: any): AxiosPromise<void> {
      return localVarFp.commentUpdate(id, commentUpdate, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
  /**
   *
   * @param {CommentCreate} [commentCreate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public commentCreate(commentCreate?: CommentCreate, options?: AxiosRequestConfig) {
    return CommentApiFp(this.configuration).commentCreate(commentCreate, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [ownerId] id
   * @param {string} [ownerType] type
   * @param {number} [cursor] cursor
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public commentList(ownerId?: number, ownerType?: string, cursor?: number, options?: AxiosRequestConfig) {
    return CommentApiFp(this.configuration).commentList(ownerId, ownerType, cursor, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public commentRemove(id: number, options?: AxiosRequestConfig) {
    return CommentApiFp(this.configuration).commentRemove(id, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id id
   * @param {CommentUpdate} [commentUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentApi
   */
  public commentUpdate(id: number, commentUpdate?: CommentUpdate, options?: AxiosRequestConfig) {
    return CommentApiFp(this.configuration).commentUpdate(id, commentUpdate, options).then((request) => request(this.axios, this.basePath));
  }
}


/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [year] year
     * @param {number} [month] name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventCalendar: async (year?: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/event/calendar`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = month;
      }


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EventCreate} [eventCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventCreate: async (eventCreate?: EventCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/event/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(eventCreate, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id event id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventGpx: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('eventGpx', 'id', id)
      const localVarPath = `/event/gpx/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id event id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventIsMember: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('eventIsMember', 'id', id)
      const localVarPath = `/event/is_member/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EventJoinRequest} [eventJoinRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventJoin: async (eventJoinRequest?: EventJoinRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/event/join`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(eventJoinRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EventLeaveRequest} [eventLeaveRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventLeave: async (eventLeaveRequest?: EventLeaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/event/leave`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(eventLeaveRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EventListFilterParameter} [filter] event filter
     * @param {number} [cursor] blog post cursor
     * @param {number} [limit] blog post limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventList: async (filter?: EventListFilterParameter, cursor?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/event/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id event id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventMembers: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('eventMembers', 'id', id)
      const localVarPath = `/event/members/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventRemove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('eventRemove', 'id', id)
      const localVarPath = `/event/remove/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id id
     * @param {EventUpdate} [eventUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventUpdate: async (id: number, eventUpdate?: EventUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('eventUpdate', 'id', id)
      const localVarPath = `/event/update/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(eventUpdate, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id event id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventView: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('eventView', 'id', id)
      const localVarPath = `/event/view/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} [year] year
     * @param {number} [month] name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventCalendar(year?: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventCalendar>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventCalendar(year, month, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {EventCreate} [eventCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventCreate(eventCreate?: EventCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RespCreate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventCreate(eventCreate, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id event id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventGpx(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventGpx(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id event id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventIsMember(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventIsMember>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventIsMember(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {EventJoinRequest} [eventJoinRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventJoin(eventJoinRequest?: EventJoinRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventJoin(eventJoinRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {EventLeaveRequest} [eventLeaveRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventLeave(eventLeaveRequest?: EventLeaveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventLeave(eventLeaveRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {EventListFilterParameter} [filter] event filter
     * @param {number} [cursor] blog post cursor
     * @param {number} [limit] blog post limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventList(filter?: EventListFilterParameter, cursor?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventList(filter, cursor, limit, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id event id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventMembers(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventMembers>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventMembers(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventRemove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventRemove(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id id
     * @param {EventUpdate} [eventUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventUpdate(id: number, eventUpdate?: EventUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventUpdate(id, eventUpdate, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id event id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async eventView(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.eventView(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = EventApiFp(configuration)
  return {
    /**
     *
     * @param {number} [year] year
     * @param {number} [month] name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventCalendar(year?: number, month?: number, options?: any): AxiosPromise<EventCalendar> {
      return localVarFp.eventCalendar(year, month, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EventCreate} [eventCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventCreate(eventCreate?: EventCreate, options?: any): AxiosPromise<RespCreate> {
      return localVarFp.eventCreate(eventCreate, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id event id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventGpx(id: number, options?: any): AxiosPromise<File> {
      return localVarFp.eventGpx(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id event id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventIsMember(id: number, options?: any): AxiosPromise<EventIsMember> {
      return localVarFp.eventIsMember(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EventJoinRequest} [eventJoinRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventJoin(eventJoinRequest?: EventJoinRequest, options?: any): AxiosPromise<void> {
      return localVarFp.eventJoin(eventJoinRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EventLeaveRequest} [eventLeaveRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventLeave(eventLeaveRequest?: EventLeaveRequest, options?: any): AxiosPromise<void> {
      return localVarFp.eventLeave(eventLeaveRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {EventListFilterParameter} [filter] event filter
     * @param {number} [cursor] blog post cursor
     * @param {number} [limit] blog post limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventList(filter?: EventListFilterParameter, cursor?: number, limit?: number, options?: any): AxiosPromise<EventList> {
      return localVarFp.eventList(filter, cursor, limit, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id event id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventMembers(id: number, options?: any): AxiosPromise<EventMembers> {
      return localVarFp.eventMembers(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventRemove(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.eventRemove(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id id
     * @param {EventUpdate} [eventUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventUpdate(id: number, eventUpdate?: EventUpdate, options?: any): AxiosPromise<void> {
      return localVarFp.eventUpdate(id, eventUpdate, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id event id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    eventView(id: number, options?: any): AxiosPromise<Event> {
      return localVarFp.eventView(id, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
  /**
   *
   * @param {number} [year] year
   * @param {number} [month] name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventCalendar(year?: number, month?: number, options?: AxiosRequestConfig) {
    return EventApiFp(this.configuration).eventCalendar(year, month, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EventCreate} [eventCreate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventCreate(eventCreate?: EventCreate, options?: AxiosRequestConfig) {
    return EventApiFp(this.configuration).eventCreate(eventCreate, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id event id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventGpx(id: number, options?: AxiosRequestConfig) {
    return EventApiFp(this.configuration).eventGpx(id, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id event id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventIsMember(id: number, options?: AxiosRequestConfig) {
    return EventApiFp(this.configuration).eventIsMember(id, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EventJoinRequest} [eventJoinRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventJoin(eventJoinRequest?: EventJoinRequest, options?: AxiosRequestConfig) {
    return EventApiFp(this.configuration).eventJoin(eventJoinRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EventLeaveRequest} [eventLeaveRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventLeave(eventLeaveRequest?: EventLeaveRequest, options?: AxiosRequestConfig) {
    return EventApiFp(this.configuration).eventLeave(eventLeaveRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EventListFilterParameter} [filter] event filter
   * @param {number} [cursor] blog post cursor
   * @param {number} [limit] blog post limit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventList(filter?: EventListFilterParameter, cursor?: number, limit?: number, options?: AxiosRequestConfig) {
    return EventApiFp(this.configuration).eventList(filter, cursor, limit, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id event id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventMembers(id: number, options?: AxiosRequestConfig) {
    return EventApiFp(this.configuration).eventMembers(id, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventRemove(id: number, options?: AxiosRequestConfig) {
    return EventApiFp(this.configuration).eventRemove(id, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id id
   * @param {EventUpdate} [eventUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventUpdate(id: number, eventUpdate?: EventUpdate, options?: AxiosRequestConfig) {
    return EventApiFp(this.configuration).eventUpdate(id, eventUpdate, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id event id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EventApi
   */
  public eventView(id: number, options?: AxiosRequestConfig) {
    return EventApiFp(this.configuration).eventView(id, options).then((request) => request(this.axios, this.basePath));
  }
}


/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} ip get location by ip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detect: async (ip: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'ip' is not null or undefined
      assertParamExists('detect', 'ip', ip)
      const localVarPath = `/location/detect`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (ip !== undefined) {
        localVarQueryParameter['ip'] = ip;
      }


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} ip get location by ip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async detect(ip: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.detect(ip, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = LocationApiFp(configuration)
  return {
    /**
     *
     * @param {string} ip get location by ip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    detect(ip: string, options?: any): AxiosPromise<Location> {
      return localVarFp.detect(ip, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
  /**
   *
   * @param {string} ip get location by ip
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LocationApi
   */
  public detect(ip: string, options?: AxiosRequestConfig) {
    return LocationApiFp(this.configuration).detect(ip, options).then((request) => request(this.axios, this.basePath));
  }
}


/**
 * MarkerApi - axios parameter creator
 * @export
 */
export const MarkerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {MarkerCreate} [markerCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markerCreate: async (markerCreate?: MarkerCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/marker/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(markerCreate, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [trackId] track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markerList: async (trackId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/marker/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (trackId !== undefined) {
        localVarQueryParameter['track_id'] = trackId;
      }


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markerRemove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('markerRemove', 'id', id)
      const localVarPath = `/marker/remove/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id id
     * @param {MarkerUpdate} [markerUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markerUpdate: async (id: number, markerUpdate?: MarkerUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('markerUpdate', 'id', id)
      const localVarPath = `/marker/update/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(markerUpdate, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markerView: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('markerView', 'id', id)
      const localVarPath = `/marker/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  }
};

/**
 * MarkerApi - functional programming interface
 * @export
 */
export const MarkerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MarkerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {MarkerCreate} [markerCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markerCreate(markerCreate?: MarkerCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Marker>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markerCreate(markerCreate, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [trackId] track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markerList(trackId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkerList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markerList(trackId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markerRemove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markerRemove(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id id
     * @param {MarkerUpdate} [markerUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markerUpdate(id: number, markerUpdate?: MarkerUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markerUpdate(id, markerUpdate, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markerView(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Marker>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markerView(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  }
};

/**
 * MarkerApi - factory interface
 * @export
 */
export const MarkerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = MarkerApiFp(configuration)
  return {
    /**
     *
     * @param {MarkerCreate} [markerCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markerCreate(markerCreate?: MarkerCreate, options?: any): AxiosPromise<Marker> {
      return localVarFp.markerCreate(markerCreate, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [trackId] track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markerList(trackId?: number, options?: any): AxiosPromise<MarkerList> {
      return localVarFp.markerList(trackId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markerRemove(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.markerRemove(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id id
     * @param {MarkerUpdate} [markerUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markerUpdate(id: number, markerUpdate?: MarkerUpdate, options?: any): AxiosPromise<void> {
      return localVarFp.markerUpdate(id, markerUpdate, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markerView(id: number, options?: any): AxiosPromise<Marker> {
      return localVarFp.markerView(id, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * MarkerApi - object-oriented interface
 * @export
 * @class MarkerApi
 * @extends {BaseAPI}
 */
export class MarkerApi extends BaseAPI {
  /**
   *
   * @param {MarkerCreate} [markerCreate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarkerApi
   */
  public markerCreate(markerCreate?: MarkerCreate, options?: AxiosRequestConfig) {
    return MarkerApiFp(this.configuration).markerCreate(markerCreate, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [trackId] track id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarkerApi
   */
  public markerList(trackId?: number, options?: AxiosRequestConfig) {
    return MarkerApiFp(this.configuration).markerList(trackId, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarkerApi
   */
  public markerRemove(id: number, options?: AxiosRequestConfig) {
    return MarkerApiFp(this.configuration).markerRemove(id, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id id
   * @param {MarkerUpdate} [markerUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarkerApi
   */
  public markerUpdate(id: number, markerUpdate?: MarkerUpdate, options?: AxiosRequestConfig) {
    return MarkerApiFp(this.configuration).markerUpdate(id, markerUpdate, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarkerApi
   */
  public markerView(id: number, options?: AxiosRequestConfig) {
    return MarkerApiFp(this.configuration).markerView(id, options).then((request) => request(this.axios, this.basePath));
  }
}


/**
 * PlaceApi - axios parameter creator
 * @export
 */
export const PlaceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {PlaceCreate} [placeCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placeCreate: async (placeCreate?: PlaceCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/place/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(placeCreate, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} xmin radius
     * @param {number} ymin radius
     * @param {number} xmax radius
     * @param {number} ymax radius
     * @param {number} [trackId] track id
     * @param {number} [radius] radius
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placeList: async (xmin: number, ymin: number, xmax: number, ymax: number, trackId?: number, radius?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'xmin' is not null or undefined
      assertParamExists('placeList', 'xmin', xmin)
      // verify required parameter 'ymin' is not null or undefined
      assertParamExists('placeList', 'ymin', ymin)
      // verify required parameter 'xmax' is not null or undefined
      assertParamExists('placeList', 'xmax', xmax)
      // verify required parameter 'ymax' is not null or undefined
      assertParamExists('placeList', 'ymax', ymax)
      const localVarPath = `/place/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (trackId !== undefined) {
        localVarQueryParameter['track_id'] = trackId;
      }

      if (radius !== undefined) {
        localVarQueryParameter['radius'] = radius;
      }

      if (xmin !== undefined) {
        localVarQueryParameter['xmin'] = xmin;
      }

      if (ymin !== undefined) {
        localVarQueryParameter['ymin'] = ymin;
      }

      if (xmax !== undefined) {
        localVarQueryParameter['xmax'] = xmax;
      }

      if (ymax !== undefined) {
        localVarQueryParameter['ymax'] = ymax;
      }


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placeRemove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('placeRemove', 'id', id)
      const localVarPath = `/place/remove/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id id
     * @param {PlaceUpdate} [placeUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placeUpdate: async (id: number, placeUpdate?: PlaceUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('placeUpdate', 'id', id)
      const localVarPath = `/place/update/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(placeUpdate, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placeView: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('placeView', 'id', id)
      const localVarPath = `/place/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  }
};

/**
 * PlaceApi - functional programming interface
 * @export
 */
export const PlaceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PlaceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {PlaceCreate} [placeCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placeCreate(placeCreate?: PlaceCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Place>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.placeCreate(placeCreate, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} xmin radius
     * @param {number} ymin radius
     * @param {number} xmax radius
     * @param {number} ymax radius
     * @param {number} [trackId] track id
     * @param {number} [radius] radius
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placeList(xmin: number, ymin: number, xmax: number, ymax: number, trackId?: number, radius?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaceList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.placeList(xmin, ymin, xmax, ymax, trackId, radius, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placeRemove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.placeRemove(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id id
     * @param {PlaceUpdate} [placeUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placeUpdate(id: number, placeUpdate?: PlaceUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.placeUpdate(id, placeUpdate, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async placeView(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Place>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.placeView(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  }
};

/**
 * PlaceApi - factory interface
 * @export
 */
export const PlaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PlaceApiFp(configuration)
  return {
    /**
     *
     * @param {PlaceCreate} [placeCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placeCreate(placeCreate?: PlaceCreate, options?: any): AxiosPromise<Place> {
      return localVarFp.placeCreate(placeCreate, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} xmin radius
     * @param {number} ymin radius
     * @param {number} xmax radius
     * @param {number} ymax radius
     * @param {number} [trackId] track id
     * @param {number} [radius] radius
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placeList(xmin: number, ymin: number, xmax: number, ymax: number, trackId?: number, radius?: number, options?: any): AxiosPromise<PlaceList> {
      return localVarFp.placeList(xmin, ymin, xmax, ymax, trackId, radius, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placeRemove(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.placeRemove(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id id
     * @param {PlaceUpdate} [placeUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placeUpdate(id: number, placeUpdate?: PlaceUpdate, options?: any): AxiosPromise<void> {
      return localVarFp.placeUpdate(id, placeUpdate, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    placeView(id: number, options?: any): AxiosPromise<Place> {
      return localVarFp.placeView(id, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * PlaceApi - object-oriented interface
 * @export
 * @class PlaceApi
 * @extends {BaseAPI}
 */
export class PlaceApi extends BaseAPI {
  /**
   *
   * @param {PlaceCreate} [placeCreate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placeCreate(placeCreate?: PlaceCreate, options?: AxiosRequestConfig) {
    return PlaceApiFp(this.configuration).placeCreate(placeCreate, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} xmin radius
   * @param {number} ymin radius
   * @param {number} xmax radius
   * @param {number} ymax radius
   * @param {number} [trackId] track id
   * @param {number} [radius] radius
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placeList(xmin: number, ymin: number, xmax: number, ymax: number, trackId?: number, radius?: number, options?: AxiosRequestConfig) {
    return PlaceApiFp(this.configuration).placeList(xmin, ymin, xmax, ymax, trackId, radius, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placeRemove(id: number, options?: AxiosRequestConfig) {
    return PlaceApiFp(this.configuration).placeRemove(id, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id id
   * @param {PlaceUpdate} [placeUpdate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placeUpdate(id: number, placeUpdate?: PlaceUpdate, options?: AxiosRequestConfig) {
    return PlaceApiFp(this.configuration).placeUpdate(id, placeUpdate, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlaceApi
   */
  public placeView(id: number, options?: AxiosRequestConfig) {
    return PlaceApiFp(this.configuration).placeView(id, options).then((request) => request(this.axios, this.basePath));
  }
}


/**
 * RouteApi - axios parameter creator
 * @export
 */
export const RouteApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {BuildRouteRequest} [buildRouteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildRoute: async (buildRouteRequest?: BuildRouteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/route`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(buildRouteRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  }
};

/**
 * RouteApi - functional programming interface
 * @export
 */
export const RouteApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RouteApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {BuildRouteRequest} [buildRouteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async buildRoute(buildRouteRequest?: BuildRouteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackRoute>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.buildRoute(buildRouteRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  }
};

/**
 * RouteApi - factory interface
 * @export
 */
export const RouteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = RouteApiFp(configuration)
  return {
    /**
     *
     * @param {BuildRouteRequest} [buildRouteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buildRoute(buildRouteRequest?: BuildRouteRequest, options?: any): AxiosPromise<TrackRoute> {
      return localVarFp.buildRoute(buildRouteRequest, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * RouteApi - object-oriented interface
 * @export
 * @class RouteApi
 * @extends {BaseAPI}
 */
export class RouteApi extends BaseAPI {
  /**
   *
   * @param {BuildRouteRequest} [buildRouteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RouteApi
   */
  public buildRoute(buildRouteRequest?: BuildRouteRequest, options?: AxiosRequestConfig) {
    return RouteApiFp(this.configuration).buildRoute(buildRouteRequest, options).then((request) => request(this.axios, this.basePath));
  }
}


/**
 * TrackApi - axios parameter creator
 * @export
 */
export const TrackApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {TrackCreateRequest} [trackCreateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackCreate: async (trackCreateRequest?: TrackCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/track/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(trackCreateRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [cursor] blog post cursor
     * @param {number} [limit] posts limit
     * @param {TrackDraftListFilterParameter} [filter] track filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackDraftList: async (cursor?: number, limit?: number, filter?: TrackDraftListFilterParameter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/track/draft/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackGpx: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('trackGpx', 'id', id)
      const localVarPath = `/track/gpx/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [cursor] blog post cursor
     * @param {number} [limit] posts limit
     * @param {TrackListFilterParameter} [filter] track filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackList: async (cursor?: number, limit?: number, filter?: TrackListFilterParameter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/track/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {TrackPersistRequest} [trackPersistRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackPersist: async (trackPersistRequest?: TrackPersistRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/track/persist`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(trackPersistRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackPublish: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('trackPublish', 'id', id)
      const localVarPath = `/track/publish/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackRemove: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('trackRemove', 'id', id)
      const localVarPath = `/track/remove/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackUnpublish: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('trackUnpublish', 'id', id)
      const localVarPath = `/track/unpublish/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id track id
     * @param {TrackUpdateRequest} [trackUpdateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackUpdate: async (id: number, trackUpdateRequest?: TrackUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('trackUpdate', 'id', id)
      const localVarPath = `/track/update/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(trackUpdateRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {File} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackUpload: async (body?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/track/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      localVarHeaderParameter['Content-Type'] = 'application/gpx+xml';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackView: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('trackView', 'id', id)
      const localVarPath = `/track/{id}`
        .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  }
};

/**
 * TrackApi - functional programming interface
 * @export
 */
export const TrackApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TrackApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {TrackCreateRequest} [trackCreateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trackCreate(trackCreateRequest?: TrackCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventLeaveRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trackCreate(trackCreateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [cursor] blog post cursor
     * @param {number} [limit] posts limit
     * @param {TrackDraftListFilterParameter} [filter] track filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trackDraftList(cursor?: number, limit?: number, filter?: TrackDraftListFilterParameter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trackDraftList(cursor, limit, filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trackGpx(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trackGpx(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} [cursor] blog post cursor
     * @param {number} [limit] posts limit
     * @param {TrackListFilterParameter} [filter] track filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trackList(cursor?: number, limit?: number, filter?: TrackListFilterParameter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trackList(cursor, limit, filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {TrackPersistRequest} [trackPersistRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trackPersist(trackPersistRequest?: TrackPersistRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackCreate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trackPersist(trackPersistRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trackPublish(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trackPublish(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trackRemove(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trackRemove(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trackUnpublish(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trackUnpublish(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id track id
     * @param {TrackUpdateRequest} [trackUpdateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trackUpdate(id: number, trackUpdateRequest?: TrackUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trackUpdate(id, trackUpdateRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {File} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trackUpload(body?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackCreate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trackUpload(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trackView(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Track>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trackView(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  }
};

/**
 * TrackApi - factory interface
 * @export
 */
export const TrackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TrackApiFp(configuration)
  return {
    /**
     *
     * @param {TrackCreateRequest} [trackCreateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackCreate(trackCreateRequest?: TrackCreateRequest, options?: any): AxiosPromise<EventLeaveRequest> {
      return localVarFp.trackCreate(trackCreateRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [cursor] blog post cursor
     * @param {number} [limit] posts limit
     * @param {TrackDraftListFilterParameter} [filter] track filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackDraftList(cursor?: number, limit?: number, filter?: TrackDraftListFilterParameter, options?: any): AxiosPromise<TrackList> {
      return localVarFp.trackDraftList(cursor, limit, filter, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackGpx(id: number, options?: any): AxiosPromise<File> {
      return localVarFp.trackGpx(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [cursor] blog post cursor
     * @param {number} [limit] posts limit
     * @param {TrackListFilterParameter} [filter] track filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackList(cursor?: number, limit?: number, filter?: TrackListFilterParameter, options?: any): AxiosPromise<TrackList> {
      return localVarFp.trackList(cursor, limit, filter, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {TrackPersistRequest} [trackPersistRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackPersist(trackPersistRequest?: TrackPersistRequest, options?: any): AxiosPromise<TrackCreate> {
      return localVarFp.trackPersist(trackPersistRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackPublish(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.trackPublish(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackRemove(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.trackRemove(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackUnpublish(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.trackUnpublish(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id track id
     * @param {TrackUpdateRequest} [trackUpdateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackUpdate(id: number, trackUpdateRequest?: TrackUpdateRequest, options?: any): AxiosPromise<void> {
      return localVarFp.trackUpdate(id, trackUpdateRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {File} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackUpload(body?: File, options?: any): AxiosPromise<TrackCreate> {
      return localVarFp.trackUpload(body, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackView(id: number, options?: any): AxiosPromise<Track> {
      return localVarFp.trackView(id, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * TrackApi - object-oriented interface
 * @export
 * @class TrackApi
 * @extends {BaseAPI}
 */
export class TrackApi extends BaseAPI {
  /**
   *
   * @param {TrackCreateRequest} [trackCreateRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public trackCreate(trackCreateRequest?: TrackCreateRequest, options?: AxiosRequestConfig) {
    return TrackApiFp(this.configuration).trackCreate(trackCreateRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [cursor] blog post cursor
   * @param {number} [limit] posts limit
   * @param {TrackDraftListFilterParameter} [filter] track filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public trackDraftList(cursor?: number, limit?: number, filter?: TrackDraftListFilterParameter, options?: AxiosRequestConfig) {
    return TrackApiFp(this.configuration).trackDraftList(cursor, limit, filter, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id track id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public trackGpx(id: number, options?: AxiosRequestConfig) {
    return TrackApiFp(this.configuration).trackGpx(id, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} [cursor] blog post cursor
   * @param {number} [limit] posts limit
   * @param {TrackListFilterParameter} [filter] track filter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public trackList(cursor?: number, limit?: number, filter?: TrackListFilterParameter, options?: AxiosRequestConfig) {
    return TrackApiFp(this.configuration).trackList(cursor, limit, filter, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TrackPersistRequest} [trackPersistRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public trackPersist(trackPersistRequest?: TrackPersistRequest, options?: AxiosRequestConfig) {
    return TrackApiFp(this.configuration).trackPersist(trackPersistRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id track id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public trackPublish(id: number, options?: AxiosRequestConfig) {
    return TrackApiFp(this.configuration).trackPublish(id, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id track id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public trackRemove(id: number, options?: AxiosRequestConfig) {
    return TrackApiFp(this.configuration).trackRemove(id, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id track id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public trackUnpublish(id: number, options?: AxiosRequestConfig) {
    return TrackApiFp(this.configuration).trackUnpublish(id, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id track id
   * @param {TrackUpdateRequest} [trackUpdateRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public trackUpdate(id: number, trackUpdateRequest?: TrackUpdateRequest, options?: AxiosRequestConfig) {
    return TrackApiFp(this.configuration).trackUpdate(id, trackUpdateRequest, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {File} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public trackUpload(body?: File, options?: AxiosRequestConfig) {
    return TrackApiFp(this.configuration).trackUpload(body, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id track id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackApi
   */
  public trackView(id: number, options?: AxiosRequestConfig) {
    return TrackApiFp(this.configuration).trackView(id, options).then((request) => request(this.axios, this.basePath));
  }
}


/**
 * TrackPhotoApi - axios parameter creator
 * @export
 */
export const TrackPhotoApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [cursor] blog post cursor
     * @param {number} [limit] posts limit
     * @param {number} [trackId] track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackPhotoList: async (cursor?: number, limit?: number, trackId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/track/photo/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (cursor !== undefined) {
        localVarQueryParameter['cursor'] = cursor;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (trackId !== undefined) {
        localVarQueryParameter['track_id'] = trackId;
      }


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} trackId
     * @param {File} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackPhotoUpload: async (trackId: number, image: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'trackId' is not null or undefined
      assertParamExists('trackPhotoUpload', 'trackId', trackId)
      // verify required parameter 'image' is not null or undefined
      assertParamExists('trackPhotoUpload', 'image', image)
      const localVarPath = `/track/photo/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)


      if (trackId !== undefined) {
        localVarFormParams.append('track_id', trackId as any);
      }

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }


      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  }
};

/**
 * TrackPhotoApi - functional programming interface
 * @export
 */
export const TrackPhotoApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TrackPhotoApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} [cursor] blog post cursor
     * @param {number} [limit] posts limit
     * @param {number} [trackId] track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trackPhotoList(cursor?: number, limit?: number, trackId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackPhotoList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trackPhotoList(cursor, limit, trackId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} trackId
     * @param {File} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async trackPhotoUpload(trackId: number, image: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackPhoto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.trackPhotoUpload(trackId, image, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  }
};

/**
 * TrackPhotoApi - factory interface
 * @export
 */
export const TrackPhotoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TrackPhotoApiFp(configuration)
  return {
    /**
     *
     * @param {number} [cursor] blog post cursor
     * @param {number} [limit] posts limit
     * @param {number} [trackId] track id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackPhotoList(cursor?: number, limit?: number, trackId?: number, options?: any): AxiosPromise<TrackPhotoList> {
      return localVarFp.trackPhotoList(cursor, limit, trackId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} trackId
     * @param {File} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    trackPhotoUpload(trackId: number, image: File, options?: any): AxiosPromise<TrackPhoto> {
      return localVarFp.trackPhotoUpload(trackId, image, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * TrackPhotoApi - object-oriented interface
 * @export
 * @class TrackPhotoApi
 * @extends {BaseAPI}
 */
export class TrackPhotoApi extends BaseAPI {
  /**
   *
   * @param {number} [cursor] blog post cursor
   * @param {number} [limit] posts limit
   * @param {number} [trackId] track id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackPhotoApi
   */
  public trackPhotoList(cursor?: number, limit?: number, trackId?: number, options?: AxiosRequestConfig) {
    return TrackPhotoApiFp(this.configuration).trackPhotoList(cursor, limit, trackId, options).then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} trackId
   * @param {File} image
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrackPhotoApi
   */
  public trackPhotoUpload(trackId: number, image: File, options?: AxiosRequestConfig) {
    return TrackPhotoApiFp(this.configuration).trackPhotoUpload(trackId, image, options).then((request) => request(this.axios, this.basePath));
  }
}


