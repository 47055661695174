import { dateTimeToMediumDate, stringToDateTime } from "@biketravel/solid-ui";
import { Component } from "solid-js";
import type { User } from '@biketravel/sdk';

export type UserSummaryProps = {
  user: User;
}

export const UserSummary: Component<UserSummaryProps> = (props) => (
  <div>
    Дата регистрации: {
    // @ts-ignore
    dateTimeToMediumDate(stringToDateTime(props.user.created_at))
  }
  </div>
);
