import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { withLoading } from "../decorator";

export type TrackPhotoUploadState = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<TrackPhotoUploadState["loading"]>(false);

const upload = async (id: number, upload: File) => withLoading(setLoading, async () => {
  await sdk.trackPhoto.trackPhotoUpload(id, upload);
});

type TrackPhotoUploadActions = {
  upload: typeof upload,
}

export const trackPhotoUploadStore = {
  loading,
  actions: {
    upload,
  }
} satisfies AppStore<TrackPhotoUploadState, TrackPhotoUploadActions>;
