import { Component } from "solid-js";
import { routeStore } from "@biketravel/state";
import { decodeRoute } from "@biketravel/map";
import type { TrackRoute } from "@biketravel/sdk";
import { SpinnerGlobal } from "@biketravel/solid-ui";
import { MapRoute, MapSolid } from "../map";
import { findRouteCenter } from "./utils";

export type TrackEditMapContainerProps = {
  route: TrackRoute
  onRouteUpdated(response: TrackRoute): void
}

export const TrackEditMapContainer: Component<TrackEditMapContainerProps> = (props) => {
  const decodedRoute = decodeRoute(props.route);
  const center = findRouteCenter(decodedRoute);

  const onRouteUpdatedCallback = (route: TrackRoute) => (
    props.onRouteUpdated(route)
  );

  return (
    <>
      <MapSolid center={center}>
        <MapRoute
          editable={true}
          onRouteUpdated={onRouteUpdatedCallback}
          currentRoute={props.route}
        />
      </MapSolid>
      <SpinnerGlobal
        style={{ top: '5.5rem' }}
        position='top-right'
        show={routeStore.loading()}
      />
    </>
  );
};
