import { hydrate, render } from "solid-js/web";
import { createHistory } from "@biketravel/solid-history";
import { createApp } from "./App";

const rootEl: HTMLElement | null = document.getElementById('app');
if (rootEl) {
    const fn = process.env.VITE_CLIENT ? render : hydrate;
    fn(() => createApp(createHistory(), [], window.__STATE__), rootEl);
} else {
    throw new Error(
        'Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got mispelled?',
    );
}
