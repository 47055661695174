import { Setter } from "solid-js";
import { captureError } from "./types";
import { AxiosError } from "axios";

export async function withLoading(state: Setter<boolean | undefined>, fn: () => Promise<any>) {
  state(true);

  try {
    return await fn();
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 400) {
        return error.response?.data.errors;
      }

      console.error(error.response?.data);
    }

    captureError(error);
  } finally {
    state(false);
  }
}

export async function withGeneric(fn: () => Promise<any>) {
  try {
    return await fn();
  } catch (error: AxiosError | unknown) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 400) {
        return error.response?.data.errors;
      }

      console.error(error.response?.data);
    }

    captureError(error);
  }
}