import { bem } from "classnames-bem";
import { Link } from "@biketravel/solid-router";
import { Component, JSX, ParentComponent } from "solid-js";
import { BemModifiers } from "@biketravel/solid-ui";

export type MenuProps = BemModifiers & {
  style?: JSX.CSSProperties
}

export const Menu: ParentComponent<MenuProps> = (props) => (
  <div class={bem('b-menu', props.modifiers)} style={props.style}>
    {props.children}
  </div>
);

export type MenuTitleProps = {
  title: string
}

export const MenuTitle: ParentComponent<MenuTitleProps> = ({ title, ...rest }) => (
  <div class='b-menu__title' {...rest}>
    {title}
  </div>
);

type MenuItemIcon = {
  icon?: JSX.Element;
}

const MenuText: ParentComponent = (props) => (
  <div class="b-menu-item__text">
    {props.children}
  </div>
)

export type MenuItemProps = {
  href?: string;
  to?: string;
  params?: object;
  query?: object;
  onClick?(): void;
} & MenuItemIcon;

export const MenuItem: ParentComponent<MenuItemProps> = (props) => {
  if (props.to || props.href) {
    return (
      <Link
        href={props.href}
        to={props.to}
        params={props.params}
        query={props.query}
        onClick={props.onClick}
        class={bem('b-menu-item__item', { icon: !!props.icon })}
      >
        {props.icon}
        <MenuText>
          {props.children}
        </MenuText>
      </Link>
    );
  }

  return (
    <div
      onClick={props.onClick}
      class={bem('b-menu-item__item', { icon: !!props.icon })}
    >
      {props.icon}
      <MenuText>
        {props.children}
      </MenuText>
    </div>
  );
};

export const MenuDivider: Component = () => (
  <div class='b-menu__divider'/>
);
