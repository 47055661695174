import { placeViewStore } from "@biketravel/state";
import { onMount, Show } from "solid-js";
import { useCurrentRoute } from "@biketravel/solid-router";
import { NotFoundPage } from "../system";
import { Title } from "@solidjs/meta";
import { AppBreadcrumbs, Comments } from "@biketravel/component";
import {
    BreadcrumbItem,
    BreadcrumbSymbol,
    H1,
    H3,
    Segment,
    SegmentContent,
    Spinner,
    Typeset
} from "@biketravel/solid-ui";

export const PlaceViewPage = () => {
    const currentRoute = useCurrentRoute<{
        place_id: number | string,
    }>();


    onMount(async () => {
        const id = parseInt(String(currentRoute().params.place_id), 10);
        if (placeViewStore.place()?.id === id) {
            return;
        }

        await placeViewStore.actions.fetch(id);
    });

    return (
        <Show when={!placeViewStore.loading()} fallback={<Spinner/>}>
            <Show when={placeViewStore.place()} fallback={<NotFoundPage/>}>
                <Title>{placeViewStore.place()?.name}</Title>
                <AppBreadcrumbs>
                    <BreadcrumbItem to='place_map'>
                        Места
                    </BreadcrumbItem>
                    <BreadcrumbSymbol/>
                    <BreadcrumbItem>
                        {placeViewStore.place()?.name}
                    </BreadcrumbItem>
                </AppBreadcrumbs>
                <H1>{placeViewStore.place()?.name}</H1>
                <Segment>
                    <SegmentContent>
                        <Typeset content={placeViewStore.place()?.description}/>
                    </SegmentContent>
                </Segment>
                <H3>Комментарии</H3>
                <Comments
                    ownerId={placeViewStore.place()!.id}
                    ownerType='place'
                />
            </Show>
        </Show>
    )
}