import { HomeAboutBlock, HomepageCategoryList, HomepageVideo } from "@biketravel/component";
import { Meta, Title } from "@solidjs/meta";

export const HomePage = () => (
    <>
        <Title>BikeTravel</Title>
        <Meta name="description" content="веломаршруты"/>
        <HomepageVideo/>
        <HomeAboutBlock/>
        <HomepageCategoryList/>
    </>
);
