import { CalendarEvent, onChangeDate } from "./types";
import { CalendarHeader } from "./CalendarHeader";
import { DateTime } from "luxon";
import { iterateYears } from "./utils";
import { FullCalendarDays } from "./FullCalendarDays";
import { Component, createSignal, mergeProps } from "solid-js";

export type FullCalendarProps = {
    yearsCount?: number
    selectedDate?: DateTime
    onChangeDate: onChangeDate,
    onClick?: (calendarEvent: CalendarEvent) => void
    events: { [key: string]: CalendarEvent[] }
}

export const FullCalendar: Component<FullCalendarProps> = (props) => {
    const newProps = mergeProps({
        yearsCount: 5,
        selectedDate: DateTime.now()
    }, props);

    const selectedDate = newProps.selectedDate.invalidReason
        ? DateTime.now()
        : newProps.selectedDate;

    const current = DateTime.now().set({
        second: 0,
        millisecond: 0,
        hour: 0,
        minute: 0,
    });

    const initialSelectedDate = selectedDate.set({
        second: 0,
        millisecond: 0,
        hour: 0,
        minute: 0,
    });

    const [
        selected,
        setSelected,
    ] = createSignal<DateTime>(initialSelectedDate);

    return (
        <div class='b-full-calendar'>
            <CalendarHeader
                years={iterateYears(selected(), newProps.yearsCount)}
                selected={selected}
                onChange={date => {
                    setSelected(date);
                    newProps.onChangeDate(date);
                }}
            />
            <FullCalendarDays
                onClick={newProps.onClick}
                events={newProps.events}
                today={current}
                selected={initialSelectedDate}/>
        </div>
    );
};
