import { H1, Spinner } from "@biketravel/solid-ui";
import { Title } from "@solidjs/meta";
import { CircleIcon } from "@biketravel/component";
import { FiClock } from "solid-icons/fi";
import { registrationConfirmStore } from "@biketravel/state";
import { useCurrentRoute, useNavigate } from "@biketravel/solid-router";
import { onMount } from "solid-js";

export const RegistrationConfirmPage = () => {
    const { navigate } = useNavigate();
    const getCurrentRoute = useCurrentRoute<{
        token: string;
    }>();
    const currentRoute = getCurrentRoute();

    onMount(async () => {
        const formErrors = await registrationConfirmStore.actions.registrationConfirm({
            token: currentRoute.params.token
        });
        if (formErrors) {
            navigate('auth_registration_fail');
        } else {
            navigate('home');
        }
    });

    return (
        <>
            <Title>Проверка данных</Title>
            <CircleIcon icon={<FiClock/>}/>
            <H1 modifiers='center'>Проверка данных</H1>
            <Spinner modifiers='center' show={registrationConfirmStore.loading()}/>
        </>
    );
}