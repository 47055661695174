import type { EventUpdate } from '@biketravel/sdk';
import { createSignal } from "solid-js";
import { AppStore, captureError, LoadingType, sdk } from "..";
import { AxiosError } from "axios";

export type EventUpdateStore = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<EventUpdateStore["loading"]>(false);

const updateAction = async (
  id: number,
  input: EventUpdate,
) => {
  setLoading(true);

  try {
    const resp = await sdk.event.eventUpdate(id, input);

    setLoading(false);

    return resp.data;
  } catch (error: AxiosError | unknown) {
    setLoading(false);

    if (error instanceof AxiosError) {
      return error.response?.data.errors;
    }

    captureError(error);
  }
}

type EventUpdateActions = {
  update: typeof updateAction,
}

export const eventUpdateStore = {
  loading,
  actions: {
    update: updateAction,
  }
} satisfies AppStore<EventUpdateStore, EventUpdateActions>;