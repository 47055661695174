import { Component, JSX, Show } from "solid-js";
import cls from './Empty.module.scss';

type EmptyProps = {
    icon: JSX.Element;
    title: string;
    text?: string;
}

export const Empty: Component<EmptyProps> = (props) => (
    <div class={cls.empty}>
        <div class={cls.empty__icon}>{props.icon}</div>
        <div class={cls.empty__title}>{props.title}</div>
        <Show when={props.text}>
            <div class={cls.empty__text}>{props.text}</div>
        </Show>
    </div>
)