import { createSignal } from "solid-js";
import { AppStore, LoadingType, sdk } from "..";
import { EventCreate } from "@biketravel/sdk";
import { withLoading } from "../decorator";

export type EventCreateStore = LoadingType;

const [
  loading,
  setLoading,
] = createSignal<EventCreateStore["loading"]>(false);

const create = async (input: EventCreate) => withLoading(setLoading, async () => {
  const {
    data,
  } = await sdk.event.eventCreate(input);

  return data;
})

type EventCreateActions = {
  create: typeof create,
}

export const eventCreateStore = {
  loading,
  actions: {
    create,
  }
} satisfies AppStore<EventCreateStore, EventCreateActions>;