import { Component, createSignal, For, Show } from "solid-js";
import { Image, InfinityScroll, InfinityScrollProps } from "@biketravel/solid-ui";
import { TrackPhotoLightbox } from "./TrackPhotoLightbox";
import type { TrackPhoto } from "@biketravel/sdk";

type TrackPhotoListType = InfinityScrollProps & {
  photos: TrackPhoto[];
  alt: string;
}

export const TrackPhotoList: Component<TrackPhotoListType> = (props) => {
  const [
    index,
    setIndex,
  ] = createSignal(-1);

  return (
    <>
      <div class="b-track-photo__grid">
        <InfinityScroll onVisible={props.onVisible}>
          <For each={props.photos} fallback={'Фотографии отсутствуют'}>
            {(photo, i) => (
              <Image
                onClick={() => setIndex(i())}
                class="b-track-photo__image"
                src={photo.path}
                width={120}
                height={120}
                alt={props.alt}
              />
            )}
          </For>
        </InfinityScroll>
      </div>
      <Show when={props.photos.length > 0}>
        <TrackPhotoLightbox
          onClose={() => setIndex(-1)}
          photos={props.photos}
          index={index}
          height={1024}
          width={1280}
        />
      </Show>
    </>
  )
}