import cls from './Footer.module.scss';
import { Logo } from './Logo';
import { DateTime } from "luxon";
import { MenuItem, MenuTitle } from "@biketravel/solid-ui";

export const Footer = () => (
  <div class={cls.footer}>
    <div class={cls.footer__container}>
      <div class={cls.footer__top}>
        <Logo class={cls.footer__logo}/>
      </div>
      <div class={cls.footer__middle}>
        <div class={cls.footer__menu}>
          <MenuTitle title='Новый маршрут'/>
          <MenuItem to="track_create">
            Создать маршрут
          </MenuItem>
          <MenuItem to="track_upload">
            Загрузить GPX
          </MenuItem>
        </div>
        <div class={cls.footer__menu}>
          <MenuTitle title='Маршруты'/>
          <MenuItem to="track_list">
            Маршруты
          </MenuItem>
          <MenuItem to="place_map">
            Точки интереса
          </MenuItem>
        </div>
        <div class={cls.footer__menu}>
          <MenuTitle title='Поездки'/>
          <MenuItem to="event_list">
            Поездки
          </MenuItem>
          <MenuItem to="event_calendar">
            Календарь поездок
          </MenuItem>
        </div>
        <div class={cls.footer__menu}>
          <MenuTitle title='О проекте'/>
          <MenuItem to="blog">Блог</MenuItem>
          <MenuItem to="about">О проекте</MenuItem>
        </div>
      </div>
    </div>
    <div class={cls.footer__bottom}>
      &copy; {DateTime.now().year} BikeTravel.
    </div>
  </div>
);