import { bem } from "classnames-bem";
import { Component } from "solid-js";

type TagProps = {
    value: string;
    onRemove?: () => void;
    onClick?: () => void;
}

export const Tag: Component<TagProps> = (props) => (
    <div
        class={bem("b-tag", { pointer: !!props.onClick })}
        onClick={() => props.onClick && props.onClick()}>
        <div class="b-tag__content">{props.value}</div>
        {props.onRemove && (
            <div class={bem("b-tag__remove")} onClick={() => props.onRemove!()}/>
        )}
    </div>
);
