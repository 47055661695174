import { Button, Form, FormRow, InputText, Label, Spinner, useForm, ValidationError } from "@biketravel/solid-ui";
import type { LoginRequest } from "@biketravel/sdk";
import { loginStore } from "@biketravel/state";
import { Component } from "solid-js";

type SignInFormProps = {
  onSuccess(): void;
}

export const SignInForm: Component<SignInFormProps> = (props) => {
  const {
    errors,
    setErrors,
    values,
    onChange,
  } = useForm<LoginRequest>({
    email: '',
    password: ''
  });

  const onSubmit = async () => {
    const formErrors = await loginStore.actions.login(values());
    if (formErrors) {
      setErrors(formErrors);
      return;
    }

    props.onSuccess();
  };

  return (
    <Form onSubmit={() => onSubmit()}>
      <FormRow modifiers='margin-bottom'>
        <Label for="email" required>Адрес электронной почты</Label>
        <InputText
          type='text'
          id='email'
          name='email'
          value={values().email}
          onChange={({ value }) => onChange('email', value)}
        />
        <ValidationError path='email' errors={errors()}/>
      </FormRow>
      <FormRow modifiers='margin-bottom'>
        <Label for="password" required>Пароль</Label>
        <InputText
          type='password'
          id='password'
          name='password'
          value={values().password}
          onChange={({ value }) => onChange('password', value)}
        />
        <ValidationError path='password' errors={errors()}/>
      </FormRow>
      <FormRow>
        <Button modifiers={['primary', 'block']} type="submit">
          Авторизоваться
        </Button>
      </FormRow>
      <Spinner overlay show={loginStore.loading()}/>
    </Form>
  );
}