import { trackUpdateStore, trackViewStore } from "@biketravel/state";
import { batch, createSignal, onMount, Show } from "solid-js";
import { Meta, Title } from "@solidjs/meta";
import { AppBreadcrumbs, TrackEditMapContainer } from "@biketravel/component";
import { toast } from "solid-toast";
import { useCurrentRoute, useNavigate } from "@biketravel/solid-router";
import type { TrackRoute } from "@biketravel/sdk";
import { BreadcrumbItem, BreadcrumbSymbol, Button, Spinner, SpinnerGlobal } from "@biketravel/solid-ui";

import cls from './TrackUpdatePage.module.scss';

export const TrackUpdatePage = () => {
    const currentRoute = useCurrentRoute<{
        track_id: number;
    }, never>();
    const { navigate } = useNavigate();

    const [
        hasRoute,
        setHasRoute,
    ] = createSignal(false);

    const [
        route,
        setRoute,
    ] = createSignal<TrackRoute>();

    onMount(async () => {
        await trackViewStore.actions.fetch(currentRoute().params.track_id);
        setRoute(trackViewStore.track()!.track_route);
    });

    const save = async () => {
        const trackRoute = route();
        if (!trackRoute) {
            return;
        }

        const errors = await trackUpdateStore.actions.update(trackViewStore.track()!.id, {
            route: trackRoute,
        });

        if (errors) {
            console.log(errors);
            return
        }

        toast.success('Маршрут сохранен');
        navigate('track_view', {
            track_id: trackViewStore.track()!.id,
        });
    };

    return (
        <>
            <Meta>
                <Title>Редактирование маршрута</Title>
            </Meta>
            <Show
                when={!trackViewStore.loading() && trackViewStore.track() && route()}
                fallback={<Spinner show={true}/>}
            >
                <div class={cls.header}>
                    <div></div>
                    <div>
                        <AppBreadcrumbs>
                            <BreadcrumbItem to='track_list'>
                                Маршруты
                            </BreadcrumbItem>
                            <BreadcrumbSymbol/>
                            <BreadcrumbItem to="track_view" params={{ track_id: trackViewStore.track()!.id }}>
                                {trackViewStore.track()!.name || `Черновик маршрута #${trackViewStore.track()!.id}`}
                            </BreadcrumbItem>
                            <BreadcrumbSymbol/>
                            <BreadcrumbItem>
                                Редактирование маршрута
                            </BreadcrumbItem>
                        </AppBreadcrumbs>
                    </div>
                    <div></div>
                </div>
                <div class={cls.container}>
                    <TrackEditMapContainer
                        route={route()!}
                        onRouteUpdated={response => {
                            batch(() => {
                                setRoute(response);
                                setHasRoute(true);
                            });
                        }}
                    />
                    <SpinnerGlobal show={trackUpdateStore.loading()}/>
                    <Show when={hasRoute() && !trackUpdateStore.loading()}>
                        <div class='b-track-create__text'>
                            <Button
                                modifiers='primary'
                                onClick={() => save()}>
                                Сохранить
                            </Button>
                        </div>
                    </Show>
                </div>
            </Show>
        </>
    );
};
